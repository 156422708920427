import { vueQueryClient } from '@app/utils/vueQueryClient.ts'
import { DASHBOARD_ROUTE, REPORTING_ROUTES_ARRAY, REPORTING_ROUTE_NAMES } from '@shared/data/constants'
import { computed } from 'vue'
import type { MaybeRef } from '@vueuse/core'
import { Level4Route } from '@/level4/router/level4.ts'
import usePermissions from '@/auth/composables/usePermissions.ts'
import type { UserPrivilegesResponse } from '@/privileges/services/UserPrivilegesService'
import UserPrivilegesService from '@/privileges/services/UserPrivilegesService'

const REFETCH_INTERVAL = 30000
const QUERY_KEY = 'current-user-privileges'

function getAllowedReports(privilegesData: UserPrivilegesResponse) {
  const reportingPrivileges = Object.entries(privilegesData?.page ?? {})
    .filter(([pageName]) => REPORTING_ROUTES_ARRAY.includes(pageName))

  const enabledReports = reportingPrivileges.filter(privilege => privilege[1])
  const allReportNamesFromPrivileges = reportingPrivileges.map(privilege => privilege[0])
  const enabledReportNamesFromPrivileges = enabledReports.map(privilege => privilege[0])

  return REPORTING_ROUTES_ARRAY
    .concat()
    .filter((reportRouteName: string) => enabledReportNamesFromPrivileges.includes(reportRouteName) || !allReportNamesFromPrivileges.includes(reportRouteName))
}

export function useUserPrivileges(clientName: MaybeRef<string | undefined>) {
  const clientNameValue = computed(() => unref(clientName))
  const router = useRouter()
  const { isLevel4 } = usePermissions()

  const privilegesQueryKey = computed(() => [QUERY_KEY, clientNameValue.value])
  const { isFetching: isPrivilegesFetching, data: privilegesData } = useQuery({
    queryKey: privilegesQueryKey,
    queryFn: async () => {
      if (!clientNameValue.value) { return {} as UserPrivilegesResponse }
      return UserPrivilegesService.loadPrivileges(clientNameValue.value)
    },
    refetchInterval: REFETCH_INTERVAL,
    enabled: computed(() => !!clientNameValue.value),
  })

  const allowedReports = computed(() => {
    return privilegesData.value && getAllowedReports(privilegesData.value)
  })

  function isRouteEnabledByName(routeName: string): boolean {
    return privilegesData.value?.page?.[routeName.toString()] ?? true
  }

  watch(privilegesData, async () => {
    // open different page if current page is not allowed
    const routeName = router.currentRoute.value?.name?.toString()
    if (allowedReports.value?.length && routeName && REPORTING_ROUTES_ARRAY.includes(routeName) && !isRouteEnabledByName(routeName)) {
      await router.push({ name: allowedReports.value.includes(REPORTING_ROUTE_NAMES?.UPLIFT) ? REPORTING_ROUTE_NAMES.UPLIFT : (allowedReports.value[0] ?? DASHBOARD_ROUTE) })
      return
    }

    if (privilegesData.value && router.currentRoute.value?.name && !isRouteEnabledByName(router.currentRoute.value.name.toString())) {
      await router.push({ name: isLevel4.value ? Level4Route.LEVEL4 : DASHBOARD_ROUTE })
    }
  })

  return {
    isRouteEnabledByName,
    allowedReports,
    isPrivilegesFetching,
  }
}

export async function useUserPrivilegesForRouter(clientName: MaybeRef<string | undefined>) {
  const clientNameValue = computed(() => unref(clientName))
  const data = await vueQueryClient.ensureQueryData({ queryKey: [QUERY_KEY, clientNameValue], queryFn: async () => {
    if (!clientNameValue.value) { return {} as UserPrivilegesResponse }
    return UserPrivilegesService.loadPrivileges(clientNameValue.value)
  } })

  const allowedReports = computed(() => {
    return data && getAllowedReports(data)
  })

  function isRouteEnabledByName(routeName: string): boolean {
    return data?.page?.[routeName] ?? true
  }

  return {
    isRouteEnabledByName,
    allowedReports,
  }
}
