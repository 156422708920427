<script setup lang="ts">
import { store } from '@app/store'
import { useDialog } from 'naive-ui'
import type { DialogReactive } from 'naive-ui/es/dialog/src/DialogProvider'
import { usePermissions } from '@/auth/composables/usePermissions'

const pipelines = computed(() => store.getters['pipeline/pipelines'])
const { isLevel4 } = usePermissions()
const dialog = useDialog()
const dialogReactive = ref<DialogReactive>()
onMounted(() => {
  watch([pipelines, isLevel4], ([_pipelines, _isLevel4]) => {
    if (_isLevel4 && !_pipelines?.length && dialog && !dialogReactive.value) {
      dialogReactive.value = dialog.warning({
        title: 'The client is not supported',
        content: 'Client has no OfferFit email pipeline. Please contact support to get it set up.',
        positiveText: 'OK',
        onPositiveClick: () => {
          window.location.pathname = '/'
        },
        onClose: () => {
          window.location.pathname = '/'
        },
        maskClosable: false,
      })
    }
  }, { immediate: true })
})
</script>

<template>
  <div />
</template>
