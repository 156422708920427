<template>
  <PageWrapperL4>
    <div class="flex justify-center items-center">Form loading error</div>
  </PageWrapperL4>
</template>

<script lang="ts">
import PageWrapperL4 from '@/level4/components/layout/PageWrapperL4.vue'

export default defineComponent({
  name: 'FormError',
  components: {
    PageWrapperL4
  }
})
</script>
