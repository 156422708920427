import AppSettings from '@app/AppSettings'
import router, { initializeLevelRoutes } from '@router/index'
import { usePermissions } from '@/auth/composables/usePermissions'

export function install({ app }) {
  const { level4Enabled, level3Enabled } = usePermissions()
  const { isLevel4Route, isLevel3Route } = AppSettings

  if (level4Enabled.value && isLevel4Route.value) { initializeLevelRoutes(4) }
  if (level3Enabled.value && isLevel3Route.value) { initializeLevelRoutes(3) }

  app.use(router)
}
