<template>
  <ul>
    <li v-for="(item, idx) in promotionData" :key="idx">{{ slugToSentenceCase(item) }}</li>
  </ul>
</template>

<script lang="ts">
import { RelationModel } from '@/deployments/models/server/DeploymentModel'
import { slugToSentenceCase } from '@shared/utils/transformHelpers'

export default defineComponent({
  name: 'DeploymentRender',
  props: {
    promotion: {
      type: Object as PropType<any>
    }
  },
  setup(props) {
    const promotionData: ComputedRef<Array<string> | undefined> = computed(() => {
      if (!props.promotion) return
      return (
        props.promotion?.pipelines?.data
          ?.map((item: RelationModel) => `pipeline: ${item?.name}`)
          .concat(props.promotion?.usecases?.data?.map((item: any) => `usecase: ${item?.name}`)) ||
        props.promotion.promotion?.concat(props.promotion.usecases)
      )
    })
    return {
      promotionData,
      slugToSentenceCase
    }
  }
})
</script>


