import { useTitle } from '@vueuse/core'
import type { RouteLocationMatched } from 'vue-router'

import router from '@router/index'

const title = useTitle('Loading...', { titleTemplate: '%s | OfferFit Portal' })

export function usePageTitle() {
  const titleRef = ref('')

  const getRouteName = () => {
    const params = Object.entries(router.currentRoute.value.params)
    return router.currentRoute.value.matched
      .map((item: RouteLocationMatched) => {
        let routeName = (item.meta?.title ?? item.name ?? '').toString()
        params.forEach(([key, value]) => {
          routeName = routeName.replaceAll(`:${key}`, Array.isArray(value) ? value[0] : value)
        })

        return routeName
      })
      .reverse()
      .join(' — ')
  }

  const getTitle = () => {
    return titleRef.value || getRouteName()
  }

  const updateTitle = () => {
    title.value = getTitle()
  }

  updateTitle()

  watch(() => [router.currentRoute.value], updateTitle)
  watch(titleRef, updateTitle)

  return {
    title: titleRef,
  }
}
