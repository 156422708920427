import { GetterTree } from 'vuex'

import { ClientModel } from '@/clients/models/server/ClientModel'
import { AppState } from '@app/store/app'
import { ClientStateType } from '@/clients/store/state'

const getters: GetterTree<ClientStateType, AppState> = {
  client(state: ClientStateType): ClientModel | null {
    return state.client
  }
}
export default getters
