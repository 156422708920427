import AppSettings from '@app/AppSettings'
import { store } from '@app/store'
import {
  CLIENT_SELECT_ROUTE,
  NO_ACCOUNT_ROUTE,
  REPORTS_ROUTE,
  TERMS_OF_SERVICE_ROUTE,
} from '@shared/data/constants'
import { debug } from '@shared/utils/logger'
import type { Router } from 'vue-router'
import { usePermissions } from '@/auth/composables/usePermissions'

export function defineMiddleware(router: Router) {
  router.beforeEach(async (to) => {
    debug('Middleware: isAuthorized')
    if (
      to.meta.public
      || to.name === TERMS_OF_SERVICE_ROUTE
      || to.name === CLIENT_SELECT_ROUTE
      || to.name === NO_ACCOUNT_ROUTE
    ) { return }

    const {
      isAIEorAdmin,
      level3Enabled,
      level4Enabled,
      isEP,
      isDefaultRole,
    } = usePermissions()

    const myClients = store.getters['user/clients'] || []
    const allClients = store.getters['user/allClients'] || []

    // if user has no role let's open NO_ACCOUNT_ROUTE
    if (isDefaultRole.value) {
      debug('Middleware: isAuthenticated isDefaultRole -> NO_ACCOUNT_ROUTE')
      return { name: NO_ACCOUNT_ROUTE }
    }

    // if user has no any clients let's open the NO_ACCOUNT_ROUTE
    if (!isEP.value && !myClients.length && !allClients.length) {
      debug('Middleware: isAuthorized no clients -> NO_ACCOUNT_ROUTE')
      return { name: NO_ACCOUNT_ROUTE }
    }

    const { isReader } = usePermissions()
    if (isReader.value && !to.meta.roles?.includes('reader')) {
      debug('Middleware: Redirecting reader to reports')
      return { name: REPORTS_ROUTE }
    }

    /**
     * BOUNCE OUTSIDERS BY LEVEL
     * These bounces are triggered with native window.location.pathname redirection because
     * Routes from other levels are not registered. A refresh will register the correct paths.
     */
    const level4SafeBounceRoute = '/app'
    if (to.path !== level4SafeBounceRoute && AppSettings.isRouteInLevel3(to.path) && !level3Enabled.value) {
      debug('Middleware: isAuthorized -> level4SafeBounceRoute')
      window.location.pathname = level4SafeBounceRoute
      return false
    }

    const level3SafeBounceRoute = '/'
    if (to.path !== level3SafeBounceRoute && AppSettings.isRouteInLevel4(to.path) && !level4Enabled.value) {
      debug('Middleware: isAuthorized -> level3SafeBounceRoute')
      window.location.pathname = level3SafeBounceRoute
      return false
    }

    // Redirect based on client existence if level 3 features are enabled
    if (level3Enabled.value) {
      // if there's no existing clients and the user has a role that can create a client, redirect
      //  to the client select route (this has a link to creating a client and provides better context for the user)
      if (isAIEorAdmin.value && !allClients.length) {
        debug('Middleware: isAuthorized -> CLIENT_SELECT_ROUTE')
        return { name: CLIENT_SELECT_ROUTE }
      }
    }
  })
}
