import type { ChannelEvent, EventChannel } from '@shared/data/constants'
import {
  ActivationEvent,
  ConversionEvent,
  EventTypeKey,
} from '@shared/data/constants'

import {
  ChannelActivationEventMap,
  ChannelEngagementEventMap,
  ChannelPenaltyEventMap,
  EventSequences,
  channelEventsMap,
} from '@shared/data/mapAndLists'
import { useUseCaseConfig } from '@/usecases/composables/useUseCaseConfig'

import type {
  ChannelEventsSequence,
  EventItemModel,
  EventsTableSequence,
  PerChannelTableDataModel,
} from '@/usecases/models/EventsModel'

import type { EventSequenceConfigModel } from '@/usecases/models/UseCaseConfigModel'

// helpers
export function returnChannelListFromEvents(item: ChannelEvent) {
  for (const [key, value] of channelEventsMap) {
    if (value.includes(item)) {
      return key
    }
  }
}

export function getSequencePerChannel(channel: EventChannel) {
  return {
    [EventTypeKey.ACTIVATION]: ChannelActivationEventMap.get(channel),
    [EventTypeKey.ENGAGEMENT]: ChannelEngagementEventMap.get(channel),
    [EventTypeKey.PENALTY]: ChannelPenaltyEventMap.get(channel),
  }
}

export const ExposedChannelEvents = EventSequences.reduce(
  (result: Map<EventChannel, ChannelEvent[]>, item: ChannelEventsSequence) => {
    for (let i = 0; i < item.sequence.length; i++) {
      const sequenceElement = item.sequence[i]
      const hiddenEvents: ChannelEvent[] = [ActivationEvent.RECOMMENDATION_SENT, ConversionEvent.CONVERSION]
      if (!hiddenEvents.includes(sequenceElement)) {
        if (!result.has(item.channel)) {
          result.set(item.channel, [])
        }
        result.get(item.channel)?.push(sequenceElement)
      }
    }

    const penaltyEvent = ChannelPenaltyEventMap.get(item.channel)
    if (penaltyEvent) {
      result.get(item.channel)?.push(penaltyEvent)
    }

    return result
  },
  new Map<EventChannel, ChannelEvent[]>(),
)

export function useEvents() {
  const {
    getUseCaseConfig,
    createdEventsList,
    eventsPerChannelTableData,
    updateEventSequence,
    deleteEvent: deleteEventMutation,
    updateEventSequenceRequiredConversionEvent,
  } = useUseCaseConfig()

  const deleteEvent = async (event: EventItemModel) => {
    deleteEventMutation(event)
    await updateEventSequenceInConfig()
  }

  const saveRequiredConversionEventToConfig = async (payload: { value: string, channel: EventChannel }) => {
    updateEventSequenceRequiredConversionEvent({
      value: payload.value,
      channel: payload?.channel,
    })
  }

  const returnSequenceForConfig = (sequence: EventsTableSequence) => {
    const sequenceArray = []
    if (createdEventsList.value?.includes(sequence.activation)) {
      sequenceArray.push(sequence.activation)
    }
    sequence.engagement?.forEach((item: ChannelEvent) => {
      if (createdEventsList.value?.includes(item)) {
        sequenceArray.push(item)
      }
    })
    return sequenceArray
  }

  const updateEventSequenceInConfig = async () => {
    const payload = eventsPerChannelTableData.value?.map((item: PerChannelTableDataModel) => {
      return {
        channel: item.channel,
        sequence: returnSequenceForConfig(item.sequence) as string[],
        required_event_for_conversion: item.requiredConversionEvent,
      } as EventSequenceConfigModel
    })
    await updateEventSequence(payload)
  }

  const getRequiredEventForConversionByChannel = (channel: EventChannel) => {
    return getUseCaseConfig.value?.training?.event_sequences.find((item: EventSequenceConfigModel) => item.channel === channel)
      ?.required_event_for_conversion
  }

  const eventHasBeenCreated = (event?: ChannelEvent) => {
    return !!event && createdEventsList.value?.includes(event)
  }

  return {
    eventHasBeenCreated,
    deleteEvent,
    returnSequenceForConfig,
    saveRequiredConversionEventToConfig,
    getRequiredEventForConversionByChannel,
    updateEventSequenceInConfig,
  }
}

export type EventsReactive = ReturnType<typeof useEvents>
