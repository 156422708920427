import AppSettings from '@app/AppSettings'

import { DataType, FEATURES_CONFIG_FIELD, Features } from '@shared/data/constants'
import { FEATURE_OPERATION_TRANSLATION } from '@shared/data/mapAndLists'
import { getReactiveNullStringObject } from '@shared/utils/vueUtils'
import { store } from '@app/store'
import type {
  NullStringObject,
  OPERATION_TYPE,
  StoreDataChangePayload,
  StringNumberKeyValuePair,
  TimeDetails,
} from '@shared/utils/Types'
import Global from '@shared/utils/global'
import { getExistingValueFromArguments, validateDataStringByType } from '@shared/utils/helpers'
import { getStandardizedString } from '@shared/utils/transformHelpers'
import { usePipelineConfig } from '@/data-assets/utils/config'
import { convertModelExpressionToConfigExpression, getComparatorsConfigModelsFromExpression } from '@/filtering/utils/filters'
import type { FeatureStatisticsModel } from '@/data-assets/models/FeatureStatisticsModel'
import type {
  AggPayload,
  BucketTimePayload,
  DefaultFeature,
  FeatureType,
  FeatureTypePayload,
  FormattedFeature,
  IdentityPayload,
  NewFeaturePayload,
  OrdinalOneHotPayload,
  RatioPayload,
  TimeSincePayload,
  defaultCreateFeatureWrapper,
} from '@/data-assets/models/FeaturesModel'
import type {
  CommonFeatureFields,
  ComparatorLogic,
  FilterConfigMatchingExpression,
  LogicConfigModel,
  RootFilterConfigExpression,
} from '@/filtering/models/FilterModel'
import type { JobHistoryModel } from '@/dags/models/server/JobHistoryModel'
import BigQueryService from '@/data-assets/services/BigQueryService'
import FeatureCalculatingTaskService from '@/dags/services/tasks/FeatureCalculatingTaskService'
import FeatureStatisticsTaskService from '@/dags/services/tasks/FeatureStatisticsTaskService'
import K8TaskService from '@/dags/services/tasks/K8TaskService'
import { reconciledFilters, reconciledTypeSpecific } from '@/data-assets/utils/defaultReconciliation'

const isFeaturesStatisticsRequestPending: Ref<boolean> = ref<boolean>(false)
const isFeaturesCalculationRequestPending: Ref<boolean> = ref<boolean>(false)

const featuresStatisticsData: Ref<FeatureStatisticsModel[] | null> = ref<FeatureStatisticsModel[] | null>(null)
const featuresPreviewData: Ref<null | string[][]> = ref<null | string[][]>(null)

const operations: FeatureType[] = [
  Features.AGGREGATION,
  Features.BOOLEAN,
  Features.COUNT,
  Features.RATIO,
  Features.TIME_SINCE,
  Features.BUCKET_TIME,
  Features.IDENTITY,
  Features.ONE_HOT,
  Features.ORDINAL,
]

export function getTableFeatureCount(typeSpecific: FeatureTypePayload, daysBack: Array<number | string>, type: FeatureType) {
  if (type === Features.AGGREGATION) { return ((typeSpecific as AggPayload).operation?.length || 0) * (daysBack?.length || 1) }

  if (type === Features.BUCKET_TIME) { return (typeSpecific as BucketTimePayload).buckets?.length || daysBack?.length || 0 }

  if (type === Features.TIME_SINCE) { return 1 }
  if (type === Features.RATIO) { return 1 }
  if (type === Features.IDENTITY) { return 1 }
  if (type === Features.ORDINAL) { return 1 }
  if (type === Features.ONE_HOT) { return (typeSpecific as OrdinalOneHotPayload).encoding_order?.length || 0 }

  return daysBack?.length || 0
}

export function useEngineeringFeatures(useGlobalState: boolean = true, assetKeyRef?: Ref, readOnly: boolean = false) {
  const { getPipelineFeatureConfig, currentDataAssetWrapper, filteringFeatureAssetsForCurrentAsset } = usePipelineConfig(
    useGlobalState,
    assetKeyRef,
    readOnly,
  )

  const featuresCpuLimit: Ref<string> = ref<string>('')
  const featuresCpuLimitError = getReactiveNullStringObject()

  const featuresMemoryLimit: Ref<string> = ref<string>('')
  const featuresMemoryLimitError = getReactiveNullStringObject()

  const featuresRetries: Ref<number | string> = ref<number>(3)
  const featuresRetriesError = getReactiveNullStringObject()

  const featuresTimeBetweenRetries: Ref<number | string> = ref<number>(3)
  const featuresTimeBetweenRetriesError = getReactiveNullStringObject()

  const featuresProcessingStartTimeDetails: Ref<TimeDetails> = ref<TimeDetails>({
    time: '00:00',
    dayPart: 'AM',
  } as TimeDetails)

  const allFeatureNameList: ComputedRef<string[] | undefined> = computed<string[] | undefined>(() => {
    const result: string[] = []
    const allAssetsMap = getPipelineFeatureConfig.value?.assets || {}

    const assetFeatureObjects = Object.keys(allAssetsMap).map((assetName: string) => {
      return allAssetsMap[assetName]
    })

    for (let i = 0; i < assetFeatureObjects.length; i++) {
      const assetFeatureObject = assetFeatureObjects[i]
      const localKeyList = getFeatureKeyList(assetFeatureObject)
      for (const featureTypeKey of localKeyList) {
        if (assetFeatureObject && assetFeatureObject[featureTypeKey]?.features) {
          const assetFeatures = assetFeatureObject[featureTypeKey]?.features || {}

          for (const featureName of Object.keys(assetFeatures)) {
            const typeSpecific = reconciledTypeSpecific(
              featureTypeKey,
              assetFeatures[featureName],
              assetFeatureObject[featureTypeKey]?.defaults,
            )

            if (assetFeatures[featureName]) {
              if (featureTypeKey === Features.AGGREGATION) {
                const operations: string[] | undefined = (typeSpecific as AggPayload)?.operation?.map((op: OPERATION_TYPE) => {
                  return FEATURE_OPERATION_TRANSLATION.get(op) || op
                })
                if (operations && assetFeatures[featureName].days_back) {
                  for (const operation of operations) {
                    for (const daysBack of assetFeatures[featureName].days_back) { result.push(`${operation}_${featureName}_${daysBack}d`) }
                  }
                }
              }

              if (featureTypeKey === Features.COUNT || featureTypeKey === Features.BOOLEAN) { assetFeatures[featureName]?.days_back?.forEach((day: number) => result.push(`${featureName}_${day}d`)) }

              if (featureTypeKey === Features.BUCKET_TIME) {
                const byBucket: string[] = []

                const buckets: number[] | null | undefined = (typeSpecific as BucketTimePayload).buckets
                const bucketsLength: number = buckets?.length || 0

                if (buckets) {
                  for (let i = 0; i < bucketsLength; i++) {
                    const currentBucket: number = buckets[i]
                    const nextBucketIndex: number = i < bucketsLength - 1 ? i + 1 : 0
                    const nextBucket: number = buckets[nextBucketIndex]

                    if (currentBucket && nextBucket) { byBucket.push(`${featureName}_${currentBucket}_to_${nextBucket}`) }
                  }
                }

                if (assetFeatures[featureName].days_back) {
                  for (const bucket of byBucket) {
                    for (const daysBack of assetFeatures[featureName].days_back) { result.push(`${bucket}_${daysBack}d`) }
                  }
                }
              }

              if (
                featureTypeKey === Features.TIME_SINCE
                || featureTypeKey === Features.IDENTITY
                || featureTypeKey === Features.RATIO
                || featureTypeKey === Features.ORDINAL
              ) { result.push(featureName) }

              if (featureTypeKey === Features.ONE_HOT) {
                ;(typeSpecific as OrdinalOneHotPayload).encoding_order.forEach((item: string) =>
                  result.push(getStandardizedString(`${featureName}_${item}`, false, false)),
                )
              }
            }
          }
        }
      }
    }

    return result
  })

  const getCountPerFeature = (feature: FeatureTypePayload, type: FeatureType) => {
    if (type === Features.AGGREGATION) { return ((feature as AggPayload).operation?.length || 0) * (feature.days_back?.length || 1) }

    if (type === Features.BUCKET_TIME) { return (feature as BucketTimePayload).buckets?.length || feature.days_back?.length || 0 }

    if (type === Features.TIME_SINCE) { return 1 }
    if (type === Features.RATIO) { return 1 }
    if (type === Features.IDENTITY) { return 1 }
    if (type === Features.ONE_HOT) { return 1 }
    if (type === Features.ORDINAL) { return 1 }

    return feature.days_back?.length || 0
  }

  const assetFeatureCountMap: ComputedRef<StringNumberKeyValuePair> = computed<StringNumberKeyValuePair>(() => {
    const getAssetFeatureCount = (assetConfig: any) => {
      const nonFeatureKeys: string[] = ['mapping', 'compute_layer', 'mapping_type', 'days_back']
      const keys: string[] = Object.keys(assetConfig).filter((key: string) => !nonFeatureKeys.includes(key))
      let total: number = 0
      keys.forEach((key: string) => {
        let count: number = 0
        if (!assetConfig[key]?.features) { return 0 }
        Object.values(assetConfig[key]?.features)?.forEach((feature: any) => {
          count += getCountPerFeature(feature as FeatureTypePayload, key as FeatureType)
        })
        total += count
      })
      return total
    }

    const map: StringNumberKeyValuePair = {}
    Object.keys(getPipelineFeatureConfig.value?.assets || {}).forEach((asset: string) => {
      const assetConfig = getPipelineFeatureConfig.value?.assets[asset]
      if (assetConfig) { map[asset] = getAssetFeatureCount(assetConfig) }
    })

    return map
  })

  const createAssetInFeatureConfig = async (key: string) => {
    const payload = { [key]: getNewAssetFeatureConfigObject() }
    await store.dispatch('pipeline/saveNewAssetFeaturesConfig', payload as defaultCreateFeatureWrapper)
  }

  const currentAssetFeatureKeyList: ComputedRef<FeatureType[]> = computed<FeatureType[]>(() => {
    if (!filteringFeatureAssetsForCurrentAsset.value) { return [] }
    return getFeatureKeyList(filteringFeatureAssetsForCurrentAsset.value)
  })

  const getFeatureKeyList = (featureObject: DefaultFeature) => {
    return Object.keys(featureObject).filter((key: string) => operations.includes(key as FeatureType)) as FeatureType[]
  }

  // create feature table data -- reconcile defaults and data formats here
  const featureTableData: ComputedRef<FormattedFeature[]> = computed<FormattedFeature[]>(() => {
    const featuresData: FormattedFeature[] = []

    for (const i in currentAssetFeatureKeyList.value) {
      const featureTypeKey = currentAssetFeatureKeyList.value[i]

      if (
        filteringFeatureAssetsForCurrentAsset.value
        && Object.keys(filteringFeatureAssetsForCurrentAsset.value[featureTypeKey] || {}).includes('features')
      ) {
        const features = filteringFeatureAssetsForCurrentAsset.value[featureTypeKey]?.features
        const defaults = filteringFeatureAssetsForCurrentAsset.value[featureTypeKey]?.defaults

        if (features) {
          for (const featureName of Object.keys(features)) {
            const feature: FormattedFeature = {
              days_back: getExistingValueFromArguments(features[featureName]?.days_back, defaults?.days_back),
              description: features[featureName]?.description || null,
              filter: reconciledFilters(features[featureName], defaults?.filter),
              name: featureName,
              type: currentAssetFeatureKeyList.value[i],
              typeSpecific: reconciledTypeSpecific(currentAssetFeatureKeyList.value[i], features[featureName], defaults),
              number_of_features: getCountPerFeature(features[featureName], currentAssetFeatureKeyList.value[i]),
              override_date_col: features[featureName]?.override_date_col,
            }
            featuresData.push(feature)
          }
        }
      }
    }

    return featuresData
  })

  const putDefaultValuesToConfig = async () => {
    if (!getPipelineFeatureConfig.value) { return }

    if (currentDataAssetWrapper.value?.key && !filteringFeatureAssetsForCurrentAsset.value) { await createAssetInFeatureConfig(currentDataAssetWrapper.value?.key || '') }

    if (getPipelineFeatureConfig.value[FEATURES_CONFIG_FIELD.CPU_LIMIT] === undefined) {
      store.commit('updateObjectInStore', {
        fieldObject: getPipelineFeatureConfig.value,
        key: FEATURES_CONFIG_FIELD.CPU_LIMIT,
        value: '',
      } as StoreDataChangePayload<typeof getPipelineFeatureConfig.value, FEATURES_CONFIG_FIELD.CPU_LIMIT>)
    }
    if (getPipelineFeatureConfig.value[FEATURES_CONFIG_FIELD.MEMORY_LIMIT] === undefined) {
      store.commit('updateObjectInStore', {
        fieldObject: getPipelineFeatureConfig.value,
        key: FEATURES_CONFIG_FIELD.MEMORY_LIMIT,
        value: '',
      } as StoreDataChangePayload<typeof getPipelineFeatureConfig.value, FEATURES_CONFIG_FIELD.MEMORY_LIMIT>)
    }
    if (getPipelineFeatureConfig.value[FEATURES_CONFIG_FIELD.RETRIES] === undefined) {
      store.commit('updateObjectInStore', {
        fieldObject: getPipelineFeatureConfig.value,
        key: FEATURES_CONFIG_FIELD.RETRIES,
        value: '',
      } as StoreDataChangePayload<typeof getPipelineFeatureConfig.value, FEATURES_CONFIG_FIELD.RETRIES>)
    }
    if (getPipelineFeatureConfig.value[FEATURES_CONFIG_FIELD.TIME_BETWEEN_RETRIES] === undefined) {
      store.commit('updateObjectInStore', {
        fieldObject: getPipelineFeatureConfig.value,
        key: FEATURES_CONFIG_FIELD.TIME_BETWEEN_RETRIES,
        value: '',
      } as StoreDataChangePayload<typeof getPipelineFeatureConfig.value, FEATURES_CONFIG_FIELD.TIME_BETWEEN_RETRIES>)
    }
  }

  const updateFieldsFromConfig: () => void = () => {
    if (!getPipelineFeatureConfig.value) { return }

    featuresCpuLimit.value = (getPipelineFeatureConfig.value[FEATURES_CONFIG_FIELD.CPU_LIMIT] as string) || ''
    featuresCpuLimitError.value = null

    featuresMemoryLimit.value = (getPipelineFeatureConfig.value[FEATURES_CONFIG_FIELD.MEMORY_LIMIT] as string) || ''
    featuresMemoryLimitError.value = null

    featuresRetries.value = (Number(getPipelineFeatureConfig.value[FEATURES_CONFIG_FIELD.RETRIES])) || 3
    featuresRetriesError.value = null

    featuresTimeBetweenRetries.value
      = (Number(getPipelineFeatureConfig.value[FEATURES_CONFIG_FIELD.TIME_BETWEEN_RETRIES])) || 3
    featuresTimeBetweenRetriesError.value = null

    featuresCpuLimit.value = (getPipelineFeatureConfig.value[FEATURES_CONFIG_FIELD.CPU_LIMIT] as string) || ''
    featuresCpuLimitError.value = null
  }

  const validateFeatureFields = (): boolean => {
    let errorFlag = true

    if (featuresRetries.value === '') {
      featuresRetriesError.value = 'A number of retries is required'
      errorFlag = false
    }

    if (featuresRetries.value < 0) {
      featuresRetriesError.value = 'A number of retries must be greater than or equal 0'
      errorFlag = false
    }

    if (featuresRetries.value > 4) {
      featuresRetriesError.value = 'A number of retries must be less than 5'
      errorFlag = false
    }

    if (featuresTimeBetweenRetries.value === '') {
      featuresTimeBetweenRetriesError.value = 'A time between retries is required'
      errorFlag = false
    }

    if (featuresTimeBetweenRetries.value <= 0) {
      featuresTimeBetweenRetriesError.value = 'A time between retries must be greater than 0'
      errorFlag = false
    }
    if (featuresTimeBetweenRetries.value > 60) {
      featuresTimeBetweenRetriesError.value = 'A time between retries must be less than or equal 60'
      errorFlag = false
    }

    // 80G maximum
    if (featuresMemoryLimit.value && !validateDataStringByType(featuresMemoryLimit.value, DataType.INTEGER)) {
      featuresMemoryLimitError.value = 'Please use integer value, maximum is 80'
      errorFlag = false
    }
    else if (featuresMemoryLimit.value && Number(featuresMemoryLimit.value) > 80) {
      featuresMemoryLimitError.value = 'Value cannot be greater than 80'
      errorFlag = false
    }

    // 32 maximum
    if (featuresCpuLimit.value && !validateDataStringByType(featuresCpuLimit.value, DataType.INTEGER)) {
      featuresCpuLimitError.value = 'Please use integer value, maximum is 32'
      errorFlag = false
    }
    else if (featuresMemoryLimit.value && Number(featuresCpuLimit.value) > 32) {
      featuresMemoryLimitError.value = 'Value cannot be greater than 32'
      errorFlag = false
    }

    return errorFlag
  }

  const onFeaturesConfigValueChange = (
    key: string,
    value: any,
    errorStringRef?: UnwrapNestedRefs<NullStringObject> | undefined,
  ) => {
    store.commit('updateObjectInStore', {
      fieldObject: getPipelineFeatureConfig.value,
      key,
      value,
    } as StoreDataChangePayload)

    if (validateFeatureFields()) { store.dispatch('pipeline/updatePipeline') }

    if (errorStringRef) { errorStringRef.value = null }
  }

  const getNewAssetFeatureConfigObject = (): DefaultFeature => {
    return {
      mapping: {
        event_time_col: null,
        user_identifier_col: undefined,
      },
      mapping_type: '',
      compute_layer: 'sql',
      days_back: [],
    } as DefaultFeature
  }

  const createNewFeature = async (newFeature: FormattedFeature, featureModalData: FormattedFeature | undefined) => {
    let wrapper: any = {}
    if (!filteringFeatureAssetsForCurrentAsset.value?.[newFeature.type]) { wrapper = { features: {} } }

    if (currentDataAssetWrapper.value) {
      let feature: FeatureTypePayload | undefined
      newFeature.days_back = newFeature.days_back.map((item: string | number) => Number.parseInt(item as string))

      let filter: ComparatorLogic | [] = []

      if (newFeature.filter && newFeature.filter.length) {
        const comparators = getComparatorsConfigModelsFromExpression(newFeature.filter)
        const expressions = convertModelExpressionToConfigExpression<FilterConfigMatchingExpression, RootFilterConfigExpression>(
          newFeature.filter,
        ) as RootFilterConfigExpression

        const logic = expressions.map((expression) => {
          return {
            id: newFeature.name,
            expression: [expression],
            display_name: newFeature.name,
            negate_expression: false,
          } as LogicConfigModel
        })
        filter = {
          comparators,
          logic,
        }
      }

      if (newFeature.type === Features.AGGREGATION) {
        feature = {
          agg_col: (newFeature.typeSpecific as AggPayload)?.agg_col || (featureModalData?.typeSpecific as AggPayload)?.agg_col,
          operation:
            (newFeature.typeSpecific as AggPayload)?.operation || (featureModalData?.typeSpecific as AggPayload)?.operation,
          days_back: newFeature.days_back,
          description: newFeature.description,
          override_date_col: newFeature.override_date_col,
        } as AggPayload
      }
      if (newFeature.type === Features.IDENTITY) {
        feature = {
          target_col:
            (newFeature.typeSpecific as IdentityPayload)?.target_col
            || (featureModalData?.typeSpecific as IdentityPayload)?.target_col,
          fill: (newFeature.typeSpecific as IdentityPayload)?.fill || (featureModalData?.typeSpecific as IdentityPayload)?.fill,
          description: newFeature.description,
          override_date_col: newFeature.override_date_col,
        } as IdentityPayload
      }
      if (newFeature.type === Features.ONE_HOT || newFeature.type === Features.ORDINAL) {
        feature = {
          target_col:
            (newFeature.typeSpecific as OrdinalOneHotPayload)?.target_col
            || (featureModalData?.typeSpecific as OrdinalOneHotPayload).target_col,
          encoding_order:
            (newFeature.typeSpecific as OrdinalOneHotPayload)?.encoding_order
            || (featureModalData?.typeSpecific as OrdinalOneHotPayload)?.encoding_order,
          description: newFeature.description,
          days_back: newFeature.days_back,
          override_date_col: newFeature.override_date_col,
        } as OrdinalOneHotPayload
      }
      if (newFeature.type === Features.COUNT || newFeature.type === Features.BOOLEAN) {
        feature = {
          days_back: newFeature.days_back,
          description: newFeature.description,
          override_date_col: newFeature.override_date_col,
        } as CommonFeatureFields
      }
      if (newFeature.type === Features.RATIO) {
        feature = {
          days_back: newFeature.days_back,
          description: newFeature.description,
          numerator:
            (newFeature.typeSpecific as RatioPayload)?.numerator || (featureModalData?.typeSpecific as RatioPayload)?.numerator,
          denominator:
            (newFeature.typeSpecific as RatioPayload)?.denominator
            || (featureModalData?.typeSpecific as RatioPayload)?.denominator,
          fill:
            (newFeature.typeSpecific as RatioPayload)?.fill
            || (featureModalData?.typeSpecific as RatioPayload)?.fill
            || undefined,
          override_date_col: newFeature.override_date_col,
        } as RatioPayload
      }
      if (newFeature.type === Features.BUCKET_TIME) {
        feature = {
          days_back: newFeature.days_back,
          description: newFeature.description,
          granularity:
            (newFeature.typeSpecific as BucketTimePayload)?.granularity
            || (featureModalData?.typeSpecific as BucketTimePayload)?.granularity,
          bucket_col:
            (newFeature.typeSpecific as BucketTimePayload)?.bucket_col
            || (featureModalData?.typeSpecific as BucketTimePayload)?.bucket_col,
          buckets:
            (newFeature.typeSpecific as BucketTimePayload)?.buckets
            || (featureModalData?.typeSpecific as BucketTimePayload)?.buckets,
          override_date_col: newFeature.override_date_col,
        } as BucketTimePayload
      }
      if (newFeature.type === Features.TIME_SINCE) {
        feature = {
          days_back: newFeature.days_back,
          description: newFeature.description,
          granularity:
            (newFeature.typeSpecific as TimeSincePayload)?.granularity
            || (featureModalData?.typeSpecific as TimeSincePayload)?.granularity,
          order:
            (newFeature.typeSpecific as TimeSincePayload)?.order || (featureModalData?.typeSpecific as TimeSincePayload)?.order,
          fill:
            (newFeature.typeSpecific as TimeSincePayload)?.fill
            || (featureModalData?.typeSpecific as TimeSincePayload)?.fill
            || undefined,
          override_date_col: newFeature.override_date_col,
        } as TimeSincePayload
      }

      const disallowedFeatureTypes: FeatureType[] = [Features.RATIO]
      if (feature && filter && !disallowedFeatureTypes.includes(newFeature.type)) { feature.filter = filter }

      if (feature) {
        const payload: NewFeaturePayload = {
          assetKey: currentDataAssetWrapper.value.key,
          type: newFeature.type,
          name: newFeature.name,
          payload: feature,
          wrapper,
        }

        await store.dispatch('pipeline/addNewFeatureToConfig', payload)
      }
    }
  }

  const loadFeaturesStatisticsDataRows = async () => {
    if (AppSettings.GBQ_QUERIES_ENABLED) {
      try {
        featuresStatisticsData.value = await BigQueryService.getFeaturesStatisticsData()
      }
      catch (error: any) {
        featuresStatisticsData.value = []
        Global.error(error.message || 'loadFeaturesStatisticsDataRows: Something went wrong.')
      }
    }
    else {
      Global.message.warning('Feature disabled: Google BigQuery')
    }
  }

  const startFeatureStatisticsCalculation = async () => {
    if (AppSettings.FEATURE_CALCULATION_TASKS_ENABLED) {
      isFeaturesStatisticsRequestPending.value = true
      try {
        const task: JobHistoryModel = await FeatureStatisticsTaskService.startTask()
        task.messageRef = Global.message?.loading(FeatureStatisticsTaskService.RUNNING_MESSAGE, {
          duration: 0,
          closable: false,
        })
        // eslint-disable-next-line no-console
        console.info(`${FeatureStatisticsTaskService.RUNNING_MESSAGE}: ${task.job_id}`)
        store.commit('addTask', task)
        isFeaturesStatisticsRequestPending.value = false
        K8TaskService.pullStatuses()
      }
      catch (error: any) {
        Global.error(error.message || 'calculateFeaturesButtonClickHandler: Something went wrong.')
      }
    }
    else {
      Global.message.warning('Feature disabled: Features calculation tasks')
    }
  }

  const startFeatureCalculation = async () => {
    if (AppSettings.FEATURE_CALCULATION_TASKS_ENABLED) {
      isFeaturesCalculationRequestPending.value = true
      try {
        const task: JobHistoryModel = await FeatureCalculatingTaskService.startTask()
        task.messageRef = Global.message?.loading(FeatureCalculatingTaskService.RUNNING_MESSAGE, {
          duration: 0,
          closable: false,
        })
        // eslint-disable-next-line no-console
        console.info(`${FeatureCalculatingTaskService.RUNNING_MESSAGE}: ${task.job_id}`)

        store.commit('addTask', task)
        K8TaskService.pullStatuses()
      }
      catch (error: any) {
        Global.error(error.message || 'calculateFeaturesButtonClickHandler: Something went wrong.')
      }
      isFeaturesCalculationRequestPending.value = false
    }
    else {
      Global.message.warning('Feature disabled: Features calculation tasks')
    }
  }

  const loadFeaturesPreviewDataRows = async () => {
    if (AppSettings.GBQ_QUERIES_ENABLED) {
      try {
        featuresPreviewData.value = await BigQueryService.getFeaturesData()
      }
      catch (error: any) {
        featuresPreviewData.value = []
        Global.error(error.message || 'loadFeaturesPreviewDataRows: Something went wrong.')
      }
    }
    else {
      Global.message.warning('Feature disabled: Google BigQuery')
    }
  }

  if (getPipelineFeatureConfig.value) {
    putDefaultValuesToConfig()
    updateFieldsFromConfig()
  }
  else {
    const destroyWatcher = watch(
      () => [getPipelineFeatureConfig.value],
      () => {
        putDefaultValuesToConfig()
        updateFieldsFromConfig()
        destroyWatcher()
      },
    )
  }

  return {
    validateFeatureFields,
    featuresCpuLimit,
    featuresCpuLimitError,
    featuresMemoryLimit,
    featuresMemoryLimitError,
    featuresRetries,
    featuresRetriesError,
    featuresTimeBetweenRetries,
    featuresTimeBetweenRetriesError,
    onFeaturesConfigValueChange,
    featuresProcessingStartTimeDetails,
    getNewAssetFeatureConfigObject,
    assetFeatureCountMap,
    getCountPerFeature,
    createNewFeature,
    allFeatureNameList,
    getFeatureKeyList,
    featureTableData,
    startFeatureStatisticsCalculation,
    isFeaturesStatisticsRequestPending,
    isFeaturesCalculationRequestPending,
    loadFeaturesStatisticsDataRows,
    loadFeaturesPreviewDataRows,
    featuresStatisticsData,
    featuresPreviewData,
    startFeatureCalculation,
    getTableFeatureCount,
  }
}

export type EngineerFeaturesReactive = ReturnType<typeof useEngineeringFeatures>
