<script lang="ts" setup>
import {useUser} from '@level3/composables/useUser'
import {UserRole} from '@level3/types/user'
import MyClientsList from './MyClientsList.vue'
import AllClientsList from './AllClientsList.vue'

const search = ref('')
const {user, isLoadingUser} = useUser()
</script>

<template>
  <div class="h-full w-[40rem] flex flex-col border rounded-xl px-4 py-8">
    <NTabs v-if="!isLoadingUser" type="line" animated>
      <NTabPane name="my-clients" tab="My Clients">
        <MyClientsList v-model:search="search" />
      </NTabPane>
      <NTabPane
        v-if="
          user?.role.type === UserRole.AIE || user?.role.type === UserRole.ADMIN
        "
        name="all-clients"
        tab="All Clients"
      >
        <AllClientsList v-model:search="search" />
      </NTabPane>
    </NTabs>
  </div>
</template>
