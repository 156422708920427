import AppSettings from '@app/AppSettings'

import { TASK_NAME } from '@shared/data/constants'
import { JobHistoryModel } from '@/dags/models/server/JobHistoryModel'
import K8TaskService from '@/dags/services/tasks/K8TaskService'
import { store } from '@app/store'
import { DataAssetFormat, StrapiDataArray } from '@shared/utils/Types'
import Global from '@shared/utils/global'
import { preprocessFailedMessageRender } from '@shared/utils/renderHelpers'

export interface PreprocessProperties {
  dataFormat: DataAssetFormat
  assetName: string
  cpu_limit?: string
  memory_limit?: string
}

export default class PreprocessTaskService {
  static RUNNING_MESSAGE: string = '"Preprocess" task is running'
  static SUCCEEDED_MESSAGE: string = '"Preprocess" task completed successfully'
  static FAILED_MESSAGE: () => VNodeChild = preprocessFailedMessageRender

  static async startTask({ dataFormat, assetName, cpu_limit, memory_limit }: PreprocessProperties): Promise<JobHistoryModel> {
    const queryObject: any = {
      client: store.getters['client/client'].name,
      pipeline: store.getters['pipeline/pipeline']?.name,
      asset: assetName,
      cpu: cpu_limit,
      memory: memory_limit,
      product_env: 'draft',
    }
    if (dataFormat === 'mparticle') {
      queryObject.task = TASK_NAME.PREPROCESS_MPARTICLE
    }
    else {
      queryObject.task = TASK_NAME.PREPROCESS
    }

    return K8TaskService.startTask(queryObject, PreprocessTaskService.SUCCEEDED_MESSAGE, PreprocessTaskService.FAILED_MESSAGE)
  }

  static getRunningMessage(task: JobHistoryModel): string {
    return `${PreprocessTaskService.RUNNING_MESSAGE} for "${task.asset}"`
  }

  static async runPreprocessTask(properties: PreprocessProperties) {
    if (AppSettings.PREPROCESS_TASKS_ENABLED) {
      try {
        const task: JobHistoryModel = await PreprocessTaskService.startTask(properties)
        task.messageRef = Global.message?.loading(PreprocessTaskService.getRunningMessage(task), {
          duration: 0,
          closable: false,
        })
        // eslint-disable-next-line no-console
        console.info(`Preprocess task ${task.job_id} started`)
        store.commit('addTask', task)
        K8TaskService.pullStatuses()
      }
      catch (error: any) {
        Global.error(error.message || 'updateAsset: Something went wrong.')
      }
    }
    else {
      Global.message.warning('Feature disabled: Preprocess tasks')
    }
  }

  static async getAllTasks(): Promise<StrapiDataArray<JobHistoryModel>> {
    return K8TaskService.getAllTasks(store.getters['client/client'].id, [TASK_NAME.PREPROCESS, TASK_NAME.PREPROCESS_MPARTICLE])
  }
}
