<script lang="ts">
import ModalConfirm from '@shared/components/basic/modal/ModalConfirm.vue'
import { hasAcknowledgedLocalRemoteConflictUseCase, useCaseHasLocalRemoteConflict, useCaseLocallyUpdatedAt } from '@/usecases/composables/useUseCaseConfig'
import { hasAcknowledgedLocalRemoteConflict, pipelineHasLocalRemoteConflict, pipelineLocallyUpdatedAt } from '@/pipelines/composables/usePipelineConfig'

export default defineComponent({
  name: 'PipelineConflictConfirmModal',
  components: {
    ModalConfirm,
  },
  props: {
    showModal: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup() {
    const conflictOnConfig = computed(() => {
      if (useCaseHasLocalRemoteConflict.value) { return 'usecase' }
      if (pipelineHasLocalRemoteConflict.value) { return 'pipeline' }
      return 'other?'
    })

    const message = computed(() => {
      return (
        `Changes have been made outside this window for the ${conflictOnConfig.value} and your changes are unable to be saved. `
        + 'Would you like to refresh the data? This will erase any changes you have made.'
      )
    })

    const sendClose = () => {
      if (conflictOnConfig.value === 'pipeline') {
        hasAcknowledgedLocalRemoteConflict.value = true
      }
      else if (conflictOnConfig.value === 'usecase') {
        hasAcknowledgedLocalRemoteConflictUseCase.value = true
      }
    }
    const refreshData = async () => {
      if (conflictOnConfig.value === 'pipeline') {
        pipelineHasLocalRemoteConflict.value = false
        hasAcknowledgedLocalRemoteConflict.value = false
        pipelineLocallyUpdatedAt.value = null
      }
      else if (conflictOnConfig.value === 'usecase') {
        useCaseHasLocalRemoteConflict.value = false
        hasAcknowledgedLocalRemoteConflictUseCase.value = false
        useCaseLocallyUpdatedAt.value = null
      }
      location.reload()
    }
    return {
      message,
      sendClose,
      refreshData,
    }
  },
})
</script>

<template>
  <ModalConfirm
    title="Refresh Data"
    :message="message"
    confirmButtonText="Refresh"
    :isOpened="showModal"
    :confirmHandler="refreshData"
    danger
    @close="sendClose"
  />
</template>
