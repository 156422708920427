import qs from 'qs'
import { PYTHON_MS_API_PREFIX } from '@shared/data/constants'
import type { HistoryRequestHash, PipelineHistoryRequest } from '@shared/composables/useHistory'
import { useHistory } from '@shared/composables/useHistory'
import { store } from '@app/store'
import type { PipelineUpdateContext } from '@shared/utils/Types'
import { getModelFromResponse, getQueryString, isEmpty } from '@shared/utils/helpers'
import http from '@app/utils/http-client'
import type { PipelineModel, PipelineModelHistoryListItem, PipelineModelNoConfig } from '@/pipelines/models/server/PipelineModel'

function query(clientId: number, tags: string[] = []) {
  return qs.stringify(
    {
      filters: {
        client: clientId,
        tags: {
          value: {
            $in: tags,
          },
        },
      },
      populate: ['tags'],
    },
    { encodeValuesOnly: true },
  )
}

function queryLastUpdateOnly() {
  return qs.stringify(
    {
      fields: ['id', 'name', 'updatedAt'],
    },
    { encodeValuesOnly: true },
  )
}

const populateQuery = qs.stringify(
  {
    populate: {
      createdByUser: {
        fields: ['username'],
      },
      updatedByUser: {
        fields: ['username'],
      },
      client: {
        fields: ['id', 'name'],
      },
    },
  },
  { encodeValuesOnly: true },
)

export default class PipelineService {
  static async getPipelines(clientId: number, tags?: string[]) {
    const response = await http.get(`/pipelines?${query(clientId, tags)}`, {
      cache: false,
    })

    return getModelFromResponse<PipelineModel>(response) as PipelineModel[]
  }

  static async getPipelineHistoryList(pipelineId: number, useCache: boolean): Promise<PipelineModelHistoryListItem[]> {
    const { pipelineHistoryListMap } = useHistory()

    const requestData: PipelineHistoryRequest = {
      filters: {
        original: pipelineId,
      },
      populate: {
        updatedByUser: {
          fields: ['username'],
        },
      },
    }
    const query = qs.stringify(requestData, { encodeValuesOnly: true })
    const cachedAnswer = pipelineHistoryListMap.get(pipelineId)?.get(query)

    if (cachedAnswer && useCache) {
      return cachedAnswer
    }

    const response = await http.get(`/pipelines-history?${query}`)

    if (!pipelineHistoryListMap.has(pipelineId)) {
      pipelineHistoryListMap.set(pipelineId, new Map<HistoryRequestHash, PipelineModelHistoryListItem[]>([]))
    }

    pipelineHistoryListMap.get(pipelineId)?.set(query, response.data as PipelineModelHistoryListItem[])

    return pipelineHistoryListMap.get(pipelineId)?.get(query) || []
  }

  static async getServerPipelineLastUpdateAt(pipelineId: number): Promise<Date> {
    const response = await http.get(`/pipelines/${pipelineId}?${queryLastUpdateOnly()}`, {
      cache: false,
    })

    const data = getModelFromResponse<PipelineModelNoConfig>(response) as PipelineModelNoConfig

    return data.updatedAtDate as Date
  }

  static async getPipelineConfigFromEnvironment(pipeline: string, product_env: string, client?: string): Promise<PipelineModel> {
    const queryParams = {
      client: client || store.getters['client/client'].name,
      pipeline,
      product_env,
    }

    const response = await http.get(`${PYTHON_MS_API_PREFIX}/strapi/get_pipeline?${getQueryString(queryParams)}`, { cache: false })

    return response.data as PipelineModel
  }

  static async updatePipeline(
    id: number,
    pipeline: PipelineModel,
    context?: PipelineUpdateContext,
  ): Promise<PipelineModel | void> {
    if (store.getters.isReadonlyMode) {
      return
    }

    const response = await http.put(`/pipelines/${id}`, {
      data: pipeline,
      context,
    })

    return getModelFromResponse<PipelineModel>(response) as PipelineModel
  }

  static async createPipeline(pipeline: PipelineModel): Promise<PipelineModel> {
    if (isEmpty(pipeline.name)) { throw new Error('Pipeline name is required!') }
    if (isEmpty(pipeline.client)) { throw new Error('Pipeline client is required!') }

    const response = await http.post(`/pipelines?${populateQuery}`, { data: pipeline })

    return getModelFromResponse<PipelineModel>(response) as PipelineModel
  }

  static async getPipelineHistoryConfigWithVersionId(
    pipelineName: string,
    product_env: string,
    historical_id: number,
    client?: string,
  ): Promise<any> {
    const queryParams = {
      pipeline: pipelineName,
      client: client || store.getters['client/client'].name,
      product_env,
      historical_id,
    }

    const response = await http.get(`${PYTHON_MS_API_PREFIX}/strapi/pipeline_history?${getQueryString(queryParams)}`, { cache: false })

    return response.data
  }
}
