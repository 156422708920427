import SystemAvailabilityService from '@app/services/SystemAvailabilityService'
import { debug } from '@shared/utils/logger'
import type { Router } from 'vue-router'

export function defineMiddleware(router: Router) {
  debug('Middleware: isOffline')
  setTimeout(() => {
    SystemAvailabilityService.registerVueRouterHook(router)
  }, 0)
}
