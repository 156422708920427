import '@assets/styles/app.css'
import '@unocss/reset/tailwind-compat.css'
import 'uno.css'
import 'animate.css'

import { appMountedHook } from '@app/hooks/app:mounted'
import { appBeforeMountHook } from '@app/hooks/app:beforeMount'
import { appCreatedHook } from '@app/hooks/app:created'
import '@plugins/jsyaml'
import Global from '@shared/utils/global'
import { inject } from '@vercel/analytics'
import OfferFitApp from './App.vue'


async function start() {
  inject()
  // to exclude any css conflicts between tailwind and naive-ui
  const meta = document.createElement('meta')
  meta.name = 'naive-ui-style'
  document.head.appendChild(meta)

  const app = createApp(OfferFitApp)
  await appCreatedHook(app)
  /**
   * Router starts to be defined here.
   * Calling it before this point will result in unpredictable errors.
   * awaiting a router initialization is being done in the 01.router.ts module
   */
  await appBeforeMountHook()
  app.mount('#app')
  await appMountedHook()
}

try {
  await start()
}
catch (e: any) {
  Global.error(e.message)
  console.trace(e)
}
