<template>
  <div>
    "Feature calculation" task failed. To see error logs
    <a class="text-link hover:text-blue-500 cursor-pointer" @click.prevent.stop="isActivityDrawerOpened = true">open the logs</a>
  </div>
</template>

<script lang="ts">
import { useTaskLogs } from '@/dags/composables/useTaskLogs'

export default {
  name: 'FeatureCalculationTaskFailedMessage',
  setup() {
    const { isActivityDrawerOpened } = useTaskLogs()
    return {
      isActivityDrawerOpened
    }
  }
}
</script>
