import { processToRoute } from '@router/routerHelpers'
import { debug } from '@shared/utils/logger'
import type { RouteLocationRaw, Router } from 'vue-router'

export function defineMiddleware(router: Router) {
  debug('Middleware: level3Preprocess')
  // extend router.push / router.replace functionality to have location preprocessed
  const routerPush = router.push
  // eslint-disable-next-line ts/unbound-method
  const routerReplace = router.replace

  router.push = async (to: RouteLocationRaw) => {
    return routerPush(processToRoute(to))
  }

  router.replace = async (to: RouteLocationRaw) => {
    return routerReplace(processToRoute(to))
  }
}
