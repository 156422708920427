import type { PortalSettings } from '@shared/utils/Types'
import { getModelFromResponse } from '@shared/utils/helpers'
import http from '@app/utils/http-client'

export default class UISettingsService {
  static async getUISettings(): Promise<PortalSettings> {
    const response = await http.get('/client-ui')
    return getModelFromResponse<PortalSettings>(response) as PortalSettings
  }
}
