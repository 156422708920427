import { UserNotificationModel } from '@/notifications/models/server/UserNotification'

export type UserNotificationsStateType = {
  notifications: UserNotificationModel[]
}

const state: UserNotificationsStateType = {
  notifications: []
}

export default state
