<script lang="ts">
import SeverityRender from '@/reporting/validio/components/SeverityRender.vue'

import type { ValidoAlertsDataModel } from '@/reporting/models/server/ReportingModel'

export default defineComponent({
  name: 'ValidioTableExpandRender',
  components: { SeverityRender },
  props: {
    row: Object as PropType<ValidoAlertsDataModel>,
  },
  setup() {
    function formatValidioValues(value: number) {
      return Number.parseInt(value.toFixed(5))?.toLocaleString()
    }
    return {
      formatValidioValues,
    }
  },
})
</script>

<template>
  <div v-if="row.incident_message" class="mb-2 mt-0">
    <div class="p-2 pb-1 font-semibold">
      Incident message
    </div>
    <div class="border-transparent rounded-md border-solid bg-orange-50 p-2 pl-4">
      Expected value to be between {{ row.lower_bound?.toLocaleString() }} - {{ row.upper_bound?.toLocaleString() }}, was {{ row.observed_value?.toLocaleString() }}
    </div>
  </div>
  <div v-if="row.segment_fields" class="mb-2 p-2 pb-1">
    <span class="font-semibold">Segment fields:</span>&nbsp;<span
      v-for="segment in row.segment_fields"
      :key="segment"
      class="bg-offerfit-gray p-4"
    >{{ segment }}</span>
  </div>
  <div class="p-2">
    <span class="mb-1 font-semibold">Bounds</span>
    <div class="grid grid-cols-3 w-1/3 gap-4">
      <div class="rounded-md bg-gray-80 p-2">
        <span>lower:</span>
        <div>{{ formatValidioValues(row.lower_bound) }}</div>
      </div>
      <div class="rounded-md bg-offerfit-green-bg p-2">
        <span>observed:</span>
        <div>{{ formatValidioValues(row.observed_value) }}</div>
      </div>
      <div class="rounded-md bg-gray-80 p-2">
        <span>upper:</span>
        <div>{{ formatValidioValues(row.upper_bound) }}</div>
      </div>
    </div>
  </div>
  <div class="p-2">
    <div v-if="row.offerfit_severity" class="flex">
      <span class="mr-1 font-semibold">Alert severity:</span> {{ formatValidioValues(row.offerfit_severity) }}
      <SeverityRender :severity="row.offerfit_severity" class="ml-1 mt-0.5" />
    </div>
  </div>
</template>

<style scoped></style>
