import type { GlobalThemeOverrides, TagProps } from 'naive-ui'

import { colors } from '@theme'

export const OFFERFIT_COLOR = {
  GREEN: 'offerfit-green',
  ORANGE: 'offerfit-orange',
  BRIGHT_PURPLE: 'offerfit-purple-bright',
  PURPLE: 'offerfit-purple',
  LIGHT_PURPLE: 'offerfit-light-purple',
  GRAY: 'offerfit-gray',
  BLACK: 'offerfit-black',
  YELLOW: 'offerfit-yellow',
  RED: 'offerfit-red',
}

// global theme variables for naive-ui components only
export const globalThemeOverrides: GlobalThemeOverrides = {
  common: {
    primaryColor: colors.brand.purple.bright,
    borderColor: colors.brand.gray,
    successColor: colors.brand.green.DEFAULT,
    warningColor: colors.brand.yellow,
    textColorBase: colors.brand.black,
    textColorDisabled: colors.brand.gray,
    hoverColor: colors.gray['80'],
    pressedColor: colors.brand.purple.bright,
    borderRadius: '6px',
    borderRadiusSmall: '2px',
    primaryColorPressed: colors.brand.purple.DEFAULT,
  },
  InternalSelection: {
    textColor: colors.brand.black,
    border: `1px solid ${colors.brand.gray}`,
    borderError: `2px solid ${colors.brand.red}`,
    borderActiveError: `2px solid ${colors.brand.red}`,
    caretColor: ' #333',
    heightMedium: '38px',
    borderFocus: `2px solid ${colors.brand.purple.bright}`,
    borderActive: `2px solid ${colors.brand.purple.bright}`,
    borderHover: `2px solid ${colors.brand.purple.bright}`,
    boxShadowFocus: 'none',
    boxShadowActive: 'none',
    textColorDisabled: colors.brand.black,
  },
  Table: {
    thTextColor: colors.gray['500'],
    thPaddingMedium: '12px 5px 12px 12px',
    tdPaddingMedium: '9px',
    borderColor: colors.gray['200'],
  },
  DataTable: {
    thTextColor: colors.gray['500'],
    thPaddingMedium: '12px 5px 12px 12px',
    tdPaddingMedium: '9px',
    borderColor: colors.gray['200'],
    peers: {
      Button: {
        color: 'white',
        colorHover: 'white',
        colorPrimary: colors.brand.purple.bright,
        colorFocusPrimary: colors.brand.purple.bright,
        colorPressedPrimary: colors.brand.purple.DEFAULT,
        colorHoverPrimary: colors.brand.purple.bright,
        textColorPrimary: 'white',
        textColor: colors.brand.gray,
        textColorHover: colors.brand.purple.bright,
        borderHoverInfo: colors.brand.purple.bright,
        borderHoverPrimary: colors.brand.purple.bright,
        borderHover: colors.brand.purple.bright,
      },
    },
  },
  InternalSelectMenu: {
    optionTextColorActive: colors.brand.purple.bright,
    optionCheckColor: colors.brand.purple.bright,
  },
  Radio: {
    color: 'white',
    colorActive: 'white',
    colorDisabled: 'white',
    dotColorActive: colors.brand.purple.bright,
    dotColorDisabled: colors.brand.gray,
    boxShadowHover: `inset 0 0 0 1px ${colors.brand.gray}, 0 0 0 2px white, 0 0 0 4px ${
      colors.brand.purple.bright
    }`,
    boxShadowActive: `inset 0 0 0 1px ${colors.brand.gray}`,
    boxShadow: `inset 0 0 0 1px ${colors.brand.gray}`,
    boxShadowFocus: `inset 0 0 0 2px white, 0 0 0 4px ${colors.brand.purple.bright}`,
    boxShadowDisabled: `inset 0 0 0 1px ${colors.brand.gray}`,
    fontSizeMedium: '1rem',
    fontSizeLarge: '1rem',
    fontSizeSmall: '1rem',
  },
  Tabs: {
    tabTextColorLine: colors.brand.black,
  },
  Checkbox: {
    colorChecked: colors.brand.purple.bright,
    borderChecked: `1px solid ${colors.brand.purple.bright}`,
    borderFocus: `1px solid ${colors.brand.purple.bright}`,
    border: `1px solid ${colors.brand.purple.bright}`,
    boxShadowFocus: 'none',
  },
  Input: {
    borderFocus: `2px solid ${colors.brand.purple.bright}`,
    borderHover: `2px solid ${colors.brand.purple.bright}`,
    heightMedium: '38px',
    caretColor: '#333',
    boxShadowFocus: 'none',
    textColorDisabled: colors.brand.black,
  },
  Spin: {
    color: colors.brand.purple.bright,
  },
  Button: {
    color: 'transparent',
    colorHover: 'transparent',
    colorPressed: 'transparent',
    border: `1px solid ${colors.brand.purple.bright}`,
    borderPressed: `1px solid ${colors.brand.purple.DEFAULT}`,
    borderFocus: `1px solid ${colors.brand.purple.DEFAULT}`,
    textColor: colors.brand.purple.bright,
    textColorFocus: colors.brand.purple.DEFAULT,
    borderHover: `1px solid ${colors.brand.purple.DEFAULT}`,
    textColorHover: colors.brand.purple.DEFAULT,
    colorPrimary: colors.brand.purple.bright,
    colorFocusPrimary: colors.brand.purple.bright,
    colorPressedPrimary: colors.brand.purple.bright,
    colorHoverPrimary: colors.brand.purple.bright,
    textColorPrimary: 'white',
    borderHoverInfo: `1px solid ${colors.brand.purple.bright}`,
    borderHoverPrimary: `1px solid ${colors.brand.purple.bright}`,
  },
  Anchor: {
    railColorActive: colors.brand.purple.bright,
    railColor: colors.brand.purple.bright,
    linkTextColorHover: colors.brand.black,
    linkColor: colors.brand.purple.bright,
  },
  Statistic: {
    valueFontSize: '1rem',
  },
  Dropdown: {
    optionHeightHuge: '4rem',
  },
  Form: {
    labelFontWeight: '500',
  },
}

type BasicTagThemeOverrides = NonNullable<TagProps['themeOverrides']>

export const orangeTagOverrides: BasicTagThemeOverrides = {
  borderPrimary: '#CCCCCC',
  borderWarning: 'transparent',
  borderError: 'transparent',
  borderSuccess: 'transparent',
  colorBorderedPrimary: colors.brand.purple.DEFAULT,
  colorBorderedSuccess: '#e4f5ed',
  textColorWarning: '#FFFFFF',
  textColorPrimary: '#FFFFFF',
  textColorError: '#FFFFFF',
  textColorSuccess: colors.brand.green.dark,
  colorSuccess: colors.brand.green.dark,
}
