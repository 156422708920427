import { event } from 'vue-gtag'

export function trackEvent(eventName: string, additionalObject?: { type?: string, action?: string, value?: any, enabled?: boolean, valueLength?: number } & Record<string, any>) {
  // GA event tracking
  event(eventName, {
    ...additionalObject,
    value: additionalObject?.value,
    event_label: additionalObject?.action,
    event_category: additionalObject?.type,
  })
}
