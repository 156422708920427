import { DeploymentState, DeploymentsUpdateKeyList, POLLING_DELAY, ROLE_TYPE } from '@shared/data/constants'
import { store } from '@app/store'
import { handlePortalError } from '@shared/utils/errorHandling.ts'
import { monthShortDateTime } from '@shared/utils/transformHelpers'
import Global from '@shared/utils/global'
import type {
  DagFailedTestModel,
  DagPollCounts,
  DagPollCountsPartial,
  DagPollModel,
  DeploymentEligibilityModel,
  DeploymentsKeys,
  DeploymentsModel,
  DeploymentsTableModel,
  DeploymentsValues,
  RelationModel,
} from '@/deployments/models/server/DeploymentModel'

import DeploymentsService from '@/deployments/services/DeploymentsService'
import PipelineService from '@/pipelines/services/PipelineService'
import UseCaseService from '@/usecases/services/UseCaseService'
import { PipelineModel } from '@/pipelines/models/server/PipelineModel'

const deploymentEligibility: Ref<DeploymentEligibilityModel | undefined> = ref<DeploymentEligibilityModel | undefined>()
const deploymentsData: Ref<DeploymentsModel[] | undefined> = ref<DeploymentsModel[] | undefined>()
const isShowingArchived: Ref<boolean> = ref<boolean>(false)
const isDiffModalOpened: Ref<boolean> = ref<boolean>(false)
const isDeploymentsDiffModelOpened: Ref<boolean> = ref<boolean>(false)
const diffUseCases: Ref<string[] | undefined> = ref<string[] | undefined>()
const isModalDataLoading = ref<boolean>(false)

export function useDeployments() {
  const isUseCasePromoted = async (usecaseId: number) => {
    let response: DeploymentsModel[] | undefined
    try {
      response = await DeploymentsService.getUseCaseDeploymentRecord(usecaseId)
    }
    catch (e) {
      response = undefined
    }
    return response ? response.length > 0 : false
  }

  const getDeploymentEligibility = async () => {
    deploymentEligibility.value = await DeploymentsService.getDeploymentEligibility()
  }

  const getDeploymentsData = async () => {
    try {
      deploymentsData.value = await DeploymentsService.getDeploymentsByClient(store.getters['client/client'].id)
    }
    catch (e) {
      console.error(`Could not fetch deployments: ${e}`)
    }
  }

  const deploymentsTableData: ComputedRef<DeploymentsTableModel[] | undefined> = computed<DeploymentsTableModel[] | undefined>(
    () => {
      if (!deploymentsData.value) { return }
      return deploymentsData.value?.map((item: DeploymentsModel) => {
        return {
          ...item,
          key: item.id || item.dag_id,
          creationDate: monthShortDateTime(item?.createdAt ? new Date(item?.createdAt) : new Date()),
          promotion:
            item.pipelines?.data?.map((item: RelationModel) => `pipeline: ${item.name}`)
            || (item.pipelines as any).map((item: RelationModel) => `pipeline: ${item}`),
        } as DeploymentsTableModel
      })
    },
  )

  const archiveFilteredDeploymentsData: ComputedRef<DeploymentsTableModel[] | undefined> = computed<
    DeploymentsTableModel[] | undefined
  >(() => {
    if (!isShowingArchived.value) {
      return deploymentsTableData.value?.filter((item: DeploymentsTableModel) => !item.isArchived)
    }
    return deploymentsTableData.value
  })

  const updateTableData = (itemId: number, key: DeploymentsKeys, value: DeploymentsValues) => {
    const deployment: DeploymentsModel | undefined = deploymentsData.value?.find((item: DeploymentsModel) => item.id === itemId)
    if (deployment && key) {
      // @ts-expect-error
      deployment[key] = value
    }
  }

  const getDeploymentTaskFailLog = async (dag_id: string, task_id: string): Promise<DagFailedTestModel> => {
    return await DeploymentsService.getTaskFailLog(dag_id, task_id)
  }

  const updateDeployment = async (deployment: DeploymentsTableModel, key: string, value: any) => {
    const payload = {
      ...deployment,
      [key]: value,
    }
    await DeploymentsService.updateDeployment(payload)
  }

  const getUseCaseLatestHistoryId = async (usecaseId: number) => {
    const response = await UseCaseService.getUsecaseHistoryList(usecaseId, false)
    return response?.[0].id
  }

  const getPipelineLatestHistoryId = async (pipelineId: number) => {
    const response = await PipelineService.getPipelineHistoryList(pipelineId, false)
    return response?.[0].id
  }

  const cancelDagRun = async (deployment: DeploymentsTableModel) => {
    let response
    try {
      response = await DeploymentsService.cancelDeployment(deployment)
      Global.message.success('Successfully canceled deployment')
    }
    catch (e) {
      response = false
      Global.message.error('Something went wrong')
    }
    return response
  }
  const migrateAllUseCasesAndPipelinesFromLiveToPending = async () => {
    const promises: Promise<void>[] = []
    const pipelines = store.getters['pipeline/pipelines']
    pipelines.forEach((pipeline: PipelineModel) => {
      promises.push(DeploymentsService.migratePipeline(pipeline.name, 'live', 'pending'))
      pipeline?.config?.usecases?.forEach((usecase: string) => {
        promises.push(DeploymentsService.migrateUsecase(pipeline.name, usecase, 'live', 'pending'))
      })
    })
    try {
      await Promise.all(promises)
      return 200
    }
    catch (e: any) {
      handlePortalError(e, {
        defaultUserErrorText: 'Sync to pending failed. Promotion failed.',
        roleErrorTextMap: {
          [ROLE_TYPE.AIE]: `Sync to pending failed. Promotion failed. Check Sentry for errors.`,
        },
      })
      return 400
    }
  }

  return {
    isUseCasePromoted,
    getDeploymentEligibility,
    getDeploymentsData,
    getDeploymentTaskFailLog,
    updateDeployment,
    updateTableData,
    getUseCaseLatestHistoryId,
    getPipelineLatestHistoryId,
    cancelDagRun,
    migrateAllUseCasesAndPipelinesFromLiveToPending,
    deploymentsTableData,
    archiveFilteredDeploymentsData,
    isShowingArchived,
    deploymentEligibility,
    deploymentsData,
    isDiffModalOpened,
    isDeploymentsDiffModelOpened,
    diffUseCases,
    isModalDataLoading,
  }
}

export function useDeploymentPolling(deployment: DeploymentsTableModel) {
  let intervalTimer: ReturnType<typeof setInterval>
  const status: Ref<DeploymentState> = ref<DeploymentState>(deployment?.state)
  const poll: Ref<DagPollModel | undefined> = ref<DagPollModel | undefined>()
  const { updateDeployment } = useDeployments()

  const getPoll = async () => {
    if (deployment.state === DeploymentState.RUNNING_TESTS) {
      try {
        poll.value = await DeploymentsService.pollDeploymentStatus(deployment.dag_id)
      }
      catch (e) {
        console.error(`Error polling status: ${e}`)
      }
    }
  }

  watch(status, () => {
    if (status.value === DeploymentState.RUNNING_TESTS) {
      intervalTimer = setInterval(getPoll, POLLING_DELAY)
    }
    else {
      clearInterval(intervalTimer)
    }
  })

  const returnRunningStatusMap = (pollData: DagPollCounts) => {
    return {
      running: pollData.running,
      up_for_retry: pollData.up_for_retry,
      queued: pollData.queued,
      restarting: pollData.restarting,
      null: pollData.null,
    } as DagPollCountsPartial
  }

  watch(poll, async () => {
    if (poll.value) {
      const runningStatusMap = returnRunningStatusMap(poll.value?.counts)
      deployment.taskInfo = poll.value
      if (Object.values(runningStatusMap).filter((item: number | any) => item > 0)?.length) {
        status.value = DeploymentState.RUNNING_TESTS
      }
      else {
        if (poll.value?.counts.failed > 0) {
          status.value = DeploymentState.TESTS_FAILED
          deployment.failedTestInfo = poll.value?.failed_tasks
          await updateDeployment(deployment, DeploymentsUpdateKeyList.FAILED_TEST_INFO, poll.value?.failed_tasks)
          // mutate table object
          deployment.state = DeploymentState.TESTS_FAILED
          // update config if there is change to TESTS_FAILED
          await updateDeployment(deployment, DeploymentsUpdateKeyList.STATE, status.value)
        }
        else {
          status.value = DeploymentState.REVIEW_APPROVED
          // mutate table object
          deployment.state = DeploymentState.REVIEW_APPROVED
          // update config if there is change to AWAITING_REVIEWs
          await updateDeployment(deployment, DeploymentsUpdateKeyList.STATE, status.value)
        }
      }
    }
    else if (deployment.id) {
      status.value = DeploymentState.DEPLOYMENT_ERROR
      deployment.state = DeploymentState.DEPLOYMENT_ERROR
      await updateDeployment(deployment, DeploymentsUpdateKeyList.STATE, status.value)
      await updateDeployment(
        deployment,
        DeploymentsUpdateKeyList.DEPLOYMENT_ERROR,
        `No dag found for dag id ${deployment?.dag_id}`,
      )
      deployment.deploymentError = `No dag found for dag id ${deployment?.dag_id}`

      console.error(deployment.deploymentError)
    }
  })

  return {
    getPoll,
    status,
    poll,
  }
}
