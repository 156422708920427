import router from '@router/index'
import { useFeatureFlags } from './useFeatureFlags'
import { useExperimenterFormState } from './useExperimenterFormState'
import type { Level4Module, WizardFormType } from '@/level4/data/forms'
import { useForms } from '@/level4/data/forms'

export enum WizardDirection {
  Next = 'next',
  Prev = 'prev',
}

export function useWizardNavigation() {
  const experimenterFormState = useExperimenterFormState()
  const { MODULE_TO_STEPS_MAP } = useForms(experimenterFormState)
  const { isFOFinOFEEnabled } = useFeatureFlags()

  const formState = reactive({
    currentStep: undefined as WizardFormType | undefined,
    currentModule: undefined as Level4Module | undefined,
    currentLayoutComponent: undefined as Component | undefined,
    currentStepIndex: 0,
    direction: WizardDirection.Next as WizardDirection,
  })

  const route = useRoute()
  const transitionName = computed<string>(() => {
    return formState.direction === WizardDirection.Next
      ? 'slide-fade-left'
      : 'slide-fade-right'
  })

  watch(() => [route.path, route.query], () => {
    void until(experimenterFormState.isFormStateLoading).toBe(false).then(async () => {
      formState.currentStep = undefined
      formState.currentModule = route.path.split('/').pop() as Level4Module
      const moduleToStepsMapGetter = formState.currentModule && MODULE_TO_STEPS_MAP.get(formState.currentModule)
      const moduleStepsMapData = moduleToStepsMapGetter && (await moduleToStepsMapGetter())

      formState.currentLayoutComponent = moduleStepsMapData && moduleStepsMapData[0]?.layout
      formState.currentStep = route.query?.step ? route.query?.step as WizardFormType : moduleStepsMapData && moduleStepsMapData[0]?.steps[0] as WizardFormType
      formState.currentStepIndex = (moduleStepsMapData && moduleStepsMapData[0]?.steps.findIndex(step => step === formState.currentStep)) ?? 0
    })
  }, { immediate: true })

  const quitWizardNavigation = async () => {
    const moduleToStepsMapGetter = formState.currentModule && MODULE_TO_STEPS_MAP.get(formState.currentModule)
    const moduleStepsMapData = moduleToStepsMapGetter && (await moduleToStepsMapGetter())

    const isLastStep = moduleStepsMapData && formState.currentStepIndex === moduleStepsMapData[0]?.steps.length - 1 && formState.direction === WizardDirection.Next
    const redirectRoute = moduleStepsMapData && moduleStepsMapData[0]?.redirectAfterLastStep
    const quitRoute = moduleStepsMapData && moduleStepsMapData[0]?.redirectOnQuit

    if (isLastStep && redirectRoute && !isFOFinOFEEnabled.value) {
      await router.push(redirectRoute)
    }
    else {
      if (quitRoute) {
        await router.push(quitRoute)
      }
      else {
        const parentPath = route.path.split('/').slice(0, -1).join('/')
        await router.push({ path: parentPath })
      }
    }
  }

  const openCurrentFormStep = async () => {
    const moduleToStepsMapGetter = formState.currentModule && MODULE_TO_STEPS_MAP.get(formState.currentModule)
    const moduleStepsMapData = moduleToStepsMapGetter && (await moduleToStepsMapGetter())
    formState.currentStep = moduleStepsMapData && moduleStepsMapData[0]?.steps[formState.currentStepIndex] as WizardFormType

    if (!formState.currentStep) {
      if (formState.direction === WizardDirection.Next) {
        // eslint-disable-next-line ts/no-use-before-define
        await nextStep()
        return
      }
      else {
        // eslint-disable-next-line ts/no-use-before-define
        await prevStep()
        return
      }
    }

    await router.push(
      {
        name: formState.currentModule,
        query: { step: formState.currentStep },
      },
    )
  }

  const nextStep = async () => {
    formState.direction = WizardDirection.Next

    const moduleToStepsMapGetter = formState.currentModule && MODULE_TO_STEPS_MAP.get(formState.currentModule)
    const moduleStepsMapData = moduleToStepsMapGetter && (await moduleToStepsMapGetter())
    const stepsLength = (moduleStepsMapData && moduleStepsMapData[0]?.steps.length - 1) ?? 1

    if (formState.currentStepIndex >= stepsLength) {
      await quitWizardNavigation()
      return
    }
    formState.currentStepIndex++
    await openCurrentFormStep()
  }

  const prevStep = async () => {
    formState.direction = WizardDirection.Prev
    if (formState.currentStepIndex === 0) {
      await quitWizardNavigation()
      return
    }
    formState.currentStepIndex--
    await openCurrentFormStep()
  }

  async function goToModule(module: Level4Module) {
    formState.currentModule = module
    formState.currentStepIndex = 0
    await openCurrentFormStep()
  }

  return {
    currentStep: toRef(formState, 'currentStep'),
    direction: toRef(formState, 'direction'),
    currentModule: toRef(formState, 'currentModule'),
    currentLayoutComponent: toRef(formState, 'currentLayoutComponent'),
    nextStep,
    prevStep,
    quitWizardNavigation,
    goToModule,
    transitionName,
  }
}
