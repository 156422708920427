import qs from 'qs'
import { Tags } from '@shared/utils/Tags'

import { PYTHON_MS_API_PREFIX, USECASE_ACTION_BANKS_FIELD } from '@shared/data/constants'
import type { HistoryRequestHash, UsecaseHistoryRequest } from '@shared/composables/useHistory'
import { useHistory } from '@shared/composables/useHistory'
import { getModelFromResponse, getQueryString, isEmpty } from '@shared/utils/helpers'
import http from '@app/utils/http-client'
import type { UseCaseConfigModelWithoutActionBank } from '@/usecases/models/UseCaseConfigModel'
import type { ConfigDimensionActionBank } from '@/usecases/models/server/ActionModel'
import type { UsecaseModelHistoryListItem } from '@/pipelines/models/server/PipelineModel'
import type { UseCaseModel, UseCaseModelWithoutConfig, UseCaseSectionModel, UseCaseSummaryModel } from '@/usecases/models/server/UseCaseModel.ts'

const USECASE_QUERY_PARAMS = {
  fields: ['id', 'name', 'description', 'displayName', 'metadata', 'status', 'updatedAt', 'createdAt'] as (keyof UseCaseModelWithoutConfig)[],
  populate: {
    createdByUser: {
      fields: ['username'],
    },
    updatedByUser: {
      fields: ['username'],
    },
    pipeline: {
      fields: ['id', 'name'],
    },
    client: {
      fields: ['id', 'name'],
    },
    controlledDimensions: {
      fields: ['dimension'],
    },
    tags: true,
  },
}

function listQuery(clientId: number, pipelineId: number) {
  return qs.stringify(
    {
      filters: {
        client: clientId,
        pipeline: pipelineId,
      },
      ...USECASE_QUERY_PARAMS,
    },
    { encodeValuesOnly: true },
  )
}

const populateUseCaseExceptConfigQuery = qs.stringify(USECASE_QUERY_PARAMS, { encodeValuesOnly: true })

function queryLastUpdateOnly() {
  return qs.stringify(
    {
      fields: ['id', 'name', 'updatedAt'],
    },
    { encodeValuesOnly: true },
  )
}

export default class UseCaseService {
  static async getAllUseCases(clientId: number, pipelineId: number): Promise<UseCaseModelWithoutConfig[]> {
    const response = await http.get(`/usecases?${listQuery(clientId, pipelineId)}`, { cache: false })
    return getModelFromResponse<UseCaseModelWithoutConfig>(response) as UseCaseModelWithoutConfig[]
  }

  static async getServerUseCaseLastUpdateAt(useCaseId: number): Promise<Date> {
    const response = await http.get(`/usecases/${useCaseId}?${queryLastUpdateOnly()}`, {
      cache: false,
    })

    const data = getModelFromResponse<UseCaseSummaryModel>(response) as UseCaseSummaryModel

    return data.updatedAtDate as Date
  }

  static async updateUseCaseConfig(
    usecaseName: string,
    clientName: string,
    config: UseCaseConfigModelWithoutActionBank,
  ) {
    const data = await UseCaseService.updateUseCaseSection(usecaseName, clientName, config, undefined, ['actionBanks'], true)
    return new Date(data.usecaseUpdatedAt)
  }

  static async updateUseCaseActionBank(
    usecaseName: string,
    clientName: string,
    actionBank: ConfigDimensionActionBank[],
  ) {
    const data = await UseCaseService.updateUseCaseSection(usecaseName, clientName, actionBank, USECASE_ACTION_BANKS_FIELD, undefined, true)
    return new Date(data.usecaseUpdatedAt)
  }

  static async updateUseCase(id: number, useCase: Partial<UseCaseModelWithoutConfig>): Promise<UseCaseModelWithoutConfig> {
    const { id: index, pipeline, client, createdByUser, updatedAt, createdAt, ...sanitizedUseCase } = useCase
    const payload: UseCaseModelWithoutConfig = { ...sanitizedUseCase }

    const response = await http.put(`/usecases/${id}?${populateUseCaseExceptConfigQuery}`, { data: payload })

    return getModelFromResponse<UseCaseModelWithoutConfig>(response) as UseCaseModelWithoutConfig
  }

  static async deleteUseCase(useCaseID: number): Promise<boolean> {
    if (isEmpty(useCaseID)) { throw new Error('UseCase id is required!') }

    return await http.delete(`/usecases/${useCaseID}`)
  }

  static async createUseCase(useCase: UseCaseModel) {
    if (isEmpty(useCase.name)) { throw new Error('UseCase name is required!') }
    if (isEmpty(useCase.client)) { throw new Error('UseCase client is required!') }
    if (isEmpty(useCase.pipeline)) { throw new Error('UseCase pipeline is required!') }

    const data = { ...useCase, tags: Tags.getProductTagObjects() }
    const response = await http.post<UseCaseModel>(`/usecases?${populateUseCaseExceptConfigQuery}`, { data })

    const useCaseModelWithConfig = getModelFromResponse<UseCaseModel>(response) as UseCaseModel

    const { config, ...useCaseModel } = useCaseModelWithConfig

    return useCaseModel as UseCaseModelWithoutConfig
  }

  static async getUsecaseHistoryList(usecaseId: number, useCache: boolean): Promise<UsecaseModelHistoryListItem[]> {
    const { usecaseHistoryListMap } = useHistory()

    const requestData: UsecaseHistoryRequest = {
      filters: {
        original: usecaseId,
      },
      populate: {
        updatedByUser: {
          fields: ['username'],
        },
      },
    }
    const query = qs.stringify(requestData, { encodeValuesOnly: true })
    const cachedAnswer = usecaseHistoryListMap.get(usecaseId)?.get(query)

    if (cachedAnswer && useCache) { return cachedAnswer }

    const response = await http.get(`/usecases-history?${query}`, { cache: false })

    if (!usecaseHistoryListMap.has(usecaseId)) { usecaseHistoryListMap.set(usecaseId, new Map<HistoryRequestHash, UsecaseModelHistoryListItem[]>([])) }

    usecaseHistoryListMap.get(usecaseId)?.set(query, response.data as UsecaseModelHistoryListItem[])

    return usecaseHistoryListMap.get(usecaseId)?.get(query) || []
  }

  static async getUseCasesSection<T>(
    clientName: string,
    pipelineName: string,
    sectionPath: string,
    usecase: string,
  ): Promise<UseCaseSectionModel<T>> {
    const params = {
      client: clientName,
      sectionPath,
      pipeline: pipelineName,
      usecase,
    }

    const response = await http.get<UseCaseSectionModel<T>>(`/usecases-section/get-section?${getQueryString(params)}`, { cache: false })
    return response.data
  }

  static async getUseCasesSectionArray<T>(
    clientName: string,
    pipelineName: string,
    sectionPath: string,
  ): Promise<UseCaseSectionModel<T>[]> {
    const params = {
      client: clientName,
      sectionPath,
      pipeline: pipelineName,
    }

    const response = await http.get<UseCaseSectionModel<T>[]>(`/usecases-section/get-section?${getQueryString(params)}`, { cache: false })
    return response.data
  }

  static async v2getUseCasesSection<T>(
    clientName?: string,
    pipelineName?: string,
    useCaseName?: string,
    sectionPath?: string,
    exclude?: string[],
  ): Promise<UseCaseSectionModel<T>[]> {
    const params = {
      client: clientName,
      sectionPath,
      pipeline: pipelineName,
      usecase: useCaseName,
      exclude,
    }

    const response = await http.get<UseCaseSectionModel<T>[]>(`/v2/usecases-section/get-section?${getQueryString(params)}`, { cache: false })
    return response.data
  }

  static async updateUseCaseSection<T>(
    usecaseName: string,
    clientName: string,
    sectionValue: T,
    sectionPath?: string,
    freezeField?: string[],
    hideValueInResponse?: boolean,
  ): Promise<UseCaseSectionModel<T>> {
    const data = {
      sectionValue,
      sectionPath,
      client: clientName,
      usecase: usecaseName,
      freezeField,
      hideValueInResponse,
    }

    const response = await http.put('/usecases-section/update-section', data)
    return response.data as UseCaseSectionModel<T>
  }

  static async getUseCaseHistoryConfigWithVersionId(
    clientName: string,
    usecaseName: string,
    pipelineName: string,
    product_env: string,
    historical_id: number,
  ): Promise<UseCaseModel> {
    const queryParams = {
      pipeline: pipelineName,
      usecase: usecaseName,
      client: clientName,
      product_env,
      historical_id,
    }

    const response = await http.get<UseCaseModel>(`${PYTHON_MS_API_PREFIX}/strapi/usecase_history?${getQueryString(queryParams)}`, { cache: false })
    return response.data
  }

  static async getUseCaseConfigFromEnvironment(usecase: string, pipeline: string, product_env: string, client: string) {
    const queryParams = {
      client,
      usecase,
      pipeline,
      product_env,
    }

    const response = await http.get<UseCaseModel>(`${PYTHON_MS_API_PREFIX}/strapi/get_usecase?${getQueryString(queryParams)}`, { cache: false })
    return response.data
  }

  static async addControlledDimension(clientName: string, pipelineName: string, usecaseName: string, dimensionName: string) {
    const response = await http.put<UseCaseModel>(`/clients/${clientName}/pipelines/${pipelineName}/usecases/${usecaseName}/add-controlled-dimension`, { data: { dimensionName } })
    return response.data
  }

  static async removeControlledDimension(clientName: string, pipelineName: string, usecaseName: string, dimensionName: string) {
    const response = await http.put<UseCaseModel>(`/clients/${clientName}/pipelines/${pipelineName}/usecases/${usecaseName}/remove-controlled-dimension`, { data: { dimensionName } })
    return response.data
  }

  static async checkDimensionBackfilling(clientName: string, pipelineName: string, usecaseName: string, dimensionName: string) {
    const response = await http.post<boolean>(`/clients/${clientName}/pipelines/${pipelineName}/usecases/${usecaseName}/check-controlled-dimension-backfill`, { data: { dimensionName } })
    return response.data
  }
}
