<script setup lang="ts">
import { OPERATOR_REPRESENTATION } from '@shared/data/mapAndLists'
import { getDescriptiveVersionOfConstantValue } from '@/filtering/utils/filters'
import { useUseCaseConfig } from '@/usecases/composables/useUseCaseConfig'
import type { CommonFilter, FilterCondition, MatchingOperator } from '@/filtering/models/FilterModel'

interface Props {
  expression: (CommonFilter | FilterCondition)[]
  nesting: number
  logicalOperator: MatchingOperator
  wrapperTag: 'div' | 'span'
  descriptiveMode: boolean
}

withDefaults(defineProps<Props>(), { expression: () => ([]),nesting: 0,logicalOperator: null,wrapperTag: 'span', })

const { nodeIsOperator, getNodeExpression, getNodeOperator } = useUseCaseConfig()
const isArray = Array.isArray
</script>

<template>
  <component :is="wrapperTag" class="font-mono text-sm">
    <span v-if="expression?.length > 0 && nesting === 0" class="text-slate-500">where </span>
    <template v-for="(item, idx) in expression" :key="idx">
      <template v-if="nodeIsOperator(item)">
        <template v-if="expression?.length > 1 && idx > 0">
          <span v-if="logicalOperator === 'and'" class="text-orange-500"><br>AND </span>
          <span v-else-if="logicalOperator === 'or'" class="text-orange-500"><br>OR </span>
        </template>
        <span v-if="nesting > 0">
          <br v-if="idx !== 0">
          <template v-if="expression?.length > 1 && getNodeExpression(item).length > 1">
            (</template></span><FilterRender :expression="getNodeExpression(item)" :nesting="1" :logicalOperator="getNodeOperator(item)"/>
        <span
          v-if="nesting > 0"
        >
          <template v-if="expression?.length > 1 && getNodeExpression(item).length > 1">)</template>
        </span>
      </template>
      <template v-else>
        <template v-if="expression?.length > 1 && idx > 0">
          <span v-if="logicalOperator === 'and'" class="text-orange-500"> AND </span>
          <span v-else-if="logicalOperator === 'or'" class="text-orange-500"> OR </span>
        </template>
        <span class="text-indigo-800 font-semibold">
          {{ item.colname || 'column' }}&nbsp;<span class="font-normal text-slate-500 tracking-widest rounded px-1">{{ OPERATOR_REPRESENTATION.get(item.compare_op) || '?' }}</span>
        </span>
        <template v-if="!(item.compare_op === 'notnull' || item.compare_op === 'isnull')"
          >&nbsp;<span
            :class="[
              `is-${isArray(item.compare_val) ? '' : 'not-'}array`,
              item.compare_val === 'True'
                ? 'text-green-500 bg-green-50'
                : item.compare_val === 'False'
                  ? 'text-red-500 bg-red-50'
                  : 'text-blue-500 bg-blue-50',
            ]"
            class="rounded px-1"
          >
            {{
              item.compare_val !== undefined
                ? descriptiveMode
                  ? getDescriptiveVersionOfConstantValue(item.compare_val)
                  : item.compare_val
                : 'value'
            }}
          </span>
        </template>
      </template>
    </template>
  </component>
</template>
