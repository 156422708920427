import { ChangeStatus, ConversionEvent, DIMENSION_TYPE, DimensionKey } from '@shared/data/constants'
import {
  CADENCE_TEMPLATE_COLUMNS,
  DIMENSION_WHAT_TYPE_VALUES,
  DimensionDefaultTriggerMap,
} from '@shared/data/mapAndLists'
import { getNewRootExpressionWrapper, isEmpty, mergeDeep } from '@shared/utils/helpers'
import { slugString, transDateStringShort } from '@shared/utils/transformHelpers'
import type { DimensionName, NullString } from '@shared/utils/Types'
import { Tags } from '@shared/utils/Tags.ts'
import type { PipelineModel } from '@/pipelines/models/server/PipelineModel.ts'
import type { TriggerDefaults } from '@/usecases/data/rewardTriggerDefaults'
import { TRIGGER_DEFAULTS } from '@/usecases/data/rewardTriggerDefaults'
import type {
  CustomerGroupConfigModel,
  DimensionActionMappingModel,
  DimensionActionMethodConfig,
  PredictionMethodConfig,
  PredictionMethodModel,
} from '@/usecases/models/CustomerGroupModel'
import type { DimensionConfigModel, DimensionConfigModelPartial } from '@/usecases/models/DimensionConfigModel'
import type {
  ExperiencesModel,
  ExperiencesModelPartial,
} from '@/usecases/models/ExperiencesModel'
import type { CommonFilter, RootFilterExpression } from '@/filtering/models/FilterModel'
import type { GuardrailConfigMatchingExpression, GuardrailConfigModel } from '@/usecases/models/GuardrailModel'
import type { ActionFieldModel, ActionModel, ActionValueModel } from '@/usecases/models/server/ActionModel'
import { ActionColumn } from '@/usecases/models/server/ActionModel'
import { TouchType } from '@/usecases/models/UseCaseConfigModel'
import type {
  GuardrailConfig,
  OfferfitGroupGuardrailsConfig,
  UseCaseConfigModel,
  UseCaseConfigModelWithoutActionBank,
} from '@/usecases/models/UseCaseConfigModel'
import { getExpressionModelsFromArray } from '@/filtering/utils/filters'
import { getActionId, getActionValueChangeStatus } from '@/usecases/utils/actionBank'

export function getRowValue(row: ActionModel, fieldModel: ActionFieldModel | string) {
  const actionFieldValues: ActionValueModel[] = row.fields || []
  const fieldName = typeof fieldModel === 'string' ? fieldModel : fieldModel.data.column
  const dataValue: ActionValueModel
    = actionFieldValues.find((item: ActionValueModel) => {
      return item.name === fieldName
    })
    || ({
      name: fieldName,
      value: '',
    } as ActionValueModel)

  return dataValue
}

export function populateUseCaseConfigArrayWithDefaults(usecaseConfig: UseCaseConfigModel, pipelineModel: PipelineModel) {
  if (
    usecaseConfig.send_progress_report
    && usecaseConfig.send_progress_report.sends
    && !usecaseConfig.send_progress_report.sends.length
  ) {
    usecaseConfig.send_progress_report.sends.push({
      every: 'day',
      recipients: [],
    })
  }

  if (pipelineModel && !Tags.isOFSS(pipelineModel) && usecaseConfig.prediction.regime.offerfit) {
    (usecaseConfig.prediction.regime.offerfit as OfferfitGroupGuardrailsConfig).pandas_assignment_query = 'fill'
  }
  else if (pipelineModel && Tags.isOFSS(pipelineModel) && !isEmpty((usecaseConfig.prediction.regime.offerfit as OfferfitGroupGuardrailsConfig).pandas_assignment_query)) {
    delete (usecaseConfig.prediction.regime.offerfit as OfferfitGroupGuardrailsConfig).pandas_assignment_query
  }

  return usecaseConfig
}

export function getDimensionType(value: DimensionKey | string): DIMENSION_TYPE {
  if (value === DimensionKey.CADENCE) {
    return DIMENSION_TYPE.CADENCE
  }

  if (value === DimensionKey.FREQUENCY) {
    return DIMENSION_TYPE.FREQUENCY
  }

  if (value === DimensionKey.DAYSOFWEEK) {
    return DIMENSION_TYPE.DAYSOFWEEK
  }

  if (DIMENSION_WHAT_TYPE_VALUES.includes(value)) {
    return DIMENSION_TYPE.WHAT
  }

  // all other dimensions are "how",
  // but let's keep this comment for case we have other types
  /* if (DIMENSION_HOW_TYPE_VALUES.includes(value)) {
    return DIMENSION_TYPE.HOW
  } */

  return DIMENSION_TYPE.HOW
}

// offerFitRegimeSection = {}

export function getUseCaseConfigWithoutActionBanks(): UseCaseConfigModelWithoutActionBank {
  return {
    path: {
      recs_file_dir: '',
    },
    channels: [],
    prediction: {
      regime: {
        offerfit: {
          prediction_method: 'offerfit',
          guardrails: {},
        },
      },
      guardrails: {
        augmentation: {
          from_data_assets: [],
          function_callbacks: [],
        },
        filter_based: {
          comparators: [],
          logic: [],
        },
      } as GuardrailConfig,
      dimension: {},
      schedule: {
        shap_calculation_frequency: 'daily',
        daily_training: true,
      },
      features_train_predict_omit: [],
      no_intervention_group: null,
      batch_size: 5000,
    },
    training: {
      legacy_reward_calculation: false,
      use_target_metric_as_reward: true,
      target_metric: {
        metric_name: '',
      },
      secondary_enabled: true,
      nb_days_back_for_historical_average: 30,
      experiences: [],
      event_sequences: [],
      attribution_type: TouchType.LAST_TOUCH,
    },
    send_progress_report: {
      staging_recipients: [],
      sends: [], // populated if empty in populateUseCaseConfigArrayWithDefaults method
    },
    events: {},
    filter_and_augment_recs: {
      delay_days_to_activation: 0,
      recs_filter: {},
      augmentation: {
        actions: {},
        features: [],
      },
    },
    deliver_recs: {
      strategy: 'bucket',
      rec_file_format: 'csv',
    },
    reporting_settings: {
      dates_to_exclude: [],
      start_date: '',
    },
  }
}

export function prepareUseCaseConfig(usecaseConfig: UseCaseConfigModelWithoutActionBank, pipelineModel: PipelineModel) {
  const mergedAndPopulatedConfig = populateUseCaseConfigArrayWithDefaults(
    mergeDeep(getUseCaseConfigWithoutActionBanks(), usecaseConfig),
    pipelineModel,
  )

  // soft migration of guardrails under the filter_based section
  if (mergedAndPopulatedConfig.prediction.guardrails.comparators) {
    mergedAndPopulatedConfig.prediction.guardrails.filter_based.comparators
        = mergedAndPopulatedConfig.prediction.guardrails.comparators
    delete mergedAndPopulatedConfig.prediction.guardrails.comparators
  }
  if (mergedAndPopulatedConfig.prediction.guardrails.logic) {
    mergedAndPopulatedConfig.prediction.guardrails.filter_based.logic = mergedAndPopulatedConfig.prediction.guardrails.logic
    delete mergedAndPopulatedConfig.prediction.guardrails.logic
  }

  return mergedAndPopulatedConfig
}

export const sortDimensionsOrderInConfigObject: (config: any) => any = (config: any): any => {
  const newDimensionsConfig: any = {}
  const oldOrderKeys: string[] = Object.keys(config)
  const targetDimensionIndex: number = oldOrderKeys.indexOf(DimensionKey.CADENCE)

  const newOrderKeys = oldOrderKeys.concat()

  if (targetDimensionIndex !== -1) {
    newOrderKeys.splice(targetDimensionIndex, 1)
    newOrderKeys.splice(0, 0, DimensionKey.CADENCE)
  }

  newOrderKeys.forEach((dimensionNameInConfig: string) => {
    newDimensionsConfig[dimensionNameInConfig] = config[dimensionNameInConfig] as DimensionConfigModel
  })

  return newDimensionsConfig
}

export function convertDimensionsConfigModelToPartial(dimension: DimensionConfigModel, name?: string): DimensionConfigModelPartial {
  const targetObject: DimensionConfigModelPartial = {
    ...dimension,
    original: dimension,
  }

  if (name) {
    targetObject.name = name
  }

  return targetObject
}

export function convertPartialDimensionsConfigModelToRegularConfigModel(dimension: DimensionConfigModelPartial): DimensionConfigModel {
  // eslint-disable-next-line unused-imports/no-unused-vars
  const { isNew, name, original, ...targetObject } = dimension

  return {
    ...targetObject,
    send_decision_to: targetObject.send_decision_to || [],
    agents: targetObject.agents ?? 'default',
  }
}

export function getDefaultFromTriggerDefaults(allEventNamesArray: NullString[], trigger: NullString, type: 'penalty' | 'primary' | 'secondary', field: string) {
  if (!trigger) {
    return null
  }

  const slugTrigger = slugString<TriggerDefaults>(trigger)

  if (!(slugTrigger in TRIGGER_DEFAULTS)) {
    return null
  }

  const typeData = TRIGGER_DEFAULTS[slugTrigger][type]
  type TriggerTypeData = typeof typeData

  const triggerName = typeData?.[field as keyof TriggerTypeData]?.[0]

  if (!triggerName) {
    return null
  }

  if (type === 'secondary' && field === 'event') {
    if (allEventNamesArray.includes(triggerName)) {
      return triggerName
    }

    return null
  }

  return triggerName
}

export function getTriggerFromDimension(dimension: NullString) {
  if (DimensionDefaultTriggerMap.has(<DimensionKey>dimension)) {
    return DimensionDefaultTriggerMap.get(<DimensionKey>dimension) ?? null
  }

  return null
}

export function getPrimaryFromTrigger(trigger: NullString) {
  if (trigger?.length) {
    return ConversionEvent.CONVERSION
  }

  return null
}

export function returnExperienceWithDefaults(allEventNamesArray: NullString[], experience?: ExperiencesModelPartial, cadenceString?: NullString) {
  const dimensionName = experience?.dimension || cadenceString || null
  const payload: ExperiencesModel = {
    channel: experience?.channel,
    dimension: dimensionName,
    trigger: experience?.trigger || getTriggerFromDimension(dimensionName),
    primary: {
      event: experience?.primary?.event || getPrimaryFromTrigger(experience?.trigger || getTriggerFromDimension(dimensionName)),
      days:
        experience?.primary?.days
        || getDefaultFromTriggerDefaults(allEventNamesArray, experience?.trigger || getTriggerFromDimension(dimensionName), 'primary', 'days'),
    },
    secondary: {
      event:
        experience?.secondary?.event
        || getDefaultFromTriggerDefaults(allEventNamesArray, experience?.trigger || getTriggerFromDimension(dimensionName), 'secondary', 'event'),
      days:
        experience?.secondary?.days
        || getDefaultFromTriggerDefaults(allEventNamesArray, experience?.trigger || getTriggerFromDimension(dimensionName), 'secondary', 'days'),
      turnoff_date:
        experience?.secondary?.event
        || getDefaultFromTriggerDefaults(allEventNamesArray, experience?.trigger || getTriggerFromDimension(dimensionName), 'secondary', 'event')
          ? transDateStringShort(new Date(Date.now() + 12096e5).toLocaleDateString())
          : null,
    },
    penalty: {
      event:
        experience?.penalty?.event
        || getDefaultFromTriggerDefaults(allEventNamesArray, experience?.trigger || getTriggerFromDimension(dimensionName), 'penalty', 'event'),
      days:
        experience?.penalty?.days
        || getDefaultFromTriggerDefaults(allEventNamesArray, experience?.trigger || getTriggerFromDimension(dimensionName), 'penalty', 'days'),
      value: experience?.penalty?.value,
    },
  }

  return payload
}

export function isActionFieldNameNotMandatory(valueName: string = '') {
  return ![ActionColumn.ACTION_ID, ActionColumn.ACTION_STATUS].includes(valueName as ActionColumn)
}

export function isActionFieldFromTemplate(currentDimensionName: string, actionFieldModel: ActionFieldModel) {
  if (actionFieldModel.data.column === ActionColumn.ACTION_ID) {
    return true
  }
  if (actionFieldModel.data.column === ActionColumn.ACTION_STATUS) {
    return true
  }

  return currentDimensionName.toLowerCase() === DimensionKey.CADENCE
    ? CADENCE_TEMPLATE_COLUMNS.includes(actionFieldModel.data.column as any)
    : false
}

export function markDuplicatedActions(data: ActionModel[]) {
  const validationStack: string[] = []
  const actionIdStack: string[] = []

  if (!data) {
    return
  }

  for (let i = 0; i < data.length; i++) {
    const actionModel: ActionModel = data[i]
    actionModel.isDuplicate = false

    const actionFieldValues: ActionValueModel[] | undefined = (actionModel).fields
    const actionId: string = getActionId(actionModel) || ''

    const actionValuesFingerprint: string
      = actionFieldValues
        ?.filter((item: ActionValueModel) => isActionFieldNameNotMandatory(item.name))
        ?.map((item: ActionValueModel) => item.value?.toString().trim())
        .join('') || ''

    if (validationStack.includes(actionValuesFingerprint) || actionIdStack.includes(actionId)) {
      actionModel.isDuplicate = true
    }
    else if (actionId !== '') {
      actionId && actionIdStack.push(actionId)
      actionValuesFingerprint && validationStack.push(actionValuesFingerprint)
    }
  }
}

export function markChangedActions(data: ActionModel[], liveData: ActionModel[]) {
  if (!data) {
    throw new Error('markChangedActions: method should receive "data"')
  }
  if (!liveData) {
    throw new Error('markChangedActions: method should receive "liveData"')
  }

  for (let i = 0; i < data.length; i++) {
    const actionModel: ActionModel = data[i]
    const liveRow = liveData.find((item: ActionModel) => item.key === actionModel.originalKey)
    actionModel.changeStatus = ChangeStatus.NONE

    if (!liveRow) {
      actionModel.changeStatus = ChangeStatus.ADDED
      continue
    }

    const actionFieldValues: ActionValueModel[] | undefined = (actionModel).fields

    for (let j = 0; j < actionFieldValues.length; j++) {
      const actionDataValue = actionFieldValues[j]

      const liveDataValue: ActionValueModel | undefined = liveRow ? getRowValue(liveRow, actionDataValue.name) : undefined
      const status: ChangeStatus = getActionValueChangeStatus(actionDataValue, liveDataValue)
      if (status !== ChangeStatus.NONE) {
        actionModel.changeStatus = status
      }
    }
  }
}

export function getDimensionActionMappingByGroupConfig(customerGroupConfig: CustomerGroupConfigModel): DimensionActionMappingModel {
  const result: DimensionActionMappingModel = new Map<DimensionName, PredictionMethodModel>([])

  const methodConfigObject: PredictionMethodConfig = customerGroupConfig.prediction_method as PredictionMethodConfig

  if (typeof customerGroupConfig.prediction_method === 'object') {
    const configDimensions: DimensionName[] = Object.keys(methodConfigObject)

    if (configDimensions.length > 0) {
      for (let i = 0; i < configDimensions.length; i++) {
        const configDimensionName = configDimensions[i]
        const actionId = (methodConfigObject as DimensionActionMethodConfig)[configDimensionName].kwargs?.assigned_action_id
        const targetObject: PredictionMethodModel = {
          method: methodConfigObject[configDimensionName].method,
        }

        if (actionId !== undefined) {
          targetObject.actionId = actionId
        }

        result.set(configDimensionName, targetObject)
      }
    }
  }

  return result
}

export function getFilterExpressionForGuardrail(guardrails: GuardrailConfigModel[], guardrailComparators: CommonFilter[], guardrailId: string) {
  const targetGuardrail: GuardrailConfigModel | undefined = guardrails.find(
    (item: GuardrailConfigModel) => item.id === guardrailId,
  )

  if (targetGuardrail) {
    return getExpressionModelsFromArray<GuardrailConfigMatchingExpression>(
      targetGuardrail.expression,
      guardrailComparators,
    ) as RootFilterExpression
  }

  return [getNewRootExpressionWrapper()] as RootFilterExpression
}
