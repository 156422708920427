const highlight = (element: HTMLElement) => {
  element.classList.add('copied')
  setTimeout(function () {
    element.classList.remove('copied')
  }, 1500)
}
const doubleClickHandler = async function (event: any) {
  const target = event.currentTarget
  await navigator.clipboard.writeText(target.textContent)
  highlight(target.parentElement ? target.parentElement : target)
  document.getSelection()?.removeAllRanges()
}

export const copyAbleDirective: Directive = {
  mounted: (el: HTMLElement) => {
    el.addEventListener('dblclick', doubleClickHandler)
  },
  beforeUnmount: (el: HTMLElement) => {
    el.removeEventListener('dblclick', doubleClickHandler)
  }
}
