import { loader } from '@guolao/vue-monaco-editor'

import * as monaco from 'monaco-editor'
import EditorWorker from 'monaco-editor/esm/vs/editor/editor.worker?worker'
import YamlWorker from '@app/utils/yaml.worker.js?worker'

export function install() {
  window.MonacoEnvironment = {
    getWorker(moduleId, label) {
      switch (label) {
        case 'editorWorkerService':
          return new EditorWorker()
        case 'yaml':
          return new YamlWorker()
        default:
          throw new Error(`Unknown label ${label}`)
      }
    },
  }

  loader.config({ monaco })
}
