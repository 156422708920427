import EmptyLayout from '@layouts/EmptyLayout.vue'
import type { RouteRecordRaw, Router } from 'vue-router'
import { store } from '@app/store'
import {
  LOCAL_STORAGE_CURRENT_CLIENT,
  REPORTING_ROUTE_NAMES,
  ReportTabKey,
} from '@shared/data/constants'
import router from '@router/index'
import { useUserPrivilegesForRouter } from '@/privileges/useUserPrivileges.ts'
import type { ClientModel } from '@/clients/models/server/ClientModel.ts'
import { INTEGRATION_PLATFORM } from '@/level4/data/l4_constants'
import WizardRouterViewVue from '@/level4/views/WizardRouterView.vue'

const PerformanceReportTab = async () => import('@/reporting/performance/pages/UpliftReportV2.page.vue')
const InsightsReportTab = async () => import('@/reporting/insights/pages/InsightsReportTab.page.vue')
const ModelPreferencesReportTab = async () => import('@/reporting/modelPreferences/pages/ModelPrefsV2.page.vue')
const ExperimenterPage = async () => import('@/level4/views/experimenters/ExperimenterPage.vue')
const UncontrolledDimensionsPage = async () => import('@/level4/views/experimenters/UncontrolledDimensionsPage.vue')
const ExperimentersPage = async () => import('@/level4/views/experimenters/ExperimentersPage.vue')
const ReportsPage = async () => import('@/reporting/shared/pages/ReportsPageWrapperL4.page.vue')
const EvergreenPage = async () => import('@/level4/views/experimenters/EvergreenPage.vue')
const CalendaredPage = async () => import('@/level4/views/experimenters/CalendaredPage.vue')

export enum Level4Route {
  LEVEL4 = 'app',
  SIGN_UP = 'sign-up',
  CLIENTS = 'clients',
  CLIENT = 'client',
  PIPELINES = 'pipelines',
  PIPELINE = 'pipeline',
  EXPERIMENTERS = 'experimenters',
  EVERGREEN = 'evergreen',
  CALENDARED = 'calendared',
  EXPERIMENTER = 'experimenter',
  AUDIENCE = 'audience',
  TYPE = 'type',
  INTEGRATIONS = 'integrations',
  USE_CREDENTIALS = 'use-credentials',
  TEMPLATES = 'templates',
  TEMPLATES_SELECTION = 'templates-selection',
  CREATE_VARIANTS = 'create-variants',
  BAU = 'bau-audience',
  FREQUENCY = 'frequency',
  DAYS_OF_WEEK = 'days-of-week',
  TIME = 'time',
  TEST_EMAILS = 'test-emails',
  REPORTS = 'reports',
  BRAZE = 'braze',
  SFMC = 'sfmc',
  KLAVIYO = 'klaviyo',
  LAUNCH_SFMC = 'launch-sfmc',
  LAUNCH_KLAVIYO = 'launch-klaviyo',
  CUSTOM_DIMENSIONS = 'custom-dimensions',
  GUARDRAILS = 'guardrails',
}

function getLevel4Routes(): RouteRecordRaw[] {
  return [{
    path: '/',
    name: Level4Route.LEVEL4,
    redirect: Level4Route.LEVEL4,
  }, {
    path: '/app',
    name: Level4Route.LEVEL4,
    meta: { title: 'App' },
    // @ts-expect-error
    redirect(to) {
      if (to.path !== '/app') { return }
      return store.getters['client/client']?.config?.industry
        ? '/app/clients'
        : '/app/sign-up'
    },
    component: EmptyLayout,
    children: [
      {
        path: 'sign-up',
        name: Level4Route.SIGN_UP,
        meta: { title: 'Sign Up' },
        component: WizardRouterViewVue,
        beforeEnter: async () => {
          if (store.getters['client/client']?.config?.industry) {
            return router.push({
              name: Level4Route.EXPERIMENTERS,
              params: {
                clientName: store.getters['client/client']?.name,
                pipelineName: 'default',
              },
            })
          }
        },
      },
      {
        path: 'clients',
        name: Level4Route.CLIENTS,
        meta: { title: 'Clients' },
        redirect: (to) => { return `${to.path}/${localStorage.getItem(LOCAL_STORAGE_CURRENT_CLIENT)}` },
        children: [{
          path: ':clientName',
          name: Level4Route.CLIENT,
          meta: { title: ':clientName' },
          redirect: (to) => { return `${to.path}/pipelines` },
          children: [
            {
              path: 'pipelines',
              name: Level4Route.PIPELINES,
              meta: { title: 'Pipelines' },
              redirect: (to) => { return `${to.path}/default` },
              children: [
                {
                  path: ':pipelineName',
                  name: Level4Route.PIPELINE,
                  meta: { title: ':pipelineName' },
                  redirect: (to) => { return `${to.path}/experimenters` },
                  children: [
                    {
                      path: 'experimenters',
                      name: Level4Route.EXPERIMENTERS,
                      meta: { title: 'Experimenters' },
                      component: ExperimentersPage,
                      beforeEnter: (from) => {
                        if (from.name !== Level4Route.EVERGREEN && from.name !== Level4Route.CALENDARED) {
                          return {
                            name: Level4Route.EVERGREEN,
                            params: {
                              clientName: store.getters['client/client']?.name,
                              pipelineName: 'default',
                            },
                          }
                        }
                      },
                      children: [
                        {
                          path: 'evergreen',
                          name: Level4Route.EVERGREEN,
                          component: EvergreenPage,
                        },
                        {
                          path: 'calendared',
                          name: Level4Route.CALENDARED,
                          component: CalendaredPage,
                        },
                      ],
                    },
                    {
                      path: 'experimenters/:experimenterName',
                      name: Level4Route.EXPERIMENTER,
                      meta: { title: 'Experimenter :experimenterName' },
                      component: ExperimenterPage,
                    },
                    {
                      path: 'experimenters/:experimenterName/type',
                      name: Level4Route.TYPE,
                      component: WizardRouterViewVue,
                    },
                    {
                      path: 'experimenters/:experimenterName/integrations',
                      name: Level4Route.INTEGRATIONS,
                      meta: { title: 'Integrations' },
                      component: WizardRouterViewVue,
                    },
                    {
                      path: 'experimenters/:experimenterName/use-credentials',
                      name: Level4Route.USE_CREDENTIALS,
                      meta: { title: 'Use Credentials' },
                      component: WizardRouterViewVue,
                    },
                    {
                      path: `experimenters/:experimenterName/integrations/${INTEGRATION_PLATFORM.BRAZE}`,
                      name: Level4Route.BRAZE,
                      meta: { title: 'Braze' },
                      component: WizardRouterViewVue,
                    },
                    {
                      path: `experimenters/:experimenterName/integrations/${INTEGRATION_PLATFORM.SFMC}`,
                      name: Level4Route.SFMC,
                      meta: { title: 'SFMC' },
                      component: WizardRouterViewVue,
                    },
                    {
                      path: `experimenters/:experimenterName/integrations/${INTEGRATION_PLATFORM.KLAVIYO}`,
                      name: Level4Route.KLAVIYO,
                      meta: { title: 'Klaviyo' },
                      component: WizardRouterViewVue,
                    },
                    {
                      path: 'experimenters/:experimenterName/audience',
                      name: Level4Route.AUDIENCE,
                      meta: { title: 'Audience' },
                      component: WizardRouterViewVue,
                    },
                    {
                      path: 'experimenters/:experimenterName/templates',
                      name: Level4Route.TEMPLATES,
                      meta: { title: 'Templates' },
                      component: WizardRouterViewVue,
                    },
                    {
                      path: 'experimenters/:experimenterName/templates-selection',
                      name: Level4Route.TEMPLATES_SELECTION,
                      meta: { title: 'Templates Selection' },
                      component: WizardRouterViewVue,
                    },
                    {
                      path: 'experimenters/:experimenterName/templates/:templateId/create-variants',
                      name: Level4Route.CREATE_VARIANTS,
                      meta: { title: 'Create Variants' },
                      component: WizardRouterViewVue,
                    },
                    {
                      path: 'experimenters/:experimenterName/bau-audience',
                      name: Level4Route.BAU,
                      meta: { title: 'BAU Audience' },
                      component: WizardRouterViewVue,
                    },
                    {
                      path: 'experimenters/:experimenterName/frequency',
                      name: Level4Route.FREQUENCY,
                      meta: { title: 'Frequency' },
                      component: WizardRouterViewVue,
                    },
                    {
                      path: 'experimenters/:experimenterName/days-of-week',
                      name: Level4Route.DAYS_OF_WEEK,
                      meta: { title: 'Days of Week' },
                      component: WizardRouterViewVue,
                    },
                    {
                      path: 'experimenters/:experimenterName/time',
                      name: Level4Route.TIME,
                      meta: { title: 'Time' },
                      component: WizardRouterViewVue,
                    },
                    {
                      path: 'experimenters/:experimenterName/guardrails',
                      name: Level4Route.GUARDRAILS,
                      meta: { title: 'Guardrails' },
                      component: WizardRouterViewVue,
                    },
                    {
                      path: 'experimenters/:experimenterName/launch-sfmc',
                      name: Level4Route.LAUNCH_SFMC,
                      meta: { title: 'Launch SFMC' },
                      component: WizardRouterViewVue,
                    },
                    {
                      path: 'experimenters/:experimenterName/custom-dimensions/:dimensionName',
                      name: Level4Route.CUSTOM_DIMENSIONS,
                      meta: { title: 'Custom Dimensions' },
                      component: UncontrolledDimensionsPage,
                    },
                    {
                      path: 'experimenters/:experimenterName/launch-klaviyo',
                      name: Level4Route.LAUNCH_KLAVIYO,
                      meta: { title: 'Launch Klaviyo' },
                      component: WizardRouterViewVue,
                    },
                    {
                      path: 'experimenters/:experimenterName/test-emails',
                      name: Level4Route.TEST_EMAILS,
                      meta: { title: 'Test Emails' },
                      component: WizardRouterViewVue,
                    },
                    {
                      path: 'reports',
                      name: Level4Route.REPORTS,
                      meta: { title: 'Reports' },
                      component: ReportsPage,
                      beforeEnter: async (to) => {
                        if (to.name === Level4Route.REPORTS) {
                          const clientName = computed(() => (store.getters['client/client'] as ClientModel)?.name)
                          const { allowedReports } = await useUserPrivilegesForRouter(clientName)
                          if (allowedReports.value?.length) {
                            return { name: allowedReports.value.includes(REPORTING_ROUTE_NAMES.UPLIFT) ? REPORTING_ROUTE_NAMES.UPLIFT : (allowedReports.value[0] ?? Level4Route.EXPERIMENTERS) }
                          }
                          return { name: REPORTING_ROUTE_NAMES.UPLIFT }
                        }

                        return true
                      },
                      children: [
                        {
                          name: REPORTING_ROUTE_NAMES.UPLIFT,
                          path: ReportTabKey.USE_CASE_PERFORMANCE,
                          meta: { title: 'Uplift' },
                          component: PerformanceReportTab,
                        },
                        {
                          name: REPORTING_ROUTE_NAMES.INSIGHTS,
                          path: ReportTabKey.INSIGHTS,
                          meta: { title: 'Insights' },
                          component: InsightsReportTab,
                        },
                        {
                          name: REPORTING_ROUTE_NAMES.MODEL_PREFERENCES,
                          path: ReportTabKey.MODEL_PREFERENCES,
                          meta: { title: 'Model Preferences' },
                          component: ModelPreferencesReportTab,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        }],
      },
    ],
  }]
}

export function initL4Routes(localRouter: Router) {
  const level4Routes = getLevel4Routes()
  level4Routes.forEach(r => localRouter.addRoute(r))
}
