export const chartBaseColors = {
  dark: {
    10: '#8336E6',
    20: '#0690ED',
    30: '#58C897',
    40: '#8381E0',
    50: '#DC7CFF',
    60: '#FFB9A0',
    70: '#94CCFF',
    80: '#EB90D7',
    90: '#56D79E',
    100: '#8336E6',
    110: '#C6C5C5'
  },
  light: {
    10: '#EADAFF',
    20: '#CCEAFF',
    30: '#DCFAED',
    40: '#ECEBFB',
    50: '#F7E4FE',
    60: '#FFEBE5',
    70: '#E2F1FF',
    80: '#FFEAFA',
    90: '#E2FBEC',
    100: '#EADAFF',
    110: '#EBEAEA',
  },
}

export const chartColorBlindColors = {
  dark: {
    // Standard TOL Color Blind Safe Palette
    10: '#332288',
    20: '#117733',
    30: '#44aa99',
    40: '#88ccee',
    50: '#ddcc77',
    60: '#cc6677',
    70: '#aa4499',
    80: '#882255',
  },
  light: {
    10: '#f1f1f8',
    20: '#eef8f0',
    30: '#f0fcf7',
    40: '#eef4fc',
    50: '#faf6ea',
    60: '#fdeff4',
    70: '#fceff9',
    80: '#fceef6',
  },
}
