<template>
  <div v-if="!item?.disabled" class="cursor-pointer p-1">
    <div class="font-semibold">{{ item?.label }}</div>
    <div class="text-gray-500">{{ item.description }}</div>
  </div>
  <NTooltip v-else trigger="hover" :theme-overrides="themeOverrides" placement="bottom-left">
    <template #trigger>
      <div class="cursor-pointer flex">
        <div class="font-semibold">{{ item?.label }}</div>
        <NoSymbolIcon class="w-4 h-4 ml-1 mt-1 text-red-300" />
      </div>
    </template>
    <span v-if="item.type === 'pipeline'">This {{ item?.type }} cannot be promoted. There are either no new changes, or this pipeline uses sample data.</span>
    <span v-else>This {{ item?.type }} cannot be promoted, as there are no new changes.</span>

  </NTooltip>
</template>

<script lang="ts">
import { NoSymbolIcon } from '@heroicons/vue/24/outline'
import { NTooltip, TooltipProps } from 'naive-ui'
type InputOverrides = NonNullable<TooltipProps['themeOverrides']>
const themeOverrides: InputOverrides = {
  peers: {
    Popover: {
      color: '#333'
    }
  }
}

export default defineComponent({
  name: 'PromotionItemRender',
  components: {
    NoSymbolIcon,
    NTooltip
  },
  props: {
    item: Object as PropType<any>
  },
  setup() {
    return {
      themeOverrides
    }
  }
})
</script>
