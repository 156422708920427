import http from '@app/utils/http-client'
import type { UseCaseModel } from '@/usecases/models/server/UseCaseModel.ts'

export default class UsecaseService {
  static async updateSelectedEmails(client: string, pipeline: string, usecase: string) {
    return http.put(`v2/clients/${client}/pipelines/${pipeline}/usecases/${usecase}/update-selected-emails`)
  }

  static async getUsecase(client: string, pipeline: string, usecase: string) {
    return (await http.get<UseCaseModel>(`clients/${client}/pipelines/${pipeline}/usecases/${usecase}`)).data
  }
}
