import AppSettings from '@app/AppSettings'
import { store } from '@app/store'
import { loadTasks } from '@app/utils/loadTasks'
import router from '@router/index'

import {
  CLIENT_SELECT_ROUTE,
  ROLE_TYPE,
  UseCaseStatus,
} from '@shared/data/constants'
import { handlePortalError } from '@shared/utils/errorHandling.ts'
import type { SelectItemWithStatus } from '@shared/utils/Types'

import { usePermissions } from '@/auth/composables/usePermissions'
import { useUseCases } from '@/usecases/composables/useUseCases'
import { Level4Route } from '@/level4/router/level4'
import type { ClientModel } from '@/clients/models/server/ClientModel'
import { useClientConfig } from '@/clients/composables/useClientConfig'
import K8TaskService from '@/dags/services/tasks/K8TaskService'

/**
 * This function is used to initialize the user client data
 * We cannot call router.currentRoute.value.path in this function. It will return undefined
 * @param requestedURLToOpen
 */
export async function initializeUserClientData(requestedURLToOpen?: string | null) {
  const { loadClientData } = useClientConfig()
  const {
    level4Enabled,
    level3Enabled,
    isUserAuthenticated,
  } = usePermissions()

  const {
    clientName,
    usecaseName,
    pipelineName,
    isLevel4Route,
    LSClientName,
    LSPipelineName,
    LSUsecaseName,
  } = AppSettings

  const myClients: ClientModel[] = store.getters['user/clients'] || []
  const existingClient: ClientModel | undefined = myClients.find(
    (client: ClientModel) => clientName.value === client.name,
  ) || myClients?.[0]

  if (!clientName.value && existingClient) {
    LSClientName.value = existingClient.name
  }

  try {
    await loadClientData(clientName.value, pipelineName.value)
    AppSettings.clientDataIsLoaded.value = true
  }
  catch (e: any) {
    const client = AppSettings.clientName.value
    LSClientName.value = ''
    LSPipelineName.value = ''
    LSUsecaseName.value = ''
    await router.push({ name: CLIENT_SELECT_ROUTE })
    handlePortalError(e, {
      defaultUserErrorText: `You don't have access to client "${client}"`,
      roleErrorTextMap: {
        [ROLE_TYPE.AIE]: `In order to open client "${client}", you need to add the client to your clients list.`,
      },
    })
    return
  }

  if (level3Enabled.value && AppSettings.tasksEnabled.value && isUserAuthenticated.value && store.getters['client/client']) {
    await loadTasks()
    K8TaskService.pullStatuses()
  }

  // if isLevel4 and no pipelines loaded, let 06.isClientSupported.ts middleware handle it
  if (
    isLevel4Route.value
    && level4Enabled.value
    && !store.getters['pipeline/pipelines']?.length
  ) {
    return
  }

  if (
    isLevel4Route.value
    && level4Enabled.value
    && store.getters['pipeline/pipelines']?.length
    && !existingClient.config?.industry
  ) {
    await router.push({ name: Level4Route.SIGN_UP })
    return
  }

  if (requestedURLToOpen) { await router.push(requestedURLToOpen) }
  else { await router.push('/') }
}

export async function updateReportingUseCaseLocalStorageValue() {
  const { useCasesList } = useUseCases()
  const { LSUsecaseName } = AppSettings
  if (useCasesList.value.length) {
    const liveUseCases = useCasesList.value.filter((item: SelectItemWithStatus) => item.status === UseCaseStatus.LIVE)
    if (liveUseCases.length) {
      LSUsecaseName.value = liveUseCases[0].value
    }
    else {
      LSUsecaseName.value = useCasesList.value[0]?.value
    }
  }
}
