<script setup lang="ts">
import { VueMonacoDiffEditor } from '@guolao/vue-monaco-editor'
import type { editor } from 'monaco-editor'

withDefaults(defineProps<Props>(), {  })

const emit = defineEmits<{
  confirm: [string]
  cancel: [void]
}>()

const CodeEditor = defineAsyncComponent(() => import('@shared/components/CodeEditor.vue'))
const isDiffEnabled = ref(true)

export interface Props {
  title: string
  isLoading?: boolean
  oldString: string
  newString: string
}

const code = ref<string>(__props.newString)
const diffOptions: editor.IStandaloneDiffEditorConstructionOptions = {
  automaticLayout: true,
  readOnly: true,
  hideUnchangedRegions: {
    enabled: true,
    contextLineCount: 5,
  },
}
</script>

<template>
  <OFModal
    class="!w-full"
    :title="title"
    :isOpened="true"
    :isLoading="isLoading"
    confirmButtonLabel="Apply"
    cancelButtonLabel="Cancel"
    :onConfirm="() => emit('confirm', code)"
    :onCancel="() => emit('cancel')"
  >
    <div class="flex justify-center">
      <NRadioGroup v-model:value="isDiffEnabled" name="diffEnabledRadioGroup">
        <NRadioButton :value="true" class="flex items-center" title="Difference view">
          <span class="i-solar-mirror-left-outline">WW</span> Diff
        </NRadioButton>
        <NRadioButton :value="false" class="flex items-center" title="Editor view">
          <span class="i-solar-code-file-linear">WW</span> Edit
        </NRadioButton>
      </NRadioGroup>
    </div>

    <keep-alive>
      <VueMonacoDiffEditor
        v-if="isDiffEnabled"
        key="code-diff"
        :original="oldString"
        :modified="code"
        :options="diffOptions"
        theme="vs-dark"
        language="yaml"
        border
        placeholder="Yaml text here"
        class="min-h-120"
      />
      <CodeEditor
        v-else
        key="code-editor"
        v-model:code="code"
        class="mb-8 mt-3 h-700px text-xs"
        label="New configuration"
        language="yaml"
        height="700px"
      />
    </keep-alive>
  </OFModal>
</template>
