import { GetterTree } from 'vuex'

import { AppState } from '@app/store/app'
import { UserNotificationsStateType } from '@/notifications/store/state'

const getters: GetterTree<UserNotificationsStateType, AppState> = {
  userNotifications(state) {
    return state.notifications
  },
  unreadNotificationsCount(state) {
    return state.notifications.filter((notification) => !notification.read).length
  }
}

export default getters
