import type { MutationTree } from 'vuex'

import type { ClientModel } from '@/clients/models/server/ClientModel'
import type { UserModel, UserModelPartial } from '@/users/models/server/UserModel'
import type { UserStateType } from '@/users/store/state'

const mutations: MutationTree<UserStateType> = {
  setUserData(state: UserStateType, user: UserModel) {
    state.id = user.id
    state.username = user.username
    state.picture = user.picture
    state.email = user.email
    state.blocked = user.blocked
    state.confirmed = user.confirmed
    state.tos_accepted = user.tos_accepted
    state.is_read_only = user.is_read_only
    state.provider = user.provider
    state.role = user.role
    state.clients = user.clients
    state.allClients = user.allClients
    state.userObject = user
  },
  updateUserData(state: UserStateType, user: UserModelPartial) {
    type UserStateField = keyof UserStateType
    type UserModelField = keyof UserModelPartial
    Object.keys(user).forEach((key: string) => {
      state[key as UserStateField] = user[key as UserModelField] as never
    })
  },
  clients(state: UserStateType, clients: ClientModel[]) {
    state.clients = clients
  },
  allClients(state: UserStateType, clients: ClientModel[]) {
    state.allClients = clients
  },
}

export default mutations
