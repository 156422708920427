import type { UseCasesState } from '@/usecases/store/state'
import type { UseCaseModelWithoutConfig } from '@/usecases/models/server/UseCaseModel.ts'

export function useUseCasesMutations(_state: UseCasesState) {
  const state = _state

  return {
    setUsecases: (value: UseCaseModelWithoutConfig[]) => {
      state.usecases = value
    },
    addNewUseCase: (value: UseCaseModelWithoutConfig) => {
      if (!state.usecases) { state.usecases = [] }
      state.usecases.push(value)
    },
    deleteUseCase: (useCaseID: number) => {
      if (!state.usecases) { state.usecases = [] }
      state.usecases = state.usecases.filter((item: UseCaseModelWithoutConfig) => item.id !== useCaseID)
    },
  }
}
