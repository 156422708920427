<script lang="ts">
import debounce from 'lodash/debounce'
import { ChevronDownIcon } from '@heroicons/vue/24/solid'
import BasicSelect from '@shared/components/basic/BasicSelect.vue'

import { ChangeStatus, REQUEST_DEBOUNCE_DELAY } from '@shared/data/constants'
import { useStore } from '@app/store'
import type { NullString } from '@shared/utils/Types'
import { trackEvent } from '@shared/utils/analytics'
import type { NaiveSelectItem } from '@shared/utils/naiveProxy'

export default defineComponent({
  name: 'TableEditableSelect',
  components: { BasicSelect, ChevronDownIcon },
  props: {
    id: {
      type: String as PropType<string>,
      required: true,
    },
    emptyPlaceholder: {
      type: String as PropType<NullString>,
      default: ' ',
    },
    options: Array as PropType<NaiveSelectItem[]>,
    placeholder: String as PropType<string | undefined>,
    modelValue: String as PropType<NullString>,
    smallText: Boolean as PropType<boolean>,
    showInput: Boolean as PropType<boolean>,
    changedStatus: String as PropType<ChangeStatus>,
    ellipsis: Boolean as PropType<boolean>,
    overflowHidden: {
      type: Boolean as PropType<boolean>,
      default: () => false,
    },
    underlined: Boolean as PropType<boolean>,
    useLowerCaseForValue: Boolean as PropType<boolean>,
    error: [String, Object] as PropType<NullString | Ref>,
    filterable: Boolean as PropType<boolean>,
    multiple: Boolean as PropType<boolean>,
    tag: Boolean as PropType<boolean>,
    size: {
      type: String as PropType<'tiny' | 'small' | 'medium' | 'large'>,
      default: 'small',
    },
  },
  emits: ['update:modelValue', 'blur'],
  setup(props, context) {
    const store = useStore()
    const isEdit = ref(false)
    const inputRef = ref<any>(null)

    const localValue = computed({
      get: () => props.modelValue,
      set: (value: any) => {
        context.emit('update:modelValue', value as any)
        // eslint-disable-next-line ts/no-use-before-define
        debouncedFieldChange()
      },
    })

    const debouncedFieldChange = debounce(() => {
      if (props.id) {
        trackEvent(props.id, { type: 'table-select', action: localValue.value !== '' ? 'change' : 'clear' })
      }
    }, REQUEST_DEBOUNCE_DELAY)

    const errorText: ComputedRef<NullString> = computed<NullString>(() => {
      return isRef(props.error) ? (props.error as Ref)?.value : props.error
    })

    const valueTitle: ComputedRef<NullString> = computed<NullString>(() => {
      let titleString: NullString = localValue.value

      const errorMessage: NullString = isRef(errorText) ? errorText.value : (errorText as string)

      if (errorMessage) {
        titleString = (localValue.value ? `${titleString} - ${errorMessage}` : errorMessage) as string
      }

      return titleString
    })

    const formattedValue: ComputedRef<NullString> = computed<NullString>(() => {
      if (!props.options) { return 'Unknown' }
      const valueFromOptions = props.options.find((item: any): boolean => {
        const val = typeof item === 'object' ? item.value : item
        return val === (props.useLowerCaseForValue ? localValue.value?.toString().toLowerCase() : localValue.value)
      })

      if (!valueFromOptions) { return localValue.value }

      return typeof valueFromOptions === 'object' ? (valueFromOptions.label as string) : (valueFromOptions as string)
    })

    const clickHandler = () => {
      isEdit.value = true
      nextTick(() => {
        inputRef.value?.focus?.()
      })
    }

    const blurAndEmitData = () => {
      context.emit('blur', localValue.value)
    }

    const inputBlurCapturedHandler = () => {
      blurAndEmitData()
    }

    const blurInput = () => {
      if (!errorText.value) {
        isEdit.value = false
      }
    }

    const enterKeyDownHandler = () => {
      blurInput()
    }

    const inputBlurHandler = () => {
      blurInput()
    }

    return {
      ChangeStatus,
      formattedValue,
      isEdit,
      inputRef,
      clickHandler,
      localValue,
      isNaN: Number.isNaN,
      errorText,
      valueTitle,
      enterKeyDownHandler,
      inputBlurHandler,
      inputBlurCapturedHandler,
      globalReadOnlyMode: computed(() => store.getters.isReadonlyMode),
    }
  },
})
</script>

<template>
  <div :class="[!($attrs.class as string)?.includes('w-') ? 'w-full' : '']" @keydown.enter="enterKeyDownHandler">
    <div
      v-if="!(isEdit || showInput) || globalReadOnlyMode"
      class="relative h-full w-full"
      :title="valueTitle || ''"
      style="padding: 3px 14px 3px 7px"
      :class="{
        'cursor-pointer hover:bg-purple-100 rounded-md border-dashed border border-gray-300': !globalReadOnlyMode,
        'overflow-hidden': overflowHidden,
        'whitespace-nowrap text-ellipsis max-w-full': ellipsis,
        'underline': underlined,
        'text-error': !!errorText && (localValue || isNaN(Number(localValue))),
        'text-gray-400': localValue === '' && !errorText,
        'text-red-200': localValue === '' && !!errorText,
        'bg-yellow-100 hover:bg-yellow-100': changedStatus === ChangeStatus.CHANGED,
        'bg-red-100 hover:bg-red-100': changedStatus === ChangeStatus.DELETED,
        'bg-green-100 hover:bg-green-100': changedStatus === ChangeStatus.ADDED,
      }"
      @click="clickHandler"
    >
      {{ formattedValue || emptyPlaceholder }}

      <ChevronDownIcon class="absolute right-2 top-1.5 h-4 w-4 text-gray-400" />
    </div>
    <BasicSelect
      v-else
      :id="id"
      ref="inputRef"
      v-model="localValue"
      class="simple-select w-full"
      :options="options"
      :smallText="smallText"
      :placeholder="placeholder"
      :tag="tag"
      :size="size"
      show
      :filterable="filterable"
      :multiple="multiple"
      :error="errorText"
      errorIsAbsolutPositioned
      @blur.capture="inputBlurCapturedHandler"
      @blur="inputBlurHandler"
      @click="isEdit = false"
    />
  </div>
</template>

<style scoped>
:deep(.simple-select) select {
  @apply py-1;
}
</style>
