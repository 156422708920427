import { ClientModel } from '@/clients/models/server/ClientModel.ts';
import AppSettings from '@app/AppSettings'
import { useStore } from '@app/store';
import * as Sentry from '@sentry/vue'
import { debug } from '@shared/utils/logger'
import Global from '@shared/utils/global'
import { Tags } from '@shared/utils/Tags'
import { useUserPrivileges } from '@/privileges/useUserPrivileges.ts'
import { SERVICE_ERROR_GLOBAL_MESSAGE } from '@/level4/data/l4_constants'
import { LLMService } from '@/level4/services/LLMService'

import { useUseCases } from '@/usecases/composables/useUseCases'
import type { PipelineModel } from '@/pipelines/models/server/PipelineModel'
import UseCaseService from '@/usecases/services/UseCaseService'

export function useClientConfig() {
  const { setUsecases } = useUseCases()
  const store = useStore()
  const clientObject = computed<ClientModel | undefined>(() => store.getters['client/client'])
  const clientName = computed(() => clientObject.value?.name)
  const getClientConfig = computed(() => clientObject.value?.config)
  useUserPrivileges(clientName)
  const { LSPipelineName, LSClientName } = AppSettings
  const loadClientData = async (clientName: string, pipelineName?: string) => {
    debug('Loading client data', clientName, pipelineName)

    if (!clientName.length) {
      throw new Error('Client name is required to load client data')
    }

    await store.dispatch('client/getClient', clientName)
    LSClientName.value = clientName
    await store.dispatch('pipeline/getPipelinesByClient', { clientID: clientObject.value?.id, tags: Tags.getProductTags() })

    if (store.getters['pipeline/pipelines']?.length) {
      const selectedPipeline
        = store.getters['pipeline/pipelines'].find(
          (pipeline: PipelineModel) => pipeline.name.toLowerCase() === (pipelineName?.toLowerCase()),
        ) || store.getters['pipeline/pipelines'][0]

      LSPipelineName.value = selectedPipeline.name

      // prepare client's usecases
      if (clientObject.value?.id && selectedPipeline) {
        setUsecases(await UseCaseService.getAllUseCases(clientObject.value.id, selectedPipeline.id))
      }
      else {
        setUsecases([])
      }

      store.commit('pipeline/pipeline', selectedPipeline)
    }
  }

  const generateCompanyIndustry = async () => {
    if (!clientObject.value?.name) { throw new Error('Company name is required') }

    try {
      const { company_info } = await LLMService.getCompanyIndustry(clientObject.value?.displayName || clientObject.value?.name)
      return company_info
    }
    catch (e: any) {
      Global.error(SERVICE_ERROR_GLOBAL_MESSAGE)
      console.error(e)
      Sentry.captureException(e)
      throw new Error(e)
    }

    return ''
  }

  const setCompanyDisplayName = (value: string) => {
    store.commit('client/clientDisplayName', value)
    void store.dispatch('client/updateClientDetails')
  }

  const setCompanyIndustry = (value: string) => {
    store.commit('client/clientIndustry', value)
    void store.dispatch('client/updateClientDetails')
  }

  return {
    setCompanyDisplayName,
    setCompanyIndustry,
    getClientConfig,
    clientObject,
    generateCompanyIndustry,
    loadClientData,
  }
}

export type ClientConfigReactive = ReturnType<typeof useClientConfig>
