import SystemAvailabilityService from '@app/services/SystemAvailabilityService'
import { initHighlightJs } from '@plugins/highlightjs'
import Hotjar from '@hotjar/browser'

import { usePageTitle } from '@shared/composables/useTitle'
import { UI_VERSION } from '@shared/data/constants'
import Global from '@shared/utils/global'
import { usePermissions } from '@/auth/composables/usePermissions'

const uiVersion = `v${PORTAL_VERSION}`
export async function appMountedHook(): Promise<void> {
  // initialize page titles
  usePageTitle()
  const { isAIEorAdmin, isInternalUser, isTestingUser } = usePermissions()

  SystemAvailabilityService.startWatching()

  const uiVersionInLocalStorage = localStorage.getItem(UI_VERSION) ?? uiVersion

  if (uiVersionInLocalStorage !== uiVersion) {
    Global.showVersionUpdatedMessage()
  }

  localStorage.setItem(UI_VERSION, uiVersion)

  initHighlightJs()

  if (import.meta.env.VITE_MAZE_API_KEY && isAIEorAdmin.value) {
    useScriptTag(
      `https://snippet.maze.co/maze-universal-loader.js?apiKey=${import.meta.env.VITE_MAZE_API_KEY}`,
      // on script tag loaded.
      () => {
        // @ts-expect-error
        window.mazeUniversalSnippetApiKey = import.meta.env.VITE_MAZE_API_KEY
        let timeStamp
        try {
          timeStamp = window.sessionStorage.getItem('maze-us')
        }
        catch (err) {}

        if (!timeStamp) {
          timeStamp = new Date().getTime()
          try {
            window.sessionStorage.setItem('maze-us', timeStamp.toString())
          }
          catch (err) {}
        }
      },
    )
  }

  if (import.meta.env.VITE_HOTJAR_ID && !(isInternalUser.value || isTestingUser.value)) {
    Hotjar.init(import.meta.env.VITE_HOTJAR_ID, 6)
  }
}
