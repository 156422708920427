import type { Module } from 'vuex'

import type { AppState } from '@app/store/app'
import { actions } from './actions'
import getters from './getters'
import mutations from './mutations'
import type { UserStateType } from './state'
import state from './state'

export const user: Module<UserStateType, AppState> = {
  namespaced: true,
  getters,
  mutations,
  actions,
  state,
}
