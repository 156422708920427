import type { AxiosResponse } from 'axios'
import { getModelFromResponse } from '@shared/utils/helpers'
import http from '@app/utils/http-client'
import type {
  UserNotificationModel,
  UserNotificationResponse,
} from '@/notifications/models/server/UserNotification'

const populateQuery = {
  client_notification: {
    populate: {
      client: {
        fields: ['id', 'name', 'displayName'],
      },
      usecase: {
        fields: ['id', 'name', 'displayName'],
      },
    },
  },
}

export default class UserNotificationsService {
  static async getAllUserNotifications() {
    const response = await http.get(`/user-notifications`, {
      params: {
        // Omit notifications with archived flags
        sort: 'createdAt:desc',
        filters: {
          archived: false,
        },
        populate: populateQuery,
      },
      cache: false,
    })
    return parseUserNotifications(response)
  }

  static async markNotificationAsRead(notificationId: string) {
    const response = await http.put<UserNotificationModel>(`/user-notifications/${notificationId}`, {
      data: {
        read: true,
      },
    }, {
      params: {
        populate: populateQuery,
      },
    })

    return response.data
  }

  static async markAllNotificationsAsRead(client?: string) {
    return http.put(`/user-notifications/read-all`, null, { params: { client } })
  }

  static async markNotificationAsArchived(notificationId: string) {
    const response = await http.put<UserNotificationModel>(`/user-notifications/${notificationId}`, {
      data: {
        archived: true,
      },
    })

    return getModelFromResponse<UserNotificationModel>(response) as UserNotificationModel
  }
}

function parseUserNotifications(response: AxiosResponse<UserNotificationResponse[]>) {
  return response.data.map((userNotification) => {
    if (!userNotification.client_notification?.client) {
      return undefined
    }

    return userNotification
  }).filter(userNotification => !!userNotification)
}
