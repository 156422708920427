import { ActivationEvent, DIMENSION_TRIGGER, EngagementEvent, PenaltyEvent } from '@shared/data/constants'

export const TRIGGER_DEFAULTS = {
  email_sent: {
    trigger: [ActivationEvent.EMAIL_SENT, 2],
    primary: {
      days: [null, 3],
    },
    secondary: {
      event: [EngagementEvent.EMAIL_CLICKED, 2],
      days: [null, 3],
    },
    penalty: {
      event: [PenaltyEvent.EMAIL_UNSUBSCRIBED, 2],
      days: [1, 2],
    },
  },

  sms_sent: {
    trigger: [ActivationEvent.SMS_SENT, 2],
    primary: {
      days: [null, 3],
    },
    secondary: {
      event: [EngagementEvent.SMS_CLICKED, 2],
      days: [null, 3],
    },
    penalty: {
      event: [PenaltyEvent.SMS_UNSUBSCRIBED, 2],
      days: [1, 2],
    },
  },

  outbound_call_dialed: {
    trigger: [ActivationEvent.OUTBOUND_CALL_DIALED, 2],
    primary: {
      days: [1, 2],
    },
    secondary: {
      event: [null, 2],
      days: [null, 2],
    },
    penalty: {
      event: [PenaltyEvent.OUTBOUND_CALL_UNSUBSCRIBED, 2],
      days: [null, 2],
    },
  },

  inbound_call_dialed: {
    trigger: [ActivationEvent.INBOUND_CALL_DIALED, 2],
    primary: {
      days: [1, 2],
    },
    secondary: {
      event: [null, 1],
      days: [null, 1],
    },
    penalty: {
      event: [null, 2],
      days: [null, 2],
    },
  },

  cadence_recommended: {
    trigger: [DIMENSION_TRIGGER.CADENCE_RECOMMENDED, 2],
    primary: {
      days: [null, 2],
    },
    secondary: {
      event: [null, 1],
      days: [null, 1],
    },
    penalty: {
      event: ['Unsubscribe', 2],
      days: [1, 2],
    },
  },

  daysofweek_recommended: {
    trigger: [DIMENSION_TRIGGER.DAYSOFWEEK_RECOMMENDED, 2],
    primary: {
      days: [null, 2],
    },
    secondary: {
      event: [null, 1],
      days: [null, 1],
    },
    penalty: {
      event: ['Unsubscribe', 2],
      days: [1, 2],
    },
  },

  frequency_recommended: {
    trigger: [DIMENSION_TRIGGER.FREQUENCY_RECOMMENDED, 2],
    primary: {
      days: [null, 2],
    },
    secondary: {
      event: [null, 1],
      days: [null, 1],
    },
    penalty: {
      event: ['Unsubscribe', 2],
      days: [1, 2],
    },
  },

  inapp_notification_sent: {
    trigger: [ActivationEvent.IN_APP_NOTIFICATION_SENT, 2],
    primary: {
      days: [null, 3],
    },
    secondary: {
      event: [EngagementEvent.IN_APP_NOTIFICATION_CLICKED, 3],
      days: [null, 3],
    },
    penalty: {
      event: [PenaltyEvent.IN_APP_NOTIFICATION_UNSUBSCRIBED, 2],
      days: [null, 2],
    },
  },

  push_notification_sent: {
    trigger: [ActivationEvent.PUSH_NOTIFICATION_SENT, 2],
    primary: {
      days: [3, 2],
    },
    secondary: {
      event: [EngagementEvent.PUSH_NOTIFICATION_CLICKED, 3],
      days: [3, 2],
    },
    penalty: {
      event: [PenaltyEvent.PUSH_NOTIFICATION_UNSUBSCRIBED, 2],
      days: [1, 2],
    },
  },
}

export type TriggerDefaults = keyof typeof TRIGGER_DEFAULTS
