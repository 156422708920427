import http from '@app/utils/http-client'
import type { UserModel, UserModelPartial } from '@/users/models/server/UserModel'

export default class UserService {
  static async getUserDetails(): Promise<UserModel> {
    return (await http.get('/users/me')).data
  }

  static async updateUserDetails(userData: UserModelPartial, ignoreReadOnly: boolean = false) {
    await http.put(`/users/${userData.id}`, userData, { ignoreReadOnly } as any)
  }

  static async getAllUsers(): Promise<UserModel> {
    return (await http.get('/users')).data
  }
}
