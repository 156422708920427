import type { IDataAsset } from '@shared/utils/Types'

export { useClientConfig } from '@/clients/composables/useClientConfig'
export { usePipelineConfig, useAssetFieldsMetadata } from '@/pipelines/composables/usePipelineConfig'
export { useUseCaseConfig } from '@/usecases/composables/useUseCaseConfig'

export function getDataAssetTemplate(): IDataAsset {
  return {
    data_format: undefined,
    description: '',
    display_name: '',
    extension: undefined,
    separator: '',
    drop_duplicated_rows: true,
    enforce_pk: true,
    standardize_fields_after_preprocess: true,
    drop_cols: [],
    rename_cols: {},
    pk_cols: [],
    partition_col: '',
    column_descriptions: {},
    asset_type: undefined,
    days_delay: 0,
    delay_on_receiving_data: 1,
  }
}
