<template>
  <div>
    <NSpace size="small">
      <NTag v-for="tag in tagsArray" :key="tag" size="small" round>{{ tag }}</NTag>
    </NSpace>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  name: 'UneditableTags',
  props: {
    tagsArray: {
      type: Array as PropType<string[]>
    }
  }
})
</script>

<style scoped></style>
