<script lang="ts">
import SimpleInput from '@shared/components/basic/SimpleInput.vue'

import { xssPlugin } from '@shared/utils/xss'
import { useStore } from '@app/store'

export default defineComponent({
  name: 'TableEditableRange',
  components: { SimpleInput },
  props: {
    id: String as PropType<string>,
    type: String as PropType<string>,
    leftValue: [String, Number] as PropType<string | number>,
    rightValue: [String, Number] as PropType<string | number>,
    leftLabel: String as PropType<string>,
    rightLabel: String as PropType<string>,
    autocomplete: Boolean as PropType<boolean>,
    error: String as PropType<string>,
    minMaxValidation: Boolean as PropType<boolean>,
  },
  emits: ['update:values'],
  setup(props, context) {
    const store = useStore()
    const isEdit: Ref = ref<boolean>(false)
    const inputRef: Ref = ref<any>(null)
    const isFirstInputFocused: Ref = ref<boolean>(false)
    const isSecondInputFocused: Ref = ref<boolean>(false)
    const localError: Ref<string | undefined> = ref<string | undefined>()

    const titleComputed = computed<string>(() => {
      return (
        localError.value
        || props.error
        || `${leftLocalValue.value ? `${props.leftLabel}: ${leftLocalValue.value || '?'}` : ''}${
          leftLocalValue.value && rightLocalValue ? ' — ' : ''
        }${rightLocalValue.value ? `${props.rightLabel}: ${rightLocalValue.value || '?'}` : ''}`
      )
    })

    const declineWatcher = watchEffect(() => {
      if (!isFirstInputFocused.value && !isSecondInputFocused.value) {
        setTimeout(() => {
          if (!isFirstInputFocused.value && !isSecondInputFocused.value) {
            isEdit.value = false
          }
        }, 100)
      }
    })

    onUnmounted(() => {
      declineWatcher()
    })

    const clickHandler = () => {
      isEdit.value = true
      nextTick(() => {
        inputRef.value?.focus?.()
      })
    }

    const leftLocalValue = computed<any>({
      get: (): any => {
        return props.leftValue
      },
      set: (value: any) => {
        localError.value = undefined
        context.emit('update:values', { leftValue: value, rightValue: rightLocalValue.value })
        if (props.minMaxValidation && Number(value) > Number(rightLocalValue.value)) {
          localError.value = 'Minimum value has to be less than maximum value'
        }
      },
    })
    const rightLocalValue = computed<any>({
      get: (): any => {
        return props.rightValue
      },
      set: (value: any) => {
        localError.value = undefined
        context.emit('update:values', { leftValue: leftLocalValue.value, rightValue: value })
        if (props.minMaxValidation && Number(value) < Number(leftLocalValue.value)) {
          localError.value = 'Maximum value has to be more than minimum value'
        }
      },
    })

    if (props.minMaxValidation && Number(leftLocalValue.value) > Number(rightLocalValue.value)) {
      localError.value = 'Minimum value has to be less than maximum value'
    }

    return {
      isEdit,
      inputRef,
      xssPlugin,
      localError,
      clickHandler,
      titleComputed,
      rightLocalValue,
      leftLocalValue,
      isFirstInputFocused,
      isSecondInputFocused,
      globalReadOnlyMode: computed(() => store.getters.isReadonlyMode),
    }
  },
})
</script>

<template>
  <div class="relative" :class="[!($attrs.class as string)?.includes('w-') ? 'w-full' : '']" @keydown.enter="isEdit = false">
    <template v-if="!isEdit || globalReadOnlyMode">
      <div
        v-if="leftLocalValue || rightLocalValue"
        class="h-full w-full cursor-pointer rounded-md hover:bg-purple-100"
        :class="{ 'bg-red-50': localError || error }"
        :title="titleComputed"
        @click="clickHandler"
      >
        <span v-if="leftLocalValue" :class="{ 'text-red-600': localError || error }">{{ leftLabel }}<span v-if="leftLabel">:</span> {{ leftLocalValue || '?' }}</span>
        <template v-if="leftLocalValue && rightLocalValue"> — </template>
        <span v-if="rightLocalValue" :class="{ 'text-red-600': localError || error }">{{ rightLabel }}<span v-if="rightLabel">:</span> {{ rightLocalValue || '?' }}</span>
      </div>
      <div
        v-else-if="!globalReadOnlyMode"
        class="h-8 w-full cursor-pointer border border-gray-300 rounded-md border-dashed p-1 text-gray-400 hover:bg-purple-100"
        @click="clickHandler"
      >
        Click to add
      </div>
    </template>

    <template v-else>
      <SimpleInput
        :id="`${id}_left`"
        ref="inputRef"
        v-model="leftLocalValue"
        class="simple-input"
        type="text"
        :placeholder="leftLabel"
        autoselect
        compact
        :errorVisually="localError || error"
        @focusin="isFirstInputFocused = true"
        @blur="isFirstInputFocused = false"
      />
      -
      <SimpleInput
        :id="`${id}_max`"
        v-model="rightLocalValue"
        class="simple-input"
        type="text"
        :placeholder="rightLabel"
        autoselect
        :errorVisually="localError || error"
        compact
        @focusin="isSecondInputFocused = true"
        @blur="isSecondInputFocused = false"
      />

      <p
        v-if="localError || error"
        :id="`${id}-error-2`"
        class="absolute mt-1.5 rounded bg-white p-0.5 text-left text-sm text-red-600"
        nosem
        v-html="xssPlugin.process(localError || error)"
      ></p>
    </template>
  </div>
</template>

<style scoped>
:deep(.simple-input) input {
  @apply py-1;
}
.simple-input {
  display: inline-block;
  width: 80px;
}
</style>
