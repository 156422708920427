<script setup lang="ts">
import { VueMonacoDiffEditor } from '@guolao/vue-monaco-editor'
import { DialogOverlay, DialogTitle, Dialog as HDialog, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/solid'
import { stableYAMLStringify } from '@shared/utils/helpers'
import SimpleButton from '@shared/components/basic/SimpleButton.vue'
import YellowWarning from '@shared/components/basic/static/YellowWarning.vue'
import PageTabs from '@layouts/level3/PageTabs.vue'
import { DiffViewTabKey, UseCaseStatus } from '@shared/data/constants'
import { useStore } from '@app/store'
import type { PipelineModel } from '@/pipelines/models/server/PipelineModel'
import PipelineService from '@/pipelines/services/PipelineService'

import { useAssetFieldsMetadata, usePipelineConfig } from '@/data-assets/utils/config'
import { loadIsolatedUseCase } from '@/usecases/composables/useUseCaseConfig'
import { useUseCases } from '@/usecases/composables/useUseCases'
import type { UseCaseModel, UseCaseModelWithoutConfig } from '@/usecases/models/server/UseCaseModel.ts'
import UseCaseService from '@/usecases/services/UseCaseService'

const props = defineProps({
  isOpened: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  diffUseCase: {
    type: String as PropType<string>,
    required: true,
  },
  compareEnv: {
    type: String as PropType<string>,
    default: UseCaseStatus.LIVE,
  },
})

const emit = defineEmits(['close'])

const store = useStore()
const { getPipelineConfig, getPipelineModel } = usePipelineConfig()
const { getPipelineMetadata } = useAssetFieldsMetadata()
const { usecases } = useUseCases()

const openedTabId = ref<string>(DiffViewTabKey.USECASE)

const tabs = [
  { name: 'Use case', id: DiffViewTabKey.USECASE },
  { name: 'Pipeline', id: DiffViewTabKey.PIPELINE },
  { name: 'Metadata', id: DiffViewTabKey.METADATA },
]

const isFetchingConfig = ref<boolean>(false)

const currentUsecaseModel = ref<UseCaseModelWithoutConfig>()
const currentUsecaseConfig = ref<UseCaseModel>()
const usecaseConfigFromLive = ref<UseCaseModel>()
const usecaseCodeDraft = ref<string>('')
const usecaseCodeLive = ref<string>('')
const usecaseConfigNotFound = ref<boolean>(false)

const pipelineConfigFromLive = ref<PipelineModel>()
const pipelineCodeDraft = ref<string>('')
const pipelineCodeLive = ref<string>('')
const pipelineMetadataDraft = ref<string>('')
const pipelineMetadataLive = ref<string>('')
const pipelineConfigNotFound = ref<boolean>(false)

const pipelineNoChanges = computed(() => {
  return pipelineCodeDraft.value === pipelineCodeLive.value
})

const usecaseNoChanges = computed(() => {
  return usecaseCodeDraft.value === usecaseCodeLive.value
})

const pipelineMetadataNoChanges = computed(() => {
  return pipelineMetadataDraft.value === pipelineMetadataLive.value
})

function closeHandler() {
  emit('close')
}
function cancelClickHandler() {
  emit('close')
}

function openTabHandler(tabId: string) {
  openedTabId.value = tabId
}

function getUseCaseModel() {
  if (!props.diffUseCase) { return }
  return (usecases.value || []).find(
    (usecase: UseCaseModelWithoutConfig) => usecase.name === props.diffUseCase,
  )
}

async function init() {
  isFetchingConfig.value = true
  currentUsecaseModel.value = getUseCaseModel()

  if (currentUsecaseModel.value?.name && getPipelineModel.value?.name) {
    try {
      const isolatedUsecase = await loadIsolatedUseCase(currentUsecaseModel.value)
      currentUsecaseConfig.value = isolatedUsecase.localUseCaseConfigWithActionBanks.value
    }
    catch (e) {
      console.error(e)
    }

    try {
      await UseCaseService.getUseCaseConfigFromEnvironment(
        currentUsecaseModel.value.name,
        getPipelineModel.value.name,
        props.compareEnv,
        store.getters['client/client'].name,
      ).then((response) => {
        usecaseConfigFromLive.value = response.config
        usecaseCodeLive.value = stableYAMLStringify(usecaseConfigFromLive.value)
      })
    }
    catch (e: any) {
      console.error(e)
      usecaseConfigNotFound.value = true
    }
  }

  if (usecaseConfigFromLive.value) {
    usecaseCodeDraft.value = stableYAMLStringify(currentUsecaseConfig.value)
  }

  if (getPipelineModel.value?.name) {
    try {
      pipelineConfigFromLive.value = await PipelineService.getPipelineConfigFromEnvironment(
        getPipelineModel.value.name,
        props.compareEnv,
      )
    }
    catch (e) {
      console.error(e)
      pipelineConfigNotFound.value = true
    }
    pipelineCodeDraft.value = stableYAMLStringify(getPipelineConfig.value)
    pipelineCodeLive.value = stableYAMLStringify(pipelineConfigFromLive.value?.config)
    pipelineMetadataDraft.value = stableYAMLStringify(getPipelineMetadata.value)
    pipelineMetadataLive.value = stableYAMLStringify(pipelineConfigFromLive.value?.metadata)
  }

  isFetchingConfig.value = false
}

const diffOptions: editor.IStandaloneDiffEditorConstructionOptions = {
  automaticLayout: true,
  readOnly: true,
  hideUnchangedRegions: {
    enabled: true,
    contextLineCount: 10,
  },
}

onMounted(async () => {
  await init()
})
</script>

<template>
  <TransitionRoot as="template" :show="isOpened" appear>
    <HDialog as="div" @close="closeHandler">
      <div class="min-h-screen flex items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">&#8203;</span>
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-300"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block w-4/5 transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:p-6 sm:align-middle"
          >
            <DialogTitle as="h3" class="text-md flex justify-between leading-6 text-gray-900">
              <span>Configuration diff: use case <span class="font-medium">{{ currentUsecaseModel?.name }}</span> & pipeline
                <span class="font-medium">{{ getPipelineModel?.name }}</span></span>
              <XMarkIcon class="h-5 w-5 cursor-pointer font-semibold text-gray-700" @click="closeHandler" />
            </DialogTitle>
            <div>For {{ UseCaseStatus.DRAFT }} &lt;&gt; {{ compareEnv }}</div>
            <div>
              <PageTabs :tabs="tabs" @openTab="openTabHandler" />
              <NSpin :show="isFetchingConfig">
                <div v-if="openedTabId === DiffViewTabKey.USECASE && !isFetchingConfig">
                  <div v-if="usecaseConfigNotFound">
                    <YellowWarning
                      title="Could not get config from LIVE environment"
                      subtitle="Use case config may not exist in environment."
                      class="mb-4"
                    />
                  </div>
                  <div v-else-if="usecaseNoChanges">
                    <NEmpty description="No changes" />
                  </div>
                  <VueMonacoDiffEditor
                    v-else
                    :original="usecaseCodeLive"
                    :modified="usecaseCodeDraft"
                    :options="diffOptions"
                    theme="vs-light"
                    language="yaml"
                    border
                    placeholder="Yaml text here"
                    class="min-h-120"
                  />
                </div>
                <div v-if="openedTabId === DiffViewTabKey.PIPELINE">
                  <div v-if="pipelineConfigNotFound">
                    <YellowWarning
                      title="Could not get config from LIVE environment"
                      subtitle="Pipeline config may not exist in environment."
                      class="mb-4"
                    />
                  </div>
                  <div v-else-if="pipelineNoChanges">
                    <NEmpty description="No changes" />
                  </div>
                  <VueMonacoDiffEditor
                    v-else
                    :original="pipelineCodeLive"
                    :modified="pipelineCodeDraft"
                    :options="diffOptions"
                    theme="vs-light"
                    language="yaml"
                    border
                    placeholder="Yaml text here"
                    class="min-h-120"
                  />
                </div>
                <div v-if="openedTabId === DiffViewTabKey.METADATA">
                  <div v-if="pipelineConfigNotFound">
                    <YellowWarning
                      title="Could not get metadata from LIVE environment"
                      subtitle="Pipeline metadata may not exist in environment."
                      class="mb-4"
                    />
                  </div>
                  <div v-else-if="pipelineMetadataNoChanges">
                    <NEmpty description="No changes" />
                  </div>
                  <VueMonacoDiffEditor
                    v-else
                    :original="pipelineMetadataLive"
                    :modified="pipelineMetadataDraft"
                    :options="diffOptions"
                    theme="vs-light"
                    language="yaml"
                    border
                    placeholder="Yaml text here"
                    class="min-h-120"
                  />
                </div>
              </NSpin>
            </div>
            <div class="flex justify-end">
              <SimpleButton class="mr-2" text="Close" @click="cancelClickHandler" />
            </div>
          </div>
        </TransitionChild>
      </div>
    </HDialog>
  </TransitionRoot>
</template>
