<script lang="ts">
import { ChevronDownIcon } from '@heroicons/vue/24/solid'
import { useRoute, useRouter } from 'vue-router'

import type { TabChildSelectItem, TabItem } from '@shared/utils/pageTabs'

export default defineComponent({
  name: 'PageTabs',
  components: { ChevronDownIcon },
  props: {
    tabs: {
      type: Array as PropType<TabItem[]>,
      required: true,
    },
    themeOverrides: {
      type: Object as PropType<Record<string, string>>,
      default: () => ({}),
    },
    selectByRoute: Boolean as PropType<boolean>,
    capitalize: Boolean as PropType<boolean>,
    size: { type: String as PropType<'small' | 'medium' | 'large'>, default: 'medium' },
    type: {
      type: String as PropType<'bar' | 'line' | 'card' | 'segment'>,
      default: 'line',
    },
  },
  emits: ['openTab'],
  setup(props, { emit }) {
    const route = useRoute()
    const router = useRouter()
    const dropdownSelect = ref<string | number>()
    /***
     * Workaround for tab line wrong offset.
     * It seems is a race condition inside the renderer
     * This is a bug in Naive UI 2.34.3. Nasty but works.
     * Please remove this workaround when the bug is fixed.
     */
    const showTabs = ref(false)
    setTimeout(() => {
      showTabs.value = true
    }, 200)
    /***
     * End of workaround
     */

    const currentTab = ref<TabItem>()

    const openTab = (tabId: string) => {
      props.tabs.forEach((tab: TabItem) => {
        if (tab.id.toString() === tabId) {
          currentTab.value = tab
          if (typeof tab.callback === 'function') {
            tab.callback()
          }
          if (tab.link) {
            router.push(tab.link)
          }
          else if (tab.route) {
            router.push(tab.route)
          }
        }
      })
      dropdownSelect.value = undefined
      emit('openTab', tabId)
    }

    const tabSwitchHandler = (tabName: string | number) => {
      const newTab = props.tabs.find((tab: TabItem) => tab.name === tabName)
      if (newTab && !newTab.isDropdown) {
        openTab(newTab.id)
      }
    }

    const openDropdownTab = (dropdownTabValue: any) => {
      const children = (props.tabs || []).map((item: TabItem) => item.children).filter((children: TabChildSelectItem[] | undefined) => Boolean(children))
      const flatChildren = (children || []).flat()
      const newDropdownTab = flatChildren.find(item => item?.value === dropdownTabValue)
      // set which child tab is selected in the dropdown
      if (newDropdownTab) {
        dropdownSelect.value = newDropdownTab.value
        // set the parent tab to be highlighted on select of child
        currentTab.value = props.tabs?.find((item: TabItem) => item.id === newDropdownTab.id)
        emit('openTab', newDropdownTab?.value)
        // route to child tab link
        if (newDropdownTab.link) {
          router.push(newDropdownTab.link)
        }
        else if (newDropdownTab.route) {
          router.push(newDropdownTab.route)
        }
      }
    }

    const selectCurrentTab = () => {
      props.tabs.forEach((tab: TabItem) => {
        if (tab.link === route.path || tab.route === route || tab.route?.name === route.name) {
          currentTab.value = tab
          if (typeof tab.callback === 'function') {
            tab.callback()
          }
        }

        if (tab.children) {
          const links = tab.children?.map(child => child.link)
          const routeNames = tab.children?.map(child => child.route?.name)
          if (links.includes(route.path) || routeNames.includes(route.name)) {
            currentTab.value = tab
            if (typeof tab.callback === 'function') {
              tab.callback()
            }
          }
        }
      })
    }

    watch(
      () => [props.tabs],
      () => {
        selectCurrentTab()
      },
    )

    if (props.selectByRoute) {
      watch(route, () => {
        selectCurrentTab()
      })

      selectCurrentTab()
    }

    return {
      openTab,
      currentTab,
      dropdownSelect,
      tabSwitchHandler,
      openDropdownTab,
      showTabs,
    }
  },
})
</script>

<template>
  <div :class="[!($attrs.class as string)?.includes('w-') ? 'w-full' : '']" class="mb-4">
    <div class="mb-4 sm:hidden">
      <label for="tabs" class="sr-only">Select a tab</label>
      <select
        id="tabs"
        name="tabs"
        class="focus:ring-bright-purple focus:border-bright-purple block w-full border-gray-300 rounded-md py-2 pl-3 pr-10 text-base focus:outline-none"
        @change="openTab($event.target?.value?.toString() || '')"
      >
        <option v-for="tab in tabs" :key="tab.name" :selected="currentTab?.id === tab.id" :value="tab.id">
          {{
            tab.name
          }}
        </option>
      </select>
    </div>
    <NTabs
      v-if="showTabs"
      class="hidden sm:block"
      :type="type"
      :value="currentTab?.name"
      :size="size"
      :themeOverrides="themeOverrides"
      @update:value="tabSwitchHandler"
    >
      <NTab v-for="tab in tabs" :key="tab.name" :name="tab.name" :class="capitalize ? 'capitalize' : ''">
        <div v-if="tab.isDropdown">
          <NPopselect
            v-model:value="dropdownSelect"
            :options="tab.children"
            placement="bottom-right"
            trigger="hover"
            @update:value="openDropdownTab"
          >
            <div class="flex">
              {{ tab.name }}
              <ChevronDownIcon class="ml-1 mt-1.5 h-3 w-3 cursor-pointer outline-none" />
            </div>
          </NPopselect>
        </div>
        <div v-else>
          {{ tab.name }}
        </div>
      </NTab>
    </NTabs>
  </div>
</template>

<style scoped>
.n-tabs :deep(.n-tab-pane) {
  padding: 0;
}
</style>
