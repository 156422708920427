import { chartBaseColors, chartColorBlindColors } from '@/accessibility/data/chartColors'

export default function useAccessibility() {
  const bodyElement = document.querySelector('body')

  const colorBlindnessMode = useStorage('offerfit/accessibility/colorBlindnessMode', false)

  const accessibleChartColors = computed(() => {
    return colorBlindnessMode.value ? chartColorBlindColors : chartBaseColors
  })

  const readableFont = useStorage('offerfit/accessibility/readableFont', false)
  const fontFamily = useCssVar('--offerfit-font-family', bodyElement)

  watch(readableFont, (value) => {
    if (value) {
      fontFamily.value = 'Inter'
    }
    else {
      fontFamily.value = 'Jost'
    }
  }, { immediate: true })

  return {
    colorBlindnessMode,
    accessibleChartColors,
    readableFont,
  }
}
