<script setup lang="ts">
import { Level4Route } from '@/level4/router/level4'
import AppSettings from '@app/AppSettings.ts';

interface Props {
  title?: string
  description?: string
  completed?: boolean
  loading?: boolean
  stickyHeader?: boolean
}

const { experimenterName } = AppSettings

defineProps<Props>()
</script>

<template>
  <div class="page-wrapper-level-4" flex="~ col" min="h-screen">
    <header :class="[stickyHeader ? 'sticky top-0 z-100' : '']" flex="~" justify="center" border="b-1" bg="white/60" backdrop="blur-10">
      <div class="container" p="4 y-2" flex="~" items="center" gap="4" w="full">
        <BasicLink class="logo max-w-8 py-2" :to="{ name: Level4Route.LEVEL4 }">
          <OfferfitLogo type="icon"/>
        </BasicLink>
        <div class="header-content" flex="1">
          <slot name="header-content"></slot>
        </div>
        <div class="header-end">
          <NButton quaternary type="primary" font="!semibold">Help
            <template #icon>
              <div class="i-solar-question-circle-outline"></div>
            </template>
          </NButton>
          <BasicLink class="logo" :to="{ name: Level4Route.EXPERIMENTER, params: { experimenterName }}" max="w-24">
            <NButton quaternary type="primary" font="!semibold">Done
              <template #icon>
                <div class="i-solar-check-circle-outline"></div>
              </template>
            </NButton>
          </BasicLink>
        </div>
      </div>
    </header>
    <main flex justify="center">
      <div class="container mb-10 pt-12">
        <slot></slot>
        <div class="justify-center">
          <slot name="controls"></slot>
        </div>
      </div>
    </main>
    <footer>
      <slot name="footer"></slot>
    </footer>
  </div>
</template>
