import type { UserConfig } from 'unocss/dist'

const neutralColors = {
  80: '#F0F0F0',
  100: '#EEEDED',
  200: '#DAD9D9',
  300: '#C6C5C5',
  400: '#A8A7A7',
  500: '#807F7F',
  600: '#3A3939',
}

const offerfitBrandColors = {
  black: '#171721',
  green: {
    DEFAULT: '#58C897',
    hover: '#98c7b2',
    bg: '#E0F5EB',
    dark: '#08824C',
  },
  purple: {
    DEFAULT: '#450E72',
    light: '#B2A1FF',
    bright: '#8336E6',
  },
  orange: '#F76532',
  light: { purple: '#B2A1FF' },
  bright: { purple: '#8336E6' },
  gray: '#C6C5C5',
  yellow: '#F7C032',
  red: '#EE4439',
}

const charts = {
  pink: '#DC7CFF',
  timelineDefault: {
    purple: '#8336E6',
    green: '#37A475',
    yellow: '#EBC400',
    blue: '#488AED',
  },
  regimeColors: {
    offerfit: offerfitBrandColors.purple.bright,
    bau: offerfitBrandColors.orange,
    holdout: '#89959b',
    control: '#66ACFF',
    other: '#676565',
  },
}

/**
 * This is the "neutral" color that is used on al backgrounds, cards, sections, etc.
 * In light mode, surface colors are kind of gray, and in dark mode, they are kind of black.
 */
const surfaceColors = {}
/**
 * This is the "neutral" color that is used to create contrast with the surface color. It is used on text, icons, etc.
 * In light mode, contrast colors are kind of black, and in dark mode, they are kind of white.
 */
const contrastColors = {}
export const colors = {
  gray: neutralColors,
  offerfit: offerfitBrandColors,
  brand: offerfitBrandColors,
  charts,
  primary: offerfitBrandColors.purple.bright,
  error: '#EE4439',
  warn: '#F7C032',
  surface: surfaceColors,
  contrast: contrastColors,
}

export const theme: UserConfig['theme'] = {
  gridTemplateColumn: {
    docs: '200px minmax(500px, 1fr)',
  },
  boxShadow: {
    in2: 'inset 0 0 1px 1px #450E72',
  },
  colors,
  container: {
    padding: '1rem',
  },
}
