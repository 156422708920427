import EmptyLayout from '@layouts/EmptyLayout.vue'
import type {RouteRecordRaw, Router} from 'vue-router'
import Posthog from 'posthog-js'
import type {DefineComponent} from 'vue'
import ClientsPage from '@level3/pages/clients/ClientsPage.vue'
import ClientsWrapper from '@level3/components/ClientsWrapper.vue'
import ManageClientsPage from '@level3/pages/clients/manage/ManageClientsPage.vue'

export enum Level3Route {
  CLIENTS = 'clients-v2',
  MANAGE_CLIENTS = 'manage-clients-v2',
  CLIENT = 'client-v2',
  PIPELINES = 'pipelines-v2',
  PIPELINE = 'pipeline-v2',
  DATA_ASSETS = 'data-assets-v2',
}

function getLevel3v2Routes(): RouteRecordRaw[] {
  return [
    {
      path: '/clients',
      component: ClientsWrapper as DefineComponent<unknown, unknown, unknown>,
      beforeEnter: async () =>
        // We cannot use the Store here as this is not wrapped under script
        // We are using the posthog-js library to get the feature flags directly in this case
        new Promise((resolve) => {
          Posthog.onFeatureFlags((flags) => {
            if (flags.includes('enable-level-3-v2')) {
              resolve(true)
            } else {
              resolve({path: '/client-selection'})
            }
          })
        }),
      children: [
        {
          path: '',
          name: Level3Route.CLIENTS,
          meta: {title: 'Clients'},
          component: ClientsPage as DefineComponent<unknown, unknown, unknown>,
        },
        {
          path: 'manage',
          name: Level3Route.MANAGE_CLIENTS,
          meta: {title: 'Manage Clients'},
          component: ManageClientsPage as DefineComponent<
            unknown,
            unknown,
            unknown
          >,
        },
        {
          path: ':clientSlug',
          name: Level3Route.CLIENT,
          meta: {title: 'Client'},
          redirect: (to) => `${to.path}/pipelines`,
          children: [
            {
              path: 'pipelines',
              name: Level3Route.PIPELINES,
              meta: {title: 'Pipelines'},
              redirect: (to) => `${to.path}/default`,
              children: [
                {
                  path: ':pipelineSlug',
                  name: Level3Route.PIPELINE,
                  meta: {title: 'Pipeline'},
                  redirect: (to) => `${to.path}/data-assets`,
                  children: [
                    {
                      path: 'data-assets',
                      name: Level3Route.DATA_ASSETS,
                      meta: {title: 'Data Assets'},
                      component: EmptyLayout as DefineComponent<
                        unknown,
                        unknown,
                        unknown
                      >,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ]
}

export function initL3v2Routes(localRouter: Router) {
  const level3Routes = getLevel3v2Routes()
  for (const r of level3Routes) {
    localRouter.addRoute(r)
  }
}
