import type { MutationTree } from 'vuex'

import type { ClientModel } from '@/clients/models/server/ClientModel'
import type { ClientStateType } from '@/clients/store/state'

const mutations: MutationTree<ClientStateType> = {
  client(state: ClientStateType, value: ClientModel) {
    state.client = value
  },
  clientDisplayName(state: ClientStateType, value: string) {
    state.client!.displayName = value
  },
  clientIndustry(state: ClientStateType, value: string) {
    if (!state.client!.config) {
      state.client!.config = {}
    }
    state.client!.config.industry = value
  },
}

export default mutations
