import type { CascaderOption, TreeSelectOption } from 'naive-ui'
import type { SelectBaseOption, SelectGroupOptionBase } from 'naive-ui/lib/select/src/interface'
import type { Option } from 'naive-ui/lib/transfer/src/interface'

import type { SelectItem, TreeItem } from '@shared/utils/Types'

export type NaiveSelectItem<T = any> = SelectBaseOption<T>
export type NaiveSelectGroupItem = SelectGroupOptionBase

export function getNaiveSelectItem<T = any>(value: SelectItem<T> | NaiveSelectItem<T>): NaiveSelectItem<T> {
  return {
    label: value.label,
    value: value.value as T,
  } as NaiveSelectItem<T>
}

export function getNaiveOptionItem<T = any>(value: SelectItem<T> | Option): Option {
  return {
    label: value.label,
    value: value.value,
    disabled: false,
  } as Option
}

export function getNaiveTreeSelectOption(value: TreeItem | TreeSelectOption): TreeSelectOption {
  return {
    label: value.label,
    // eslint-disable-next-line ts/no-unsafe-assignment
    key: value.value,
    disabled: value.disabled,
    isLeaf: value.isLeaf,
    children: value.children?.length ? value.children.map(getNaiveTreeSelectOption) : undefined,
  } as TreeSelectOption
}

export function getNaiveCascaderOption(value: TreeItem | TreeSelectOption): CascaderOption {
  return {
    label: value.label,
    // eslint-disable-next-line ts/no-unsafe-assignment
    value: value.value,
    disabled: value.disabled,
    children: value.children?.length ? value.children.map(getNaiveCascaderOption) : undefined,
  } as CascaderOption
}

export function getNaiveSelectGroupOption<T = any>(
  value: TreeItem<T> | NaiveSelectGroupItem | NaiveSelectItem<T> | SelectItem<T>,
): NaiveSelectGroupItem | NaiveSelectItem<T> {
  if (!('children' in value)) {
    return {
      label: value.label,
      value: value.value as T,
    } as NaiveSelectItem<T>
  }

  return {
    type: 'group',
    label: value.label,
    key: value.value,
    disabled: value.disabled,
    isLeaf: value.isLeaf,
    // eslint-disable-next-line ts/no-unsafe-call,ts/no-unsafe-assignment
    children: (value.children ?? []).map((value: TreeItem | NaiveSelectGroupItem | NaiveSelectItem) =>
      getNaiveSelectGroupOption(value),
    ),
  } as NaiveSelectGroupItem
}
