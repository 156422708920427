<script lang="ts" setup>
import {useClients} from '@level3/composables/useClients'
import {useUser} from '@level3/composables/useUser'

const search = defineModel<string>('search')

const {user, isLoadingUser, onAddUserClient} = useUser()
const {clients, isLoadingClients} = useClients()
</script>

<template>
  <ClientsList
    v-model:search="search"
    :clients="clients || []"
    :user-clients="user?.clients || []"
    :is-loading="isLoadingUser || isLoadingClients"
    @add-client="onAddUserClient"
  />
</template>
