import type { DATA_ASSET_TYPES, DataType } from '@shared/data/constants'
import type { JoinAssetConfigModel } from '@/data-assets/models/DataJoiningModel'
import type { MappingColumns } from '@/data-assets/models/FeaturesModel'
import type { TransformationConfigModel } from '@/data-assets/models/TransformationConfigModel'
import type { ActionModel, ActionValueModel } from '@/usecases/models/server/ActionModel'
import type { JobHistoryModel } from '@/dags/models/server/JobHistoryModel'
import type { UserModel } from '@/users/models/server/UserModel'

export interface Indexed {
  id: number
}

export interface PortalSettings {
  maintenance_mode: boolean
  readonly: boolean
}

// https://stackoverflow.com/questions/61393505/how-to-define-a-type-and-make-sure-its-part-of-another-type-in-typescript
export type DeepPartial<T> = { [K in keyof T]?: DeepPartial<T[K]> }

export type DeepNoExcess<T, U> = {
  [K in keyof U]: K extends keyof T ? DeepNoExcess<Required<T>[K], U[K]> : never
}

export type PartOf<T, U extends DeepPartial<T> & DeepNoExcess<T, U>> = U

export interface StrapiLogParameters {
  createdAt?: string
  updatedAt?: string

  createdAtDate?: Date
  updatedAtDate?: Date
  createdByUser?: UserModel | number
  updatedByUser?: UserModel | number
}

export type StrapiDataItem<T> = Indexed & {
  attributes: StrapiLogParameters & T
}

export interface StrapiData<T> {
  data: StrapiDataItem<T>
  meta?: any
}
export interface StrapiDataArray<T> {
  data: StrapiDataItem<T>[]
  meta?: any
}

export interface TagModel {
  id?: number
  value: string
}

export interface PythonMSResponse<R = any> {
  success: boolean
  message: string | undefined
  body?: R
}

export type NullString = null | undefined | string
export interface NullStringObject { value: NullString }

export type DataAssetFormat = 'csv' | 'csv.gz' | 'mparticle' | 'json' | 'parquet'
export type FileExtension = 'csv' | 'csv.gz' | 'txt' | 'json' | 'parquet'

export interface DataAssetHeaders { dataFormat: DataAssetFormat, headers: string[], name: string }

export type DimensionName = string
export type ActionId = number | string

export interface StoreDataChangePayload<T = any, K extends keyof T = keyof T> {
  fieldObject?: T
  key: K
  value: T[K]
  oldValue?: T[K]
}

export interface StoreDataRewritePayload<T = any> {
  targetObject: T
  byObject: any
}

export interface StoreRangeDataChangePayload {
  fieldObject: any
  keyLeft: string
  keyRight: string
  valueLeft: any
  valueRight: any
}

export interface ActionFieldValueChangePayload {
  action: ActionModel
  actionFieldValueData: ActionValueModel
  newValue: string
}

export interface UseCaseSectionChangePayload<T> {
  usecaseName: string
  sectionPath?: string
  sectionValue: T
}

export interface SeriesDataArray {
  x: number | string
  y: number | string
}

export interface SeriesArray {
  name: string
  data: SeriesDataArray[] | number[]
}

export interface TimeDetails { time: string, dayPart: 'AM' | 'PM' }

export type JobStatus = 'PENDING' | 'RUNNING' | 'COMPLETED' | 'DELETED' | 'FAILED'

export type DatetimePickerTypes = 'date' | 'datetime' | 'daterange' | 'datetimerange' | 'month' | 'year' | 'quarter'

export interface MockUseCase {
  id: number
  name: string
}

export interface AssetColumnType { asset: string, column: string, isCustomerId?: boolean }
export class AssetColumn {
  public data: AssetColumnType
  constructor(_data: AssetColumnType) {
    this.data = _data
  }
}

export type ColumnMap = Record<string, string>

export interface Pair<T = any> { left: T, right: T }

export type AssetColumnPair = Pair<AssetColumn> & {
  id: number
}

export interface LogsMap {
  [key: string]: string | number
}

export interface BucketFileInfo {
  name: string
  dataFormat: DataAssetFormat
  updated: string
}

export interface SelectItem<V = any> {
  label: string
  value: V
}

export interface ItemWithDescription<V = any> {
  label: string
  description?: string
  value: V
}

export type SelectItemWithType<V = any, T = any> = SelectItem<V> & {
  type: T
}

export type SelectItemWithStatus<V = any, S = any> = SelectItem<V> & {
  status: S
}

export interface TreeItem<T = any> {
  label?: string
  value?: T
  disabled?: boolean
  children?: TreeItem<T>[]
  isLeaf?: boolean
  [key: string]: unknown
}

export interface IDataAsset {
  display_name: string
  description?: NullString
  separator: string
  cpu_limit?: NullString
  memory_limit?: NullString
  file_name_format?: NullString
  data_format: NullString
  extension: NullString
  partition_col: string
  days_delay: number
  delay_on_receiving_data: number
  asset_type?: DATA_ASSET_TYPES
  column_descriptions?: ColumnMap

  optional?: boolean

  drop_cols?: string[]
  pk_cols?: string[]
  rename_cols?: ColumnMap
  fill_na?: ColumnMap

  callback?: any
  remove_data_after_X_days?: number

  drop_duplicated_rows: boolean
  enforce_pk: boolean
  standardize_fields_after_preprocess: boolean

  asset_joining?: JoinAssetConfigModel[]
  transform?: {
    transforms: TransformationConfigModel[]
    drop_fields: string[]
  }
}

export interface DataAssetWrapper {
  key: string
  asset: IDataAsset | undefined
}

export interface TaskListener { name: string, listener: (task: JobHistoryModel) => void | Promise<void> }

export interface FunctionReturnsString {
  (): NullString
}

export interface PrimarySecondaryRewards {
  reward_trigger: NullString
  days_to_wait: number | null
  unsubscribe_penalty?: number | null
}

export interface RewardConfig {
  name: string
  trigger: NullString
  primary_reward: PrimarySecondaryRewards
}

export type BaseKeyNames = 'base_table_callback' | 'base_table_name' | 'base_table_user_identifier_col'

export interface BaseTableConfig {
  base_table_name: string
  base_table_user_identifier_col: string
  base_table_callback: string | 'get_latest_callback'
}

export interface BaseKeyValue {
  key: BaseKeyNames
  value: string
}

export interface ColumnMappingPayload {
  map_value: NullString
  col_value: keyof MappingColumns
  asset: string
}

export interface StringNumberKeyValuePair {
  [key: string]: number
}

export interface PipelineUpdateContext {
  assetName: string
  clientId: number

  pipelineName: string
  updateExpectations: boolean
}

export interface ActionBankContext {
  clientId?: number
  pipelineId?: number
  usecaseId?: number
}

export interface TransformMapDefinition {
  index?: number
  valueToFind: string
  mapValueTo: string
}

export type EventType = 'activation_event' | 'engagement_event'
export type EventArrayType = 'activation_events' | 'engagement_events'
export type EmailRecipientsType = 'staging_recipients' | 'recipients'

export enum OutPutItemType {
  ACTION_FEATURES_ITEM_TYPE = 'action_features',
  FEATURES_ITEM_TYPE = 'features',
  PREDICTED_VALUE_TYPE = 'predicted_value',
}

export interface ColumnDataType {
  name: string
  dataType: DataType
}

export type ColumnDataTypeMap = Map<string, ColumnDataType[]>

export enum MicroserviceDType {
  STRING = 'string',
  INTEGER = 'integer',
  FLOAT = 'float',
  DATE = 'date',
  TIMESTAMP = 'timestamp',
  TIMESTAMP_TZ = 'timestamp_tz',
  BOOLEAN = 'boolean',
  LIST = 'list',
  DICT = 'dict',
  BINARY = 'binary',
}

export type OPERATION_TYPE = 'sum' | 'average' | 'sdev' | 'max' | 'min' | 'nunique'

export type WrapComputedRef<Rec> = { [k in keyof Rec]: ComputedRef<Rec[k]> }

export type CustomerGroupType = 'offerfit' | 'control' | 'bau' | 'holdout'
