import type { NullString } from '@shared/utils/Types'
import type { UseCaseConfigModelWithoutActionBank } from '@/usecases/models/UseCaseConfigModel'
import type { ConfigDimensionActionBank } from '@/usecases/models/server/ActionModel'
import type { UseCaseModel, UseCaseModelWithoutConfig } from '@/usecases/models/server/UseCaseModel.ts'
import type { AudienceBreakdownDataModel } from '@/usecases/models/server/InboundDataValModel'

export function createUseCaseStateObject(useCaseModel?: UseCaseModelWithoutConfig) {
  let useCaseModelWithoutConfig

  if (useCaseModel) {
    useCaseModelWithoutConfig = useCaseModel
  }

  return reactive({
    timerMap: {} as Record<string, number>,
    currentDimensionName: undefined as string | undefined,
    targetedAudienceData: undefined as AudienceBreakdownDataModel[] | undefined,
    isCurrentUseCaseLive: false as boolean,
    localUsecaseConfigHash: undefined as string | undefined,
    remoteUsecaseConfigHash: undefined as string | undefined,
    remoteUsecaseUpdatedAtDate: undefined as Date | undefined,
    localUsecase: useCaseModelWithoutConfig as UseCaseModelWithoutConfig | undefined | null,
    remoteUseCase: useCaseModelWithoutConfig as UseCaseModelWithoutConfig | undefined | null,
    localUseCaseConfig: undefined as UseCaseConfigModelWithoutActionBank | undefined | null,
    localActionBank: undefined as ConfigDimensionActionBank[] | undefined,
    localActionBankConfigHash: undefined as string | undefined,
    remoteActionBankConfigHash: undefined as string | undefined,
    liveUsecase: undefined as DeepReadonly<UseCaseModel> | undefined,
    actionBankPromotionError: null as NullString,
    usecaseActionBankUpdatedCallbackOnce: undefined as undefined | (() => Promise<void>),
  })
}
export type UseCaseState = ReturnType<typeof createUseCaseStateObject>
