import { Features } from '@shared/data/constants'
import { getExpressionModelsFromArray, getFilterUniqueId, nodeIsOperator } from '@/filtering/utils/filters'
import {
  AggPayload,
  BucketTimePayload,
  FeatureType,
  FeatureTypePayload,
  IdentityPayload,
  OrdinalOneHotPayload,
  RatioPayload,
  TimeSincePayload
} from '@/data-assets/models/FeaturesModel'
import { CommonFilter, FilterCondition, LogicConfigModel, RootFilterExpression } from '@/filtering/models/FilterModel'
import { getExistingValueFromArguments } from '@shared/utils/helpers'

export function reconciledFilters(features: any, defaults: any): any {
  if (!(features && features.filter)) return []

  // If it's the newer config, where comparators/logic falls under filters
  //  extract and format the relevant filter from that
  if ('logic' in features.filter) {
    return features.filter.logic.map((item: LogicConfigModel) => {
      const converted = getExpressionModelsFromArray(item.expression, features.filter.comparators) as RootFilterExpression
      return converted[0]
    })
  }
  const reconciled: any[] = []

  for (const item in features.filter) {
    let dict = {}

    // Feature Engineering previously did not support and/or grouping logic
    //  and instead wrote out its config as straight CommonFilter's
    //  This will find those and wrap them into a basic RootFilterExpression
    //    to allow backwards compatibility
    //  (though it will save them out with logic/comparators as the format)
    if (!nodeIsOperator(features.filter[item])) {
      const common: CommonFilter = {
        id: getFilterUniqueId(features.filter[item]),
        colname: getExistingValueFromArguments(features.filter[item].colname, defaults?.colname),
        compare_val: getExistingValueFromArguments(features.filter[item].compare_val, defaults?.compare_val),
        compare_op: getExistingValueFromArguments(features.filter[item].compare_op, defaults?.compare_op)
      }
      dict = {
        and: [{ and: [common] }]
      } as FilterCondition
    } else {
      dict = features.filter[item] as RootFilterExpression
    }
    reconciled.push(dict)
  }
  return reconciled
}

export function reconciledTypeSpecific(
  type: FeatureType,
  feature: FeatureTypePayload,
  defaults: any
): FeatureTypePayload | undefined {
  if (type === Features.AGGREGATION) {
    return {
      agg_col: getExistingValueFromArguments((feature as AggPayload).agg_col, defaults?.agg_col),
      operation: getExistingValueFromArguments((feature as AggPayload).operation, defaults?.operation)
    } as AggPayload
  }
  if (type === Features.IDENTITY) {
    return {
      target_col: getExistingValueFromArguments((feature as IdentityPayload).target_col, defaults?.target_col),
      fill: getExistingValueFromArguments((feature as IdentityPayload).fill, defaults?.fill)
    } as IdentityPayload
  }
  if (type === Features.ONE_HOT || type === Features.ORDINAL) {
    return {
      target_col: getExistingValueFromArguments((feature as OrdinalOneHotPayload).target_col, defaults?.target_col),
      encoding_order: getExistingValueFromArguments((feature as OrdinalOneHotPayload).encoding_order, defaults?.encoding_order)
    } as OrdinalOneHotPayload
  }
  if (type === Features.BUCKET_TIME) {
    return {
      bucket_col: getExistingValueFromArguments((feature as BucketTimePayload).bucket_col, defaults?.bucket_col),
      buckets: getExistingValueFromArguments((feature as BucketTimePayload).buckets, defaults?.buckets),
      granularity: getExistingValueFromArguments((feature as BucketTimePayload).granularity, defaults?.granularity)
    } as BucketTimePayload
  }
  if (type === Features.TIME_SINCE) {
    return {
      fill: getExistingValueFromArguments((feature as TimeSincePayload).fill, defaults?.fill),
      granularity: getExistingValueFromArguments((feature as TimeSincePayload).granularity, defaults?.granularity),
      order: getExistingValueFromArguments((feature as TimeSincePayload).order, defaults?.order)
    } as TimeSincePayload
  }
  if (type === Features.RATIO) {
    return {
      numerator: getExistingValueFromArguments((feature as RatioPayload).numerator, defaults?.numerator),
      denominator: getExistingValueFromArguments((feature as RatioPayload).denominator, defaults?.denominator),
      fill: getExistingValueFromArguments((feature as RatioPayload).fill, defaults?.fill)
    } as RatioPayload
  }

  return undefined
}
