import type { TagModel } from '@shared/utils/Types'
import { LEVEL, OfferfitProduct } from '@shared/data/constants'
import { usePermissions } from '@/auth/composables/usePermissions'
import type { ClientModel } from '@/clients/models/server/ClientModel'
import type { PipelineModel } from '@/pipelines/models/server/PipelineModel'
import type { UseCaseModelWithoutConfig } from '@/usecases/models/server/UseCaseModel.ts'

export class Tags {
  /* will be deprecated after backend refactoring and db migration */
  static readonly OFE = 'OFE' // TODO: migrate the backend to use OFSS instead

  static readonly OFSS = 'OFSS'

  static getTags(entity: PipelineModel | ClientModel | UseCaseModelWithoutConfig): string[] {
    return entity?.tags?.map((tag: TagModel) => tag.value) || []
  }

  static isTagged(entity: PipelineModel | ClientModel | UseCaseModelWithoutConfig, tag: string): boolean {
    return Tags.getTags(entity).includes(tag)
  }

  static isOFSS(entity: PipelineModel | ClientModel | UseCaseModelWithoutConfig): boolean {
    return Tags.isTagged(entity, Tags.OFE) || Tags.isTagged(entity, Tags.OFSS)
  }

  static isFOF(entity: PipelineModel | ClientModel | UseCaseModelWithoutConfig): boolean {
    return !Tags.isOFSS(entity)
  }

  static getLevel(entity: PipelineModel | ClientModel | UseCaseModelWithoutConfig) {
    if (Tags.isOFSS(entity)) {
      return LEVEL.FOUR
    }

    if (Tags.isFOF(entity)) {
      return LEVEL.THREE
    }
  }

  static getProductTags(): string[] {
    const { isLevel4 } = usePermissions()
    const productName = isLevel4.value ? OfferfitProduct.EMAIL : OfferfitProduct.FULL
    return ({ [OfferfitProduct.EMAIL]: [Tags.OFSS], [OfferfitProduct.FULL]: [], '': [] } as Record<string, string[]>)[productName]
  }

  static getProductTagObjects(): TagModel[] {
    return Tags.getProductTags().map((tag: string) => ({ value: tag }))
  }
}
