import { AxiosResponse } from 'axios'
import qs from 'qs'

import { JobHistoryModel } from '@/dags/models/server/JobHistoryModel'
import { getModelFromResponse } from '@shared/utils/helpers'
import http from '@app/utils/http-client'

const query = (clientId: number, job_status?: string) => {
  return qs.stringify(
    {
      filters: {
        client: clientId,
        job_status: job_status
      },
      sort: ['createdAt:desc'],
      pagination: {
        start: 0,
        limit: 10
      }
    },
    { encodeValuesOnly: true }
  )
}

export default class JobHistoryService {
  static JOB_HISTORY_URL: string = '/job-histories'

  static async getJobHistoryRecords(clientId: number, job_status?: string): Promise<JobHistoryModel[]> {
    let response: AxiosResponse
    try {
      response = await http.get(`${JobHistoryService.JOB_HISTORY_URL}?${query(clientId, job_status)}`, {
        cache: false
      })
    } catch (error: any) {
      throw new Error(error.response?.data?.error?.message || error.message)
    }

    return getModelFromResponse<JobHistoryModel>(response) as JobHistoryModel[]
  }
}
