<script lang="ts">
import { QuestionMarkCircleIcon } from '@heroicons/vue/24/solid'
import hljs from 'highlight.js/lib/core'
import { darkTheme } from 'naive-ui'
import type { TooltipProps } from 'naive-ui'
import type { TooltipContent } from '@assets/help/tooltips'
import { xssPlugin } from '@shared/utils/xss'
import type { NullString } from '@shared/utils/Types'

type InputOverrides = NonNullable<TooltipProps['themeOverrides']>

const themeOverrides: InputOverrides = {
  peers: {
    Popover: {
      color: '#333',
    },
  },
}

export default defineComponent({
  name: 'TooltipSpan',
  components: {
    QuestionMarkCircleIcon,
  },
  props: {
    text: {
      type: String as PropType<NullString>,
      default: null,
    },
    hasIcon: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    hoverAll: Boolean as PropType<boolean>,
    isHtmlContent: Boolean as PropType<boolean>,
    widthClass: String as PropType<NullString>,
    tooltipContent: Object as PropType<Partial<TooltipContent>>,
    noWrap: Boolean as PropType<boolean>,
  },
  setup(props) {
    const yamlRendered: ComputedRef<string | undefined> = computed(() => {
      return props.tooltipContent?.yaml
        ? (hljs.highlight(props.tooltipContent.yaml, { language: 'yaml' }).value as string)
        : undefined
    })

    const questionMarkIconDoubleClickHandler = async () => {
      if (props.tooltipContent && props.tooltipContent.id) {
        await navigator.clipboard.writeText(props.tooltipContent.id)
      }
    }

    return {
      themeOverrides,
      yamlRendered,
      xssPlugin,
      darkTheme,
      questionMarkIconDoubleClickHandler,
    }
  },
})
</script>

<template>
  <span class="flex-inline items-center" :class="{ 'whitespace-nowrap': noWrap }">
    <span v-if="!hoverAll && text">{{ text }}</span>
    <NTooltip
      raw
      :placement="tooltipContent?.tooltipPlacement || 'top-start'"
      trigger="hover"
      :delay="500"
      :duration="600"
      :contentStyle="{ backgroundColor: '#222', borderRadius: '5px', padding: '12px' }"
      class="offerfit-tooltip max-w-md"
      :class="widthClass"
      :themeOverrides="themeOverrides"
    >
      <template #trigger>
        <slot name="trigger">
          <span>
            <template v-if="hoverAll && text">{{ text }}&nbsp;</template>
            <div
              v-if="hasIcon"
              class="i-solar-info-circle-linear mx-1 cursor-help text-xs text-gray-400"
              @dblclick="questionMarkIconDoubleClickHandler"
            />
          </span>
        </slot>
      </template>
      <span v-if="!isHtmlContent">{{ tooltipContent.text }}</span>
      <div v-else nosem v-html="xssPlugin.process(tooltipContent.text)" />
      <div v-if="tooltipContent.collapsedText || tooltipContent.yaml">
        <br>
        <NCollapse :theme="darkTheme">
          <NCollapseItem :title="tooltipContent.collapseHeader">
            <pre
              v-if="tooltipContent.yaml"
              class="hljs overflow-auto border-2 border-gray-600 rounded-md p-3"
              nosem
              v-html="xssPlugin.process(yamlRendered)"
            />
            <template v-else>{{ tooltipContent.collapsedText }}</template>
          </NCollapseItem>
        </NCollapse>
      </div>
    </NTooltip>
  </span>
</template>
