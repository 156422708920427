import { GetterTree } from 'vuex'

import { PipelineModel } from '@/pipelines/models/server/PipelineModel'
import { AppState } from '@app/store/app'
import { PipelineStateType } from '@/pipelines/store/state'

const getters: GetterTree<PipelineStateType, AppState> = {
  pipeline(state: PipelineStateType): PipelineModel | null {
    return state.pipeline as PipelineModel
  },
  pipelines(state: PipelineStateType): PipelineModel[] | null {
    return state.pipelines as PipelineModel[]
  }
}
export default getters
