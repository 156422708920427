import { createPinia } from 'pinia'
import { key, store } from '@app/store'

export function install({ app }) {
  const pinia = createPinia()
  app.use(pinia)

  // TEMP: Initialize Vuex here so it code-smells and we are forced to refactor 💃
  app.use(store, key)
}
