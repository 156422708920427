import { DAGModelHistoryListItem, PipelineModelHistoryListItem, UsecaseModelHistoryListItem } from '@/pipelines/models/server/PipelineModel'

export type PipelineId = number
export type UsecaseId = number
export type DAGId = number
export type HistoryRequestHash = string

export type PipelineHistoryRequest = {
  filters: {
    original: PipelineId
  }
  populate: {
    updatedByUser: {
      fields: ['username']
    }
  }
}

export type UsecaseHistoryRequest = {
  filters: {
    original: UsecaseId
  }
  populate: {
    updatedByUser: {
      fields: ['username']
    }
  }
}

export type DAGHistoryRequest = {
  filters: {
    original: DAGId
  }
  populate: {
    updatedByUser: {
      fields: ['username']
    }
  }
}

const pipelineHistoryListMap = new Map<PipelineId, Map<HistoryRequestHash, PipelineModelHistoryListItem[]>>([])

const usecaseHistoryListMap = new Map<UsecaseId, Map<HistoryRequestHash, UsecaseModelHistoryListItem[]>>([])

const dagHistoryListMap = new Map<DAGId, Map<HistoryRequestHash, DAGModelHistoryListItem[]>>([])

export const useHistory = () => {
  const clearLocalPipelineHistory = (pipelineId: PipelineId) => {
    if (pipelineHistoryListMap.has(pipelineId)) {
      pipelineHistoryListMap.delete(pipelineId)
    }
  }

  const clearLocalUsecaseHistory = (usecaseId: UsecaseId) => {
    if (usecaseHistoryListMap.has(usecaseId)) {
      usecaseHistoryListMap.delete(usecaseId)
    }
  }

  const clearLocalDAGHistory = (dagId: DAGId) => {
    if (dagHistoryListMap.has(dagId)) {
      dagHistoryListMap.delete(dagId)
    }
  }

  return {
    pipelineHistoryListMap,
    usecaseHistoryListMap,
    dagHistoryListMap,
    clearLocalPipelineHistory,
    clearLocalUsecaseHistory,
    clearLocalDAGHistory
  }
}
