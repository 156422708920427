import { LOCAL_STORAGE_LOG_LEVEL } from '@shared/data/constants'

export enum LOG_LEVEL {
  NONE = 0,
  LOG = 1,
  DEBUG = 2,
  TRACE = 3,
}

export const LOG_LEVEL_NUMBER = useStorage(LOCAL_STORAGE_LOG_LEVEL, 0)

export function log(...args: any[]) {
  // eslint-disable-next-line no-console
  if ((LOG_LEVEL_NUMBER.value >= LOG_LEVEL.LOG) && args && args.length) { console.log(...args) }
}

export function warn(...args: any[]) {
  if ((LOG_LEVEL_NUMBER.value >= LOG_LEVEL.LOG) && args && args.length) { console.warn(...args) }
}

export function debug(...args: any[]) {
  // eslint-disable-next-line no-console
  if ((LOG_LEVEL_NUMBER.value >= LOG_LEVEL.DEBUG) && args && args.length) { console.debug(...args) }
}

export function trace(...args: any[]) {
  // eslint-disable-next-line no-console
  if ((LOG_LEVEL_NUMBER.value >= LOG_LEVEL.TRACE) && args && args.length) { console.trace(...args) }
}

export function logGroupStart(...args: any[]) {
  // eslint-disable-next-line no-console
  if (LOG_LEVEL_NUMBER.value >= LOG_LEVEL.DEBUG) { console.group(...args) }
}

export function logGroupEnd() {
  // eslint-disable-next-line no-console
  if (LOG_LEVEL_NUMBER.value >= LOG_LEVEL.DEBUG) { console.groupEnd() }
}
