import { xxhash64 } from 'hash-wasm'
import debounce from 'lodash/debounce'
import type { ChannelEvent, EventChannel, EventTypeKey } from '@shared/data/constants'
import type { NullString, StoreDataChangePayload, StoreDataRewritePayload } from '@shared/utils/Types'
import { hasValue } from '@shared/utils/hasValue'
import { DIMENSION_WHEN_TYPE_VALUES, DimensionDefaultTriggerMap, EVENT_DEFAULTS } from '@shared/data/mapAndLists'
import {
  ActionFieldType,
  ActionStatus,
  ConversionEvent,
  DIMENSION_TYPE,
  DataType,
  UseCaseStatus,
} from '@shared/data/constants'
import type { UseCaseState } from '@/usecases/composables/state'
import { getDimensionType, getUseCaseConfigWithoutActionBanks } from '@/usecases/composables/usecaseUtils'

import type { CommonEventType, ConversionEventType, EventItemModel } from '@/usecases/models/EventsModel'
import type { ExperiencesModel } from '@/usecases/models/ExperiencesModel'
import type {
  ActionAugmentations,
  ConfigKPI,
  ConfigMetric,
  DeliverRecFileFormat,
  EventSequenceConfigModel,
  RecsAugmentationConfig,
  TouchType,
  UseCaseConfigModelWithoutActionBank,
} from '@/usecases/models/UseCaseConfigModel'
import type {
  ActionBanksForOutput,
  ActionBanksForOutputPartial,
  ActionPayload,
  ConfigDimensionActionBank,
  DimensionActionBank,
  DimensionActionBankPartial,
} from '@/usecases/models/server/ActionModel'
import type { UseCaseMetadataModel, UseCaseModel, UseCaseModelWithoutConfig } from '@/usecases/models/server/UseCaseModel.ts'
import {
  DEFAULT_COLUMN_NAME,
  getActionParameterObject,
  getUnwrappedActionBank,
  getUnwrappedActionConfigModel,
} from '@/usecases/utils/actionBank'
import { ExposedChannelEvents } from '@/usecases/composables/useEvents'

export function useUseCaseMutations(_state: UseCaseState) {
  const state = _state

  const debouncedTriggerUseCaseConfigHashRecalculation = debounce(() => {
    xxhash64(JSON.stringify(toRaw(state.localUseCaseConfig))).then((hash: string) => {
      state.localUsecaseConfigHash = hash
    }).catch((e: Error) => {
      console.error(e)
    })
  })

  const debouncedTriggerActionBankConfigHashRecalculation = debounce(() => {
    xxhash64(JSON.stringify(toRaw(state.localActionBank) || [])).then((hash: string) => {
      state.localActionBankConfigHash = hash
    }).catch((e: Error) => {
      console.error(e)
    })
  })

  const triggerHashRecalculation = (
    linkToChangedObject?: any,
    isUseCaseConfigChanged?: boolean,
    isActionBankChanged?: boolean,
  ) => {
    if (!state.localUseCaseConfig) { return }

    // if changed object in usecase config, recalculate usecase hash
    if (isUseCaseConfigChanged || (state.localUseCaseConfig && hasValue(state.localUseCaseConfig, linkToChangedObject))) { debouncedTriggerUseCaseConfigHashRecalculation() }

    // if changed object in action bank, recalculate action bank hash
    if (isActionBankChanged || (state.localActionBank && hasValue(state.localActionBank, linkToChangedObject))) { debouncedTriggerActionBankConfigHashRecalculation() }
  }

  const updateActionBankWithUnWrapped = (configDimensionActionBank: ConfigDimensionActionBank) => {
    const actionBankToUpdate = state.localActionBank?.find(
      (item: ConfigDimensionActionBank) => item.name === configDimensionActionBank.name,
    )

    if (actionBankToUpdate) {
      actionBankToUpdate.actions = configDimensionActionBank.actions.length
        ? configDimensionActionBank.actions
        : actionBankToUpdate.actions
      actionBankToUpdate.fields = configDimensionActionBank.fields.length
        ? configDimensionActionBank.fields
        : actionBankToUpdate.fields
    }
    else {
      state.localActionBank?.push(configDimensionActionBank)
    }
  }

  const populateExperiences = (state: UseCaseState) => {
    const result: ExperiencesModel[] = []

    const dimensions = Object.keys(state.localUseCaseConfig?.prediction?.dimension ?? {})

    const whenDimensions = dimensions.filter((dimensionName: string) => {
      return DIMENSION_WHEN_TYPE_VALUES.includes(dimensionName)
    })

    const whatDimensions = dimensions.filter((dimensionName: string) => {
      return getDimensionType(dimensionName) === DIMENSION_TYPE.WHAT
    })

    // get experiences for date/frequency/cadence dimensions
    whenDimensions.forEach((dimensionName: string) => {
      const dimensionKey = DIMENSION_WHEN_TYPE_VALUES.find(name => name === dimensionName)
      if (!dimensionKey) {
        return
      }
      if (!DimensionDefaultTriggerMap.has(dimensionKey)) {
        return
      }

      result.push({
        dimension: dimensionKey,
        trigger: DimensionDefaultTriggerMap.get(dimensionKey)!,
        primary: {
          event: ConversionEvent.CONVERSION,
          days: 7,
        },
      })
    })

    state.localUseCaseConfig?.channels.forEach((channelName: EventChannel) => {
      whatDimensions.forEach((dimensionName) => {
        result.push({
          channel: channelName,
          dimension: dimensionName,
          trigger: null,
          primary: {
            event: ConversionEvent.CONVERSION,
            days: null,
          },
        })
      })
    })

    if (state.localUseCaseConfig && !state.localUseCaseConfig.training) {
      state.localUseCaseConfig.training = getUseCaseConfigWithoutActionBanks().training
    }

    result.forEach((experience: ExperiencesModel) => {
      if (state.localUseCaseConfig
        && !state.localUseCaseConfig.training.experiences.find((item: ExperiencesModel) => item.dimension === experience.dimension && (!experience.channel || item.channel === experience.channel))) {
        state.localUseCaseConfig.training.experiences.push(experience)
      }
    })
  }

  return {
    setCurrentUsecase: (value: UseCaseModelWithoutConfig | null) => {
      if (!value) { return }
      state.localUsecase = value
      state.isCurrentUseCaseLive = state.localUsecase.status === UseCaseStatus.LIVE
      state.remoteUseCase = structuredClone(toRaw(value))
    },
    setLiveUsecase: (value: UseCaseModel) => {
      state.liveUsecase = readonly(value)
    },
    initUseCaseConfig: (value: UseCaseConfigModelWithoutActionBank) => {
      state.localUseCaseConfig = value
      xxhash64(JSON.stringify(toRaw(state.localUseCaseConfig))).then((hash: string) => {
        state.remoteUsecaseConfigHash = hash
        state.localUsecaseConfigHash = hash
      }).catch((e: Error) => {
        console.error(e)
      })
    },
    remoteUsecaseConfigHash: (value: string) => {
      state.remoteUsecaseConfigHash = value
    },
    localUsecaseConfigHash: (value: string) => {
      state.localUsecaseConfigHash = value
    },
    syncUseCaseConfigHashes: () => {
      if (state.localUsecaseConfigHash) { state.remoteUsecaseConfigHash = state.localUsecaseConfigHash }
    },
    syncActionBankConfigHashes: () => {
      if (state.localActionBankConfigHash) { state.remoteActionBankConfigHash = state.localActionBankConfigHash }
    },
    removeObjectInStore: ({ fieldObject, key }: StoreDataChangePayload) => {
      delete fieldObject[key]
      triggerHashRecalculation(fieldObject)
    },
    updateObjectInConfig: ({ fieldObject, value, key }: StoreDataChangePayload) => {
      fieldObject[key] = value
      triggerHashRecalculation(fieldObject)
    },
    pushToConfigArray: ({ fieldObject, value }: StoreDataChangePayload) => {
      fieldObject.push(value)
      triggerHashRecalculation(fieldObject)
    },
    rewriteObjectInStore: ({ targetObject, byObject }: StoreDataRewritePayload) => {
      for (const rewriteKey in byObject) { targetObject[rewriteKey] = byObject[rewriteKey] }

      triggerHashRecalculation(targetObject)
    },
    editPredictionOmit: (omit: string[]) => {
      if (!state.localUseCaseConfig) { return }
      state.localUseCaseConfig.prediction.features_train_predict_omit = omit
      triggerHashRecalculation(undefined, true)
    },
    editTrainingExperiences: (experiences: ExperiencesModel[]) => {
      if (!state.localUseCaseConfig) { return }
      if (!state.localUseCaseConfig.training) {
        state.localUseCaseConfig.training = getUseCaseConfigWithoutActionBanks().training
      }
      state.localUseCaseConfig.training.experiences = experiences
      populateExperiences(state)
      triggerHashRecalculation(undefined, true)
    },
    addTrainingExperience: (experience: ExperiencesModel) => {
      if (!state.localUseCaseConfig) { return }
      if (!state.localUseCaseConfig.training) {
        state.localUseCaseConfig.training = getUseCaseConfigWithoutActionBanks().training
      }
      state.localUseCaseConfig.training.experiences.push(experience)
      populateExperiences(state)
      triggerHashRecalculation(undefined, true)
    },
    deleteTrainingExperiencesByChannel: (channel: EventChannel) => {
      if (!state.localUseCaseConfig) { return }
      if (!state.localUseCaseConfig.training) {
        state.localUseCaseConfig.training = getUseCaseConfigWithoutActionBanks().training
      }
      state.localUseCaseConfig.training.experiences = state.localUseCaseConfig.training.experiences?.filter((item: ExperiencesModel) => item.channel !== channel)
      populateExperiences(state)
      triggerHashRecalculation(undefined, true)
    },
    addFeatureAugmentation: (feature: string) => {
      if (!state.localUseCaseConfig) { return }
      if (state.localUseCaseConfig.filter_and_augment_recs.augmentation === undefined) {
        state.localUseCaseConfig.filter_and_augment_recs.augmentation = {
          actions: {} as ActionAugmentations,
          features: [],
        } as RecsAugmentationConfig
      }
      const augmentation = state.localUseCaseConfig?.filter_and_augment_recs?.augmentation as RecsAugmentationConfig
      const setArray = (augmentation.features || []).concat([feature])
      augmentation.features = [...new Set<string>(setArray)]
      triggerHashRecalculation(undefined, true)
    },
    removeFeatureAugmentation: (feature: string) => {
      if (!state.localUseCaseConfig) { return }
      if (state.localUseCaseConfig.filter_and_augment_recs.augmentation === undefined) {
        state.localUseCaseConfig.filter_and_augment_recs.augmentation = {
          actions: {} as ActionAugmentations,
          features: [],
        } as RecsAugmentationConfig
      }

      const augmentation = state.localUseCaseConfig.filter_and_augment_recs.augmentation as RecsAugmentationConfig
      augmentation.features = augmentation.features?.filter((item: string) => item !== feature) || []

      triggerHashRecalculation(undefined, true)
    },
    addActionAugmentation: (newAction: ActionBanksForOutputPartial) => {
      if (!state.localUseCaseConfig) { return }
      if (state.localUseCaseConfig.filter_and_augment_recs.augmentation === undefined) {
        state.localUseCaseConfig.filter_and_augment_recs.augmentation = {
          actions: {} as ActionAugmentations,
          features: [],
        } as RecsAugmentationConfig
      }
      const augmentationActions
        = state.localUseCaseConfig.filter_and_augment_recs.augmentation.actions || ({} as ActionAugmentations)

      if (!augmentationActions[newAction.dimension as string]) { augmentationActions[newAction.dimension as string] = [] }

      if (!augmentationActions[newAction.dimension as string].includes(newAction.value || '')) { augmentationActions[newAction.dimension as string].push(newAction.value || '') }

      state.localUseCaseConfig.filter_and_augment_recs.augmentation.actions = augmentationActions

      triggerHashRecalculation(undefined, true)
    },
    removeActionAugmentation: (removeAction: ActionBanksForOutput) => {
      if (!state.localUseCaseConfig) { return }
      if (state.localUseCaseConfig.filter_and_augment_recs.augmentation === undefined) {
        state.localUseCaseConfig.filter_and_augment_recs.augmentation = {
          actions: {} as ActionAugmentations,
          features: [],
        } as RecsAugmentationConfig
      }
      const augmentationActions
        = state.localUseCaseConfig.filter_and_augment_recs.augmentation.actions || ({} as ActionAugmentations)

      augmentationActions[removeAction.dimension] = augmentationActions[removeAction.dimension].filter(
        (item: string) => item !== removeAction.value,
      )
      // remove dimension key if removing the last action from dimension
      if (augmentationActions[removeAction.dimension]?.length === 0) { delete augmentationActions[removeAction.dimension] }

      state.localUseCaseConfig.filter_and_augment_recs.augmentation.actions = augmentationActions

      triggerHashRecalculation(undefined, true)
    },
    addChannel: (channel: EventChannel) => {
      if (!state.localUseCaseConfig) { return }

      if (!state.localUseCaseConfig.channels) { state.localUseCaseConfig.channels = [] }

      state.localUseCaseConfig.channels.push(channel)

      populateExperiences(state)
      triggerHashRecalculation(undefined, true)
    },
    deleteChannel: (channel: EventChannel) => {
      if (!state.localUseCaseConfig) { return }
      const events = state.localUseCaseConfig.events

      if (events) {
        // delete all related events first
        const eventsToDelete = ExposedChannelEvents.get(channel)
        eventsToDelete?.forEach((channelEvent: ChannelEvent) => {
          const eventTypeKey = EVENT_DEFAULTS.get(channelEvent)

          if (eventTypeKey) {
            events[eventTypeKey] = events[eventTypeKey]?.filter((item: CommonEventType | ConversionEventType) => item.event_type !== channelEvent)
            if (events[eventTypeKey] && !events[eventTypeKey].length) { delete events[eventTypeKey] }
          }
        })
      }

      state.localUseCaseConfig.channels = state.localUseCaseConfig.channels.filter((item: EventChannel) => item !== channel)
      state.localUseCaseConfig.training.experiences = state.localUseCaseConfig.training.experiences?.filter((item: ExperiencesModel) => item.channel !== channel)
      state.localUseCaseConfig.training.event_sequences = state.localUseCaseConfig.training.event_sequences?.filter((item: EventSequenceConfigModel) => item.channel !== channel)

      populateExperiences(state)

      triggerHashRecalculation(undefined, true)
    },
    // Is really an upsert function
    updateEvents: ({ type, payload }: { type: EventTypeKey, payload: Partial<CommonEventType | ConversionEventType> }) => {
      if (!state.localUseCaseConfig) { return }

      state.localUseCaseConfig.events ??= {}
      const events = state.localUseCaseConfig.events
      if (!events[type]) { events[type] = [] }

      const foundEvent = events[type].find(
        item => ((item.id && payload.id && item.id === payload.id) || item.event_type === payload.event_type),
      )

      if (foundEvent) {
        Object.assign(foundEvent, payload)
      }
      else {
        events[type].push(payload)
      }

      triggerHashRecalculation(undefined, true)
    },
    deleteEvent: ({ event_type }: Pick<EventItemModel, 'event_type'>) => {
      if (!state.localUseCaseConfig) { return }

      const eventTypeKey = EVENT_DEFAULTS.get(event_type)
      const events = state.localUseCaseConfig.events

      if (eventTypeKey && events) {
        events[eventTypeKey] = events[eventTypeKey]?.filter((item: CommonEventType | ConversionEventType) => item.event_type !== event_type)
        if (events[eventTypeKey] && !events[eventTypeKey].length) { delete events[eventTypeKey] }
      }

      triggerHashRecalculation(undefined, true)
    },
    deleteCustomEvent: ({ id }: { id: string }) => {
      if (!state.localUseCaseConfig) { return }

      const events = state.localUseCaseConfig.events

      if (!events) { return }

      for (const key in events) {
        events[key] = events[key].filter(item => item.id !== id)
      }

      triggerHashRecalculation(undefined, true)
    },
    upsertReportingMetric: ({
      // If idx is not provided, it will search for the metric by id or name.
      reportingMetric,
    }: { reportingMetric: Partial<ConfigMetric> }) => {
      if (!state.localUseCaseConfig) { return }

      if (!state.localUseCaseConfig.reporting) { state.localUseCaseConfig.reporting = { metrics: [] } }
      if (!state.localUseCaseConfig.reporting.metrics) { state.localUseCaseConfig.reporting.metrics = [] }

      const metrics = state.localUseCaseConfig.reporting.metrics

      if (!metrics) { return }

      const index = metrics.findIndex(item => item.id === reportingMetric.id)

      if (index === -1) { metrics.push(reportingMetric) }
      else { metrics[index] = reportingMetric }

      triggerHashRecalculation(undefined, true)
    },
    deleteReportingMetric: (id: string) => {
      if (!state.localUseCaseConfig) { return }

      const metrics = state.localUseCaseConfig.reporting?.metrics
      if (!metrics || !state.localUseCaseConfig.reporting) { return }

      const metricIdx = metrics.findIndex((item: ConfigMetric) => item.id === id)

      if (metricIdx === -1) { return }

      state.localUseCaseConfig.reporting.metrics.splice(metricIdx, 1)

      triggerHashRecalculation(undefined, true)
    },
    upsertReportingKPI: ({
      idx,
      reportingKPI,
    }: { idx?: number, reportingKPI: Partial<ConfigKPI> }) => {
      if (!state.localUseCaseConfig) { return }

      if (!state.localUseCaseConfig.reporting) { state.localUseCaseConfig.reporting = { kpis: [] } }
      if (!state.localUseCaseConfig.reporting.kpis) { state.localUseCaseConfig.reporting.kpis = [] }

      const kpis = state.localUseCaseConfig.reporting.kpis

      if (!kpis) { return }

      const index = idx ?? kpis.findIndex(item => item.id === reportingKPI.id)

      if (index === -1) { kpis.push(reportingKPI) }
      else { kpis[index] = reportingKPI }

      triggerHashRecalculation(undefined, true)
    },
    deleteReportingKPI: (kpiId: string) => {
      if (!state.localUseCaseConfig) { return }

      const kpis = state.localUseCaseConfig.reporting?.kpis
      if (!kpis || !state.localUseCaseConfig.reporting) { return }

      const kpiIdx = kpis.findIndex((item: ConfigKPI) => item.id === kpiId)

      state.localUseCaseConfig.reporting.kpis.splice(kpiIdx, 1)

      triggerHashRecalculation(undefined, true)
    },
    updateEventSequence: (payload: any) => {
      if (!state.localUseCaseConfig) { return }
      state.localUseCaseConfig.training.event_sequences = payload

      triggerHashRecalculation(undefined, true)
    },
    updateEventSequenceRequiredConversionEvent: (payload: { value: string, channel: string }) => {
      if (!state.localUseCaseConfig) { return }
      const eventSequence = state.localUseCaseConfig.training.event_sequences.find(
        (item: any) => item.channel === payload.channel,
      )
      if (eventSequence) {
        eventSequence.required_event_for_conversion = payload.value
        triggerHashRecalculation(undefined, true)
      }
    },
    updateConversionAttributionType: (payload: TouchType) => {
      if (!state.localUseCaseConfig) { return }
      state.localUseCaseConfig.training.attribution_type = payload
      triggerHashRecalculation(undefined, true)
    },
    updateTargetMetricName: (payload: NullString) => {
      if (!state.localUseCaseConfig) { return }
      state.localUseCaseConfig.training.target_metric.metric_name = payload
      triggerHashRecalculation(undefined, true)
    },
    updateActivationDelay: (payload: number) => {
      if (!state.localUseCaseConfig) { return }
      state.localUseCaseConfig.filter_and_augment_recs.delay_days_to_activation = payload
      triggerHashRecalculation(undefined, true)
    },
    updateOutputFileType: (payload: DeliverRecFileFormat) => {
      if (!state.localUseCaseConfig) { return }
      state.localUseCaseConfig.deliver_recs.rec_file_format = payload
      triggerHashRecalculation(undefined, true)
    },
    updateNoInterventionGroup: (payload: string) => {
      if (!state.localUseCaseConfig) { return }
      state.localUseCaseConfig.prediction.no_intervention_group = payload
      triggerHashRecalculation(undefined, true)
    },
    initActionBank: (payload: ConfigDimensionActionBank[]) => {
      state.localActionBank = payload
      xxhash64(JSON.stringify(toRaw(state.localActionBank))).then((hash: string) => {
        state.localActionBankConfigHash = hash
        state.remoteActionBankConfigHash = hash
      }).catch((e: Error) => {
        console.error(e)
      })
    },
    setActionBank: (payload: ConfigDimensionActionBank[]) => {
      state.localActionBank = payload
      triggerHashRecalculation(undefined, false, true)
    },
    addActionBank: (payload: DimensionActionBank) => {
      if (state.localActionBank === undefined) {
        state.localActionBank = [getUnwrappedActionBank(payload)]
        return
      }

      state.localActionBank.push(getUnwrappedActionBank(payload))
      triggerHashRecalculation(undefined, false, true)
    },
    updateConfigActionBank: (payload: ConfigDimensionActionBank) => {
      if (state.localActionBank === undefined) {
        state.localActionBank = [payload]
        triggerHashRecalculation(undefined, false, true)
        return
      }
      updateActionBankWithUnWrapped(payload)
      triggerHashRecalculation(undefined, false, true)
    },
    updateActionBank: (payload: DimensionActionBank | DimensionActionBankPartial) => {
      if (state.localActionBank === undefined) {
        state.localActionBank = [getUnwrappedActionBank(payload as DimensionActionBank)]
        triggerHashRecalculation(undefined, false, true)
        return
      }
      updateActionBankWithUnWrapped(getUnwrappedActionBank(payload as DimensionActionBank))
      triggerHashRecalculation(undefined, false, true)
    },
    addAction: (payload: ActionPayload) => {
      if (state.localActionBank === undefined) {
        state.localActionBank = []
        triggerHashRecalculation(undefined, false, true)
        return
      }

      let actionBankToUpdate = state.localActionBank.find(
        (item: ConfigDimensionActionBank) => item.name === payload.dimension.name,
      )

      if (!actionBankToUpdate && payload.dimension.name) {
        actionBankToUpdate = {
          name: payload.dimension.name,
          fields: [
            getActionParameterObject(
              DEFAULT_COLUMN_NAME.ACTION_ID,
              payload.dimension.name,
              DataType.INTEGER,
              0,
              ActionFieldType.PARAMETER,
            ).data,
            getActionParameterObject(
              DEFAULT_COLUMN_NAME.CREATED_DATE,
              payload.dimension.name,
              DataType.DATE,
              1,
              ActionFieldType.PARAMETER,
            ).data,
            getActionParameterObject(
              DEFAULT_COLUMN_NAME.ACTION_STATUS,
              payload.dimension.name,
              DataType.STRING,
              1,
              ActionFieldType.PARAMETER,
              ActionStatus.ACTIVE,
            ).data,
          ],
          actions: [],
        } as ConfigDimensionActionBank
      }

      if (actionBankToUpdate && !actionBankToUpdate.actions) { actionBankToUpdate.actions = [] }

      if (actionBankToUpdate) { actionBankToUpdate.actions.push(getUnwrappedActionConfigModel(payload.action)) }

      triggerHashRecalculation(undefined, false, true)
    },
    updateUseCaseMetadata: (payload: UseCaseMetadataModel) => {
      if (!state.localUsecase) { return }
      state.localUsecase.metadata = payload
    },
    triggerHashRecalculation,
  }
}
