<template>
  <div class="">
    <NTooltip trigger="hover" :theme-overrides="themeOverrides">
      <template #trigger>
        <div class="static top-1 float-right h-0">
          <span
            v-if="validationStatus.status"
            class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium relative bottom-2 w-6 h-6"
            :class="[
              validationStatus.status === 'warning' ? 'bg-orange-100 text-yellow-800' : '',
              validationStatus.status === 'pass' ? 'bg-green-100 text-green-800' : ''
            ]"
          >
            <span v-if="validationStatus.status === 'warning'">!</span>
            <span v-if="validationStatus.status === 'pass'"><CheckIcon class="w-4 h-4 relative right-1" /></span>
          </span>
        </div>
      </template>
      {{ validationStatus.description }}
    </NTooltip>
    <BasicLink :to="`/data-asset/${rowData.key}/${tabLink}`">
      <div>
        <NDescriptions :columns="1">
          <NDescriptionsItem label="Last modified">
            <TimezonedDateLabel :date="lastModified" mode="friendly"/>
          </NDescriptionsItem>
          <NDescriptionsItem>
            <template #label>
              Last run
              <span>{{ lastRunResult }}</span>
            </template>
            <TimezonedDateLabel :date="lastRunDate" mode="friendly"/>
          </NDescriptionsItem>
        </NDescriptions>
      </div>
    </BasicLink>
  </div>
</template>

<script lang="ts">
import { CheckIcon } from '@heroicons/vue/24/solid'
import { NTooltip, TooltipProps } from 'naive-ui'
import { AssetTabKey } from '@shared/data/constants'
import { NullString } from '@shared/utils/Types'
import { monthShortDateTime } from '@shared/utils/transformHelpers'

type InputOverrides = NonNullable<TooltipProps['themeOverrides']>
type WarningStatus = {
  status: NullString
  description?: NullString
}
const themeOverrides: InputOverrides = {
  peers: {
    Popover: {
      color: '#333'
    }
  }
}

export default defineComponent({
  name: 'DataAssetsTableMetadataRender',
  components: { CheckIcon, NTooltip },
  props: {
    rowData: Object as PropType<any>,
    lastModified: String as PropType<NullString>,
    lastRunDate: String as PropType<NullString>,
    lastRunResult: String as PropType<NullString>,
    tab: String as PropType<string>
  },
  setup(props) {
    const validationStatus = computed<WarningStatus>(() => {
      const status: WarningStatus = { status: 'pass', description: 'Setup is successful.' }
      if (props.lastModified && props.lastRunDate) {
        if (props.lastRunResult === 'FAILED' && props.tab === AssetTabKey.VALIDATE) {
          status.status = 'warning'
          status.description = 'Validation has failed. Adjust validation requirements and run again.'
        }
        if (props.lastRunResult === 'FAILED' && props.tab === AssetTabKey.PREPROCESS) {
          status.status = 'warning'
          status.description = 'Preprocessing has failed.'
        }
        if (new Date(props.lastModified) > new Date(props.lastRunDate)) {
          status.status = 'warning'
          status.description = 'Modifications have made since the last sample run.'
        }
      } else {
        status.status = null
        status.description = null
      }

      return status
    })
    // Preprocessing used to be its own separate tab, but was folded into Validation to make the UI cleaner
    // As of today - 2023-04-20 - Preprocessing still exists as a separate pipeline step, though,
    //  so we want to keep its status separate.
    // So this updates the link location to the Validate page while retaining the proper warnings
    const tabLink = computed<string>(() => {
      if (props.tab === AssetTabKey.PREPROCESS) {
        return AssetTabKey.VALIDATE
      }
      return props.tab || ''
    })
    return {
      monthShortDateTime,
      validationStatus,
      tabLink,
      themeOverrides
    }
  }
})
</script>
