import AppSettings from '@app/AppSettings'
import SystemAvailabilityService from '@app/services/SystemAvailabilityService'
import { store } from '@app/store'
import { AVAILABLE_STATUS, UNAVAILABLE_ROUTE_PATH } from '@shared/data/constants'
import type { App } from 'vue'

/**
 * @description This hook is called after the app is instantiated but before it is mounted.
 *
 * 1. It verifies Strapi availability.
 * 2. It loads the user object and sets the user object in the store using Vuex.
 *
 * This happens before app is mounted. We can use Vuex without any issues here.
 * It is important to load the user as soon as possible to avoid any issues with the router guard.
 * Then it imports and installs all /modules/*.ts files
 */
export async function appCreatedHook(app: App): Promise<void> {
  /**
   * 1. Verify Strapi availability
   */

  SystemAvailabilityService.available.value = await SystemAvailabilityService.isStrapiAvailable()
  SystemAvailabilityService.startPolling()

  if (
    SystemAvailabilityService.available.value === AVAILABLE_STATUS.OFFLINE
    && !window.location.pathname.endsWith(UNAVAILABLE_ROUTE_PATH)
  ) {
    const { redirectRouteAfterRecover } = AppSettings
    redirectRouteAfterRecover.value = window.location.pathname
  }

  /**
   * 2. Load the user object
   * This is important to load the user as soon as possible to avoid any issues with the router guard.
   * This user object is including clients and allClients
   */
  await store.dispatch('user/getUser')

  interface ModuleInstall { install: ({ app }: { app: App }) => Promise<void> | void }
  /**
   * Automatically import and define all modules inside the modules folder
   * This is useful to automatically import and define all modules inside the modules folder
   * Example: 01.module.ts will be executed before 02.module.ts
   * Modules are used to define global components, directives, and plugins that are used across the app
   * Example: Router, Vuex, Product tracking with Sentry, etc.
   */
  for (
    const i
    of Object.values(import.meta.glob<ModuleInstall>('../modules/*.ts', { eager: true }))
  ) {
    await i.install?.({ app })
  }
}
