<script lang="ts">
import { PlusCircleIcon, PencilSquareIcon } from '@heroicons/vue/24/outline'
import { NDatePicker, NPopover } from 'naive-ui'
import SimpleButton from '@shared/components/basic/SimpleButton.vue'

import type { DatesToExcludeTableData } from '@/reporting/models/server/ReportingModel'
import type { NullString } from '@shared/utils/Types'
import { store } from '@app/store'

export default defineComponent({
  name: 'SelectExclusionDateRender',
  components: { PlusCircleIcon, PencilSquareIcon, NPopover, NDatePicker, SimpleButton },
  props: {
    item: Object as PropType<DatesToExcludeTableData>,
    text: String as PropType<string>,
    type: String as PropType<'add' | 'edit'>
  },
  emits: ['dateSave'],
  setup(props, { emit }) {
    const datePopoverOpen = ref<boolean>(false)
    const selectedDate = ref<NullString>()
    const selectionError = ref<NullString>()

    const dateSelectionUpdated = (value: string) => {
      selectedDate.value = value
      selectionError.value = undefined
    }
    const validate = () => {
      selectionError.value = undefined
      if (!selectedDate.value)
        selectionError.value = 'No date selected'

      if (props.item?.dates?.includes(selectedDate.value as string))
        selectionError.value = 'Date is already excluded'
    }

    const closeHandler = () => {
      datePopoverOpen.value = false
    }

    const confirmHandler = () => {
      validate()
      if (!selectionError.value) {
        datePopoverOpen.value = false
        const payload = {
          ...props.item,
          dateToAdd: selectedDate.value,
        }
        emit('dateSave', payload)
      }
    }

    return {
      datePopoverOpen,
      selectionError,
      dateSelectionUpdated,
      confirmHandler,
      closeHandler,
      globalReadOnlyMode: computed(() => store.getters.isReadonlyMode),
    }
  },
})
</script>

<template>
  <div class="flex cursor-pointer font-semibold">
    <NPopover v-model:show="datePopoverOpen" trigger="click" :disabled="globalReadOnlyMode" placement="right-start">
      <template #activator>
        <div class="flex" :class="[globalReadOnlyMode ? 'text-gray-300' : 'text-offerfit-bright-purple']">
          <PlusCircleIcon v-if="type === 'add'" class="mr-1 mt-0.5 h-5 w-5 print:hidden" />
          <PencilSquareIcon v-else class="mr-1 mt-0.5 h-5 w-5 print:hidden" />
          {{ text }}
        </div>
      </template>
      <div class="w-80 p-2">
        <div class="flex justify-center">
          <NDatePicker panel type="date" :actions="null" format="yyyy-MM-dd" :on-update:formatted-value="dateSelectionUpdated" />
        </div>
        <div class="flex justify-end p-2 text-offerfit-bright-purple">{{ selectionError }}.</div>
        <div class="mt-2 flex justify-end">
          <SimpleButton text="Cancel" small class="mr-2" @click="closeHandler" />
          <SimpleButton :text="type === 'add' ? 'Add' : 'Select'" primary small @click="confirmHandler" />
        </div>
      </div>
    </NPopover>
  </div>
</template>

<style scoped></style>
