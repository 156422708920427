<script lang="ts">
import { EyeIcon, EyeSlashIcon, StopCircleIcon } from '@heroicons/vue/24/outline'
import { NSpin } from 'naive-ui'
import type { DiffViewTabKey } from '@shared/data/constants'
import { DeploymentState, DeploymentsUpdateKeyList } from '@shared/data/constants'
import { store } from '@app/store'
import type { TabItem } from '@shared/utils/pageTabs'
import { slugToSentenceCase } from '@shared/utils/transformHelpers'
import PipelineService from '@/pipelines/services/PipelineService'
import { useDeploymentPolling, useDeployments } from '@/deployments/composables/useDeployments'
import type { UseCaseConfigModel } from '@/usecases/models/UseCaseConfigModel'
import type {
  DeploymentsTableModel,
  DiffModalEmitObject,
  PipelineConfigObject,
  UseCaseConfigObject,
} from '@/deployments/models/server/DeploymentModel'
import UseCaseService from '@/usecases/services/UseCaseService'

export default defineComponent({
  name: 'DeploymentFailedTestsRender',
  components: { EyeIcon, StopCircleIcon, EyeSlashIcon, NSpin },
  props: {
    row: {
      type: Object as PropType<DeploymentsTableModel>,
    },
  },
  emit: ['emitConfigObjects'],
  setup(props, { emit }) {
    const { updateDeployment, updateTableData, cancelDagRun, isDeploymentsDiffModelOpened } = useDeployments()
    const { getPoll, status } = useDeploymentPolling(props.row as DeploymentsTableModel)

    onMounted(() => {
      getPoll()
    })

    const actionCancelStatuses = [DeploymentState.AWAITING_REVIEW, DeploymentState.RUNNING_TESTS]

    const useCaseDeploymentConfigObject: Ref<UseCaseConfigObject | undefined> = ref<UseCaseConfigObject | undefined>()
    const useCaseProdConfigObject: Ref<UseCaseConfigObject | undefined> = ref<UseCaseConfigObject | undefined>()

    const pipelineDeploymentConfigObject: Ref<PipelineConfigObject | undefined> = ref<PipelineConfigObject | undefined>()
    const pipelineProdConfigObject: Ref<PipelineConfigObject | undefined> = ref<PipelineConfigObject | undefined>()

    const modalTabs = ref<TabItem[] | undefined>()
    const loadingDiffConfigs = ref<boolean>(false)

    // TODO: this tabs are used in the dialog, so it should be moved to the dialog component
    const returnTabs = (usecaseObject: UseCaseConfigObject, pipelineObject: PipelineConfigObject) => {
      const array: TabItem[] = []
      if (usecaseObject && Object.keys(usecaseObject)) {
        Object.keys(usecaseObject)?.forEach((item: string) =>
          array.push({
            name: `Use case: ${slugToSentenceCase(item)}`,
            id: item as DiffViewTabKey,
          }),
        )
      }
      if (pipelineObject && Object.keys(pipelineObject)) {
        Object.keys(pipelineObject)?.forEach((item: string) => {
          if (item !== 'undefined') {
            array.push({
              name: `Pipeline: ${slugToSentenceCase(item)}`,
              id: item,
            })
          }
        })
      }
      return array
    }

    const clear = () => {
      modalTabs.value = undefined
      pipelineDeploymentConfigObject.value = undefined
      pipelineProdConfigObject.value = undefined
      useCaseProdConfigObject.value = undefined
      useCaseDeploymentConfigObject.value = undefined
    }

    // Get usecase configs by config version and for LIVE
    const getUseCaseDeploymentConfigObject = async () => {
      if (props.row?.usecases?.data) {
        const useCaseConfigs: UseCaseConfigObject = {}
        for (let i = 0; i < props.row?.usecases.data.length; i++) {
          const usecaseName = props.row?.usecases.data[i]?.name as string
          try {
            const response = await UseCaseService.getUseCaseHistoryConfigWithVersionId(
              store.getters['client/client'].name,
              usecaseName,
              store.getters['pipeline/pipeline']?.name,
              'draft',
              props.row?.usecases_config_version_map[usecaseName],
            )
            useCaseConfigs[usecaseName] = response.config || response
          }
          catch (e) {
            console.error(`Could not find config for deployment use case ${usecaseName}`, e)
            useCaseConfigs[usecaseName] = undefined
          }
        }
        useCaseDeploymentConfigObject.value = useCaseConfigs
      }
    }

    const getUseCaseProdConfigObject = async () => {
      if (props.row?.usecases.data) {
        const prodCaseConfigs: UseCaseConfigObject = {}
        for (let i = 0; i < props.row?.usecases.data.length; i++) {
          const usecaseName = props.row?.usecases.data[i]?.name as string
          try {
            // TODO add logic to get the use case's pipeline to address multi-pipeline
            const response = await UseCaseService.getUseCaseConfigFromEnvironment(
              usecaseName,
              store.getters['pipeline/pipeline']?.name,
              'live',
              store.getters['client/client'].name,
            )
            prodCaseConfigs[usecaseName] = (response?.config as UseCaseConfigModel) || response
          }
          catch (e) {
            console.error(`Could not find production config for use case ${usecaseName}`, e)
            prodCaseConfigs[usecaseName] = undefined
          }
        }
        useCaseProdConfigObject.value = prodCaseConfigs
      }
    }

    // Get pipeline configs by config version and for LIVE
    const getPipelineProdConfigObject = async () => {
      if (props.row?.pipelines.data) {
        const useProdCaseConfigs: PipelineConfigObject = {}
        for (let i = 0; i < props.row?.usecases.data.length; i++) {
          const pipelineName = props.row?.pipelines.data[i]?.name as string
          try {
            const response = await PipelineService.getPipelineConfigFromEnvironment(pipelineName, 'live')
            useProdCaseConfigs[pipelineName] = response.config
          }
          catch (e) {
            console.error(`Could not find production config for pipeline ${pipelineName}`, e)
            useProdCaseConfigs[pipelineName] = undefined
          }
        }
        pipelineProdConfigObject.value = useProdCaseConfigs
      }
    }

    const getPipelineDeploymentConfigObject = async () => {
      if (props.row?.pipelines.data) {
        const pipelineConfigs: PipelineConfigObject = {}
        for (let i = 0; i < props.row?.pipelines.data.length; i++) {
          const pipelineName = props.row?.pipelines.data?.[0]?.name as string
          try {
            const response = await PipelineService.getPipelineHistoryConfigWithVersionId(
              pipelineName,
              'draft',
              props.row?.pipelines_config_version_map?.[`${pipelineName}`],
            )
            pipelineConfigs[pipelineName] = response.config
          }
          catch (e) {
            console.error(`Could not find config for deployment pipeline ${[`${pipelineName}`]}`, e)
            pipelineConfigs[pipelineName] = undefined
          }
        }
        pipelineDeploymentConfigObject.value = pipelineConfigs
      }
    }

    const cancelDeploymentClickHandler = async () => {
      const cancelIsSuccessful = await cancelDagRun(props.row as DeploymentsTableModel)
      if (cancelIsSuccessful) {
        await Promise.allSettled([updateDeployment(props.row as DeploymentsTableModel, DeploymentsUpdateKeyList.STATE, DeploymentState.CANCELED)])
        updateTableData(props.row?.id as number, DeploymentsUpdateKeyList.STATE, DeploymentState.CANCELED)
      }
    }

    const archiveDeploymentClickHandler = async (value: boolean) => {
      await updateDeployment(props.row as DeploymentsTableModel, DeploymentsUpdateKeyList.IS_ARCHIVED, value)
      updateTableData(props.row?.id as number, DeploymentsUpdateKeyList.IS_ARCHIVED, value)
    }

    const viewConfigDiffClickHandler = async () => {
      loadingDiffConfigs.value = true
      clear()
      await Promise.allSettled([
        getUseCaseDeploymentConfigObject(),
        getUseCaseProdConfigObject(),
        getPipelineProdConfigObject(),
        getPipelineDeploymentConfigObject(),
      ])
      modalTabs.value = returnTabs(
        useCaseProdConfigObject.value as UseCaseConfigObject,
        pipelineProdConfigObject.value as PipelineConfigObject,
      )
      emit('emitConfigObjects', {
        row: props.row,
        modalTabs: modalTabs.value,
        useCaseDeploymentConfigObject: useCaseDeploymentConfigObject.value,
        useCaseProdConfigObject: useCaseProdConfigObject.value,
        pipelineProdConfigObject: pipelineProdConfigObject.value,
        pipelineDeploymentConfigObject: pipelineDeploymentConfigObject.value,
      } as DiffModalEmitObject)
      loadingDiffConfigs.value = false
    }

    return {
      isDeploymentsDiffModelOpened,
      actionCancelStatuses,
      status,
      useCaseDeploymentConfigObject,
      useCaseProdConfigObject,
      pipelineProdConfigObject,
      pipelineDeploymentConfigObject,
      modalTabs,
      loadingDiffConfigs,
      viewConfigDiffClickHandler,
      cancelDeploymentClickHandler,
      archiveDeploymentClickHandler,
      DeploymentState,
    }
  },
})
</script>

<template>
  <div class="flex">
    <div class="mr-4 flex cursor-pointer font-semibold text-offerfit-bright-purple" @click="viewConfigDiffClickHandler">
      <EyeIcon class="mr-1 mt-0.5 h-5 w-5" />
      config diff
    </div>
    <div
      v-if="actionCancelStatuses.includes(row.state)"
      :key="status"
      class="flex cursor-pointer font-semibold text-offerfit-bright-purple"
      @click="cancelDeploymentClickHandler"
    >
      <StopCircleIcon class="mr-1 mt-0.5 h-5 w-5" />
      cancel
    </div>

    <div
      v-else-if="!row.isArchived"
      class="flex cursor-pointer font-semibold text-offerfit-bright-purple"
      @click="archiveDeploymentClickHandler(true)"
    >
      <EyeSlashIcon class="mr-1 mt-0.5 h-5 w-5" />
      Archive
    </div>

    <div
      v-else-if="row.isArchived"
      class="flex cursor-pointer font-semibold text-offerfit-bright-purple"
      @click="archiveDeploymentClickHandler(false)"
    >
      <EyeIcon class="mr-1 mt-0.5 h-5 w-5" />
      un-archive
    </div>
  </div>
  <div class="flex justify-center">
    <NSpin v-if="loadingDiffConfigs" :show="loadingDiffConfigs" />
  </div>
</template>
