<script setup lang="ts">
import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/24/solid'
import type { RouterLinkProps } from 'vue-router'
import { RouterLink } from 'vue-router'
import { trackEvent } from '@shared/utils/analytics'

import router from '@router/index'
import { processToRoute } from '@router/routerHelpers'

interface Props extends Partial<RouterLinkProps> {
  inactiveClass?: string
  withExternalIcon?: boolean
  disabled?: boolean
  href?: string
}

const props = defineProps<Props>()

const extendedTo = computed(() => processToRoute(props.to || {}))
const isExternalLink = computed(() => props.href && typeof props.href === 'string' && props.href.startsWith('http'))
const secureHref = computed(() => {
  const newHref = isExternalLink.value ? props.href || '#' : router.resolve(extendedTo.value)?.href || '#'
  return (newHref && newHref.replaceAll('javascript:', '')) || '#'
})
function trackAndNavigate(navigate: () => void) {
  trackEvent('Link click', { value: secureHref.value, isExternal: isExternalLink.value })
  navigate()
}
</script>

<template>
  <a v-if="isExternalLink" v-bind="$attrs" :href="secureHref" target="_blank" rel="noopener noreferrer">
    <slot name="default" :href="secureHref" /><ArrowTopRightOnSquareIcon class="mb-2 ml-1 inline-block h-3 w-3" />
  </a>
  <RouterLink v-else v-slot="{ isActive, navigate }" :to="extendedTo" custom>
    <a
      v-bind="$attrs"
      :href="secureHref"
      :class="[isActive ? activeClass : inactiveClass, disabled ? 'pointer-events-none text-gray-600' : '']"
      @click.prevent="trackAndNavigate(navigate)"
    >
      <slot name="default" :href="secureHref" /><ArrowTopRightOnSquareIcon v-if="withExternalIcon" class="mb-2 ml-1 inline-block h-3 w-3" />
    </a>
  </RouterLink>
</template>
