<template>
  <div class="rounded-md bg-amber-50 p-4">
    <div class="flex">
      <div class="flex-shrink-0">
        <div class="i-solar-danger-triangle-bold text-amber-500"></div>
      </div>
      <div class="ml-3">
        <h3 class="font-medium text-sm">{{ title }}</h3>
        <div class="mt-2 text-gray-500 text-sm">
          <p>{{ subtitle }}</p>
        </div>
        <slot />
      </div>
      <div v-if="closable" class="ml-auto pl-3">
        <div class="-mx-1.5 -my-1.5">
          <button
            @click="dismiss"
            type="button"
            class="inline-flex bg-amber-50 rounded-md p-1.5 text-amber-500 hover:bg-amber-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-violet-50 focus:ring-amber-500"
          >
            <span class="sr-only">Dismiss</span>
            <span class="i-solar-close-circle-outline text-gray-800" ></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/24/solid'
import { NullString } from '@shared/utils/Types'

export default defineComponent({
  name: 'YellowWarning',
  components: {
    ExclamationTriangleIcon,
    XMarkIcon
  },
  props: {
    title: String as PropType<NullString>,
    subtitle: String as PropType<NullString>,
    closable: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  emits: ['dismiss'],
  setup(props, { emit }) {
    const dismiss = () => {
      emit('dismiss', true)
    }
    return {
      dismiss
    }
  }
})
</script>
