import AppSettings from '@app/AppSettings'
import { OFFERFIT_DOMAIN, ROLE_TYPE } from '@shared/data/constants'
import { store } from '@app/store'
import { Tags } from '@shared/utils/Tags.ts'

/**
 * Attention! this could be a composable, a service, a function or a class.
 * Probably not a pinia store because we want to use usePermissions before Vue initialization.
 * And pinia does not work without Vue being mounted.
 * Right now is kind of a composable that exports some variables.
 * The reason for these vars to be outside of the function is to mantain a singleton instance.
 */

const roleListFromEnum = Object.values(ROLE_TYPE)
const roleType = computed(() => store?.getters['user/role']?.type)
const userEmail = computed(() => store?.getters['user/email'])
const client = computed(() => store?.getters['client/client'])
const isRole = (role: ROLE_TYPE) => roleType.value === role
const hasAnyRole = computed(() => roleListFromEnum.includes(roleType.value))

const isUserAuthenticated = computed(() => store?.getters.isUserAuthenticated)
const userHasAcceptedTos = computed(() => store?.getters['user/userObject']?.tos_accepted)
const isAIEorAdmin = computed<boolean>(
  () => isRole(ROLE_TYPE.AIE) || isRole(ROLE_TYPE.ADMIN),
)

const isAdmin = computed(() => {
  return isRole(ROLE_TYPE.ADMIN)
})

const isInternalUser = computed(() => {
  return userEmail.value?.endsWith('@offerfit.ai')
})

const isTestingUser = computed(() => {
  return Tags.getTags(client.value).includes('E2E')
})

const isEP = computed(() => {
  return isRole(ROLE_TYPE.EP)
})

const isDefaultRole = computed(() => {
  return isRole(ROLE_TYPE.DEFAULT)
})

const isAIE = computed(() => {
  return isRole(ROLE_TYPE.AIE)
})

const isAIEorAdminOrEp = computed<boolean>(
  () => isAdmin.value || isAIE.value || isEP.value,
)

const isReader = computed(() => {
  return isRole(ROLE_TYPE.READER)
})

const isCustomer = computed(() => {
  return isRole(ROLE_TYPE.CUSTOMER)
})

const isLevel4 = computed(() => {
  return AppSettings.isLevel4Route.value
})

const isLevel3 = computed(() => {
  return AppSettings.isLevel3Route.value
})

const level3Enabled = computed(() => {
  return hasAnyRole.value && !isCustomer.value && !isDefaultRole.value
})

const level4Enabled = computed(() => {
  return hasAnyRole.value && !isReader.value && !isDefaultRole.value
})

const isOfferFitUser = computed(() => {
  // eslint-disable-next-line ts/no-unsafe-return
  return store?.getters['user/email']?.endsWith(OFFERFIT_DOMAIN) ?? false
})

export function usePermissions() {
  return {
    isUserAuthenticated,
    userHasAcceptedTos,
    isAIEorAdmin,
    isAIEorAdminOrEp,
    isAdmin,
    isEP,
    isAIE,
    isReader,
    isDefaultRole,
    isLevel3,
    isLevel4,
    level3Enabled,
    level4Enabled,
    isCustomer,
    isOfferFitUser,
    isTestingUser,
    isInternalUser,
  }
}

export default usePermissions
