import type { ASSET_CONNECTION_TYPES, DATA_ASSET_TYPES } from '@shared/data/constants.ts'
import { DEFAULT_CSV_SEPARATOR } from '@shared/data/constants.ts'
import { getUSDayTime } from '@shared/utils/transformHelpers.ts'
import type { DataAssetFormat, DataAssetWrapper, NullString, TimeDetails } from '@shared/utils/Types.ts'
import { getReactiveNullStringObject } from '@shared/utils/vueUtils.ts'
import type { BucketFileInfoType } from '@/data-assets/utils/types.ts'
import type { AssetDTypes } from '@/data-assets/models/server/BucketAssetMetadata.ts'

const initialTime: TimeDetails = getUSDayTime('00:00')
export function createReactiveDataAssetStateObject() {
  return reactive({
    assetWrapperOriginalCopy: ref<DataAssetWrapper | undefined>(),

    files: ref<File[]>([]),
    isOverUploadingArea: ref<boolean>(false),
    parsedFileName: ref<NullString>(null),

    assetName: ref<string>(''),

    assetDisplayName: ref<string>(''),
    assetDisplayNameError: getReactiveNullStringObject(),

    assetDescription: ref<string>(''),
    assetDescriptionError: getReactiveNullStringObject(),

    assetDataFormat: ref<undefined | DataAssetFormat>(undefined),
    assetDataFormatError: getReactiveNullStringObject(),

    timeObject: initialTime,

    assetCpuLimit: ref<string>(''),

    assetMemoryLimit: ref<string>(''),

    assetCSVSeparator: ref<string>(DEFAULT_CSV_SEPARATOR),

    assetFilePathFormat: ref<string>(''),
    assetFilePathFormatInput: ref<string>(''),
    assetFilePathFormatError: getReactiveNullStringObject(),

    userIdentifierColumnError: getReactiveNullStringObject(),
    eventTimeColumnError: getReactiveNullStringObject(),
    assetTypeError: getReactiveNullStringObject(),

    assetPartitionColumn: ref<undefined | string>(),

    assetRequired: ref<boolean>(false),

    event_time_col: ref<NullString>(),
    user_identifier_col: ref<NullString>(),
    assetType: ref<DATA_ASSET_TYPES | undefined>(),

    assetHeadersWatcher: ref<WatchStopHandle | null>(null),

    sampleDataFileInfo: ref<BucketFileInfoType>(),
    datasetFileInfo: ref<BucketFileInfoType>(),
    foundDatasetFileInfo: ref<BucketFileInfoType>(),

    isUploadingToParse: ref<boolean>(false),
    uploadingToParseProgress: ref<number>(0),
    isDataAssetRequestPending: ref<boolean>(false),
    uploadingToBucketProgress: ref<number>(0),
    isParsingFromBucket: ref<boolean>(false),

    assetDataHeaders: ref<string[] | null | undefined>(),
    assetDataHeadersdTypes: ref<AssetDTypes | undefined>(),
    sampleUploadOrBucketConnection: ref<ASSET_CONNECTION_TYPES | undefined>(),
    initialized: false,
    readOnly: false,
  })
}
