import { TASK_NAME } from '@shared/data/constants'
import { JobHistoryModel } from '@/dags/models/server/JobHistoryModel'
import K8TaskService from '@/dags/services/tasks/K8TaskService'
import { store } from '@app/store'
import { StrapiDataArray } from '@shared/utils/Types'
import { featureStatisticsFailedMessageRender } from '@shared/utils/renderHelpers'

export default class FeatureStatisticsTaskService {
  static RUNNING_MESSAGE: string = 'Features statistics task is running'
  static COMPLETED_MESSAGE: string = 'Features statistics task completed'
  static FAILED_MESSAGE: () => VNodeChild = featureStatisticsFailedMessageRender

  static async startTask(): Promise<JobHistoryModel> {
    const queryObject: any = {
      client: store.getters['client/client'].name,
      pipeline: store.getters['pipeline/pipeline']?.name.toLowerCase(),
      task: TASK_NAME.FEATURE_STATISTICS,
      product_env: 'draft'
    }

    return K8TaskService.startTask(
      queryObject,
      FeatureStatisticsTaskService.COMPLETED_MESSAGE,
      FeatureStatisticsTaskService.FAILED_MESSAGE
    )
  }

  static async getAllTasks(): Promise<StrapiDataArray<JobHistoryModel>> {
    return K8TaskService.getAllTasks(store.getters['client/client'].id, TASK_NAME.FEATURE_STATISTICS)
  }
}
