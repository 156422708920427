import AppSettings from '@app/AppSettings'
import type { INinjaAction } from 'ninja-keys/dist/interfaces/ininja-action'
import FeatureFlags from '@app/utils/featureFlags'
import {
  CONFIG_EDITOR_ROUTE,
  DAG_LIST_ROUTE,
  DASHBOARD_ROUTE,
  DATA_ASSETS_ROUTE,
  PIPELINES_ROUTE,
  PRIVILEGES_ROUTE,
  REPORTS_ROUTE,
  USER_CLIENT_RELATION_ROUTE,
} from '@shared/data/constants'
import router from '@router/index'
import { store } from '@app/store'
import useAccessibility from '@/accessibility/composables/useAccessibility'
import { Level4Route } from '@/level4/router/level4'
import { useUseCases } from '@/usecases/composables/useUseCases'
import type { PipelineModel } from '@/pipelines/models/server/PipelineModel'
import UseCaseService from '@/usecases/services/UseCaseService'

const { isLevel3Route, isLevel4Route } = AppSettings

export async function openPipeline(pipelineName: string) {
  const { setUsecases } = useUseCases()
  const { LSUsecaseName, LSPipelineName } = AppSettings
  LSPipelineName.value = ''
  LSUsecaseName.value = ''

  const selectedPipeline
    = store.getters['pipeline/pipelines'].find(
      (pipelineModel: PipelineModel) => pipelineName.toLowerCase() === (pipelineModel.name?.toLowerCase()),
    ) || store.getters['pipeline/pipelines'][0]

  store.commit('pipeline/pipeline', selectedPipeline)
  LSPipelineName.value = selectedPipeline.name

  // prepare client's usecases
  setUsecases(await UseCaseService.getAllUseCases(store.getters['client/client'].id, selectedPipeline.id))
}

export function getMenuItems() {
  let hotkeys: INinjaAction[] = [
    {
      id: 'mode',
      title: 'Change mode...',
      children: ['read_write', 'read_only'],
    },
    {
      id: 'read_write',
      title: 'Read/write mode',
      parent: 'mode',
      handler: () => {
        store.commit('isReadonlyMode', false)
        localStorage.setItem('isReadonlyMode', 'false')
      },
    },
    {
      id: 'read_only',
      title: 'Read-only mode',
      parent: 'mode',
      handler: () => {
        store.commit('isReadonlyMode', true)
        localStorage.setItem('isReadonlyMode', 'true')
      },
    },
    {
      id: 'level',
      title: 'Change level',
      children: ['level_3', 'level_4'],
    },
    {
      id: 'level_3',
      title: 'Level3',
      parent: 'level',
      handler: () => {
        window.location.pathname = '/'
      },
    },
    {
      id: 'level_4',
      title: 'Level4',
      parent: 'level',
      handler: () => {
        window.location.pathname = '/app'
      },
    },
    {
      id: 'font_jost',
      title: 'Disable high density font',
      section: 'Display',
      handler: () => {
        const { readableFont } = useAccessibility()
        readableFont.value = false
      },
    },
    {
      id: 'font_inter',
      title: 'Enable high density font',
      section: 'Display',
      handler: () => {
        const { readableFont } = useAccessibility()
        readableFont.value = true
      },
    },
    {
      id: 'bau_configuration',
      title: `Enable BAU configuration UI (${FeatureFlags.ofeBAUConfiguration.value})`,
      section: 'Display',
      handler: () => {
        FeatureFlags.ofeBAUConfiguration.value = !FeatureFlags.ofeBAUConfiguration.value
        void router.go()
      },
    },
  ]

  if (isLevel3Route.value) {
    hotkeys = [
      {
        id: 'dashboard',
        title: 'Dashboard',
        hotkey: 'ctrl+h,cmd+h',
        handler: () => {
          void router.push({ name: DASHBOARD_ROUTE })
        },
      },

      {
        id: 'data_assets',
        title: 'Data assets',
        hotkey: 'ctrl+d,cmd+d',
        handler: () => {
          void router.push({ name: DATA_ASSETS_ROUTE })
        },
      },
      {
        id: 'usecases',
        title: 'Use cases',
        hotkey: 'ctrl+u,cmd+u',
        handler: () => {
          void router.push({ name: DASHBOARD_ROUTE })
        },
      },
      {
        id: 'reports',
        title: 'Reports',
        hotkey: 'ctrl+q,cmd+q',
        handler: () => {
          void router.push({ name: REPORTS_ROUTE })
        },
      },
      {
        id: 'config_editor',
        title: 'Config editor',
        hotkey: 'ctrl+y,cmd+y',
        handler: () => {
          void router.push({ name: CONFIG_EDITOR_ROUTE })
        },
      },
      {
        id: 'privileges',
        title: 'Privileges',
        handler: () => {
          void router.push({ name: PRIVILEGES_ROUTE })
        },
      },
      {
        id: 'history',
        title: 'History',
        hotkey: 'ctrl+i,cmd+i',
        handler: () => {
          void router.push({
            name: CONFIG_EDITOR_ROUTE,
            query: { tab: 'history' },
          })
        },
      },
      {
        id: 'pipelines',
        title: 'Pipelines',
        section: 'Hidden pages',
        hotkey: 'ctrl+1,cmd+1',
        handler: () => {
          void router.push({ name: PIPELINES_ROUTE })
        },
      },
      {
        id: 'dags',
        title: 'DAGs',
        section: 'Hidden pages',
        hotkey: 'ctrl+4,cmd+4',
        handler: () => {
          void router.push({ name: DAG_LIST_ROUTE })
        },
      },
      {
        id: 'relations',
        title: 'User groups',
        section: 'Hidden pages',
        hotkey: 'ctrl+5,cmd+5',
        handler: () => {
          void router.push({ name: USER_CLIENT_RELATION_ROUTE })
        },
      },
      {
        id: 'events_v2',
        title: `Events v2 (${FeatureFlags.eventsV2.value})`,
        parent: 'feature_flags',
        handler: () => {
          FeatureFlags.eventsV2.value = !FeatureFlags.eventsV2.value
          void router.go()
        },
      },
      {
        id: 'editor_validation',
        title: `Editor Validation & Autocomplete (${FeatureFlags.editorValidation.value})`,
        parent: 'feature_flags',
        handler: () => {
          FeatureFlags.editorValidation.value = !FeatureFlags.editorValidation.value
        },
      },
      ...hotkeys,
    ]

    if (store.getters['pipeline/pipelines']?.length > 1) {
      hotkeys.push({
        id: 'pipelines',
        title: 'Change pipeline...',
        children: store.getters['pipeline/pipelines'].map((pipeline: PipelineModel) => pipeline.name),
      })

      store.getters['pipeline/pipelines'].forEach(((pipeline: PipelineModel) => {
        hotkeys.push({
          id: pipeline.name,
          title: pipeline.name,
          parent: 'pipelines',
          handler: async () => {
            await openPipeline(pipeline.name)
            void router.push({ name: DASHBOARD_ROUTE })
          },
        })
      }))
    }
  }

  if (isLevel4Route.value) {
    hotkeys = [
      {
        id: 'client_creation',
        title: 'New client',
        handler: async () => {
          AppSettings.LSPipelineName.value = ''
          AppSettings.LSClientName.value = ''
          AppSettings.LSUsecaseName.value = ''
          await router.push({ name: Level4Route.SIGN_UP })
        },
      },
      /* {
        id: 'some_feature_flag',
        title: `Some Feature (${FeatureFlags.SOME_FEATURE_ENABLED})`,
        parent: 'feature_flags',
        handler: () => {
          FeatureFlags.SOME_FEATURE_ENABLED = !FeatureFlags.SOME_FEATURE_ENABLED
          localStorage.setItem(LOCAL_STORAGE_FLAG_SOME_FEATURE, FeatureFlags.SOME_FEATURE_ENABLED.toString())
          void router.go()
        },
      }, */
      ...hotkeys,
    ]

    if (store.getters['pipeline/pipelines']?.length > 1) {
      hotkeys.push({
        id: 'pipelines',
        title: 'Change pipeline...',
        children: store.getters['pipeline/pipelines'].map((pipeline: PipelineModel) => pipeline.name),
      })

      store.getters['pipeline/pipelines'].forEach((pipeline: PipelineModel) => {
        hotkeys.push({
          id: pipeline.name,
          title: pipeline.name,
          parent: 'pipelines',
          handler: async () => {
            await openPipeline(pipeline.name)
            void router.push({ name: Level4Route.EXPERIMENTERS })
          },
        })
      })
    }
  }

  return hotkeys
}
