export function hasValue(object: any, value: any): boolean {
  return Object.values(object).some(function (val: any) {
    if (val === value) {
      return true
    }
    if (val && typeof val === 'object') {
      return hasValue(val, value)
    }
    if (val && Array.isArray(val)) {
      return val.some((obj: any) => {
        return hasValue(obj, value)
      })
    }

    return false
  })
}
