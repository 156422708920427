<script setup lang="ts">
import { VueMonacoDiffEditor } from '@guolao/vue-monaco-editor'
import type { editor } from 'monaco-editor'
import { stringify } from 'yaml'
import ModalConfirm from '@shared/components/basic/modal/ModalConfirm.vue'

import { allowCallback, disallowCallback, nextMetadata, previousMetadata } from '@/data-assets/composables/useMetadataChangeModal'

defineProps<Props>()
const emit = defineEmits<{
  close: []
}>()
interface Props {
  showModal: boolean
}

async function disallowOrQuitHandler() {
  emit('close')
  await disallowCallback.value?.()
}

async function allowClickHandler() {
  await allowCallback.value?.()
}

const nextMetadataCode = computed(() => stringify(nextMetadata.value))
const previousMetadataCode = computed(() => stringify(previousMetadata.value))

const diffOptions: editor.IStandaloneDiffEditorConstructionOptions = {
  automaticLayout: true,
  readOnly: true,
  hideUnchangedRegions: {
    enabled: true,
    contextLineCount: 10,
  },
}
</script>

<template>
  <ModalConfirm
    title="Pipeline metadata is about to change"
    message="Portal wants to commit this change. Do you allow?"
    confirmButtonText="Allow"
    cancelButtonText="Disallow"
    :isOpened="showModal"
    :confirmHandler="allowClickHandler"
    warning
    class="min-w-4xl"
    @close="disallowOrQuitHandler"
  >
    <span>It happens when data asset file headers differ from expected in validation metadata</span>
    <br>
    <VueMonacoDiffEditor
      :original="previousMetadataCode"
      :modified="nextMetadataCode"
      :options="diffOptions"
      theme="vs-light"
      language="yaml"
      placeholder="Yaml text here"
      class="min-h-120 min-w-3xl"
      border
    />
  </ModalConfirm>
</template>
