<script setup lang="ts">
import FormError from '@/level4/components/forms/FormError.vue'
import FormLoading from '@/level4/components/forms/FormLoading.vue'
import type { Level4Module, WizardFormType } from '@/level4/data/forms'
import FormWrapperL4 from '@/level4/components/layout/FormWrapperL4.vue'
import { useWizardNavigation } from '@/level4/composables/useWizardNavigation'

const components = import.meta.glob('@/level4/views/modules/**/*.vue', {
  import: 'default',
})

const componentRef = ref<any>(null)

const {
  currentStep,
  nextStep,
  prevStep,
  quitWizardNavigation,
  currentModule,
  transitionName,
  currentLayoutComponent,
} = useWizardNavigation()

const submitPending = ref(false)

function getFilePath(moduleName: Level4Module, formName: WizardFormType) {
  return `/features/level4/views/modules/${moduleName}/${formName}/Form.vue`
}
function getFormAsyncComponent(moduleName: Level4Module, formName: WizardFormType) {
  const componentPath = getFilePath(moduleName, formName)

  if (!components[componentPath]) { throw new Error(`Component not found: ${componentPath}`) }

  return defineAsyncComponent({
    loader: async () => components[componentPath](),
    loadingComponent: FormLoading, /* shows while loading */
    errorComponent: FormError,
  })
}

const dynamicComponent = computed<Component | undefined>(() => {
  if (!currentModule.value || !currentStep.value) { return undefined }
  return getFormAsyncComponent(currentModule.value, currentStep.value)
})

const isStepLastFromAllowedGroups = computed(() => {
  return false
})

const isComponentAvailable = computed(() => {
  return !!dynamicComponent.value
})

const isBackButtonAvailable = computed(() => {
  return (window?.history?.length || 0) > 2
})

const computedKey = computed(() => {
  return `key-${currentModule.value}-${currentStep.value}`
})

const stepIsCompleted = computed(() => {
  // we need to keep computedKey.value as trigger for completed value recalculation
  return computedKey.value && (componentRef.value?.dataIsComplete === undefined || componentRef.value?.dataIsComplete === true)
})

const stepSkippable = computed(() => {
  // we need to keep computedKey.value as trigger for completed value recalculation
  return computedKey.value && (componentRef.value?.skipIsAvailable !== undefined && componentRef.value?.skipIsAvailable === true)
})

async function goForward() {
  nextStep()
}

async function submitHandler() {
  if (!stepIsCompleted.value) { return }

  try {
    submitPending.value = true
    if (componentRef.value && componentRef.value?.submitHandler) {
      await componentRef.value?.submitHandler()
      // console.log('submit handled')
      // console.log(experimenterFormState.value?.platform)
    }
    goForward()
  }
  // eslint-disable-next-line no-useless-catch
  catch (e) {
    throw e
  }
  finally {
    submitPending.value = false
  }
}

async function quitHandler() {
  await quitWizardNavigation()
}

async function backClickHandler() {
  if (componentRef.value && componentRef.value?.backHandler) {
    await componentRef.value?.backHandler()
  }

  prevStep()
}

function skipClickHandler() {
  goForward()
}

function submitClickHandler() {
  submitHandler()
}

const submitButtonText = computed(() => {
  if ('nextFormButtonText' in componentRef.value) {
    return componentRef.value.nextFormButtonText
  }
  if (isStepLastFromAllowedGroups.value) {
    return 'Save & Back'
  }
  return 'Save & Continue'
})

const backButtonText = computed(() => {
  if ('backFormButtonText' in componentRef.value) {
    return componentRef.value.backFormButtonText
  }
  return 'Back'
})
</script>

<template>
  <Component :is="currentLayoutComponent || FormWrapperL4" :completed="stepIsCompleted" stickyHeader>
    <div class="h-full w-full flex flex-col gap-4">
      <!--  <div v-if="" w="full" flex="~" justify="center">
        <DotStepsMinimap
            :current-step-value="currentStep"
            :steps="computedGroupSteps"
            @update:current-step-value="stepClickHandler"
        />
      </div> -->
      <Transition :name="transitionName" mode="out-in">
        <div :key="computedKey">
          <Component
            :is="dynamicComponent"
            v-if="isComponentAvailable"
            ref="componentRef"
            :key="computedKey"
            @submit="submitHandler"
            @back="backClickHandler"
            @quit="quitHandler"
          />
          <FormLoading v-else />
        </div>
      </Transition>
    </div>
    <template #header-content>
      <div flex="~" justify="center" p="x-2">
        <TokensMinimap />
      </div>
    </template>
    <template #controls>
      <Transition :name="transitionName" mode="out-in">
        <div :key="computedKey" class="pb-10">
          <div v-if="componentRef && !componentRef.areControlsDisabled" class="flex items-center justify-center gap-4">
            <SimpleButton v-show="isBackButtonAvailable" :text="backButtonText" @click="backClickHandler" />
            <SimpleButton v-if="stepSkippable" :loading="submitPending" primary text="Continue" @click="skipClickHandler" />
            <SimpleButton
              v-else
              :disabled="!stepIsCompleted"
              primary
              :text="submitButtonText"
              :loading="submitPending"
              @click="submitClickHandler"
            />
          </div>
        </div>
      </Transition>
    </template>
  </Component>
</template>

<style scoped>
/* Slide+fade transition */
.slide-fade-left-leave-active,
.slide-fade-right-leave-active {
  transition: transform 0.3s cubic-bezier(0.73, 0, 0.75, 1.02),
    opacity 0.25s cubic-bezier(0.73, 0, 0.75, 1.02);
}

.slide-fade-right-enter-active,
.slide-fade-left-enter-active {
  transition: transform 0.3s cubic-bezier(0.43, 0.01, 0.13, 1),
    opacity 0.25s cubic-bezier(0.43, 0.01, 0.13, 1);
}

.slide-fade-left-enter-to,
.slide-fade-right-enter-to {
  transform: translateX(0);
  opacity: 1;
}

.slide-fade-right-leave-to,
.slide-fade-left-leave-to {
  opacity: 0;
}

.slide-fade-right-leave-to,
.slide-fade-left-enter-from {
  transform: translateX(10%);
}

.slide-fade-left-leave-to,
.slide-fade-right-enter-from {
  transform: translateX(-10%);
}
</style>
