import { TransformationConfigModel, TransformationType } from '@/data-assets/models/TransformationConfigModel'
import { filterOnlyUniqueCallback } from '@shared/utils/helpers'

/**
 * Returns common transformation related methods.
 * @returns {{isOneToMany: (transformType: TransformationType) => boolean, getTransformSourceOperationId: (transformation: TransformationConfigModel) => string, isTwoToOne: (transformType: TransformationType) => boolean, isOneToOne: (transformType: TransformationType) => boolean, getTransformsWithCurrentTransformDestinationColumns: (allList: TransformationConfigModel[], transform: TransformationConfigModel) => TransformationConfigModel[], getTransformationUniqueId: (transformation: TransformationConfigModel) => string, isManyToOne: (transformType: TransformationType) => boolean}}
 */
export const useTransform = () => {
  const isOneToMany = (transformType: TransformationType) => transformType === TransformationType.SPLIT

  const isManyToOne = (transformType: TransformationType) =>
    [TransformationType.SUM, TransformationType.MULTIPLY, TransformationType.COMBINE].includes(transformType)

  const isTwoToOne = (transformType: TransformationType) =>
    [TransformationType.DIFFERENCE, TransformationType.DIVIDE].includes(transformType)

  const isOneToOne = (transformType: TransformationType) =>
    [TransformationType.TYPE_CAST, TransformationType.FILL_NA].includes(transformType)

  /**
   * Returns string combination that consists of transform operation, source_columns, destination_columns and arguments.
   * @param {TransformationConfigModel} transformation
   * @returns {string}
   */
  const getTransformationUniqueId = (transformation: TransformationConfigModel): string => {
    const transformArgumentsString = transformation.arguments
      ? Object.keys(transformation.arguments).map((key: string) => `key_${key}=${transformation.arguments?.[key]}`)
      : ''

    return `transform_${transformation.transform}_src_${transformation.source_columns.join(
      '-'
    )}_trgt_${transformation.destination_columns.join('-')}_${transformArgumentsString}`
  }

  /**
   * Returns string combination that consists of transform operation, source_columns and arguments without destination columns.
   * @param {TransformationConfigModel} transformation
   * @returns {string}
   */
  const getTransformSourceOperationId = (transformation: TransformationConfigModel): string => {
    const transformArgumentsString = transformation.arguments
      ? Object.keys(transformation.arguments).map(
          (key: string) => `key_${key}=${JSON.stringify(transformation.arguments?.[key] || '{}')}`
        )
      : ''

    return `transform_${transformation.transform}_src_${transformation.source_columns.join('-')}_${transformArgumentsString}`
  }

  /**
   * Returns all transformations from data asset config that use at least one destination_columns array element from specific transform object.
   * @param {TransformationConfigModel[]} allList
   * @param {TransformationConfigModel} transform
   * @returns {TransformationConfigModel[]}
   */
  const getTransformsWithCurrentTransformDestinationColumns = (
    allList: TransformationConfigModel[],
    transform: TransformationConfigModel
  ): TransformationConfigModel[] => {
    const result = []
    const filteredList = allList.filter(
      (item: TransformationConfigModel) => getTransformSourceOperationId(item) !== getTransformSourceOperationId(transform)
    )

    for (let i = 0; i < filteredList.length; i++) {
      const filteredListElement = filteredList[i]
      if (filteredListElement.source_columns.some((item: string) => transform.destination_columns.includes(item))) {
        result.push(filteredListElement)
        result.push(getTransformsWithCurrentTransformDestinationColumns(filteredList, filteredListElement))
      }
    }

    return result.flat().filter(filterOnlyUniqueCallback)
  }

  return {
    isOneToMany,
    isManyToOne,
    isTwoToOne,
    isOneToOne,
    getTransformationUniqueId,
    getTransformSourceOperationId,
    getTransformsWithCurrentTransformDestinationColumns
  }
}
