import { store } from '@app/store'
import { CLIENT_IS_NOT_SUPPORTED } from '@shared/data/constants'
import { debug } from '@shared/utils/logger'
import type { Router } from 'vue-router'
import { usePermissions } from '@/auth/composables/usePermissions'

export function defineMiddleware(router: Router) {
  const pipelines = computed(() => store.getters['pipeline/pipelines'])
  router.beforeEach(async (to) => {
    const { isLevel4 } = usePermissions()
    debug('Middleware: isClientSupported')
    if (isLevel4.value && !pipelines.value?.length && to.name !== CLIENT_IS_NOT_SUPPORTED) {
      return { name: CLIENT_IS_NOT_SUPPORTED }
    }
  })
}
