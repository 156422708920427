<script lang="ts">
const DEFAULT_TIMEZONE = 'UTC'
</script>
<script setup lang="ts">
import timezones from 'timezones-list'
import { format } from 'date-fns'
import dateUtils from '@shared/utils/dates'
import { useStore } from '@app/store'
import { dateFormat, monthShortDateTime, timeFormat } from '@shared/utils/transformHelpers'

interface Props {
  displayFormat?: string | ((date: Date) => string)
  date?: string | Date
  mode?: 'relative' | 'datetime' | 'date' | 'friendly'
  showIcon?: boolean
}

withDefaults(defineProps<Props>(), { showIcon: true,mode: 'datetime', })



const store = useStore()
const customTimezones = computed(() => store.getters['client/client']?.config?.customTimezones ?? [])
const defaultTimezone = useLocalStorage('offerfit/l3/globals/defaultTimezone', DEFAULT_TIMEZONE)

const timeZonesList = computed(() => {
  return ['UTC', ...customTimezones.value]
})

const dateObject = computed(() => {
  if (!__props.date) { return undefined }
  return __props.date instanceof Date ? __props.date : new Date(__props.date)
})

const formattedTime = computed(() => {
  if (!dateObject.value) { return '' }

  if (!__props.displayFormat) {
    return formatWithSelectedMode(dateObject.value)
  }

  if (typeof __props.displayFormat === 'string') {
    return format(dateObject.value, __props.displayFormat)
  }

  return __props.displayFormat(dateObject.value)
})

function formatWithSelectedMode(date: Date) {
  switch (__props.mode) {
    case 'relative':
      return dateUtils.dateToRelativeString(date)
    case 'datetime':
      return `${dateToDetailedDate(date)} ${dateToDetailedTime(date)}`
    case 'date':
      return dateToDetailedDate(date)
    case 'friendly':
      return dateToFriendlyDate(date)
  }
}

const tableDetails = computed(() => {
  return [{
    label: 'Date',
    value: timeZonesList.value.map(tz => dateToDetailedDate(dateObject.value, tz)),
  }, {
    label: 'Time',
    value: timeZonesList.value.map(tz => dateToDetailedTime(dateObject.value, tz)),
  }, {
    label: 'Offset',
    value: timeZonesList.value.map(tz => getTimezoneOffset(tz)),
  }]
})
function dateToDetailedDate(date: Date, timezone: string = defaultTimezone.value) {
  return dateFormat(date, timezone)
}
function dateToDetailedTime(date: Date, timezone: string = defaultTimezone.value) {
  return timeFormat(date, timezone)
}
function dateToFriendlyDate(date: Date, timezone: string = defaultTimezone.value) {
  return monthShortDateTime(date, timezone)
}
function getTimezoneOffset(timezone: string = defaultTimezone.value) {
  if (timezone === 'UTC') {
    return '+00:00'
  }
  return timezones.find(tz => tz.tzCode === timezone)?.utc
}

const addingNewTimezone = ref(false)
const selectableTimeZones = computed(() => {
  return timezones.map(tz => ({
    label: tz.label,
    value: tz.tzCode,
    disabled: customTimezones.value.includes(tz.tzCode),
  }))
})

const timezoneEditLoading = ref(false)
async function handleTimeZoneDelete(timezone: string) {
  timezoneEditLoading.value = true
  if (timezone === defaultTimezone.value) {
    defaultTimezone.value = DEFAULT_TIMEZONE
  }
  store.getters['client/client']?.config?.customTimezones?.splice(customTimezones.value.indexOf(timezone), 1)
  await store.dispatch('client/updateClientDetails')
  timezoneEditLoading.value = false
}
async function handleNewTimezone(value: string) {
  timezoneEditLoading.value = true
  if (!value || !timezones.find(tz => tz.tzCode === value)) { return }
  const client = store.getters['client/client']
  if (!client.config?.customTimezones) {
    client.config = {
      customTimezones: [],
      ...client.config,
    }
  }
  store.getters['client/client']?.config?.customTimezones?.push(value)
  await store.dispatch('client/updateClientDetails')
  addingNewTimezone.value = false
  timezoneEditLoading.value = false
}

const { alt: editingMode } = useMagicKeys()
</script>

<template>
<div class="timezoned-date-label-wrapper flex items-center gap-2">
  <NTooltip
    :disabled="!date && !$slots.default"
    @hide="addingNewTimezone = false"
  >
    <template #trigger>
      <div
        v-if="date || $slots.default"
        rounded="md"
        hover="underline underline-dotted underline-gray-300"
        flex="~"
        gap="1"
        items="center"
        cursor="default"
      >
        <NBadge
          v-if="showIcon"
          :show="defaultTimezone !== 'UTC'"
          dot
          :offset="[0, 5]"
          :size="1"
          cursor="pointer"
        >
          <div
            class="i-solar-clock-circle-outline text-xs text-gray-500 opacity-50"
          />
        </NBadge>
        <span v-if="!$slots.default">{{ formattedTime }}</span>
      </div>
      <span v-else>
        ⸺
      </span>
    </template>
    <template #default>
      <NSpin
        :show="timezoneEditLoading"
        size="small"
        description="Updating timezones..."
        style="--n-text-color: white; --n-color: white;"
      >
        <table class="time-details-table">
          <thead>
            <tr>
              <th>
              </th>
              <th
                v-for="tz in timeZonesList"
                class="px-1"
                :underline="editingMode && tz !== 'UTC' ? '~ dotted hover:red-500' : ''"
                :text="editingMode && tz !== 'UTC' ? 'hover:red-500' : ''"
                :cursor="editingMode && tz !== 'UTC' ? 'pointer' : ''"
                @click="editingMode && tz !== 'UTC' && handleTimeZoneDelete(tz)"
              >
                {{ tz }}
              </th>
              <th class="pl-4" @click="addingNewTimezone = true">
                <div
                  v-if="!addingNewTimezone && editingMode"
                  class="i-solar-add-square-bold-duotone text-xs opacity-70 hover:opacity-100"
                  cursor="pointer"
                ></div>
                <NSelect
                  v-if="addingNewTimezone"
                  class="!text-white"
                  size="tiny"
                  style="--n-text-color: white;"
                  max="w-30"
                  filterable
                  autofocus
                  :options="selectableTimeZones"
                  :consistent-menu-width="false"
                  @update:value="handleNewTimezone"
                >
                  <template>
                    <div
                      class="i-solar-add-square-bold-duotone text-xs text-white"
                      cursor="pointer"
                    ></div>
                  </template>
                </NSelect>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr></tr>
            <tr
              v-for="tableRow in tableDetails"
              :key="tableRow.label"
              rounded="md"
              m="x-6"
            >
              <td class="pl-1 pr-4"><b>{{ tableRow.label }}</b></td>
              <template v-for="value in tableRow.value">
                <td class="px-1 text-center text-xs font-mono"> {{ value }}</td>
              </template>
            </tr>
            <tr v-if="editingMode">
              <td></td>
              <td
                v-for="timezone in timeZonesList"
                text="center"
                class="text-xs font-mono"
                :opacity="timezone === defaultTimezone ? '100' : '40'"
                hover="underline underline-dashed"
                cursor="pointer"
                @click="defaultTimezone = timezone"
              >
                <span flex="~" items="center" justify="center" gap="1">
                  <div v-if="timezone === defaultTimezone" class="i-solar-check-circle-outline text-xs" />
                  default
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <footer
          :flex="`~ ${customTimezones.length ? 'row' : 'col'}`"
          justify="between"
          text="gray-4 xs"
          m="t-1"
          min="w-20"
        >
          <span> {{ dateUtils.dateToRelativeString(dateObject) }} </span>
          <span> Press <span class="keyboard-key">alt</span> to edit</span>
        </footer>
      </NSpin>
    </template>
  </NTooltip>
  <slot :timezone="defaultTimezone" />
</div>
</template>

<style scoped>
.keyboard-key {
  @apply px-1;
  background-color: theme('colors.gray.5');
  color: theme('colors.gray.8');
  border-radius: 3px;
  font-size: 0.7rem;
  font-weight: 500;
}
:deep(.n-badge.n-badge--dot .n-badge-sup){
  --n-color: theme('colors.brand.orange');
  height: 3px;
  width: 3px;
  min-width: 3px;
}
</style>
