import http from '@app/utils/http-client'

export type UserPrivilegesResponse = Record<'page' | 'block', Record<string, boolean>>

export default class UserPrivilegesService {
  static async loadPrivileges(client: string) {
    const response = await http.get<UserPrivilegesResponse>(`/me/privileges`, {
      cache: false,
      params: {
        client,
      },
    })

    return response?.data
  }
}
