<template>
  <div v-for="item in failedTests" :key="item" class="mb-2">
    <div class="flex font-semibold">
      <ExclamationCircleIcon class="w-5 h-5 mr-1 text-yellow-500" />
      <div>{{ item.task_id }} failed</div>
    </div>
    <div class="text-sm text-gray-400"><span class="font-semibold">fail date:</span> {{ new Date(item.end_date) }}</div>
    <div class="text-sm text-gray-400"><span class="font-semibold">dag_id:</span> {{ item.dag_id }}</div>

    <NCollapse arrow-placement="right">
      <NCollapseItem :key="item.task_id" :name="item.task_id">
        <template #header>
          <span class="text-link cursor-pointer">view task log</span>
        </template>
        <template #arrow>
          <ChevronRightIcon class="h-4 w-4 text-link" />
        </template>
        <NLog trim :rows="10" :loading="loadingLogs" :log="taskLogs[item.task_id] || 'Empty'" />
      </NCollapseItem>
    </NCollapse>
  </div>
</template>

<script lang="ts">
import { ChevronRightIcon, ExclamationCircleIcon } from '@heroicons/vue/24/solid'
import { NCollapse, NCollapseItem, NLog } from 'naive-ui'
import { useDeployments } from '@/deployments/composables/useDeployments'
import { DagFailedTestModel, DeploymentsFailedTaskPoll } from '@/deployments/models/server/DeploymentModel'
export default defineComponent({
  name: 'DeploymentFailedTestsRender',
  components: { NCollapse, NCollapseItem, ChevronRightIcon, NLog, ExclamationCircleIcon },
  props: {
    failedTests: {
      type: Array as PropType<DeploymentsFailedTaskPoll[]>
    }
  },
  setup(props) {
    const { getDeploymentTaskFailLog } = useDeployments()
    const loadingLogs: Ref<boolean> = ref<boolean>(false)
    const taskLogs: Ref<Record<string, string>> = ref<Record<string, string>>({})

    onMounted(async () => {
      loadingLogs.value = true
      if (props.failedTests) {
        for (let i = 0; i < props.failedTests.length; i++) {
          const response: DagFailedTestModel = await getDeploymentTaskFailLog(
            props.failedTests[i].dag_id,
            props.failedTests[i].task_id
          )
          taskLogs.value[props.failedTests[i].task_id] = response.logs
        }
      }
      loadingLogs.value = false
    })

    return {
      taskLogs,
      loadingLogs
    }
  }
})
</script>


