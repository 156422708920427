import {getClients} from '@level3/services/clients'
import Global from '@shared/utils/global'
import {useQuery} from '@tanstack/vue-query'
import {watch} from 'vue'

export const useClients = () => {
  const {
    data: clients,
    isLoading: isLoadingClients,
    error,
  } = useQuery({
    queryKey: ['clients'],
    queryFn: getClients,
    staleTime: 10_000,
    refetchOnMount: true,
  })

  watch(error, (error) => {
    if (error) {
      Global.error(error.message)
    }
  })

  return {
    clients,
    isLoadingClients,
  }
}
