import { getApiPath } from '@app/utils/common-http-client'

export default class AuthService {
  static getSignInLocation = () => {
    const host = location.host || 'localhost:8080'
    const apiPath = getApiPath()
    // Use one of the following methods to determine the preview branch.
    interface OfferfitPreviewBranchMethod { branch: string }
    interface PlatformPreviewMethod { platform_origin: string, platform_url: string }
    type UrlState = OfferfitPreviewBranchMethod | PlatformPreviewMethod | {}

    let state: UrlState = {}

    const auth0Url = new URL(`${apiPath}/connect/auth0`)

    // OfferFit in-house manual preview branch.
    if (host.startsWith('preview-branch')) {
      state = {
        branch: host.split('.')[0],
      }
    }

    // Vercel preview branch.
    if (import.meta.env.VITE_VERCEL_ENV === 'preview') {
      const vercelBranchUrl = import.meta.env.VITE_VERCEL_BRANCH_URL || ''
      const vercelCommitUrl = import.meta.env.VITE_VERCEL_URL || ''
      const vercelUrl = location.host.includes('-git-')
        ? vercelBranchUrl
        : vercelCommitUrl
      state = {
        platform_origin: 'vercel',
        platform_url: vercelUrl,
      }
    }

    auth0Url.searchParams.set('state', JSON.stringify(state))

    if (import.meta.env.VITE_VERCEL_ENV === 'preview') {
      console.log('generated auth0 url', auth0Url.toString())
    }

    return auth0Url.toString()
  }
}
