<script setup lang="ts">
import {ref, computed} from 'vue'
import {createClient} from '@level3/services/clients'
import {QueryClient} from '@tanstack/vue-query'
import Global from '@/shared/utils/global.ts'

const vueQueryClient = new QueryClient()

const showModal = defineModel<boolean>('show', {required: true})

const formData = ref({
  displayName: '',
  productType: null,
})

const productOptions = [
  {
    label: 'OfferFit Self Serve',
    value: 'FOF',
  },
  {
    label: 'OfferFit Full',
    value: 'OFSS',
  },
]

const generatedBucketName = computed(() => {
  if (!formData.value.displayName) return ''
  return `${formData.value.displayName.toLowerCase().replaceAll(/\s+/g, '_')}_offerfit${import.meta.env.VITE_APP_ENVIRONMENT === 'staging' ? '_staging' : ''}`
})

const standardizedClientName = computed(() => {
  if (!formData.value.displayName) return ''
  return formData.value.displayName.toLowerCase().replaceAll(/\s+/g, '_')
})

const isFormValid = computed(() => {
  return formData.value.displayName && formData.value.productType
})

const handleClose = () => {
  showModal.value = false
  formData.value = {
    displayName: '',
    productType: null,
  }
}

const handleCreate = async () => {
  if (!isFormValid.value) {
    Global.error('Please fill in all required fields')
    return
  }

  try {
    const newClient = await createClient({
      name: formData.value.displayName,
      slug: generatedBucketName.value,
      type: formData.value.productType ?? 'FOF', // We should never reach fallback, as we have validation that TS doesn't compile properly
    })

    await vueQueryClient.invalidateQueries({queryKey: ['clients']})

    Global.success(`Client ${newClient.displayName} was created successfully`)

    handleClose()
  } catch (error: unknown) {
    console.error(error)
    if (error instanceof Error) {
      Global.error(error.message)
    } else {
      Global.error('An error occurred while creating the client')
    }
  }
}
</script>

<template>
  <NModal v-model:show="showModal" :mask-closable="false">
    <NCard
      style="width: 600px"
      :bordered="false"
      size="huge"
      role="dialog"
      aria-modal="true"
    >
      <template #header>
        <h2 class="text-xl font-semibold">Create new client</h2>
      </template>

      <div class="space-y-6">
        <div class="space-y-2">
          <label class="block font-medium">Display name</label>
          <NInput
            v-model:value="formData.displayName"
            placeholder="Client display name"
          />
        </div>

        <div class="space-y-2">
          <label class="block font-medium">Product type</label>
          <NSelect
            v-model:value="formData.productType"
            :options="productOptions"
            placeholder="Select product type"
          />
        </div>

        <div class="space-y-2">
          <div>
            <label class="block font-medium">Generated Bucket Name:</label>
            <p class="text-gray-600">{{ generatedBucketName }}</p>
          </div>
          <div>
            <label class="block font-medium">Standardised client name:</label>
            <p class="text-gray-600">{{ standardizedClientName }}</p>
          </div>
        </div>
      </div>

      <template #footer>
        <div class="flex justify-end space-x-4">
          <NButton @click="handleClose">Cancel</NButton>
          <NButton
            type="primary"
            :disabled="!isFormValid"
            @click="handleCreate"
          >
            Create
          </NButton>
        </div>
      </template>
    </NCard>
  </NModal>
</template>
