<template>
  <div>
    <div class="flex">
      <BasicLink :to="link" class="text-link hover:text-blue-700">{{ name }}</BasicLink>
      <span v-if="isSampleData">
        <NTooltip
          raw
          trigger="hover"
          placement="top"
          :delay="500"
          :duration="600"
          :theme-overrides="themeOverrides"
          :contentStyle="{ backgroundColor: '#222', borderRadius: '5px', padding: '12px' }"
        >
          <template #trigger><ExclamationCircleIcon class="w-5 h-5 ml-1 text-yellow-600" /></template>
          {{ SAMPLE_DATA_WARNING_ICON.text }}
        </NTooltip>
      </span>
    </div>
    <div v-if="description" class="text-gray-500">{{ description }}</div>
  </div>
</template>

<script lang="ts">
import { ExclamationCircleIcon } from '@heroicons/vue/24/outline'
import { NTooltip, TooltipProps } from 'naive-ui'
import { getTooltipContent, TOOLTIP } from '@assets/help/tooltips'

type InputOverrides = NonNullable<TooltipProps['themeOverrides']>

const themeOverrides: InputOverrides = {
  peers: {
    Popover: {
      color: '#333'
    }
  }
}

export default defineComponent({
  name: 'DataAssetsTableNameDescriptionRender',
  components: { NTooltip, ExclamationCircleIcon },
  props: {
    name: String as PropType<string>,
    link: String as PropType<string>,
    description: String as PropType<string>,
    isSampleData: Boolean as PropType<boolean>
  },
  setup() {
    return {
      themeOverrides,
      SAMPLE_DATA_WARNING_ICON: getTooltipContent(TOOLTIP.SAMPLE_DATA_WARNING_ICON)
    }
  }
})
</script>

<style scoped></style>
