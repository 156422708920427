import type { AxiosResponse } from 'axios'
import { Tags } from '@shared/utils/Tags'

import type { ClientModel } from '@/clients/models/server/ClientModel'
import { store } from '@app/store'
import { getModelFromResponse } from '@shared/utils/helpers'
import http from '@app/utils/http-client'

export default class ClientService {
  static async getClientDetailsByName(name: string): Promise<ClientModel[]> {
    const response: AxiosResponse = await http.get('/clients', {
      params: {
        filters: {
          name,
        },
        populate: '*',
      },
    })
    return getModelFromResponse<ClientModel>(response) as ClientModel[]
  }

  static async updateClientDetails(id: number, client: ClientModel): Promise<ClientModel | void> {
    if (store.getters.isReadonlyMode) {
      return
    }
    const response: AxiosResponse = await http.put(`/clients/${id}`, { data: client })
    return getModelFromResponse<ClientModel>(response) as ClientModel
  }

  static async doesClientExist(clientName: string): Promise<boolean> {
    const response: AxiosResponse = await http.get(`/client/exists?name=${clientName}`)
    return response.data as boolean
  }

  static async createClient(client: ClientModel): Promise<ClientModel | void> {
    if (store.getters.isReadonlyMode) {
      return
    }

    // populate tags
    const data = { ...client, tags: client.tags?.length ? client.tags : Tags.getProductTagObjects() }

    const response: AxiosResponse = await http.post('/clients', { data })
    return getModelFromResponse<ClientModel>(response) as ClientModel
  }
}
