import type { ActionTree, GetterTree, MutationTree } from 'vuex'

import type { StoreDataChangePayload, StoreDataRewritePayload, StoreRangeDataChangePayload } from '@shared/utils/Types'
import type { JobHistoryModel } from '@/dags/models/server/JobHistoryModel'

export interface AppState {
  host: string
  isUserAuthenticated: boolean
  isReadonlyMode: boolean
  jobTasks: JobHistoryModel[]
}

export const appState: AppState = {
  host: 'localhost:8080',
  isUserAuthenticated: false,
  isReadonlyMode: false,
  jobTasks: [],
}

export const appMutations: MutationTree<AppState> = {
  host(state: AppState, value: string) {
    state.host = value
  },
  isUserAuthenticated(state: AppState, value: boolean) {
    state.isUserAuthenticated = value
  },
  isReadonlyMode(state: AppState, value: boolean) {
    state.isReadonlyMode = value
  },
  jobTasks(state: AppState, value: JobHistoryModel[]) {
    state.jobTasks = value
  },
  addTask(state: AppState, value: JobHistoryModel) {
    state.jobTasks.push(value)
  },
  addTasks(state: AppState, value: JobHistoryModel) {
    state.jobTasks = state.jobTasks.concat(value)
  },
  updateObjectInStore(state: AppState, { fieldObject, value, key }: StoreDataChangePayload) {
    fieldObject[key] = value
  },
  rewriteObjectInStore(state: AppState, { targetObject, byObject }: StoreDataRewritePayload) {
    for (const rewriteKey in byObject) { targetObject[rewriteKey] = byObject[rewriteKey] }
  },
  removeObjectInStore(state: AppState, { fieldObject, key }: StoreDataChangePayload) {
    delete fieldObject[key]
  },
  popFromArray(state: AppState, array: any[]) {
    array.pop()
  },
  pushToArray(state: AppState, { fieldObject, value }: StoreDataChangePayload) {
    fieldObject.push(value)
  },
  updateObjectRangeInStore(
    state: AppState,
    { fieldObject, keyRight, keyLeft, valueRight, valueLeft }: StoreRangeDataChangePayload,
  ) {
    fieldObject[keyRight] = valueRight
    fieldObject[keyLeft] = valueLeft
  },
}

export const appGetters: GetterTree<AppState, AppState> = {
  isUserAuthenticated(state: AppState): boolean {
    return state.isUserAuthenticated
  },
  host(state: AppState): string {
    return state.host
  },
  isReadonlyMode(state: AppState): boolean {
    const hasBeenReadOnlyModeBefore = localStorage.getItem('isReadonlyMode') === 'true'
    return state.isReadonlyMode || hasBeenReadOnlyModeBefore
  },
  jobTasks(state: AppState): JobHistoryModel[] | null {
    return state.jobTasks
  },
}

export const appActions: ActionTree<AppState, AppState> = {}
