<script setup lang="ts">
import 'ninja-keys'
import EnvironmentTopBar from '@app/components/EnvironmentTopBar.vue'
import useOfferfitEnvironment from '@app/composables/useOfferfitEnvironment'
import AppSettings from '@app/AppSettings'

import MetadataChangeConfirmModal from '@shared/components/basic/modal/MetadataChangeConfirmModal.vue'

import GlobalProvider from '@shared/components/providers/GlobalProvider'
import { getMenuItems } from '@shared/utils/hotkeys'
import { globalThemeOverrides } from '@shared/utils/offerfitTheme'
import { VueQueryDevtools } from '@tanstack/vue-query-devtools'
import { DialogsWrapper } from 'vuejs-confirm-dialog'
import {
  hasAcknowledgedLocalRemoteConflictUseCase,
  useCaseHasLocalRemoteConflict,
} from '@/usecases/composables/useUseCaseConfig'
import {
  hasAcknowledgedLocalRemoteConflict,
  pipelineHasLocalRemoteConflict,
} from '@/pipelines/composables/usePipelineConfig'
import PipelineConflictConfirmModal from '@/pipelines/components/PipelineConflictConfirmModal.vue'
import { isModalOpened } from '@/data-assets/composables/useMetadataChangeModal'
import { useClientConfig } from '@/clients/composables/useClientConfig'
import { usePermissions } from '@/auth/composables/usePermissions'
import { usePosthogStore } from '@/level4/stores/usePosthogStore'

useOfferfitEnvironment()
usePosthogStore()

const { loadClientData } = useClientConfig()
const { isAIEorAdmin, isLevel3, isLevel4, isOfferFitUser } = usePermissions()
const isLoadingOverlayOnScreen = ref<boolean>(true)
const isHiddenMenuRendered = ref<boolean>(false)
const hasLoadedClientData = ref<boolean>(false)

const showPipelineConflictModal = computed<boolean>(() => {
  const pipelineHasUnacknowledgedConflict = pipelineHasLocalRemoteConflict.value && !hasAcknowledgedLocalRemoteConflict.value
  const useCaseHasUnacknowledgedConflict
    = useCaseHasLocalRemoteConflict.value && !hasAcknowledgedLocalRemoteConflictUseCase.value

  return pipelineHasUnacknowledgedConflict || useCaseHasUnacknowledgedConflict
})

const { clientName, pipelineName, isLevel4Route } = AppSettings
watch([clientName, pipelineName], async ([clientName, pipelineName]) => {
  if (isLevel4Route.value && clientName && pipelineName) {
    try {
      await loadClientData(clientName, pipelineName)
    }
    finally {
      hasLoadedClientData.value = true
    }
  }
  else {
    hasLoadedClientData.value = true
  }
}, { immediate: true })

onMounted(() => {
  nextTick(() => {
    isLoadingOverlayOnScreen.value = false
    isHiddenMenuRendered.value = true
  })
})
</script>

<template>
  <NConfigProvider :themeOverrides="globalThemeOverrides" inlineThemeDisabled preflightStyleDisabled>
    <NModalProvider>
      <NDialogProvider>
        <EnvironmentTopBar />
        <RouterView v-if="hasLoadedClientData" />
      </NDialogProvider>
    </NModalProvider>

    <!-- Modals -->
    <PipelineConflictConfirmModal :showModal="showPipelineConflictModal" />
    <MetadataChangeConfirmModal :showModal="isModalOpened" />
    <DialogsWrapper />
    <!-- Modals -->

    <!-- Notifications -->
    <NMessageProvider placement="bottom-left" :duration="10000" closable keepAliveOnHover>
      <NNotificationProvider>
        <GlobalProvider style="height: 0; width: 0; visibility: hidden" />
      </NNotificationProvider>
    </NMessageProvider>
    <!-- Notifications -->
  </NConfigProvider>

  <ninja-keys
    v-if="((isLevel4 && isOfferFitUser) || (isLevel3 && isAIEorAdmin)) && isHiddenMenuRendered"
    placeholder="Search" :data="getMenuItems()"
  />

  <VueQueryDevtools />
</template>

<style scoped>
ninja-keys {
  --ninja-z-index: 50;
}
</style>
