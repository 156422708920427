<script lang="ts">
import { ExclamationCircleIcon } from '@heroicons/vue/24/solid'
import debounce from 'lodash/debounce'
import { vMaska } from 'maska'
import { REQUEST_DEBOUNCE_DELAY } from '@shared/data/constants'
import { FocusController } from '@shared/utils/FocusController'
import { xssPlugin } from '@shared/utils/xss'
import { useStore } from '@app/store'
import type { NullString } from '@shared/utils/Types'
import { trackEvent } from '@shared/utils/analytics'

export default defineComponent({
  name: 'SimpleInput',
  components: {
    ExclamationCircleIcon,
  },
  directives: { maska: vMaska },
  props: {
    id: String as PropType<string>,
    type: {
      type: String as PropType<string>,
      default: 'text',
    },
    placeholder: String as PropType<string>,
    modelValue: [String, Number] as PropType<NullString | number>,
    label: String as PropType<string>,
    error: String as PropType<NullString>,
    errorVisually: Boolean as PropType<boolean>,
    required: Boolean as PropType<boolean>,
    autocomplete: Boolean as PropType<boolean>,
    errorIconEnabled: Boolean as PropType<boolean>,
    errorIsAbsolutPositioned: Boolean as PropType<boolean>,
    autoselect: Boolean as PropType<boolean>,
    maska: Object as PropType<any | null>,
    compact: Boolean as PropType<boolean>,
    disabled: Boolean as PropType<boolean>,
    smallText: Boolean as PropType<boolean>,
  },
  emits: ['update:modelValue', 'blur'],
  setup(props, context) {
    const store = useStore()
    const inputRef: Ref = ref<any>(null)
    const localValue = computed({
      get: () => props.modelValue,
      set: (value) => {
        context.emit('update:modelValue', typeof props.modelValue === 'number' ? Number(value) : value)
        debouncedFieldChange()
      },
    })
    const focus = () => {
      inputRef.value?.focus?.()
    }

    const inputSelectHandler = ($event: Event) => {
      FocusController.focusedElementReference = inputRef
      if (props.autoselect) {
        ;($event.target as HTMLInputElement)?.select()
      }
    }

    const debouncedFieldChange = debounce(() => {
      if (props.id) {
        trackEvent(props.id, { type: 'input', action: localValue.value !== '' ? 'change' : 'clear' })
      }
    }, REQUEST_DEBOUNCE_DELAY)

    return {
      inputRef,
      inputSelectHandler,
      focus,
      localValue,
      xssPlugin,
      globalReadOnlyMode: computed(() => store.getters.isReadonlyMode),
    }
  },
})
</script>

<template>
  <div :class="[!($attrs.class as string)?.includes('w-') ? 'w-full' : '']">
    <label v-if="label" :for="id" class="mb-1 block font-medium"> {{ label }} <sup v-show="required">*</sup></label>
    <div class="relative rounded-md shadow-sm">
      <input
        :id="id"
        ref="inputRef"
        v-model="localValue"
        class="block h-full w-full rounded-md px-1 focus:outline-none"
        v-maska="maska"
        :disabled="disabled || globalReadOnlyMode"
        :type="type"
        :name="id"
        :autocomplete="autocomplete ? id : 'off'"
        :placeholder="placeholder"
        :class="[
          error || errorVisually
            ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
            : 'border-gray-300 placeholder-gray-300 focus:ring-offerfit-green focus:border-offerfit-green',
          errorIconEnabled ? 'pr-8' : '',
          compact ? 'px-1' : 'px-2',
          smallText ? 'text-sm' : 'text-base',
        ]"
        :aria-invalid="error || errorVisually ? 'true' : 'false'"
        :aria-describedby="`${id}-error`"
        @focus="inputSelectHandler($event)"
        @blur="$emit('blur')"
      />
      <div v-if="error && errorIconEnabled" class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
        <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
      </div>
    </div>
    <p
      v-if="error"
      :id="`${id}-error`"
      class="mt-1.5 rounded bg-white p-0.5 text-sm text-red-600"
      :class="{ absolute: errorIsAbsolutPositioned }"
      nosem
      v-html="xssPlugin.process(error)"
    ></p>
  </div>
</template>

<style scoped></style>
