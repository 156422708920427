import type { UserStateType } from '@/users/store/state'

const getters = {
  id: (state: UserStateType) => {
    return state.id
  },
  username: (state: UserStateType) => {
    return state.username
  },
  picture: (state: UserStateType) => {
    return state.picture
  },
  email: (state: UserStateType) => {
    return state.email
  },
  clients: (state: UserStateType) => {
    return state.clients || []
  },
  role: (state: UserStateType) => {
    return state.role
  },
  allClients: (state: UserStateType) => {
    return state.allClients
  },
  confirmed: (state: UserStateType) => {
    return state.confirmed
  },
  tos_accepted: (state: UserStateType) => {
    return state.tos_accepted
  },
  is_read_only: (state: UserStateType) => {
    return state.is_read_only
  },
  blocked: (state: UserStateType) => {
    return state.blocked
  },
  userObject: (state: UserStateType) => {
    return state.userObject
  },
}
export default getters
