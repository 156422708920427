import AppSettings from '@app/AppSettings.ts'
import type { RouteLocationRaw } from 'vue-router'
import router from '@router/index'
import type { useExperimenterFormState } from '../composables/useExperimenterFormState'
import { useFeatureFlags } from '../composables/useFeatureFlags'
import { INTEGRATION_PLATFORM } from './l4_constants'
import {
  getExistingIntegrationCredentials,
  hasIntegrationEstablished,
} from '@/level4/services/PlatformIntegrationService.ts'
import type { Group } from '@/level4/utils/level4Forms'
import SignUpFormsLayout from '@/level4/components/layout/OfferfitEmailSignupFormsLayout.vue'
import { Level4Route } from '@/level4/router/level4'

export enum Level4Module {
  SIGN_UP = 'sign-up',
  TYPE = 'type',
  INTEGRATIONS = 'integrations',
  USE_CREDENTIALS = 'use-credentials',
  BRAZE = 'braze',
  SFMC = 'sfmc',
  KLAVIYO = 'klaviyo',
  TEST_EMAILS = 'test-emails',
  TEMPLATES_SELECTION = 'templates-selection',
  TEMPLATES = 'templates',
  CREATE_VARIANTS = 'create-variants',
  BAU = 'bau-audience',
  FREQUENCY = 'frequency',
  DAYS_OF_WEEK = 'days-of-week',
  TIME = 'time',
  AUDIENCE = 'audience',
  LAUNCH_SFMC = 'launch-sfmc',
  LAUNCH_KLAVIYO = 'launch-klaviyo',
  GUARDRAILS = 'guardrails',
}

export enum SignUpSteps {
  COMPANY_NAME = 'name',
  INDUSTRY = 'industry',
}

export enum TypeSteps {
  CHOOSE = 'choose',
}

export enum IntegrateSteps {
  PLATFORM_CONNECTION = 'connection',
}

export enum ReuseCredentialsSteps {
  DEFAULT = 'default',
}

export enum BrazeSteps {
  PLATFORM_CONFIGURATION_BRAZE_CONFIGURE_URL = 'configure-url',
  PLATFORM_CONFIGURATION_BRAZE_CONFIGURE_API = 'configure-api',
  PLATFORM_CONFIGURATION_BRAZE_CONFIGURE_APP_ID = 'configure-appid',
  PLATFORM_CONFIGURATION_BRAZE_CONFIGURE_CAMPAIGN = 'configure-campaign',
  PLATFORM_CONFIGURATION_BRAZE_CONFIGURE_MESSAGE_VAR_ID = 'configure-messageid',
  PLATFORM_CONFIGURATION_BRAZE_CONFIGURE_EMAIL_FROM = 'configure-email',
}

export enum SFMCSteps {
  PLATFORM_CONFIGURATION_SMFC_CONFIGURE_KEYS = 'configure-keys',
  PLATFORM_CONFIGURATION_SMFC_CLIENT_ID = 'client-id',
  PLATFORM_CONFIGURATION_SMFC_CONFIGURE_CLIENT_SECRET = 'configure-secret',
  PLATFORM_CONFIGURATION_SMFC_CONFIGURE_AUTH_BASE_URI = 'configure-auth',
  PLATFORM_CONFIGURATION_SMFC_CONFIGURE_REST_BASE_URI = 'configure-rest',
  PLATFORM_CONFIGURATION_SMFC_CONFIGURE_SOAP_BASE_URI = 'configure-soap',
}

export enum KlaviyoSteps {
  PLATFORM_CONFIGURATION_KLAVIYO_API = 'configure-api',
}

export enum TemplatesSteps {
  EMAILS = 'emails',
}

export enum TemplatesSelectionSteps {
  CHOOSE = 'choose',
}

export enum CreateVariantsSteps {
  CHOOSE_ELEMENTS = 'choose-elements',
  TONE_GUIDANCE = 'tone-guidance',
  SELECT_SUBJECT_LINES = 'subject-lines',
  CTA_TEXT_SELECTION = 'cta-text-selection',
  HERO_IMAGE_SELECTION = 'hero-image-selection',
  CONFIRM = 'confirm',
}
export enum FrequencySteps {
  CHOOSE = 'choose',
}

export enum DaysSteps {
  CHOOSE = 'choose',
}

export enum AudienceSteps {
  CHOOSE = 'choose',
  PERCENT = 'percent',
}

export enum BauAudienceSteps {
	CHOOSE = 'choose',
  CHOOSE_CAMPAIGN = 'choose-campaign',
  CHOOSE_AUDIENCE = 'choose-audience',
}

export enum TimeSteps {
  TIMEZONE = 'timezone',
  TIME_OF_DAY = 'time-of-day',
}

export enum GuardRailSteps {
  CHOOSE = 'choose',
}

export enum TestEmailsSteps {
  VARIANTS = 'select-variants',
  EMAILS = 'select-emails',
  SUMMARY = 'summary',
}

export enum LaunchSFMCSteps {
  SFMC_DATA_QUERIES = 'sfmc-data-queries',
  SFMC_FINISH_JOURNEY = 'sfmc-finish-journey',
  SFMC_VALIDATE_RESULTS = 'sfmc-validate-results',
}

export enum LaunchKlaviyoFormGroup {
  KLAVIYO_FINISH_FLOW = 'klaviyo-finish-flow',
  KLAVIYO_VALIDATE_RESULTS = 'klaviyo-validate-results',
}

export type WizardFormType = FrequencySteps | TypeSteps | IntegrateSteps | ReuseCredentialsSteps | BrazeSteps | SFMCSteps | KlaviyoSteps | TemplatesSelectionSteps | TemplatesSteps | CreateVariantsSteps | SignUpSteps | DaysSteps | TimeSteps | GuardRailSteps | AudienceSteps | LaunchSFMCSteps | LaunchKlaviyoFormGroup | TestEmailsSteps

export function useForms(experimenterState: ReturnType<typeof useExperimenterFormState>) {
  const {
    experimenterFormState,
    isLive,
    isPlatformIntegrationFormStateDataEmpty,
    isTypeCompleted,
    isIntegrationComplete,
    isAudienceCompleted,
    isEmailCompleted,
    isFrequencyAndDaysCompleted,
    isTimeCompleted,
    isGuardrailsCompleted,
  } = experimenterState

  const { isEmailVariantsGuardrailsEnabled, isCalendarSendsEnabled } = useFeatureFlags()

  async function handleRedirectToNextExperimenterModule(module: Level4Module): Promise<RouteLocationRaw | undefined> {
    const targetPlatformModule = experimenterFormState.value?.platform ? { name: experimenterFormState.value.platform === INTEGRATION_PLATFORM.BRAZE ? Level4Module.BRAZE : experimenterFormState.value.platform === INTEGRATION_PLATFORM.SFMC ? Level4Module.SFMC : Level4Module.KLAVIYO } : undefined

    if (module === Level4Module.INTEGRATIONS && experimenterFormState.value?.platform) {
      const hasIntegration = await hasIntegrationEstablished(AppSettings.experimenterName.value, AppSettings.clientName.value)
      if (hasIntegration || !isPlatformIntegrationFormStateDataEmpty.value) {
        return targetPlatformModule
      }

      const data = await getExistingIntegrationCredentials(experimenterFormState.value?.platform)
      if (data?.length > 0) {
        return { name: Level4Module.USE_CREDENTIALS }
      }
      else {
        return targetPlatformModule
      }
    }

    if (module === Level4Module.USE_CREDENTIALS && targetPlatformModule) {
      return targetPlatformModule
    }

    const currentModule = module === Level4Module.BRAZE || module === Level4Module.SFMC || module === Level4Module.KLAVIYO ? Level4Module.INTEGRATIONS : module

    const completedModules = new Map<Level4Module, boolean>([
      [Level4Module.TYPE, isTypeCompleted.value],
      [Level4Module.INTEGRATIONS, !!isIntegrationComplete.value],
      [Level4Module.AUDIENCE, isAudienceCompleted.value],
      [Level4Module.TEMPLATES, isEmailCompleted.value],
      [Level4Module.BAU, true],
      [Level4Module.FREQUENCY, !!isFrequencyAndDaysCompleted.value],
      [Level4Module.TIME, isTimeCompleted.value],
    ])

    if (!isCalendarSendsEnabled.value) {
      completedModules.delete(Level4Module.TYPE)
    }

    if (isEmailVariantsGuardrailsEnabled.value) {
      completedModules.set(Level4Module.GUARDRAILS, !!isGuardrailsCompleted.value)
    }

    if (experimenterFormState.value?.platform === INTEGRATION_PLATFORM.KLAVIYO) {
      completedModules.set(Level4Module.LAUNCH_KLAVIYO, !!experimenterFormState.value?.platformNeedsUpdateFlag)
    }
    else if (experimenterFormState.value?.platform === INTEGRATION_PLATFORM.SFMC) {
      completedModules.set(Level4Module.LAUNCH_SFMC, !!experimenterFormState.value?.platformNeedsUpdateFlag)
    }

    const modules = [...completedModules.keys()]
    const nextModule = modules.find((module: Level4Module, index) => modules.indexOf(currentModule) < index && !completedModules.get(module))

    if (!nextModule) {
      return undefined
    }

    return { name: nextModule === Level4Module.TEMPLATES ? Level4Module.TEMPLATES_SELECTION : nextModule }
  }

  const LEVEL4_SIGN_UP_WIZARD_FORMS: () => Promise<Group<SignUpSteps>[]> = async () => [
    {
      layout: SignUpFormsLayout,
      steps: [
        SignUpSteps.COMPANY_NAME,
        SignUpSteps.INDUSTRY,
      ],
    },
  ]

  const LEVEL4_TEST_EMAILS_WIZARD_FORMS: () => Promise<Group<TestEmailsSteps>[]> = async () => [
    {
      steps: [
        TestEmailsSteps.VARIANTS,
        TestEmailsSteps.EMAILS,
        TestEmailsSteps.SUMMARY,
      ],
    },
  ]

  const LEVEL4_TYPE_WIZARD_FORMS: () => Promise<Group<TypeSteps>[]> = async () => [
    {
      redirectAfterLastStep: await handleRedirectToNextExperimenterModule(Level4Module.TYPE),
      steps: [
        TypeSteps.CHOOSE,
      ],
    },
  ]

  const LEVEL4_INTEGRATION_WIZARD_FORMS: () => Promise<Group<IntegrateSteps>[]> = async () => {
    return [
      {
        redirectAfterLastStep: await handleRedirectToNextExperimenterModule(Level4Module.INTEGRATIONS),
        steps: [IntegrateSteps.PLATFORM_CONNECTION],
      },
    ]
  }

  const LEVEL4_REUSE_CREDENTIALS_WIZARD_FORMS: () => Promise<Group<ReuseCredentialsSteps>[]> = async () => {
    return [
      {
        redirectAfterLastStep: await handleRedirectToNextExperimenterModule(Level4Module.USE_CREDENTIALS),
        steps: [ReuseCredentialsSteps.DEFAULT],
      },
    ]
  }

  const LEVEL4_BRAZE_WIZARD_FORMS: () => Promise<Group<BrazeSteps>[]> = async () => [
    {
      redirectAfterLastStep: await handleRedirectToNextExperimenterModule(Level4Module.BRAZE),
      redirectOnQuit: { name: Level4Route.EXPERIMENTER },
      steps: [
        BrazeSteps.PLATFORM_CONFIGURATION_BRAZE_CONFIGURE_URL,
        BrazeSteps.PLATFORM_CONFIGURATION_BRAZE_CONFIGURE_API,
        BrazeSteps.PLATFORM_CONFIGURATION_BRAZE_CONFIGURE_CAMPAIGN,
        BrazeSteps.PLATFORM_CONFIGURATION_BRAZE_CONFIGURE_MESSAGE_VAR_ID,
        BrazeSteps.PLATFORM_CONFIGURATION_BRAZE_CONFIGURE_APP_ID,
        BrazeSteps.PLATFORM_CONFIGURATION_BRAZE_CONFIGURE_EMAIL_FROM,
      ],
    },
  ]

  const LEVEL4_SFMC_WIZARD_FORMS: () => Promise<Group<SFMCSteps>[]> = async () => [
    {
      redirectAfterLastStep: await handleRedirectToNextExperimenterModule(Level4Module.SFMC),
      redirectOnQuit: { name: Level4Route.EXPERIMENTER },
      steps: [SFMCSteps.PLATFORM_CONFIGURATION_SMFC_CONFIGURE_KEYS],
    },
  ]

  const LEVEL4_KLAVIYO_WIZARD_FORMS: () => Promise<Group<KlaviyoSteps>[]> = async () => [
    {
      redirectAfterLastStep: await handleRedirectToNextExperimenterModule(Level4Module.KLAVIYO),
      redirectOnQuit: { name: Level4Route.EXPERIMENTER },
      steps: [KlaviyoSteps.PLATFORM_CONFIGURATION_KLAVIYO_API],
    },
  ]

  const LEVEL4_AUDIENCE_WIZARD_FORMS: () => Promise<Group<AudienceSteps>[]> = async () => [
    {
      redirectAfterLastStep: await handleRedirectToNextExperimenterModule(Level4Module.AUDIENCE),
      steps: [
        AudienceSteps.CHOOSE,
        AudienceSteps.PERCENT,
      ],
    },
  ]

  const LEVEL4_BAU_AUDIENCE_WIZARD_FORMS: () => Promise<Group<BauAudienceSteps>[]> = async () => {
	  const isAddingBAU = !!experimenterFormState.value?.isAddingBauCampaign
	  const form: Group<BauAudienceSteps | null> = {
		  redirectAfterLastStep: await handleRedirectToNextExperimenterModule(Level4Module.BAU),
      steps: [
        BauAudienceSteps.CHOOSE,
	      isAddingBAU ? BauAudienceSteps.CHOOSE_CAMPAIGN : null,
	      isAddingBAU ? BauAudienceSteps.CHOOSE_AUDIENCE : null,
      ],
	  }
	  return [form as Group<BauAudienceSteps>]
  }

  const LEVEL4_TEMPLATES_WIZARD_FORMS: () => Promise<Group<TemplatesSteps>[]> = async () => [
    {
      redirectAfterLastStep: await handleRedirectToNextExperimenterModule(Level4Module.TEMPLATES),
      steps: [
        TemplatesSteps.EMAILS,
      ],
    },
  ]

  const LEVEL4_TEMPLATE_SELECTION_WIZARD_FORMS: () => Promise<Group<TemplatesSelectionSteps>[]> = async () => [
    {
      redirectAfterLastStep: { name: Level4Module.TEMPLATES },
      steps: [
        TemplatesSelectionSteps.CHOOSE,
      ],
    },
  ]

  // We kept this after router refactor, but we should not keep using conditionalRedirectionChainForEmailVariants as it is (lifecycle hooks issue)
  const LEVEL4_CREATE_VARIANTS_WIZARD_FORMS: () => Promise<Group<CreateVariantsSteps>[]> = async () => {
    const variantsDataMap = experimenterFormState.value?.variantsDataMap
    const templateId = router.currentRoute.value.params?.templateId as string
    const hasToneGuidance = variantsDataMap?.[templateId]?.toneGuidanceFeedback !== undefined
    const shouldVarySubjectLine = variantsDataMap?.[templateId]?.varySubjectLine
    const shouldVaryCTA = variantsDataMap?.[templateId]?.varyCTA
    const shouldVaryHeroImage = variantsDataMap?.[templateId]?.varyHeroImage
    const shouldSetToneGuidance = !hasToneGuidance && (shouldVarySubjectLine || shouldVaryCTA)
    const hasStartedVariantsCreation = !(shouldVarySubjectLine || shouldVaryCTA || shouldVaryHeroImage)
    const shouldSetVariantsCreation = !isLive.value || hasStartedVariantsCreation
    const form: Group<CreateVariantsSteps | null> = {
      redirectAfterLastStep: {
        name: Level4Route.TEMPLATES,
      },
      redirectOnQuit: {
        name: Level4Route.TEMPLATES,
      },
      steps: [
        shouldSetVariantsCreation ? CreateVariantsSteps.CHOOSE_ELEMENTS : null,
        shouldSetToneGuidance ? CreateVariantsSteps.TONE_GUIDANCE : null,
        shouldVarySubjectLine ? CreateVariantsSteps.SELECT_SUBJECT_LINES : null,
        shouldVaryCTA ? CreateVariantsSteps.CTA_TEXT_SELECTION : null,
        shouldVaryHeroImage ? CreateVariantsSteps.HERO_IMAGE_SELECTION : null,
        CreateVariantsSteps.CONFIRM,
      ],
    }

    return [form as Group<CreateVariantsSteps>]
  }

  const LEVEL4_FREQUENCY_WIZARD_FORMS: () => Promise<Group<FrequencySteps>[]> = async () => [
    {
      redirectAfterLastStep: await handleRedirectToNextExperimenterModule(Level4Module.FREQUENCY),
      steps: [FrequencySteps.CHOOSE],
    },
  ]

  const LEVEL4_DAYS_WIZARD_FORMS: () => Promise<Group<DaysSteps>[]> = async () => [
    {
      redirectAfterLastStep: await handleRedirectToNextExperimenterModule(Level4Module.DAYS_OF_WEEK),
      steps: [DaysSteps.CHOOSE],
    },
  ]

  const LEVEL4_TIME_WIZARD_FORMS: () => Promise<Group<TimeSteps>[]> = async () => [
    {
      redirectAfterLastStep: await handleRedirectToNextExperimenterModule(Level4Module.TIME),
      steps: [
        TimeSteps.TIMEZONE,
        TimeSteps.TIME_OF_DAY,
      ],
    },
  ]

  const LEVEL4_GUARDRAILS_WIZARD_FORMS: () => Promise<Group<GuardRailSteps>[]> = async () => [
    {
      redirectAfterLastStep: await handleRedirectToNextExperimenterModule(Level4Module.GUARDRAILS),
      steps: [
        GuardRailSteps.CHOOSE,
      ],
    },
  ]

  const LEVEL4_LAUNCH_SFMC_WIZARD_FORMS: () => Promise<Group<LaunchSFMCSteps>[]> = async () => [
    {
      steps: [
        LaunchSFMCSteps.SFMC_DATA_QUERIES,
        LaunchSFMCSteps.SFMC_FINISH_JOURNEY,
        LaunchSFMCSteps.SFMC_VALIDATE_RESULTS,
      ],
    },
  ]

  const LEVEL4_LAUNCH_KLAVIYO_WIZARD_FORMS: () => Promise<Group<LaunchKlaviyoFormGroup>[]> = async () => [
    {
      steps: [
        LaunchKlaviyoFormGroup.KLAVIYO_FINISH_FLOW,
        LaunchKlaviyoFormGroup.KLAVIYO_VALIDATE_RESULTS,
      ],
    },
  ]

  const MODULE_TO_STEPS_MAP = new Map<Level4Module, () => Promise<Group<WizardFormType>[]>>([
    [Level4Module.SIGN_UP, LEVEL4_SIGN_UP_WIZARD_FORMS],
    [Level4Module.TYPE, LEVEL4_TYPE_WIZARD_FORMS],
    [Level4Module.INTEGRATIONS, LEVEL4_INTEGRATION_WIZARD_FORMS],
    [Level4Module.USE_CREDENTIALS, LEVEL4_REUSE_CREDENTIALS_WIZARD_FORMS],
    [Level4Module.BRAZE, LEVEL4_BRAZE_WIZARD_FORMS],
    [Level4Module.SFMC, LEVEL4_SFMC_WIZARD_FORMS],
    [Level4Module.KLAVIYO, LEVEL4_KLAVIYO_WIZARD_FORMS],
    [Level4Module.AUDIENCE, LEVEL4_AUDIENCE_WIZARD_FORMS],
    [Level4Module.TEMPLATES_SELECTION, LEVEL4_TEMPLATE_SELECTION_WIZARD_FORMS],
    [Level4Module.TEMPLATES, LEVEL4_TEMPLATES_WIZARD_FORMS],
    [Level4Module.CREATE_VARIANTS, LEVEL4_CREATE_VARIANTS_WIZARD_FORMS],
    [Level4Module.BAU, LEVEL4_BAU_AUDIENCE_WIZARD_FORMS],
    [Level4Module.FREQUENCY, LEVEL4_FREQUENCY_WIZARD_FORMS],
    [Level4Module.DAYS_OF_WEEK, LEVEL4_DAYS_WIZARD_FORMS],
    [Level4Module.TIME, LEVEL4_TIME_WIZARD_FORMS],
    [Level4Module.GUARDRAILS, LEVEL4_GUARDRAILS_WIZARD_FORMS],
    [Level4Module.TEST_EMAILS, LEVEL4_TEST_EMAILS_WIZARD_FORMS],
    [Level4Module.LAUNCH_SFMC, LEVEL4_LAUNCH_SFMC_WIZARD_FORMS],
    [Level4Module.LAUNCH_KLAVIYO, LEVEL4_LAUNCH_KLAVIYO_WIZARD_FORMS],
  ])

  return {
    MODULE_TO_STEPS_MAP,
  }
}
