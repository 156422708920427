<script setup lang="ts">
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'
import { xssPlugin } from '@shared/utils/xss'

const props = withDefaults(defineProps<{
  confirmHandler?: () => void // should be deprecated soon
  confirmButtonText?: string
  cancelButtonText?: string
  danger?: boolean
  warning?: boolean
  isOpened: boolean
  title?: string
  message?: string
  htmlMessage?: string
}>(), {
  confirmHandler: () => {},
  confirmButtonText: 'Accept',
  cancelButtonText: 'Cancel',
  danger: false,
  warning: false,
  isOpened: false,
  title: '',
  message: '',
  htmlMessage: '',
})

const emit = defineEmits<{
  close: [void]
  confirm: [void]
  cancel: [void]
}>()

function confirmButtonClickHandler() {
  emit('confirm')
  props.confirmHandler?.()
}

function cancelClickHandler() {
  emit('cancel')
  emit('close')
}
</script>

<template>
  <OFModal
    :title="title"
    :isOpened="isOpened"
    :onConfirm="confirmButtonClickHandler"
    :onCancel="cancelClickHandler"
    :confirmButtonLabel="confirmButtonText"
    :cancelButtonLabel="cancelButtonText"
    :danger="danger"
    :warning="warning"
  >
    <template #icon>
      <ExclamationTriangleIcon
        :class="{ 'text-red-600': danger, 'text-yellow-500': warning }"
        class="h-6 w-6"
        aria-hidden="true"
      />
    </template>
    <slot name="default" />
    <p v-if="htmlMessage" class="text-base text-gray-500" v-html="xssPlugin.process(htmlMessage)" />
    <p v-else-if="message" class="whitespace-pre-line text-base text-gray-500">
      {{ message }}
    </p>
  </OFModal>
</template>
