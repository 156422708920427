import { Environment, PYTHON_MS_API_PREFIX } from '@shared/data/constants'
import { store } from '@app/store'

import http from '@app/utils/http-client'

export default class ValidationService {
  static async validateCfg(client: string, pipeline: string, usecase?: string, product_env: Environment = Environment.DRAFT) {
    if (store.getters.isReadonlyMode) {
      return
    }

    const queryData = {
      client,
      pipeline,
      usecase,
      product_env,
    }

    return http.get(`${PYTHON_MS_API_PREFIX}/general/validate_engine_cfg`, {
      params: queryData,
      cache: false,
    })
  }
}
