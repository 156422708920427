<template>
  <div>
    <div class="flex force-colname-width">
      <NPopover :delay="1000" trigger="hover" :duration="800" placement="right" @update:show="showPopoverHandler">
        <template #trigger>
          <span class="hover:cursor-help">{{ name }}</span>
        </template>

        <div class="flex flex-col p-2 text-sm">
          <NSpin v-if="!sampleColumnRows?.length" size="small" />
          <NEmpty v-else-if="sampleColumnRows.length === 0" />
          <template v-else>
            <h3 class="font-medium pb-2">SAMPLE VALUES</h3>
            <ul
              v-if="sampleColumnRows?.length < 60"
              class="gap-x-6 gap-y-1"
              style="display: inline-grid; grid-auto-flow: column; grid-template-rows: repeat(5, auto)"
            >
              <li class="whitespace-nowrap" v-for="(row, index) in sampleColumnRows" :key="index">{{ row }}</li>
            </ul>
            <ul
              v-else
              class="gap-x-6 gap-y-1"
              style="display: inline-grid; grid-auto-flow: column; grid-template-rows: repeat(15, auto)"
            >
              <li class="whitespace-nowrap" v-for="(row, index) in sampleColumnRows" :key="index">{{ row }}</li>
            </ul>
            <template v-if="sampleColumnRows?.length < 60">
              <span class="whitespace-nowrap text-link cursor-pointer pt-2" @click="loadMoreDataClickHandler">More</span>
            </template>
          </template>
        </div>
      </NPopover>
    </div>
    <div class="grid grid-rows-1 grid-cols-5 grid-flow-col text-gray-500">
      <div class="col-span-4">
        <div>
          <span class="truncate inline-block w-full cursor-pointer" @click="toggleEditing">{{
            localDescription || 'add description'
          }}</span>
        </div>
      </div>
      <div class="col-span-1 flex justify-end">
        <PencilSquareIcon class="print:hidden w-5 h-5 text-offerfit-bright-purple cursor-pointer" @click="toggleEditing" />
      </div>
    </div>
    <TextEditModal
      :isOpened="isEditingDescription"
      :title="modalTitle"
      :text="localDescription"
      @close="isEditingDescription = false"
      @onUpdate="updateModelEvent"
    ></TextEditModal>
  </div>
</template>

<script lang="ts">
import { PencilSquareIcon } from '@heroicons/vue/20/solid'
import { TooltipProps, NPopover, NSpin, NEmpty } from 'naive-ui'
import TextEditModal from '@shared/components/basic/modal/TextEditModal.vue'

import { getTooltipContent, TOOLTIP } from '@assets/help/tooltips'
import { useAssetData } from '@/data-assets/composables/useAssetData'
import { NullString } from '@shared/utils/Types'

type InputOverrides = NonNullable<TooltipProps['themeOverrides']>

const themeOverrides: InputOverrides = {
  peers: {
    Popover: {
      color: '#333'
    }
  }
}

export default defineComponent({
  name: 'AssetValidationTableNameDescriptionRender',
  components: { TextEditModal, PencilSquareIcon, NPopover, NSpin, NEmpty },
  props: {
    name: String as PropType<string>,
    fieldName: String as PropType<string>,
    description: String as PropType<string>,
    index: Number as PropType<number>
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const localDescription: Ref = ref<NullString>(props.description)
    const isModalOpen: Ref = ref<boolean>(false)
    const modalTitle = `Update description for ${props.name}`
    const isEditingDescription: Ref = ref<Boolean>(false)
    const sampleColumnRows = ref<string[] | undefined>()

    const { getSampleDataForColumn } = useAssetData()

    const showPopoverHandler = async () => {
      if (props.name && !sampleColumnRows.value?.length) {
        sampleColumnRows.value = await getSampleDataForColumn(props.name, 15)
      }
    }

    const loadMoreDataClickHandler = async () => {
      if (props.name) {
        sampleColumnRows.value = await getSampleDataForColumn(props.name, 60)
      }
    }

    const updateModelEvent = (value: string) => {
      localDescription.value = value
      context.emit('update:modelValue', value)
    }

    const toggleEditing = () => {
      isEditingDescription.value = !isEditingDescription.value
    }

    return {
      themeOverrides,
      loadMoreDataClickHandler,
      showPopoverHandler,
      updateModelEvent,
      sampleColumnRows,
      toggleEditing,
      localDescription,
      isEditingDescription,
      isModalOpen,
      modalTitle,
      SAMPLE_DATA_WARNING_ICON: getTooltipContent(TOOLTIP.SAMPLE_DATA_WARNING_ICON)
    }
  }
})
</script>

<!--The table column containing this component is fixed to 225px. This forces the container values
to match that width everything looks correct-->
<style scoped>
.force-colname-width {
  width: 225px;
  min-width: 225px;
}
</style>
