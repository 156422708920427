import router from '@router/index'
import {
  CLIENT_QUERY_PARAM_NAME,
  LOCAL_STORAGE_CURRENT_CLIENT,
  LOCAL_STORAGE_CURRENT_PIPELINE,
  LOCAL_STORAGE_CURRENT_USECASE,
  LOCAL_STORAGE_LOG_LEVEL,
  LOCAL_STORAGE_REDIRECT_URL,
  PIPELINE_QUERY_PARAM_NAME,
  USECASE_QUERY_PARAM_NAME,
} from '@shared/data/constants'

export const redirectRouteAfterRecover = useStorage(LOCAL_STORAGE_REDIRECT_URL, '')

/**
 * What is app settings? Please go grab a coffee/tea , this is a long one.
 *
 * AppSettings is class that aims to work with the following conditions:
 *
 * 1. It must work before Vue mounting, because we do some stuff on hooks before app mount
 * 2. It must work AFTER Vue mounting, because we need the data from here in some components
 * 3. It must be as reactive as possible.
 *
 * The result is a Singleton class with plenty of things here. flags, computed flags, and client important
 * data that may be needed before Vue being mounted.
 *
 * This class is not intented to be scaled with time, it should disappear or only include flags.
 * If we use this across the app it will not scale well and become really confusing.
 *
 * Almost all logic that exists here works as a migration vehicle that allows us to conciliate
 * l3 and l4 routing & state differences.
 *
 * When to refactor?
 *
 * Once level 3 routing is refactored, we should remove all the logic related to params and LS
 */
export default class AppSettings {
  // FLAGS
  static readonly FEATURE_CALCULATION_TASKS_ENABLED = true
  static readonly PREPROCESS_TASKS_ENABLED = true
  static readonly PREDICT_TASKS_ENABLED = true
  static readonly AUGMENT_RECS_TASKS_ENABLED = true
  static readonly DELIVER_RECS_TASKS_ENABLED = true
  static readonly JOIN_ASSETS_TASKS_ENABLED = true
  static readonly GBQ_QUERIES_ENABLED = true
  static readonly BUCKET_QUERIES_ENABLED = true
  static readonly READONLY_MODE = false

  // STORAGE AND ROUTE INITIALIZATION
  static readonly redirectRouteAfterRecover = useStorage(LOCAL_STORAGE_REDIRECT_URL, '')
  static readonly LSClientName = useStorage(LOCAL_STORAGE_CURRENT_CLIENT, '')
  static readonly LSPipelineName = useStorage(LOCAL_STORAGE_CURRENT_PIPELINE, '')
  static readonly LSUsecaseName = useStorage(LOCAL_STORAGE_CURRENT_USECASE, '')
  static readonly LOG_LEVEL_NUMBER = useStorage(LOCAL_STORAGE_LOG_LEVEL, 0)
  static readonly clientDataIsLoaded = ref(false)
  static readonly browserLocation = useBrowserLocation()

  static isRouteInLevel3(routePath: string) {
    return !routePath?.startsWith('/app')
  }

  static isRouteInLevel4(routePath: string) {
    return routePath?.startsWith('/app')
  }

  static getUrlQuery(name: string) {
    return window.location.search ? new URLSearchParams(window.location.search).get(name) : null
  }

  // COMPUTED PROPERTIES
  // IMPORTANT, THESE NEED TO BE COMPUTED PROPERTIES SO THEY CAN BE WATCHED OR USED IN OTHER COMPUTED PROPERTIES
  // CANNOT BE VANILLA GETTERS
  static get isLevel3Route() {
    return computed(() => !this.browserLocation.value.pathname?.startsWith('/app'))
  }

  static get isLevel4Route() {
    return computed(() => this.browserLocation.value.pathname?.startsWith('/app'))
  }

  static get isLevel3v2Route() {
    return computed(() => this.browserLocation.value.pathname?.startsWith('/clients'))
  }

  static get tasksEnabled() {
    return computed(() => this.isLevel3Route.value)
  }

  /**
   * Ok, this following piece of logic seems confusing, I know.
   *
   * Why this exists?
   * Across code lifetime we've been tracking which client is being selected using
   * multiple methods. For level 3 we used a combination of LocalStorage and Query parameters
   * This forced us to use query param propagation (<BasicLink/> or processRoute middleware)
   * This also forced us to use LS syncronization which lead us to have 2 Sources of truth for that data
   * With the introduction of level 4 route refactoring (client/:clientName/pipelines/:pipelineName...)
   * We started to mantain client state using query Params. Now we have 3 sources of truth.
   * That is what you see in the following code. These values aim to compute all 3 with the following hierarchy:
   *
   * PARAMS > QUERY > LS
   *
   * Hopefully this would not be needed anymore as soon as L3 route refactoring is made.
   */
  static get clientName() {
    return computed(() => {
      return (Array.isArray(router.currentRoute.value?.params?.clientName) ? router.currentRoute.value?.params?.clientName[0] : router.currentRoute.value?.params?.clientName)
        ?? (Array.isArray(router.currentRoute.value?.params?.clientSlug) ? router.currentRoute.value?.params?.clientSlug[0] : router.currentRoute.value?.params?.clientSlug)
        ?? this.getUrlQuery(CLIENT_QUERY_PARAM_NAME)
        ?? (this.isLevel3v2Route.value ? undefined : this.LSClientName.value)
    })
  }

  static get pipelineName() {
    return computed(() => {
      return (Array.isArray(router.currentRoute.value?.params?.pipelineName) ? router.currentRoute.value?.params?.pipelineName[0] : router.currentRoute.value?.params?.pipelineName)
        ?? (Array.isArray(router.currentRoute.value?.params?.pipelineSlug) ? router.currentRoute.value?.params?.pipelineSlug[0] : router.currentRoute.value?.params?.pipelineSlug)
        ?? this.getUrlQuery(PIPELINE_QUERY_PARAM_NAME)
        ?? 'default'
    })
  }

  static get usecaseName() {
    return computed(() => {
      return (Array.isArray(router.currentRoute.value?.params?.usecaseName) ? router.currentRoute.value?.params?.usecaseName[0] : router.currentRoute.value?.params?.usecaseName)
        ?? (Array.isArray(router.currentRoute.value?.params?.usecaseSlug) ? router.currentRoute.value?.params?.usecaseSlug[0] : router.currentRoute.value?.params?.usecaseSlug)
        ?? this.getUrlQuery(USECASE_QUERY_PARAM_NAME)
    })
  }

  static get experimenterName() {
    return computed(() => {
      return (Array.isArray(router.currentRoute.value?.params?.experimenterName) ? router.currentRoute.value?.params?.experimenterName[0] : router.currentRoute.value?.params?.experimenterName)
    })
  }
}
