<template>
  <div class="flex items-center">
    <CalendarIcon v-if="type === DataType.DATE" class="w-4 h-4 mr-1 text-offerfit-orange" />
    <ClockIcon v-if="type === DataType.DATETIME" class="w-4 h-4 mr-1 text-offerfit-orange" />
    <div v-if="type === DataType.INTEGER" class="text-offerfit-orange font-semibold text-xs h-full mr-1">123</div>
    <div v-if="type === DataType.FLOAT" class="text-offerfit-orange font-semibold text-xs h-full mr-1">1.2</div>
    <div v-if="type === DataType.STRING" class="text-offerfit-orange font-semibold text-xs h-full mr-1">ABC</div>
    <div v-if="type === DataType.BOOLEAN" class="text-offerfit-orange font-semibold text-xs h-full mr-1">T|F</div>
    {{ columnName }}
  </div>
</template>

<script lang="ts">
import { CalendarIcon, ClockIcon } from '@heroicons/vue/24/outline'
import { DataType } from '@shared/data/constants'

export default {
  name: 'ColumnNameAndTypeRender',
  components: { CalendarIcon, ClockIcon },
  props: {
    columnName: String as PropType<string>,
    type: String as PropType<string>
  },
  setup() {
    return {
      DataType
    }
  }
}
</script>
