import { getQueryString } from '@shared/utils/helpers'
import type { AxiosResponse } from 'axios'
import { DEFAULT_CSV_SEPARATOR } from '@shared/data/constants'
import { store } from '@app/store'
import type { BucketFileInfo, DataAssetFormat, DataAssetHeaders } from '@shared/utils/Types'
import { escapeTabs } from '@shared/utils/transformHelpers'
import http from '@app/utils/http-client'

export default class SampleDataService {
  static POST_BUCKET_FILE_UPLOAD_URL: string = '/sample-data/bucket/upload'
  static POST_UPLOAD_AND_GET_HEADERS_URL: string = '/sample-data/headers'
  static GET_FILE_INFO_FROM_BUCKET_URL: string = '/sample-data/bucket/file-info'

  static async uploadSampleDataFileToBucket(
    file: any,
    assetName: string,
    onUploadProgress: (progressEvent: any) => void = () => {},
  ) {
    const formData = new FormData()
    formData.append('file', file, file.name?.replaceAll(' ', '_'))
    formData.append('assetName', assetName)
    formData.append('clientId', store.getters['client/client'].id)

    return await http.post(SampleDataService.POST_BUCKET_FILE_UPLOAD_URL, formData, { onUploadProgress })
  }

  static async getSampleDataFileInfoFromBucket(assetName: string): Promise<'None' | BucketFileInfo> {
    let response: AxiosResponse | undefined

    const queryParams = {
      clientId: store.getters['client/client'].id,
      assetName,
    }

    try {
      response = await http.get(`${SampleDataService.GET_FILE_INFO_FROM_BUCKET_URL}?${getQueryString(queryParams)}`)
    }
    catch (error: any) {
      if (error.response && error.response.statusCode !== 404) {
        throw error
      }
      else {
        return 'None'
      }
    }

    return response?.data as BucketFileInfo
  }

  static async uploadSampleDataFileToParse(
    file: File,
    dataFormat: DataAssetFormat,
    config: undefined | { separator: string },
    onUploadProgress: (progressEvent: any) => void = () => {},
  ): Promise<DataAssetHeaders> {
    const formData = new FormData()
    formData.append('file', file, file.name?.replaceAll(' ', '_'))
    formData.append('dataFormat', dataFormat)
    formData.append('separator', escapeTabs(config?.separator) || DEFAULT_CSV_SEPARATOR)

    const response: AxiosResponse | undefined = await http.post(SampleDataService.POST_UPLOAD_AND_GET_HEADERS_URL, formData, {
      onUploadProgress,
    })

    return response?.data as DataAssetHeaders
  }
}
