import { useMessage, useNotification } from 'naive-ui'
import Global from '@shared/utils/global'

export default defineComponent({
  name: 'GlobalProvider',
  setup() {
    Global.message = useMessage()
    Global.notification = useNotification()
  },
  render: () => h('div'),
})
