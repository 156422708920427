<script lang="ts">
const nonbreakingSpace = '\u00A0'
</script>
<script setup lang="ts">
import debounce from 'lodash/debounce'
import TagInput from '@shared/components/basic/TagInput.vue'

import { REQUEST_DEBOUNCE_DELAY } from '@shared/data/constants'
import type { NullString } from '@shared/utils/Types'
import { trackEvent } from '@shared/utils/analytics'

const props = defineProps({
  id: String as PropType<string>,
  placeholder: String as PropType<NullString>,
  modelValue: Array as PropType<string[]>,
  widthStyle: String as PropType<NullString>,
  maxTagCount: {
    type: Number as PropType<number>,
    default: 3,
  },
})

const emit = defineEmits<{ 'update:modelValue'?: [string] }>()



const debouncedFieldChange = debounce(() => {
  if (props.id) {
    trackEvent(props.id, {
      type: 'table-tags-input',
      action: 'change',
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      value: localValue.value,
    })
  }
}, REQUEST_DEBOUNCE_DELAY)

const localValue = computed({
  get: () => props.modelValue?.map(v => v.replaceAll(' ', nonbreakingSpace)),
  set: (value) => {
    emit('update:modelValue', value)
    debouncedFieldChange()
  },
})
</script>

<template>
  <div :style="{ width: widthStyle }">
    <TagInput v-model="localValue" :new-placeholder="placeholder" />
  </div>
</template>

<style scoped></style>
