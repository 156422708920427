import { AssetColumn, NullString } from '@shared/utils/Types'

export enum TransformationType {
  SUM = 'sum',
  MULTIPLY = 'multiply',
  DIFFERENCE = 'difference',
  DIVIDE = 'divide',
  TYPE_CAST = 'type_cast',
  FILL_NA = 'fillNA',
  SPLIT = 'split_by_char',
  COMBINE = 'combine',
  ROUND = 'round',
  MAX = 'max',
  MIN = 'min',
  FLOOR = 'floor',
  CEIL = 'ceil',
  STRING_CASE = 'string_case',
  STRING_REPLACE = 'string_replace',
  STRING_SUBSET = 'string_subset',
  DATE_TIME_EXTRACT = 'datetime_extract',
  MAP = 'mapping'
}

export type TransformPayloadWrapper = {
  sourceColumns: AssetColumn[]
  arguments: {
    [key: string]: string
  }
}

export type TransformDescription = {
  description?: NullString
}

export type ManyToOneTransformationConfigModel = {
  transform: TransformationType.SUM | TransformationType.MULTIPLY | TransformationType.COMBINE
  source_columns: string[]
  destination_columns: [string]
  arguments?: {
    [key: string]: string
  }
} & TransformDescription

export type TwoToOneTransformationConfigModel = {
  transform: TransformationType.DIFFERENCE | TransformationType.DIVIDE
  source_columns: [string, string]
  destination_columns: [string]
  arguments?: {
    [key: string]: string
  }
} & TransformDescription

export type OneToOneTransformationConfigModel = {
  transform: TransformationType.TYPE_CAST | TransformationType.FILL_NA | TransformationType.MAP
  source_columns: [string]
  destination_columns: [string]
  arguments?: {
    [key: string]: string
  }
} & TransformDescription

export type OneToManyTransformationConfigModel = {
  transform: TransformationType.SPLIT
  source_columns: [string]
  destination_columns: string[]
  arguments?: {
    [key: string]: string
  }
} & TransformDescription

export type TransformationConfigModel =
  | ManyToOneTransformationConfigModel
  | OneToManyTransformationConfigModel
  | OneToOneTransformationConfigModel
  | TwoToOneTransformationConfigModel

export type TransformUpdatePayload = {
  assetKey: string
  transformConfig: TransformationConfigModel
  oldId?: string
}

export type SubColumnTransformationModel = {
  destination_columns: [string]
  immutableParent: TransformationConfigModel
}
