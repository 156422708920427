<template>
  <div class="p-4">
    <div v-if="row.promoter_user?.username" class="text-sm text-offerfit-gray ml-3 mb-2">
      Deployment created by {{ row.promoter_user?.username }}
    </div>
    <div v-if="row.state === DeploymentState.TESTS_FAILED">
      <DeploymentFailedTestsRender :failedTests="row?.failedTestInfo" :key="row" />
    </div>
    <div v-else-if="row.state === DeploymentState.CANCELED" class="text-sm text-offerfit-gray ml-3 mb-2">
      Canceled {{ monthShortDateTime(new Date(row.updatedAt)) }}
    </div>
    <div v-else-if="row.state === DeploymentState.REVIEW_APPROVED">
      <div class="text-sm text-offerfit-gray ml-3 mb-2">
        Deployment approved on {{ monthShortDateTime(new Date(row.updatedAt)) }} By
      </div>
      <ReviewerRender @openReviewModal="openReviewModalHandler" class="mt-3" :deployment="row" />
      <div class="mt-4 p-2 pl-4 bg-green-50 border-solid border-transparent rounded-md">
        {{ row.review_message }}
      </div>
    </div>
    <div v-else-if="row.state === DeploymentState.PROMOTED" class="text-sm text-offerfit-gray ml-3 mb-2">
      Deployment promoted on {{ monthShortDateTime(new Date(row.updatedAt)) }}
    </div>
    <div v-else-if="row.state === DeploymentState.REVIEW_DECLINED">
      <div class="text-sm text-offerfit-gray ml-3 mb-2">Review rejected on {{ monthShortDateTime(new Date(row.updatedAt)) }}</div>
      <ReviewerRender :deployment="row" @openReviewModal="openReviewModalHandler" />
      <div class="mt-4 p-2 pl-4 bg-orange-50 border-solid border-transparent rounded-md">
        {{ row.review_message }}
      </div>
    </div>
    <div v-else-if="row.state === DeploymentState.RUNNING_TESTS">
      <div class="text-sm text-offerfit-gray ml-3 mb-2">
        Started running tests {{ monthShortDateTime(new Date(row.updatedAt) || new Date()) }}
      </div>
      <div class="flex ml-3">
        <CheckCircleIcon class="w-5 h-5 text-offerfit-green mt-0.5 mr-1" />
        <div class="font-semibold">Successfully ran {{ row.taskInfo?.counts?.success }} tasks</div>
      </div>
      <div class="flex ml-3">
        <EllipsisHorizontalCircleIcon class="w-5 h-5 text-offerfit-bright-purple mt-0.5 mr-1" />
        <div class="font-semibold">{{ row.taskInfo?.scheduled_tasks?.length }} tasks scheduled to run</div>
      </div>
    </div>
    <div v-else-if="row.state === DeploymentState.AWAITING_REVIEW">
      <div class="text-sm text-offerfit-gray ml-3 mb-2">
        Passed tests, and awaiting review {{ monthShortDateTime(new Date(row.updatedAt)) }}
      </div>
      <ReviewerRender :deployment="row" @openReviewModal="openReviewModalHandler" />
    </div>
    <div v-else-if="row.state === DeploymentState.DEPLOYMENT_ERROR">
      <div class="text-sm text-offerfit-gray ml-3 mb-2">
        Something went wrong on {{ monthShortDateTime(new Date(row.updatedAt)) }}
      </div>
      <div class="mt-4 p-2 pl-4 bg-orange-50 border-solid border-transparent rounded-md">Error: "{{ row.deploymentError }}"</div>
    </div>
  </div>
</template>

<script lang="ts">
import { CheckCircleIcon, EllipsisHorizontalCircleIcon } from '@heroicons/vue/24/solid'

import DeploymentFailedTestsRender from '@/deployments/components/DeploymentFailedTestsRender.vue'
import ReviewerRender from '@/deployments/components/ReviewerRender.vue'

import { DEFAULT_USER_IMG_SRC, DeploymentState } from '@shared/data/constants'
import { DeploymentsTableModel } from '@/deployments/models/server/DeploymentModel'
import { monthShortDateTime } from '@shared/utils/transformHelpers'

export default defineComponent({
  name: 'DeploymentsExpandedView',
  components: { DeploymentFailedTestsRender, ReviewerRender, CheckCircleIcon, EllipsisHorizontalCircleIcon },
  props: {
    row: {
      type: Object as PropType<DeploymentsTableModel>
    }
  },
  emits: ['openReviewModal'],
  setup(props, { emit }) {
    const openReviewModalHandler = (deployment: DeploymentsTableModel) => {
      emit('openReviewModal', deployment)
    }

    return {
      DEFAULT_USER_IMG_SRC,
      DeploymentState,
      monthShortDateTime,
      openReviewModalHandler
    }
  }
})
</script>


