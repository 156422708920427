import type { Engine } from '@tsparticles/engine'
import Particles from '@tsparticles/vue3'
import { loadFull } from 'tsparticles'
import type { App } from 'vue'

export function install({ app }: { app: App }) {
  app.use(Particles, {
    init: async (engine: Engine) => {
      await loadFull(engine)
    },
  })
}
