import type { ClientModel } from '@/clients/models/server/ClientModel'

export interface ClientStateType {
  client: ClientModel | null
}

const state: ClientStateType = {
  client: null,
}

export default state
