<script setup lang="tsx">
import { useWizardNavigation } from '@/level4/composables/useWizardNavigation'
import { Level4Module } from '@/level4/data/forms'
import {INTEGRATION_PLATFORM, PLATFORM_DISPLAY_NAME} from '@/level4/data/l4_constants'
import { useExperimenterFormState } from '../../../composables/useExperimenterFormState'
import { useFeatureFlags } from '@/level4/composables/useFeatureFlags'

const wizardNavigation = useWizardNavigation()
const { experimenterFormState } = useExperimenterFormState()
const { isEmailVariantsGuardrailsEnabled, isCalendarSendsEnabled } = useFeatureFlags()

interface TokensMiniMapStep {
  label?: string
  icon: VNode
  completed: boolean
  tooltip?: string
  module: Level4Module[]
}

// TODO: add complete calculation and related tooltip info
const experimenterMinimap : ComputedRef<TokensMiniMapStep[]> = computed(() => {
  const map =
   [
    {
      label: 'Integration',
      icon: <div class="i-solar-plug-circle-outline"></div>,
      completed: false,
      tooltip: `${experimenterFormState.value?.platform ? PLATFORM_DISPLAY_NAME[experimenterFormState.value?.platform ] : 'No platform'} connected`,
      module: [Level4Module.INTEGRATIONS, Level4Module.USE_CREDENTIALS, Level4Module.BRAZE, Level4Module.SFMC, Level4Module.KLAVIYO],
    },{
      label: 'Audience',
      icon: <div class="i-solar-users-group-rounded-outline"></div>,
      completed: false,
      tooltip: `${experimenterFormState.value?.importedAudiences?.length ?? 0} audience${experimenterFormState.value?.importedAudiences?.length && experimenterFormState.value.importedAudiences.length > 1 ? 's' : ''} selected`,
      module: [Level4Module.AUDIENCE],
    },{
      label: 'Email Variants',
      icon: <div class="i-solar-letter-outline"></div>,
      completed: false,
      tooltip: '',
      module: [Level4Module.TEMPLATES, Level4Module.TEMPLATES_SELECTION, Level4Module.CREATE_VARIANTS],
    },{
      label: 'Frequency & days',
      icon: <div class="i-solar-calendar-outline"></div>,
      completed: false,
      tooltip: `Every ${experimenterFormState.value?.selectedFrequenciesArray?.map((item: number) => ` ${item}`)} days`,
      module: [Level4Module.FREQUENCY],
    },{
      label: 'Time',
      icon: <div class="i-solar-clock-circle-outline"></div>,
      tooltip: '',
      completed: false,
      module: [Level4Module.TIME],
    }
  ]

  if(isCalendarSendsEnabled.value) {
    map.unshift({
      label: 'Type & Duration',
      icon: <div class="i-solar-calendar-outline"></div>,
      completed: false,
      tooltip: ``,
      module: [Level4Module.TYPE],
    })
  }

  if(isEmailVariantsGuardrailsEnabled.value){
    map.push({
      label: 'Guardrails',
      icon: <div class="i-solar-document-add-outline"></div>,
      tooltip: '',
      completed: false,
      module: [Level4Module.GUARDRAILS],
    })
  }

  if (experimenterFormState.value?.platform === INTEGRATION_PLATFORM.SFMC) {
    map.push({
      label: '',
      icon: <div class="i-solar-flag-bold"></div>,
      tooltip: '',
      completed: false,
      module: [Level4Module.LAUNCH_SFMC],
    })
  }

  if (experimenterFormState.value?.platform === INTEGRATION_PLATFORM.KLAVIYO) {
    map.push({
      label: '',
      icon: <div class="i-solar-flag-bold"></div>,
      tooltip: '',
      completed: false,
      module: [Level4Module.LAUNCH_KLAVIYO],
    })
  }

  return map
})
</script>

<template>
    <div v-for="(formGroup, formGroupIndex) in experimenterMinimap" class="step-module flex items-center">
      <MinimapChip
        :index="formGroupIndex"
        :icon="formGroup.icon"
        :label="formGroup.label"
        :isCompleted="formGroup.completed"
        :isActive="!!formGroup.module.includes(wizardNavigation.currentModule.value)"
        @click="wizardNavigation.goToModule(formGroup.module[0])"
      />
      <div v-if="formGroupIndex < experimenterMinimap.length - 1" class="i-solar-alt-arrow-right-outline text-sm text-gray-400"></div>
    </div>
</template>
