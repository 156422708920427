import AppSettings from '@app/AppSettings'
import { store } from '@app/store'
import { TASK_NAME, TASK_STATUS } from '@shared/data/constants'
import Global from '@shared/utils/global'
import { getModelArrayFromStrapiData } from '@shared/utils/helpers'
import type { StrapiDataArray } from '@shared/utils/Types'
import type { JobHistoryModel } from '@/dags/models/server/JobHistoryModel'
import DeliverRecsTaskService from '@/dags/services/tasks/DeliverRecsTaskService'
import FeatureCalculatingTaskService from '@/dags/services/tasks/FeatureCalculatingTaskService'
import FeatureStatisticsTaskService from '@/dags/services/tasks/FeatureStatisticsTaskService'
import FilterAndAugmentRecsTaskService from '@/dags/services/tasks/FilterAndAugmentRecsService'
import JoinAssetsTaskService from '@/dags/services/tasks/JoinAssetsTaskService'
import K8TaskService from '@/dags/services/tasks/K8TaskService'
import PredictTaskService from '@/dags/services/tasks/PredictTaskService'
import PreprocessTaskService from '@/dags/services/tasks/PreprocessTaskService'
import { useEngineeringFeatures } from '@/usecases/composables/useEngineeringFeatures'

export async function loadTasks() {
  const { startFeatureStatisticsCalculation, loadFeaturesStatisticsDataRows } = useEngineeringFeatures()
  const FEATURE_CALC_LISTENER_ID: string = 'FeatureStatisticsAfterFeatureCalculation'
  const FEATURE_STAT_LISTENER_ID: string = 'LoadingFeatureStatistics'

  const featureCalculationTaskFinishListener = async (task: JobHistoryModel) => {
    if (task.pipeline_task === TASK_NAME.ENGINEER_FEATURES && task.job_status === TASK_STATUS.SUCCEEDED) { await startFeatureStatisticsCalculation() }
  }
  const featureStatisticsCalculationTaskFinishListener = async (task: JobHistoryModel) => {
    if (task.pipeline_task === TASK_NAME.FEATURE_STATISTICS && task.job_status === TASK_STATUS.SUCCEEDED) { await loadFeaturesStatisticsDataRows() }
  }

  K8TaskService.registerListener(featureCalculationTaskFinishListener, FEATURE_CALC_LISTENER_ID)
  K8TaskService.registerListener(featureStatisticsCalculationTaskFinishListener, FEATURE_STAT_LISTENER_ID)

  if (AppSettings.PREPROCESS_TASKS_ENABLED) {
    PreprocessTaskService.getAllTasks()
      .then((response: StrapiDataArray<JobHistoryModel>) => {
        const tasks = getModelArrayFromStrapiData<JobHistoryModel>(response)
        if (tasks) {
          tasks.forEach((task: JobHistoryModel) => {
            task.messageRef = Global.message?.loading(PreprocessTaskService.getRunningMessage(task), {
              duration: 0,
              closable: false,
            })
            task.taskSucceededText = PreprocessTaskService.SUCCEEDED_MESSAGE
          })
          store.commit('addTasks', tasks)
        }
      })
      .catch((reason: any) => {
        Global.message?.error(reason.toString())
      })
  }
  else {
    Global.message?.warning('Feature disabled: "Preprocess" task')
  }

  if (AppSettings.JOIN_ASSETS_TASKS_ENABLED) {
    JoinAssetsTaskService.getAllTasks()
      .then((response: StrapiDataArray<JobHistoryModel>) => {
        const tasks = getModelArrayFromStrapiData<JobHistoryModel>(response)
        if (tasks) {
          tasks.forEach((task: JobHistoryModel) => {
            task.messageRef = Global.message?.loading(JoinAssetsTaskService.getRunningMessage(task), {
              duration: 0,
              closable: false,
            })
            task.taskSucceededText = JoinAssetsTaskService.SUCCEEDED_MESSAGE
          })
          store.commit('addTasks', tasks)
        }
      })
      .catch((reason: any) => {
        Global.message?.error(reason.toString())
      })
  }
  else {
    Global.message?.warning('Feature disabled: "Join assets" task')
  }

  if (AppSettings.FEATURE_CALCULATION_TASKS_ENABLED) {
    FeatureCalculatingTaskService.getAllTasks()
      .then((response: StrapiDataArray<JobHistoryModel>) => {
        const tasks = getModelArrayFromStrapiData<JobHistoryModel>(response)
        if (tasks) {
          tasks.forEach((task: JobHistoryModel) => {
            task.messageRef = Global.message?.loading(FeatureCalculatingTaskService.RUNNING_MESSAGE, {
              duration: 0,
              closable: false,
            })
            task.taskSucceededText = FeatureCalculatingTaskService.COMPLETED_MESSAGE
            task.imitateProgress = true
          })
          store.commit('addTasks', tasks)
        }
      })
      .catch((reason: any) => {
        Global.message?.error(reason.toString())
      })

    FeatureStatisticsTaskService.getAllTasks()
      .then((response: StrapiDataArray<JobHistoryModel>) => {
        const tasks = getModelArrayFromStrapiData<JobHistoryModel>(response)
        if (tasks) {
          tasks.forEach((task: JobHistoryModel) => {
            task.messageRef = Global.message?.loading(FeatureStatisticsTaskService.RUNNING_MESSAGE, {
              duration: 0,
              closable: false,
            })
            task.taskSucceededText = FeatureStatisticsTaskService.COMPLETED_MESSAGE
          })
          store.commit('addTasks', tasks)
        }
      })
      .catch((reason: any) => {
        Global.message?.error(reason.toString())
      })
  }
  else {
    Global.message.warning('Feature disabled: "Validation" task')
  }

  if (AppSettings.PREDICT_TASKS_ENABLED) {
    const tasks = getModelArrayFromStrapiData<JobHistoryModel>(await PredictTaskService.getAllTasks())

    if (tasks) {
      tasks.forEach((task: JobHistoryModel) => {
        task.messageRef = Global.message?.loading(PredictTaskService.getRunningMessage(task), { duration: 0, closable: false })
        task.taskSucceededText = PredictTaskService.SUCCEEDED_MESSAGE
      })
      store.commit('addTasks', tasks)
    }
  }
  else {
    Global.message?.warning('Feature disabled: "Predict" task')
  }

  if (AppSettings.AUGMENT_RECS_TASKS_ENABLED) {
    const tasks = getModelArrayFromStrapiData<JobHistoryModel>(await FilterAndAugmentRecsTaskService.getAllTasks())

    if (tasks) {
      tasks.forEach((task: JobHistoryModel) => {
        task.messageRef = Global.message?.loading(FilterAndAugmentRecsTaskService.getRunningMessage(task), {
          duration: 0,
          closable: false,
        })
        task.taskSucceededText = FilterAndAugmentRecsTaskService.SUCCEEDED_MESSAGE
      })
      store.commit('addTasks', tasks)
    }
  }
  else {
    Global.message?.warning('Feature disabled: "Augment recs" task')
  }

  if (AppSettings.DELIVER_RECS_TASKS_ENABLED) {
    const tasks = getModelArrayFromStrapiData<JobHistoryModel>(await DeliverRecsTaskService.getAllTasks())

    if (tasks) {
      tasks.forEach((task: JobHistoryModel) => {
        task.messageRef = Global.message?.loading(DeliverRecsTaskService.getRunningMessage(task), {
          duration: 0,
          closable: false,
        })
        task.taskSucceededText = DeliverRecsTaskService.SUCCEEDED_MESSAGE
      })
      store.commit('addTasks', tasks)
    }
  }
  else {
    Global.message?.warning('Feature disabled: "Deliver recs" task')
  }
}
