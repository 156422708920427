import type { ActionContext, ActionTree } from 'vuex'

import type { ClientModel } from '@/clients/models/server/ClientModel'
import ClientService from '@/clients/services/ClientService'
import type { AppState } from '@app/store/app'
import type { ClientStateType } from '@/clients/store/state'

export const actions: ActionTree<ClientStateType, AppState> = {
  async getClient({ commit }: ActionContext<ClientStateType, AppState>, clientName: string) {
    if (!clientName) { throw new Error('getClient: clientName is required to make a request') }

    const clientData: ClientModel[] = await ClientService.getClientDetailsByName(clientName)
    commit('client', clientData[0])
  },
  async updateClientDetails({ state, getters, rootGetters }: ActionContext<ClientStateType, AppState>) {
    if (rootGetters.isReadonlyMode) {
      return
    }
    if (!state.client?.id) {
      throw new Error('Client ID does not exist. Cannot execute ClientService.updateClientDetails method')
    }

    await ClientService.updateClientDetails(state.client?.id, getters.client)
  }
}
