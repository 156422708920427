import { NButton } from 'naive-ui'
import type { MessageApiInjection } from 'naive-ui/lib/message/src/MessageProvider'
import type { NotificationApiInjection } from 'naive-ui/lib/notification/src/NotificationProvider'

export default class Global {
  static message: MessageApiInjection
  static notification: NotificationApiInjection

  static error(message: string | (() => VNodeChild)): void {
    if (!message) { return }

    if (Global.message) {
      Global.message.error(message, { duration: 0, closable: true })
    }
    else if (typeof message === 'string') {
      console.error(message)
    }
  }

  static success(message: string | (() => VNodeChild)): void {
    if (!message) { return }

    if (Global.message) {
      Global.message.success(message, { duration: 0, closable: true })
    }
    if (typeof message === 'string') {
      // eslint-disable-next-line no-console
      console.info(message)
    }
  }

  static warning(message: string | (() => VNodeChild)): void {
    if (!message) { return }

    if (Global.message) {
      Global.message.warning(message, { duration: 0, closable: true })
    }
    if (typeof message === 'string') {
      console.warn(message)
    }
  }

  static showMessageNotification(message: string | (() => VNodeChild), type: 'success' | 'warning' = 'success'): void {
    if (!message) { return }

    if (Global.notification) {
      Global.notification[type]({ content: message, duration: 4000, keepAliveOnHover: true })
    }
  }

  static showVersionUpdateRequest() {
    if (Global.notification) {
      Global.notification.create({
        title: 'A new Portal version is available',
        content: 'You are working with an old version of the Portal. To use the latest features, please update the Portal.',
        meta: 'The page will be reloaded',
        action: () =>
          h(
            NButton,
            {
              type: 'primary',
              onClick: () => {
                window.location.reload()
              },
            },
            {
              default: () => 'Update',
            },
          ),
        closable: import.meta.env.VITE_APP_ENVIRONMENT !== 'production',
      })
    }
  }

  static showVersionUpdatedMessage() {
    if (Global.notification) {
      Global.message.success('Portal version is updated!', {
        duration: 3000,
        keepAliveOnHover: true,
        closable: false,
      })
    }
  }
}
