import { store } from '@app/store'
import type { SelectItemWithStatus } from '@shared/utils/Types'
import Global from '@shared/utils/global'
import { UseCaseStatus } from '@shared/data/constants'
import { useUseCasesMutations } from '@/usecases/store/mutations'
import type { UseCasesState } from '@/usecases/store/state'
import { createUseCasesStateObject } from '@/usecases/store/state'
import type { UseCaseModelWithoutConfig } from '@/usecases/models/server/UseCaseModel.ts'
import UseCaseService from '@/usecases/services/UseCaseService'

let globalState: UseCasesState | undefined

export function useUseCases(stateObject?: UseCasesState) {
  if (!globalState) { globalState = createUseCasesStateObject() }

  const _currentState: UseCasesState = stateObject || globalState

  const useCasesMutations = useUseCasesMutations(_currentState)

  const liveUseCases = computed(() => {
    return (_currentState.usecases || []).filter(usecase => usecase.status === UseCaseStatus.LIVE)
  })

  const liveUseCasesList = computed<SelectItemWithStatus<string, UseCaseStatus>[]>(() => {
    return liveUseCases.value.map((item: UseCaseModelWithoutConfig) => {
      return {
        value: item.name,
        label: item.displayName || item.name,
        status: item.status,
      }
    })
  })

  const useCasesList = computed<SelectItemWithStatus<string, UseCaseStatus>[]>(() => {
    return (_currentState.usecases || []).map((item: UseCaseModelWithoutConfig) => {
      return {
        value: item.name,
        label: item.displayName || item.name,
        status: item.status,
      }
    })
  })

  const deleteUseCase = async (useCaseID: number) => {
    if (store.getters.isReadonlyMode) {
      return
    }

    if (!useCaseID) {
      throw new Error('UseCase ID is required. Cannot execute UseCaseService.updateUseCase method')
    }

    try {
      await UseCaseService.deleteUseCase(useCaseID)
      useCasesMutations.deleteUseCase(useCaseID)
    }
    catch (e: any) {
      Global.error(e.message)
    }
  }

  return {
    usecases: toRef(_currentState, 'usecases'),
    ...useCasesMutations,
    liveUseCasesList,
    liveUseCases,
    useCasesList,
    deleteUseCase,
  }
}
