import AppSettings from '@app/AppSettings'
import ConfirmCfgChangeModal from '@shared/components/ConfirmCfgChangeModal.vue'
import { PreviewDataType } from '@shared/data/constants'
import { store } from '@app/store'
import type { DataAssetWrapper, IDataAsset, SelectItem } from '@shared/utils/Types'
import { AssetColumn } from '@shared/utils/Types'
import Global from '@shared/utils/global'
import {
  deepToRaw,
  getNewExpressionWrapper,
  stableYAMLStringify,
} from '@shared/utils/helpers'
import { createConfirmDialog } from 'vuejs-confirm-dialog'
import { parse } from 'yaml'
import usePermissions from '@/auth/composables/usePermissions.ts'
import type { PipelineConfigModel } from '@/pipelines/models/PipelineConfigModel'

import type { MetadataModel, PipelineModel } from '@/pipelines/models/server/PipelineModel'
import {
  convertModelExpressionToConfigExpression,
  getComparatorsConfigModelsFromExpression,
  getExpressionModelsFromArray,
  getFilterUniqueId,
} from '@/filtering/utils/filters'
import { useUseCases } from '@/usecases/composables/useUseCases'
import type { CustomerLogicElement, CustomerPopulationConfigModel, CustomerPopulationModel } from '@/pipelines/models/CustomerPopulationModel'
import type { JoinAssetConfigModel } from '@/data-assets/models/DataJoiningModel'
import type {
  CommonFilter,
  FilterConfigMatchingExpression,
  FilterConfigModel,
  RootFilterConfigExpression,
  RootFilterExpression,
} from '@/filtering/models/FilterModel'

import type { TransformationConfigModel } from '@/data-assets/models/TransformationConfigModel'
import type { AssetFieldMetadataModel, AssetMetadataModel } from '@/data-assets/models/server/AssetMetadataModel'
import type { PipelineValidationEmail } from '@/reporting/models/server/ReportingModel'
import type { UseCaseModelWithoutConfig } from '@/usecases/models/server/UseCaseModel.ts'
import BigQueryService from '@/data-assets/services/BigQueryService'

const globalCurrentDataAssetKey = ref<string | undefined>()

const processedDataAssetRowsFromBQMap = ref<Map<string, null | string[][]>>(new Map([]))
const transformedDataAssetRowsFromBQMap = ref<Map<string, null | string[][]>>(new Map([]))
const joinedDataAssetRowsFromBQMap = ref<Map<string, null | string[][]>>(new Map([]))

export const pipelineHasLocalRemoteConflict = ref<boolean>(false)
export const hasAcknowledgedLocalRemoteConflict = ref<boolean>(false)
export const pipelineLocallyUpdatedAt = ref<Date | null>(null)

let initialized: boolean = false
let diffIsOpened: boolean = false

// The "base" template for the Pipeline config that should exist as a "stub" whenever
// the default pipleine is created.
export const PIPELINE_CONFIG_TEMPLATE: PipelineConfigModel = {
  path: {
    raw_data_bucket: '',
    processed_data_dir: '',
  },
  preprocess: {
    use_modern_preprocess_structure: true,
    assets: {},
  },
  usecases: [],
  validation: {
    email: {
      staging_recipients: [],
      recipients: [],
    },
  },
  engineer_features: {
    use_modern_fe_structure: true,
    base_table: {
      base_table_name: '',
      base_table_callback: '',
      base_table_user_identifier_col: '',
    },
    assets: {},
  },
  reporting_segments: {},
}

export function usePipelineConfig(useGlobalState: boolean = true, assetKeyRef?: Ref, readOnly: boolean = false) {
  const _currentDataAssetKey = useGlobalState ? globalCurrentDataAssetKey : assetKeyRef || ref<string | undefined>()

  const { usecases } = useUseCases()
  const { isAIEorAdmin } = usePermissions()

  const areRowsLoading = ref<boolean>(false)

  const getPipelineModel = computed<PipelineModel | undefined>(() => store?.getters['pipeline/pipeline'])
  const getPipelineConfig = computed<PipelineConfigModel | undefined>(() => getPipelineModel.value?.config)
  const getPipelinePreprocessConfig = computed(() => getPipelineConfig.value?.preprocess)
  const getPipelineFeatureConfig = computed(() => getPipelineConfig.value?.engineer_features)
  const getPipelineUsecasesArray = computed(() => getPipelineConfig.value?.usecases)
  const getPipelineValidationEmail = computed(() => getPipelineConfig.value?.validation?.email)

  const updateCustomerPopulation: (value: CustomerPopulationModel) => void = (value: CustomerPopulationModel) => {
    let existingComparatorList: CommonFilter[] | undefined
      = getPipelineFeatureConfig.value?.customer_population?.filter?.comparators?.concat()

    if (!existingComparatorList) {
      existingComparatorList = []
    }

    let customerPopulationObject: CustomerLogicElement | undefined

    if (value.expression) {
      const comparatorsConfig: CommonFilter[] = getComparatorsConfigModelsFromExpression(
        value.expression,
      )

      for (let i = 0; i < comparatorsConfig.length; i++) {
        const comparatorsConfigElement: CommonFilter = comparatorsConfig[i]
        const existingConfigElement: CommonFilter | undefined = existingComparatorList.find(
          (item: CommonFilter) => item.id === comparatorsConfigElement.id,
        )

        if (!existingConfigElement) {
          existingComparatorList.push(comparatorsConfigElement)
        }
      }

      customerPopulationObject = {
        id: 'default',
        negate_expression: false,
        expression: convertModelExpressionToConfigExpression<FilterConfigMatchingExpression, RootFilterConfigExpression>(
          value.expression,
        ) as RootFilterConfigExpression,
      }
    }

    const customerPopulationConfigModel: CustomerPopulationConfigModel = {
      asset_name: value.assetName,
      customer_identifier_field: value.customerIdentifierField,
    } as CustomerPopulationConfigModel

    if (customerPopulationObject) {
      customerPopulationConfigModel.filter = {
        comparators: existingComparatorList.concat(),
        logic: [customerPopulationObject],
      }
    }

    store.commit('pipeline/updateCustomerPopulationConfig', customerPopulationConfigModel)
  }

  const customerPopulation = computed<CustomerPopulationModel | undefined>(() => {
    const customerPopulationConfigObject = getPipelineFeatureConfig.value?.customer_population
    if (!customerPopulationConfigObject) { return undefined }

    const filterLogicConfigModel: FilterConfigModel | undefined = customerPopulationConfigObject?.filter?.logic?.[0]
    let expression: RootFilterExpression | undefined

    if (filterLogicConfigModel) {
      expression = getExpressionModelsFromArray(
        filterLogicConfigModel.expression,
        customerPopulationComparators.value,
      ) as RootFilterExpression
    }

    const customerPopulationModel: CustomerPopulationModel = {
      assetName: customerPopulationConfigObject.asset_name,
      customerIdentifierField: customerPopulationConfigObject.customer_identifier_field,
    }

    if (expression) {
      customerPopulationModel.expression = expression.length ? expression : ([getNewExpressionWrapper()] as RootFilterExpression)
    }

    return customerPopulationModel
  })
  const customerPopulationComparators = computed(() => {
    const customerPopulationConfigObject = getPipelineFeatureConfig.value?.customer_population
    if (!customerPopulationConfigObject?.filter?.comparators) { return undefined }

    const commonFilters: CommonFilter[] = customerPopulationConfigObject.filter?.comparators?.map((comparator: CommonFilter) => {
      return {
        id: comparator.id || getFilterUniqueId(comparator),
        colname: comparator.colname,
        compare_op: comparator.compare_op,
        compare_val: comparator.compare_val,
      } as CommonFilter
    })

    return commonFilters
  })

  const filteringFeatureAssetsForCurrentAsset = computed(() =>
    _currentDataAssetKey.value ? getPipelineFeatureConfig.value?.assets[_currentDataAssetKey.value] : undefined,
  )

  const dataJoiningList = computed(() => currentDataAssetWrapper.value?.asset?.asset_joining)

  const transformationList = computed(() =>
    currentDataAssetWrapper.value?.asset?.transform?.transforms?.filter((item: TransformationConfigModel) => !!item),
  )

  const getAssetColumn = (item: string, asset?: string): AssetColumn => {
    return new AssetColumn({ asset: asset || (_currentDataAssetKey.value as string), column: item })
  }

  const joinedDataAssetColumns = computed(() => {
    return dataJoiningList.value
      ?.map((item: JoinAssetConfigModel) => {
        return item.keep_fields?.map((field: string) => new AssetColumn({ asset: item.asset, column: field }))
      })
      .flat() as AssetColumn[]
  })

  const joinedDataAssetColumnsOnSelectedJoining = (joiningAssetKey: string) =>
    computed<AssetColumn[] | undefined>(() => {
      const copy = dataJoiningList.value?.concat() || []

      const targetIndex = copy.findIndex((value: JoinAssetConfigModel) => value.asset === joiningAssetKey)

      copy.length = targetIndex !== -1 ? targetIndex : copy.length

      return copy
        ?.map((item: JoinAssetConfigModel) => {
          return item.keep_fields?.map((field: string) => new AssetColumn({ asset: item.asset, column: field }))
        })
        .flat() as AssetColumn[]
    })

  const getValidationEmailData = computed(() => {
    let recipients = getPipelineValidationEmail.value?.recipients || []

    if (import.meta.env.VITE_APP_ENVIRONMENT === 'staging') {
      recipients = getPipelineValidationEmail.value?.staging_recipients || []
    }

    return {
      name: 'Daily validation report',
      type: 'Data validation',
      usecaseDisplayName: 'n/a',
      recipients,
      cadence: 'Daily',
    } as PipelineValidationEmail
  })

  const dataAssetWrappers = computed(() => {
    const dataAssetsFromConfig: any = getPipelinePreprocessConfig.value?.assets
    const assetsArray: DataAssetWrapper[] = []

    if (dataAssetsFromConfig) {
      Object.keys(dataAssetsFromConfig)
        .sort()
        .forEach((key) => {
          const row: DataAssetWrapper = {
            key,
            asset: dataAssetsFromConfig[key] as IDataAsset,
          }
          assetsArray.push(row)
        })
    }

    return assetsArray
  })

  const currentDataAssetWrapper = computed<DataAssetWrapper | undefined>(() => {
    return getDataAsset(_currentDataAssetKey.value)
  })

  const dropColumns = computed(() => {
    return currentDataAssetWrapper.value?.asset?.drop_cols
  })

  const primaryKeyColumns = computed(() => {
    return currentDataAssetWrapper.value?.asset?.pk_cols
  })

  const partitionColumn = computed(() => {
    return currentDataAssetWrapper.value?.asset?.partition_col
  })

  const renameColumns = computed(() => {
    return currentDataAssetWrapper.value?.asset?.rename_cols
  })

  const descriptionColumns = computed(() => {
    return currentDataAssetWrapper.value?.asset?.column_descriptions
  })

  const addOrUpdateDataAsset = (assetWrapper: DataAssetWrapper) => {
    store.dispatch('pipeline/addOrUpdateDataAsset', assetWrapper)
  }

  const getDataAsset = (key: string | undefined) => {
    return dataAssetWrappers.value.find((item: DataAssetWrapper) => item.key === key)
  }

  const immutableAsset = computed<IDataAsset | undefined>(() => currentDataAssetWrapper.value?.asset)

  /**
   * Returns all data asset columns (with joining) wrapped in { asset: 'name', column: 'name' }
   * @type {ComputedRef<string[]>}
   */
  const processedAssetColumns = computed<AssetColumn[]>(() => {
    const result = processedDataAssetRowsFromBQMap.value.get(_currentDataAssetKey.value || '')

    if (!result?.length) { return [] }
    return Object.keys(result[0])
      .map((item: string) => getAssetColumn(item))
      .concat(joinedDataAssetColumns.value || [])
  })

  /**
   * Returns all data asset columns (with joining) wrapped in { asset: 'name', column: 'name' } that defined before joining
   * @type {ComputedRef<string[]>}
   */
  const processedAssetColumnsOnSelectedJoining = (joiningAssetKey: string) =>
    computed<AssetColumn[] | undefined>(() => {
      const result = processedDataAssetRowsFromBQMap.value.get(_currentDataAssetKey.value || '')
      const columnsOnSelectedJoining = joinedDataAssetColumnsOnSelectedJoining(joiningAssetKey)

      if (!result?.length) { return [] }
      return Object.keys(result[0])
        .map((item: string) => getAssetColumn(item))
        .concat(columnsOnSelectedJoining.value || [])
    })

  /**
   * Returns all data asset columns (with joining) wrapped in { asset: 'name', column: 'name' } that defined after joining and transformations.
   *
   *    !!! Remember to get all columns properly you'd need to get preprocessed columns using
   *    "loadProcessedDataRows(PreviewDataType.PREPROCESS, true, 1)" function somewhere before this getter.
   *    !!!
   *
   *    Note: You do not need to load loadProcessedDataRows(PreviewDataType.JOINING) or
   *    loadProcessedDataRows(PreviewDataType.TRANSFORMATION). They are using for other purposes.
   *
   * @type {ComputedRef<string[]>}
   */
  const processedAssetColumnsAfterJoiningAndTransform = computed<AssetColumn[]>(() => {
    const result = processedAssetColumns.value
    const transformColumns: AssetColumn[]
      = transformationList.value
        ?.map((item: TransformationConfigModel) => item.destination_columns)
        .flat()
        .map<AssetColumn>((item: string) => getAssetColumn(item)) || []

    if (!result?.length) { return [] }
    return result.concat(transformColumns)
  })

  const preprocessedAssetRows = computed<string[][] | undefined>(() => {
    return processedDataAssetRowsFromBQMap.value?.get(_currentDataAssetKey.value || '') || undefined
  })

  const joinedAssetRows = computed<string[][] | undefined>(() => {
    return joinedDataAssetRowsFromBQMap.value?.get(_currentDataAssetKey.value || '') || undefined
  })

  const transformedAssetRows = computed<string[][] | undefined>(() => {
    return transformedDataAssetRowsFromBQMap.value?.get(_currentDataAssetKey.value || '') || undefined
  })

  const loadAllTypeProcessedDataRows = async () => {
    await Promise.allSettled([
      loadProcessedDataRows(PreviewDataType.PREPROCESS, true, 1),
      loadProcessedDataRows(PreviewDataType.JOINING, true, 1),
      loadProcessedDataRows(PreviewDataType.TRANSFORMATION, true, 1),
    ])
  }

  const getLatestDataProcessingType = () => {
    if (transformedAssetRows.value?.length) { return PreviewDataType.TRANSFORMATION }
    if (joinedAssetRows.value?.length) { return PreviewDataType.JOINING }
    if (preprocessedAssetRows.value?.length) { return PreviewDataType.PREPROCESS }

    return undefined
  }

  const getLatestProcessedData = () => {
    const rowsMap = {
      [PreviewDataType.PREPROCESS]: processedDataAssetRowsFromBQMap,
      [PreviewDataType.JOINING]: joinedDataAssetRowsFromBQMap,
      [PreviewDataType.TRANSFORMATION]: transformedDataAssetRowsFromBQMap,
    }

    const dataType = getLatestDataProcessingType()

    if (dataType) {
      return rowsMap[dataType]?.value.get(_currentDataAssetKey.value || '')
    }

    return undefined
  }

  const loadProcessedDataRows = async (type: PreviewDataType, silent: boolean = true, limit?: number) => {
    if (areRowsLoading.value && (limit === undefined || limit > 1)) { return }

    areRowsLoading.value = true

    const rowsMap = {
      [PreviewDataType.PREPROCESS]: processedDataAssetRowsFromBQMap,
      [PreviewDataType.JOINING]: joinedDataAssetRowsFromBQMap,
      [PreviewDataType.TRANSFORMATION]: transformedDataAssetRowsFromBQMap,
    }

    const requestMethodMap = {
      [PreviewDataType.PREPROCESS]: BigQueryService.getProcessedDataRows,
      [PreviewDataType.JOINING]: BigQueryService.getJoinedDataRows,
      [PreviewDataType.TRANSFORMATION]: BigQueryService.getTransformationDataRows,
    }

    rowsMap[type].value.set(_currentDataAssetKey.value as string, null)

    let requestConfig

    if (limit !== undefined) {
      requestConfig = { limit }
    }

    if (AppSettings.GBQ_QUERIES_ENABLED) {
      try {
        rowsMap[type].value.set(
          _currentDataAssetKey.value as string,
          await requestMethodMap[type](_currentDataAssetKey.value as string, requestConfig),
        )
        areRowsLoading.value = false
      }
      catch (error: any) {
        const errorMessage = `Cannot load the '${type}' data. Please check your asset configuration and/or run '${type}' task.`

        if (!silent) {
          Global.showMessageNotification(errorMessage, 'warning')
        }

        console.warn(errorMessage)

        rowsMap[type].value.set(_currentDataAssetKey.value as string, [])
        areRowsLoading.value = false
      }
    }
    else {
      Global.message.warning('Feature disabled: Google BigQuery')
      rowsMap[type].value.set(_currentDataAssetKey.value as string, [])
      areRowsLoading.value = false
    }
  }

  const assetSelectOptions = computed<SelectItem<string>[]>((): any => {
    return dataAssetWrappers.value.map(
      (assetWrapper: DataAssetWrapper) =>
        ({
          label: assetWrapper.asset?.display_name || assetWrapper.key,
          value: assetWrapper.key,
        } as SelectItem<string>),
    )
  })

  const processedColumnsSelectOptions = computed<SelectItem<AssetColumn>[]>((): any => {
    return processedAssetColumns.value.map(
      (item: AssetColumn) =>
        ({
          label: item.data.column,
          value: item,
        } as SelectItem<AssetColumn>),
    )
  })

  const processedJoinedTransformedColumnsSelectOptions: ComputedRef<SelectItem<AssetColumn>[]> = computed<
    SelectItem<AssetColumn>[]
  >((): any => {
    return processedAssetColumnsAfterJoiningAndTransform.value.map(
      (item: AssetColumn) =>
        ({
          label: item.data.column,
          value: item,
        } as SelectItem<AssetColumn>),
    )
  })

  /**
   * Wraps the result of processedAssetColumnsAfterJoiningAndTransform for using in BasicSelect as options
   * @type {ComputedRef<SelectItem<string>[]>}
   */
  const processedJoinedTransformedColumnNameSelectOptions: ComputedRef<SelectItem<string>[]> = computed<SelectItem<string>[]>(
    (): any => {
      return processedAssetColumnsAfterJoiningAndTransform.value.map(
        (item: AssetColumn) =>
          ({
            label: item.data.column,
            value: item.data.column,
          } as SelectItem<string>),
      )
    },
  )

  const syncUsecaseList = async () => {
    // if usecases changed we should sync the config and save it
    const usecasesList = usecases.value ?? []
    const realUsecasesNames = usecasesList.map((usecase: UseCaseModelWithoutConfig) => usecase.name)
    const pipelineUsecasesNames = getPipelineUsecasesArray.value || []
    const useCaseArraysAreDifferent = !(realUsecasesNames.every((usecaseName: string) => pipelineUsecasesNames.includes(usecaseName)) && realUsecasesNames.length === pipelineUsecasesNames.length)

    /// overwrite the usecases list to make sure all of them in the usecases list
    // we don't want to show this dialog and make any changes in case it's not OfferFit User or Admin
    if (isAIEorAdmin.value && usecasesList.length && useCaseArraysAreDifferent && !readOnly && !diffIsOpened) {
      diffIsOpened = true
      const changedConfig = structuredClone(deepToRaw(getPipelineConfig.value ?? PIPELINE_CONFIG_TEMPLATE))
      changedConfig.usecases = realUsecasesNames

      const { data, isCanceled } = await createConfirmDialog(ConfirmCfgChangeModal, {
        title: 'The Portal is attempting to make the below automated pipeline configuration change(s). If you did not intend to make these changes, click Cancel below',
        oldString: stableYAMLStringify(getPipelineConfig.value || {}),
        newString: stableYAMLStringify(changedConfig),
      }).reveal()

      if (!isCanceled) {
        const jsonData = parse(data)
        store.commit('pipeline/updatePipelineConfig', jsonData)
        await store.dispatch('pipeline/updatePipeline')
      }

      diffIsOpened = false
    }
  }

  if (!readOnly && useGlobalState && !initialized) {
    initialized = true
  }

  watch(getPipelineModel, async (value, oldValue) => {
    if (value && value.name === oldValue?.name) {
      await syncUsecaseList()
    }
  }, { immediate: true })

  return {
    getPipelineModel,
    getPipelineConfig,
    getPipelinePreprocessConfig,
    getPipelineFeatureConfig,
    getPipelineValidationEmail,
    getValidationEmailData,
    dataAssetWrappers,
    addOrUpdateDataAsset,
    dropColumns,
    dataJoiningList,
    transformationList,
    joinedDataAssetColumns,
    joinedDataAssetColumnsOnSelectedJoining,
    processedAssetColumnsOnSelectedJoining,
    renameColumns,
    descriptionColumns,
    primaryKeyColumns,
    partitionColumn,
    currentDataAssetWrapper,
    currentDataAssetKey: _currentDataAssetKey,
    immutableAsset,
    filteringFeatureAssetsForCurrentAsset,
    getDataAsset,
    getAssetColumn,
    processedAssetColumns,
    processedDataAssetRowsFromBQMap,
    processedColumnsSelectOptions,
    processedJoinedTransformedColumnNameSelectOptions,
    loadProcessedDataRows,
    loadAllTypeProcessedDataRows,
    assetSelectOptions,
    getPipelineUsecasesArray,
    updateCustomerPopulation,
    customerPopulation,
    joinedAssetRows,
    transformedAssetRows,
    preprocessedAssetRows,
    processedAssetColumnsAfterJoiningAndTransform,
    processedJoinedTransformedColumnsSelectOptions,
    areRowsLoading,
    getLatestDataProcessingType,
    getLatestProcessedData,
  }
}

export type PipelineConfigReactive = ReturnType<typeof usePipelineConfig>

export function useAssetFieldsMetadata(useGlobalState: boolean = true) {
  const _currentDataAssetKey: Ref<string | undefined> = useGlobalState ? globalCurrentDataAssetKey : ref<string | undefined>()

  const getPipelineMetadata = computed<MetadataModel>(() => store.getters['pipeline/pipeline']?.metadata)

  const immutableMetadata: ComputedRef<AssetMetadataModel | null> = computed<AssetMetadataModel | null>(() => {
    if (!_currentDataAssetKey.value) { return null }
    return getPipelineMetadata.value.assets[_currentDataAssetKey.value]
  })

  const fieldsMetadata: ComputedRef<AssetFieldMetadataModel[] | undefined> = computed<AssetFieldMetadataModel[] | undefined>(
    () => {
      return getPipelineMetadata.value?.assets?.[_currentDataAssetKey.value as string]?.columns
    },
  )

  const getMetaDataByAssetName = (asset: string) => {
    return getPipelineMetadata.value?.assets?.[asset]
  }

  const getMetaDataColumnsByAssetName = (asset: string) => {
    return getMetaDataByAssetName(asset)?.columns
  }

  return {
    currentDataAssetKey: _currentDataAssetKey,
    getPipelineMetadata,
    fieldsMetadata,
    immutableMetadata,
    getMetaDataByAssetName,
    getMetaDataColumnsByAssetName,
  }
}

export type AssetFieldsMetadataReactive = ReturnType<typeof useAssetFieldsMetadata>
