<template>
  <div :class="[!$attrs.class?.includes('w-') ? 'w-full' : '']">
    <span v-if="label" class="flex">
      <label :for="id" class="block font-medium mb-1"> {{ label }}<sup v-show="required">*</sup></label>
      <TooltipSpan
        v-if="tooltipContent"
        :tooltipContent="typeof tooltipContent === 'string' ? { text: tooltipContent } as TooltipContent : tooltipContent"
        hasIcon
      />
    </span>

    <NDynamicTags :name="id" :id="id" v-model:value="localValue" :render-tag="renderTag">
      <template #trigger="{ activate, disabled }">
        <NButton v-if="!globalReadOnlyMode" size="medium" type="primary" dashed :disabled="disabled" @click="activate()">
          <template #icon>
            <PlusIcon />
          </template>
          {{ newPlaceholder }}
        </NButton>
      </template>
    </NDynamicTags>

    <span v-if="error" class="flex items-center pointer-events-none">
      <span class="text-red-500 ml-1 text-sm">{{ error }}</span>
    </span>
  </div>
</template>

<script lang="ts">
import { PlusIcon } from '@heroicons/vue/24/solid'
import debounce from 'lodash/debounce'
import { NTag } from 'naive-ui'
import TooltipSpan from '@shared/components/basic/TooltipSpan.vue'

import { TooltipContent } from '@assets/help/tooltips'
import { REQUEST_DEBOUNCE_DELAY } from '@shared/data/constants'
import { useStore } from '@app/store'
import { NullString } from '@shared/utils/Types'
import { trackEvent } from '@shared/utils/analytics'
import { stringContainsOnlyDigits } from '@shared/utils/helpers'

export default defineComponent({
  name: 'TagInput',
  components: { PlusIcon, TooltipSpan },
  props: {
    tooltipContent: [String, Object] as PropType<TooltipContent | string>,
    placeholder: String as PropType<NullString>,
    modelValue: Array as PropType<any[]>,
    id: String as PropType<NullString>,
    label: String as PropType<NullString>,
    error: String as PropType<NullString>,
    onlyNumber: Boolean as PropType<boolean>,
    newPlaceholder: {
      type: String as PropType<NullString>,
      default: () => 'Add'
    },
    required: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const store = useStore()

    const localValue = computed({
      get: () => props.modelValue,
      set: (value) => {
        context.emit('update:modelValue', value)
        debouncedFieldChange()
      }
    })

    const status = computed(() => {
      if (props.error) {
        return 'error'
      }
      return ''
    })

    const renderTag = (tag: string) => {
      return h(
        NTag,
        {
          size: 'large',
          type: validate(tag.toString()) ? 'default' : 'error',
          closable: !store.getters.isReadonlyMode,
          onClose: () => {
            localValue.value = localValue.value?.filter((item: string | number) => item.toString() !== tag.toString())
          }
        },
        { default: () => tag.toString() }
      )
    }

    const validate = (tagValue: string) => {
      if ((localValue.value || []).filter((item) => item.toString() === tagValue.toString()).length > 1) {
        return false
      }

      return props.onlyNumber ? stringContainsOnlyDigits(tagValue.toString() || '0') : true
    }

    const debouncedFieldChange = debounce(() => {
      if (props.id) {
        trackEvent(props.id, { type: 'tag-input', action: (localValue.value || '') !== '' ? 'change' : 'clear' })
      }
    }, REQUEST_DEBOUNCE_DELAY)

    return {
      renderTag,
      localValue,
      status,
      globalReadOnlyMode: computed(() => store.getters.isReadonlyMode)
    }
  }
})
</script>
<style scoped></style>
