import AppSettings from '@app/AppSettings'
import { UNAVAILABLE_ROUTE_PATH } from '@shared/data/constants'
import { debug } from '@shared/utils/logger'
import type { Router } from 'vue-router'
import AuthService from '@/auth/services/AuthService'
import { usePermissions } from '@/auth/composables/usePermissions'

export function defineMiddleware(router: Router) {
  router.beforeEach((to) => {
    debug('Middleware: isAuthenticated')
    const { isUserAuthenticated } = usePermissions()
    if (!isUserAuthenticated.value && !to.meta.public) {
      const { redirectRouteAfterRecover } = AppSettings
      if (!to.fullPath.endsWith(UNAVAILABLE_ROUTE_PATH)) {
        redirectRouteAfterRecover.value = to.fullPath
      }
      debug('Middleware: isAuthenticated -> getSignInLocation')
      window.location.href = AuthService.getSignInLocation()
      return false
    }
  })
}
