import { AxiosResponse } from 'axios'

import { DEFAULT_SAMPLE_DATA_ROWS_LIMIT } from '@shared/data/constants'
import { FeatureStatisticsModel } from '@/data-assets/models/FeatureStatisticsModel'
import { store } from '@app/store'
import { getQueryString } from '@shared/utils/helpers'
import http from '@app/utils/http-client'

export default class BigQueryService {
  static GET_PROCESSED_DATA_URL: string = '/query_processed_data'
  static GET_TRANSFORMED_DATA_URL: string = '/query_transformed_data'
  static GET_JOINED_DATA_URL: string = '/query_joined_data'
  static GET_FEATURES_STATISTICS_DATA_URL: string = '/query_feature_summary'
  static GET_FEATURES_DATA_URL: string = '/query_features'

  static async getProcessedDataRows(assetName: string, config?: undefined | { limit: number }): Promise<string[][]> {
    const queryParams = {
      client: store.getters['client/client'].name.toLowerCase(),
      asset: assetName,
      limit: config?.limit || DEFAULT_SAMPLE_DATA_ROWS_LIMIT
    }

    const response = await http.get(`${BigQueryService.GET_PROCESSED_DATA_URL}?${getQueryString(queryParams)}`)

    return response?.data?.data || []
  }

  static async getJoinedDataRows(assetName: string, config?: undefined | { limit: number }): Promise<string[][]> {
    const queryParams = {
      client: store.getters['client/client'].name.toLowerCase(),
      asset: assetName,
      limit: config?.limit || DEFAULT_SAMPLE_DATA_ROWS_LIMIT
    }

    const response = await http.get(`${BigQueryService.GET_JOINED_DATA_URL}?${getQueryString(queryParams)}`)

    return response?.data?.data || []
  }

  static async getTransformationDataRows(assetName: string, config?: undefined | { limit: number }): Promise<string[][]> {
    const queryParams = {
      client: store.getters['client/client'].name.toLowerCase(),
      asset: assetName,
      limit: config?.limit || DEFAULT_SAMPLE_DATA_ROWS_LIMIT
    }

    const response = await http.get(`${BigQueryService.GET_TRANSFORMED_DATA_URL}?${getQueryString(queryParams)}`)

    return response?.data?.data || []
  }

  static async getFeaturesStatisticsData(): Promise<FeatureStatisticsModel[]> {
    const queryParams = {
      client: store.getters['client/client'].name.toLowerCase(),
      pipeline: store.getters['pipeline/pipeline']?.name.toLowerCase(),
      product_env: 'draft',
    }

    const response = await http.get(`${BigQueryService.GET_FEATURES_STATISTICS_DATA_URL}?${getQueryString(queryParams)}`)

    return response?.data?.data || []
  }

  static async getFeaturesData(config?: undefined | { limit: number }): Promise<string[][]> {
    const queryParams = {
      client: store.getters['client/client'].name.toLowerCase(),
      limit: config?.limit || 100,
      pipeline: store.getters['pipeline/pipeline']?.name.toLowerCase(),
      product_env: 'draft',
    }

    const response = await http.get(`${BigQueryService.GET_FEATURES_DATA_URL}?${getQueryString(queryParams)}`)

    return response?.data?.data || []
  }
}
