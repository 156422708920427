import { store } from '@app/store'
import posthogPlugin from '@plugins/posthog'
import { initSentry } from '@plugins/sentry'
import router from '@router/index'
import type { App } from 'vue'
import VueGtag, { set } from 'vue-gtag'

export function install({ app }: { app: App }) {
  const userName: string = store.getters['user/username']
  const userEmail: string = store.getters['user/email']
  const userId: string = store.getters['user/id']
  const userRole: string = store.getters['user/role']?.type?.toUpperCase()
  const client: string = store.getters['client/client']?.displayName || store.getters['client/client']?.name
  const isReadOnly: boolean = store.getters['user/is_read_only'] || false

  if (import.meta.env.VITE_SENTRY_DSN) {
    initSentry(app, router, userName, userEmail, userId, userRole, client, isReadOnly)
  }

  if (import.meta.env.VITE_APP_ENVIRONMENT === 'production') {
    app.use(
      VueGtag,
      {
        config: {
          id: 'G-FVXXT1TYQT',
        },
        pageTrackerScreenviewEnabled: true,
        appName: 'The Portal',
        onReady() {
          set({
            user_id: userId,
          })
        },
      },
      router,
    )
  }

  if (!!import.meta.env.VITE_POSTHOG_API_KEY && !!import.meta.env.VITE_POSTHOG_API_HOST) {
    app.use(posthogPlugin)
  }
  else {
    // eslint-disable-next-line no-console
    console.debug('Posthog: disabled in environment, intended actions will only be logged')
  }
}
