<script lang="ts">
import { ExclamationCircleIcon } from '@heroicons/vue/24/solid'
import debounce from 'lodash/debounce'
import { colors } from '@theme'
import TooltipSpan from '@shared/components/basic/TooltipSpan.vue'

import { REQUEST_DEBOUNCE_DELAY } from '@shared/data/constants'
import { xssPlugin } from '@shared/utils/xss'
import { useStore } from '@app/store'
import type { NullString } from '@shared/utils/Types'
import { trackEvent } from '@shared/utils/analytics'
import type { TooltipContent } from '@assets/help/tooltips'

export default defineComponent({
  name: 'SimpleTextarea',
  components: {
    TooltipSpan,
    ExclamationCircleIcon,
  },
  props: {
    id: String as PropType<string>,
    placeholder: String as PropType<NullString>,
    modelValue: [String, Number] as PropType<NullString | number>,
    label: String as PropType<string>,
    tooltipContent: [String, Object] as PropType<TooltipContent | string>,
    error: String as PropType<NullString>,
    rows: {
      type: Number as PropType<number>,
      default: 3,
    },
    required: Boolean as PropType<boolean>,
    disabled: Boolean as PropType<boolean>,
    autocomplete: Boolean as PropType<boolean>,
    errorIconEnabled: Boolean as PropType<boolean>,
    errorIsAbsolutPositioned: Boolean as PropType<boolean>,
    autoselect: Boolean as PropType<boolean>,
  },
  emits: ['update:modelValue', 'blur'],
  setup(props, context) {
    const store = useStore()
    const inputRef: Ref = ref<any>(null)
    const localValue = computed({
      get: () => props.modelValue,
      set: (value) => {
        context.emit('update:modelValue', typeof props.modelValue === 'number' ? Number(value) : value)
        debouncedFieldChange()
      },
    })
    const focus = () => {
      inputRef.value?.focus?.()
    }

    const inputSelectHandler = ($event: Event) => {
      if (props.autoselect) {
        ;($event.target as HTMLInputElement)?.select()
      }
    }

    const debouncedFieldChange = debounce(() => {
      if (props.id) { trackEvent(props.id, { type: 'textarea', action: localValue.value !== '' ? 'change' : 'clear' }) }
    }, REQUEST_DEBOUNCE_DELAY)

    const textAreaDisabled = computed<boolean>(() => props.disabled || store.getters.isReadonlyMode)

    const additionalAttrs = computed(() => {
      const attrs = {} as any

      if (textAreaDisabled.value) { attrs.disabled = 'disabled' }

      return attrs
    })

    const railStyle = ({ checked }: { focused: boolean; checked: boolean }) => {
      const style: CSSProperties = {}
      if (checked) { style.background = colors.brand.purple.light }
      else { style.background = colors.brand.gray }

      return style
    }

    return {
      railStyle,
      inputRef,
      inputSelectHandler,
      focus,
      localValue,
      xssPlugin,
      additionalAttrs,
      textAreaDisabled,
      globalReadOnlyMode: computed(() => store.getters.isReadonlyMode),
    }
  },
})
</script>

<template>
  <div>
    <div class="item-center flex justify-between">
      <div class="item-center flex">
        <label v-if="label" :for="id" class="mb-1 block font-medium"> {{ label }}<sup v-show="required">*</sup></label>
        <TooltipSpan
          v-if="tooltipContent"
          :tooltipContent="typeof tooltipContent === 'string' ? { text: tooltipContent } as TooltipContent : tooltipContent"
          hasIcon
        />
      </div>
    </div>
    <div class="relative rounded-md shadow-sm">
      <textarea
        :id="id"
        ref="inputRef"
        v-model="localValue"
        class="block w-full border border-gray-300 rounded-md p-3 text-base shadow-sm focus:outline-0"
        spellcheck="false"
        :name="id"
        :rows="rows"
        :autocomplete="autocomplete ? id : 'off'"
        :placeholder="placeholder"
        :class="[
          textAreaDisabled ? 'bg-gray-50 cursor-not-allowed' : '',
          error
            ? 'border-offerfit-red text-offerfit-red placeholder-offerfit-red focus:ring-offerfit-red focus:border-offerfit-red'
            : '',
          !(error || textAreaDisabled)
            ? 'hover:border-offerfit-purple hover:shadow-in2 border-gray-300 placeholder-gray-300 focus:ring-offerfit-bright-purple focus:border-offerfit-bright-purple'
            : '',
          errorIconEnabled ? 'pr-8' : '',
        ]"
        :aria-invalid="error ? 'true' : 'false'"
        :aria-describedby="`${id}-error`"
        v-bind="additionalAttrs"
        @focus="inputSelectHandler($event)"
        @blur="$emit('blur')"
      />
      <div v-if="error && errorIconEnabled" class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
        <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
      </div>
    </div>
    <p
      v-if="error"
      :id="`${id}-error`"
      class="mt-1.5 rounded bg-white p-0.5 text-sm text-red-600"
      :class="{ absolute: errorIsAbsolutPositioned }"
      nosem
      v-html="xssPlugin.process(error)"
    ></p>
  </div>
</template>

<style scoped></style>
