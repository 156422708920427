<template>
  <div class="flex font-semibold">
    <div v-if="isActivationEventMissing">
      <n-tooltip trigger="hover">
        <template #trigger>
          <span class="text-red-500 cursor-pointer">?</span>
        </template>
        An activation event is required.
      </n-tooltip>
      <span class="m-1">-></span>
    </div>
    <div v-else>{{ sequence.activation }} <span class="m-1">-></span></div>
    <div v-if="!areEngagementEventsMissing" class="flex">
      <div v-for="(item, idx) in sequence?.engagement" :key="idx">
        {{ eventHasBeenCreated(item) ? getLastWord(item) : '' }}<span v-if="eventHasBeenCreated(item)" class="m-1">-></span>
      </div>
    </div>
    <div v-else>?<span class="m-1">-></span></div>
    <div>{{ eventHasBeenCreated(sequence.penalty) ? getLastWord(sequence.penalty) : '?' }}<span class="m-1">-></span></div>
    <div>{{ eventHasBeenCreated(ConversionEvent.CONVERSION) || '?' }}</div>
  </div>
</template>

<script lang="ts">
import { EventChannel, ChannelEvent, ConversionEvent } from '@shared/data/constants'
import { useUseCaseConfig } from '@/data-assets/utils/config'
import { useEvents } from '@/usecases/composables/useEvents'
import { EventsTableSequence } from '@/usecases/models/EventsModel'
import { getLastWord } from '@shared/utils/transformHelpers'

export default defineComponent({
  name: 'EventSequenceRender',
  props: {
    channel: String as PropType<EventChannel>,
    sequence: Object as PropType<EventsTableSequence>
  },
  setup(props) {
    const { createdEventsList } = useUseCaseConfig()
    const { eventHasBeenCreated } = useEvents()

    const areEngagementEventsMissing = computed<boolean>(() => {
      return props.sequence?.engagement?.filter((item: ChannelEvent) => createdEventsList.value?.includes(item)).length === 0
    })

    // TODO: very confusing logic that includes check for the last word. Please describe what's behind that
    const isActivationEventMissing = computed<boolean>(() => {
      return eventHasBeenCreated(props.sequence?.activation) ? !getLastWord(props.sequence?.activation || '') : false
    })

    return {
      isActivationEventMissing,
      areEngagementEventsMissing,
      eventHasBeenCreated,
      getLastWord,
      ConversionEvent
    }
  }
})
</script>
