import type { Store } from 'vuex'
import { useStore as baseUseStore, createLogger, createStore } from 'vuex'

import type { AppState } from '@app/store/app'
import { appActions, appGetters, appMutations, appState } from '@app/store/app'
import { pipeline } from '@/pipelines/store'
import { client } from '@/clients/store'

import { notifications } from '@/notifications/store'
import { user } from '@/users/store'

const debug = false

// define injection key
// eslint-disable-next-line symbol-description
export const key: InjectionKey<Store<AppState>> = Symbol()

export const store: Store<AppState> = createStore<AppState>({
  modules: {
    user,
    client,
    pipeline,
    notifications,
  },
  state: appState,
  getters: appGetters,
  mutations: appMutations,
  actions: appActions,
  strict: debug,
  plugins: debug ? [createLogger()] : [],
})

export function useStore(): Store<AppState> {
  return baseUseStore(key)
}
