import type { ActionContext, ActionTree } from 'vuex'

import type { AppState } from '@app/store/app'
import Global from '@shared/utils/global'
import UserNotificationsService from '@/notifications/services/UserNotificationsService'
import type { UserNotificationsStateType } from '@/notifications/store/state'

export const actions: ActionTree<UserNotificationsStateType, AppState> = {
  async getNotifications({ commit }: ActionContext<UserNotificationsStateType, AppState>) {
    try {
      const notificationsData = await UserNotificationsService.getAllUserNotifications()
      commit('setUserNotifications', notificationsData)
    }
    catch (e: any) {
      Global.error(e.message)
    }
  },

  async markNotificationAsRead({ state, commit }: ActionContext<UserNotificationsStateType, AppState>, notificationId: string) {
    try {
      const notification = await UserNotificationsService.markNotificationAsRead(notificationId)
      commit('setUserNotifications', state.notifications.map(n => (n.id === notification.id ? notification : n)))
    }
    catch (e: any) {
      Global.error(e.message)
    }
  },

  async markAllNotificationsAsRead({ dispatch }: ActionContext<UserNotificationsStateType, AppState>, client?: string) {
    try {
      await UserNotificationsService.markAllNotificationsAsRead(client)
      dispatch('getNotifications')
    }
    catch (e: any) {
      Global.error(e.message)
    }
  },

  async markNotificationAsArchived({ commit, state }: ActionContext<UserNotificationsStateType, AppState>, notificationId: string) {
    try {
      const notification = await UserNotificationsService.markNotificationAsArchived(notificationId)
      commit('setUserNotifications', state.notifications.filter(n => n.id !== notification.id))
    }
    catch (e: any) {
      Global.error(e.message)
    }
  },
}
