import { MetadataModel } from '@/pipelines/models/server/PipelineModel'

export const isModalOpened = ref<boolean>(false)
export const previousMetadata = ref<MetadataModel | undefined>()
export const nextMetadata = ref<MetadataModel | undefined>()
export const allowCallback = ref<() => Promise<void> | undefined>()
export const disallowCallback = ref<() => Promise<void> | undefined>()
export const useMetadataChangeModal = (
  oldMetadata: MetadataModel,
  newMetadata: MetadataModel,
  _allowCallback: () => Promise<void>,
  _disallowCallback?: () => Promise<void>
) => {
  isModalOpened.value = true
  previousMetadata.value = oldMetadata
  nextMetadata.value = newMetadata

  const allowCallbackWrapper = async () => {
    isModalOpened.value = false
    if (typeof _allowCallback === 'function') await _allowCallback()
  }

  const disallowCallbackWrapper = async () => {
    isModalOpened.value = false
    if (typeof _disallowCallback === 'function') await _disallowCallback()
  }

  allowCallback.value = allowCallbackWrapper
  disallowCallback.value = disallowCallbackWrapper
}
