<template>
  <div>
    <ExclamationTriangleIcon class="h-5 w-5" :style="`color: rgba(247, 101, 50, ${severity});`" />
  </div>
</template>

<script lang="ts">
import { ExclamationTriangleIcon } from '@heroicons/vue/24/solid'
export default defineComponent({
  name: 'SeverityRender',
  props: {
    severity: Number as PropType<number>
  },
  components: {
    ExclamationTriangleIcon
  },
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
