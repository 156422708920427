import Vers from 'vers'

export const LATEST_VERSION = 1

export const metadataVersioning = new Vers({
  latest: LATEST_VERSION
})

metadataVersioning.addConverter('metadata@0.1', 0.1, (targetObject: any) => {
  targetObject.version = 0.1
})
metadataVersioning.addConverter('metadata@1.0', 0.1, (targetObject: any) => {
  targetObject.version = 0.1
})

metadataVersioning.addConverter(0.1, 1, (targetObject: any) => {
  targetObject.version = 1

  const keys = Object.keys(targetObject.assets)
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    const assetObject = targetObject.assets[key]
    const columns = assetObject.columns

    for (let j = 0; j < columns.length; j++) {
      const column = columns[j] as any
      if (column.foreignKey !== undefined) delete column.foreignKey
      if (column.foreignKeyAssetColumn !== undefined) delete column.foreignKeyAssetColumn
      if (column.primaryKey !== undefined) delete column.primaryKey
      if (column.description !== undefined) delete column.description
      if (column.newColumnName !== undefined) delete column.newColumnName
      if (column.fillNA !== undefined) delete column.fillNA
      if (column.validation?.required !== undefined) delete column.validation?.required
    }
  }
})
