<script lang="ts">
import { useStore } from '@app/store'
import { trackEvent } from '@shared/utils/analytics'

export default defineComponent({
  name: 'SimpleButton',
  props: {
    id: String,
    primary: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    danger: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    nonFocusable: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    text: {
      type: String as PropType<string>,
      default: 'OK',
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    small: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    ignoreGlobalReadOnly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props) {
    const store = useStore()
    const disabledButtonClickHandler = () => {
      if (props.id)
        trackEvent(props.id, { type: 'button', action: 'click', enabled: false })
    }
    const buttonClickHandler = () => {
      if (props.id)
        trackEvent(props.id, { type: 'button', action: 'click', enabled: true })
    }

    return {
      disabledButtonClickHandler,
      buttonClickHandler,
      globalReadOnlyMode: computed(() => store.getters.isReadonlyMode),
    }
  },
})
</script>

<template>
  <NSpin :show="!!loading" size="small" class="inline-flex text-md print:hidden" @click.stop.prevent>
    <button
      v-if="disabled || loading || (globalReadOnlyMode && !ignoreGlobalReadOnly)"
      :id="id"
      disabled
      type="button"
      :tabindex="nonFocusable ? -1 : 0"
      :class="[small ? 'px-2 py-1' : 'px-4 py-2']"
      class="w-full inline-flex cursor-not-allowed items-center justify-center border border-transparent rounded-md bg-brand-gray leading-tight text-white shadow-sm hover:bg-gray-300 focus:outline-none"
      data-test="disabled-button"
      @click.stop.prevent="disabledButtonClickHandler"
    >
      {{ text }}
    </button>
    <button
      v-else-if="primary"
      :id="id"
      type="button"
      :tabindex="nonFocusable ? -1 : 0"
      :class="[small ? 'px-2 py-1' : 'px-4 py-2']"
      class="w-full inline-flex items-center justify-center border border-transparent rounded-md bg-brand-purple-bright leading-tight text-white shadow-sm hover:bg-brand-purple-light focus:outline-none"
      data-test="primary-button"
      @click="buttonClickHandler"
    >
      {{ text }}
    </button>
    <button
      v-else-if="danger"
      :id="id"
      type="button"
      :tabindex="nonFocusable ? -1 : 0"
      :class="[small ? 'px-2 py-1' : 'px-4 py-2']"
      class="w-full inline-flex items-center justify-center border border-transparent rounded-md bg-error text-md leading-tight text-white shadow-sm sm:w-auto hover:bg-red-700 focus:outline-none"
      data-test="danger-button"
      @click="buttonClickHandler"
    >
      {{ text }}
    </button>
    <button
      v-else
      :id="id"
      type="button"
      :tabindex="nonFocusable ? -1 : 0"
      :class="[small ? 'px-2 py-1' : 'px-4 py-2']"
      class="w-full inline-flex items-center justify-center border border-brand-black rounded-md bg-white text-md leading-tight text-brand-black shadow-sm hover:bg-gray-100 focus:outline-none"
      data-test="secondary-button"
      @click="buttonClickHandler"
    >
      {{ text }}
    </button>
  </NSpin>
</template>
