import type { RenderLabel } from 'naive-ui/lib/_internal/select-menu/src/interface'
import { DataType } from '@shared/data/constants'
import { STANDARD_ASSET_COLUMNS_TYPE_MAP } from '@shared/data/mapAndLists'
import type { ColumnDataType, ColumnDataTypeMap, DataAssetWrapper, SelectItem } from '@shared/utils/Types'
import { AssetColumn } from '@shared/utils/Types'
import { isFiniteNumber } from '@shared/utils/helpers'
import { getStandardizedString } from '@shared/utils/transformHelpers'
import { columnNameAndTypeRender } from '@shared/utils/renderHelpers'
import { useAssetFieldsMetadata, usePipelineConfig } from '@/pipelines/composables/usePipelineConfig'
import type { JoinAssetConfigModel } from '@/data-assets/models/DataJoiningModel'
import type { TransformationConfigModel } from '@/data-assets/models/TransformationConfigModel'
import { TransformationType } from '@/data-assets/models/TransformationConfigModel'
import type { AssetFieldMetadataModel } from '@/data-assets/models/server/AssetMetadataModel'

export function useColumnDataTypeMap() {
  const { getMetaDataColumnsByAssetName } = useAssetFieldsMetadata()
  const { dataAssetWrappers, currentDataAssetKey, renameColumns } = usePipelineConfig()

  const assetColumnDataTypeMap = computed<ColumnDataTypeMap>(() => {
    const mapping: ColumnDataTypeMap = new Map<string, ColumnDataType[]>()

    dataAssetWrappers.value.forEach((assetWrapper: DataAssetWrapper) => {
      // get column types for original columns from metadata
      let existingColumnsTypes: [ColumnDataType[]] | ColumnDataType[] = toRaw(getMetaDataColumnsByAssetName(assetWrapper.key) || []).map(
        (item: AssetFieldMetadataModel) => {
          const rawColumnDataType = {
            name: item.name,
            dataType: item.validation?.type || STANDARD_ASSET_COLUMNS_TYPE_MAP.get(item.name) || DataType.STRING,
          } as ColumnDataType
          // Add a record for the renamed column/datatype pairing if column is renamed
          if (Object.keys(renameColumns.value || []).includes(item.name)) {
            const mappedColumnDataType = {
              name: renameColumns.value[item.name],
              dataType: rawColumnDataType.dataType,
            } as ColumnDataType
            return [rawColumnDataType, mappedColumnDataType]
          }
          return rawColumnDataType
        },
      )
      existingColumnsTypes = existingColumnsTypes.flat()

      // get types from joined columns
      const joinAssetColumnsTypeMap: ColumnDataType[] = []
      toRaw(assetWrapper.asset?.asset_joining)?.forEach((join: JoinAssetConfigModel) => {
        join.keep_fields?.forEach((field: string) => {
          const matchItem = toRaw(getMetaDataColumnsByAssetName(join.asset) || []).find(
            (item: AssetFieldMetadataModel) => item.name === field,
          )

          if (matchItem) {
            const mapping: ColumnDataType = {
              name: matchItem.name,
              dataType: matchItem.validation?.type || STANDARD_ASSET_COLUMNS_TYPE_MAP.get(matchItem.name) || DataType.STRING,
            }
            joinAssetColumnsTypeMap.push(mapping)
          }
        })
      })

      // get types for transform columns
      const transformedColumnsTypeMap: ColumnDataType[] = []
      toRaw(
        assetWrapper.asset?.transform?.transforms?.forEach((transform: TransformationConfigModel) => {
          returnTransformColumnAndType(transform).forEach((item: any) => transformedColumnsTypeMap.push(item))
        }),
      )

      const targetArray = existingColumnsTypes.concat(joinAssetColumnsTypeMap).concat(transformedColumnsTypeMap)
      mapping.set(assetWrapper.key, targetArray)
    })

    return mapping
  })

  const returnTransformColumnAndType = (transform: TransformationConfigModel): ColumnDataType[] => {
    const arr: any = []
    transform.destination_columns.forEach((col: string) => {
      arr.push({
        name: col,
        dataType: getTransformColumnDataType(transform),
      })
    })
    return arr
  }

  const getTransformColumnDataType = (transform: TransformationConfigModel): string => {
    const numeric: Array<string> = [
      TransformationType.SUM,
      TransformationType.MULTIPLY,
      TransformationType.DIVIDE,
      TransformationType.MAX,
      TransformationType.MIN,
    ]
    if (numeric.includes(transform.transform)) {
      return DataType.FLOAT
    }
    if (transform.transform === TransformationType.TYPE_CAST) {
      return transform.arguments?.type as string
    }
    if (transform.transform === TransformationType.FILL_NA) {
      if (isFiniteNumber(transform.arguments?.value as string)) {
        return Number.isInteger(Number.parseFloat(transform.arguments?.value as string)) ? DataType.INTEGER : DataType.FLOAT
      }
      return DataType.STRING
    }
    return DataType.STRING
  }

  const getColumnDataType = (assetColumn: AssetColumn): DataType => {
    return (
      assetColumnDataTypeMap.value
        ?.get(assetColumn.data.asset)
        ?.find((item: ColumnDataType) =>
          item.name === assetColumn.data.column || getStandardizedString(item.name) === assetColumn.data.column,
        )?.dataType
        || STANDARD_ASSET_COLUMNS_TYPE_MAP.get(assetColumn.data.column)
        || DataType.STRING
    )
  }

  const returnColumnDataTypeRenderLabel: RenderLabel = (
    option: SelectItem<string> | SelectItem<AssetColumn>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    selected: boolean = false,
  ) => {
    const asset = (option.value as AssetColumn).data
      ? (option.value as AssetColumn).data.asset
      : (currentDataAssetKey.value as string)

    const value: string = (option.value as AssetColumn).data
      ? ((option.value as AssetColumn).data.column)
      : (option.value as string)

    const type = getColumnDataType(new AssetColumn({ asset, column: value }))

    return value ? columnNameAndTypeRender(value, type) : ''
  }

  return {
    returnColumnDataTypeRenderLabel,
    getColumnDataType,
  }
}

export type ColumnDataTypeMapReactive = ReturnType<typeof useColumnDataTypeMap>
