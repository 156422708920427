<script setup lang="tsx">
import { useWizardNavigation } from '@/level4/composables/useWizardNavigation'
import { Level4Module } from '@/level4/data/forms'

const wizardNavigation = useWizardNavigation()

const stepsGroupedBy: ComputedRef<'experimenter' | 'test-emails'> = computed(() => {
  const currentModule = wizardNavigation.currentModule.value as Level4Module

  const experimenterFlow = [
    Level4Module.TYPE,
    Level4Module.AUDIENCE,
    Level4Module.BRAZE,
    Level4Module.CREATE_VARIANTS,
    Level4Module.DAYS_OF_WEEK,
    Level4Module.BAU,
    Level4Module.FREQUENCY,
    Level4Module.INTEGRATIONS,
    Level4Module.USE_CREDENTIALS,
    Level4Module.KLAVIYO,
    Level4Module.LAUNCH_KLAVIYO,
    Level4Module.LAUNCH_SFMC,
    Level4Module.SFMC,
    Level4Module.TEMPLATES,
    Level4Module.TEMPLATES_SELECTION,
    Level4Module.TIME,
    Level4Module.GUARDRAILS,
  ]

  if (experimenterFlow.includes(currentModule)) {
    return 'experimenter'
  }
  if (currentModule === Level4Module.TEST_EMAILS) {
    return 'test-emails'
  }
})
</script>

<template>
  <div class="wizard-forms-group-mini-map flex">
    <div class="flex flex-wrap cursor-default gap-x-1 gap-y-3 rounded-full p-1 px-3">
      <ExperimenterMinimap v-if="stepsGroupedBy === 'experimenter'" />
      <TestEmailsMinimap v-else />
    </div>
  </div>
</template>
