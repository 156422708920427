<template>
  <div class="flex ml-2">
    <img
      v-if="deployment?.reviewer?.picture"
      class="h-6 w-6 rounded-full"
      :src="deployment?.reviewer?.picture"
      alt=""
    />
    <UserIcon v-else class="w-5 h-5 font-semibold text-offerfit-bright-purple ml-2 mt-0.5" />
    <span class="ml-2 text-offerfit-bright-purple"
      >Reviewer: {{ deployment?.reviewer?.username || 'No reviewer assigned' }}</span
    >
    <EnvelopeIcon
      v-if="deployment?.reviewer?.email && !copied"
      @click="copyEmailAddress(deployment?.reviewer?.email || 'Email not found')"
      class="w-5 h-5 font-semibold text-offerfit-bright-purple ml-2 mt-0.5 cursor-pointer"
    />
    <div v-else-if="copied">
      <ClipboardDocumentCheckIcon class="w-5 h-5 font-semibold text-offerfit-bright-purple ml-2 mt-0.5" />
    </div>
  </div>
  <!--  <div-->
  <!--    v-if="!deployment?.reviewer?.email && isAIEorAdmin"-->
  <!--    @click="addReviewerClickHandler"-->
  <!--    class="ml-4 mt-1 flex font-semibold text-offerfit-bright-purple cursor-pointer"-->
  <!--  >-->
  <!--    + Add reviewer-->
  <!--  </div>-->
</template>

<script lang="ts">
import { UserIcon } from '@heroicons/vue/24/outline'
import { EnvelopeIcon, ClipboardDocumentCheckIcon } from '@heroicons/vue/24/solid'
import { usePermissions } from '@/auth/composables/usePermissions'
import { DeploymentsTableModel } from '@/deployments/models/server/DeploymentModel'

export default defineComponent({
  name: 'ReviewerRender',
  components: { EnvelopeIcon, UserIcon, ClipboardDocumentCheckIcon },
  props: {
    deployment: {
      type: Object as PropType<DeploymentsTableModel>
    }
  },
  emits: ['openReviewModal'],
  setup(props, { emit }) {
    const { isAIEorAdmin } = usePermissions()
    const copied: Ref<boolean> = ref<boolean>(false)
    const copyEmailAddress = async (email: string) => {
      try {
        await navigator.clipboard.writeText(email)
        copied.value = true
        setTimeout(() => {
          copied.value = false
        }, 3000)
      } catch (e) {
        alert('Unable to copy OfferFit reviewer email address to clipboard.')
      }
    }

    const addReviewerClickHandler = () => {
      emit('openReviewModal', props.deployment)
    }

    return {
      copied,
      isAIEorAdmin,
      copyEmailAddress,
      addReviewerClickHandler
    }
  }
})
</script>


