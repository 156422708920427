import type { ActionContext, ActionTree } from 'vuex'

import type { AppState } from '@app/store/app'
import Global from '@shared/utils/global'
import type { UserModelPartial } from '@/users/models/server/UserModel'
import { getEmptyUser } from '@/users/models/server/UserModel'
import UserService from '@/users/services/UserService'
import type { UserStateType } from '@/users/store/state'

export const actions: ActionTree<UserStateType, AppState> = {
  async getUser({ commit }: ActionContext<UserStateType, AppState>) {
    try {
      const userData = await UserService.getUserDetails()
      commit('setUserData', userData)
      commit('isUserAuthenticated', true, { root: true })
      return userData
    }
    catch (e: any) {
      Global.error(e.message)
      commit('isUserAuthenticated', false, { root: true })
    }
  },

  async updateUser({ rootGetters }: ActionContext<UserStateType, AppState>, data: UserModelPartial) {
    if (rootGetters.isReadonlyMode) {
      return
    }

    try {
      await UserService.updateUserDetails(data)
    }
    catch (e: any) {
      Global.error(e.message)
    }
  },

  async updateUserTOS(context: ActionContext<UserStateType, AppState>, data: UserModelPartial) {
    try {
      await UserService.updateUserDetails(data, true)
    }
    catch (e: any) {
      Global.error(e.message)
    }
  },

  resetUser({ commit }: ActionContext<UserStateType, AppState>) {
    commit('isUserAuthenticated', false, { root: true })
    commit('setUserData', getEmptyUser())
  },
}
