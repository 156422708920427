<script lang="ts">
import debounce from 'lodash/debounce'
import { ChangeStatus, REQUEST_DEBOUNCE_DELAY } from '@shared/data/constants'
import { useStore } from '@app/store'
import type { NullString } from '@shared/utils/Types'
import { trackEvent } from '@shared/utils/analytics'
import { isEmpty } from '@shared/utils/helpers'

export default defineComponent({
  name: 'TableEditableInput',
  props: {
    id: String as PropType<string>,
    type: String as PropType<string>,
    placeholder: String as PropType<NullString>,
    emptyPlaceholder: {
      type: String as PropType<NullString>,
      default: ' ',
    },
    modelValue: {
      type: [String, Number] as PropType<NullString | number>,
      default: '',
    },
    error: [String, Object] as PropType<NullString | Ref>,
    suffix: String as PropType<NullString>,
    changedStatus: String as PropType<ChangeStatus>,
    autocomplete: Boolean as PropType<boolean>,
    showInput: Boolean as PropType<boolean>,
    numeric: Boolean as PropType<boolean>,
    ellipsis: Boolean as PropType<boolean>,
    maximumValue: Number as PropType<number>,
    multiplier: {
      type: Number as PropType<number>,
      default: 1,
    },
    underlined: Boolean as PropType<boolean>,
    smallText: Boolean as PropType<boolean>,
    overflowHidden: {
      type: Boolean as PropType<boolean>,
      default: () => false,
    },
    standardized: Boolean as PropType<boolean>,
    size: {
      type: String as PropType<'tiny' | 'small' | 'medium' | 'large'>,
      default: 'small',
    },
  },
  emits: ['update:modelValue', 'blur', 'enter'],
  setup(props, context) {
    const store = useStore()
    const isEdit = ref(false)
    const inputRef = ref<any>(null)

    const localValue: Ref = ref<NullString | number>()

    if (props.numeric) {
      localValue.value = !isEmpty(props.modelValue) ? Math.round(Number(props.modelValue) * props.multiplier) : undefined
    }
    else {
      localValue.value = props.modelValue?.toString()
    }

    const valueTitle: ComputedRef<NullString> = computed<NullString>(() => {
      let titleString: NullString = `${localValue.value}${props.suffix ? props.suffix : ''}`

      const errorMessage: NullString = isRef(errorText) ? errorText.value : (errorText as string)

      if (errorMessage) {
        titleString = (localValue.value ? `${titleString} - ${errorMessage}` : errorMessage) as string
      }

      if (!localValue.value) {
        return 'No value'
      }

      return titleString
    })

    let ignoreLocalValueChange: boolean = false

    let unWatchModelValue: WatchStopHandle
    let unWatchLocalValue: WatchStopHandle

    onMounted(() => {
      unWatchModelValue = watch(
        () => [props.modelValue],
        () => {
          ignoreLocalValueChange = true

          if (props.numeric) {
            localValue.value = !isEmpty(props.modelValue) ? Math.round(Number(props.modelValue) * props.multiplier) : undefined
            if (!isEmpty(props.maximumValue)) {
              localValue.value = localValue.value > (props.maximumValue || 0) ? props.maximumValue : localValue.value
            }
          }
          else {
            localValue.value = props.modelValue?.toString()
          }

          nextTick(() => {
            ignoreLocalValueChange = false
          })
        },
      )

      unWatchLocalValue = watch(localValue, () => {
        if (ignoreLocalValueChange) { return }
        emitEventWithData('update:modelValue')
      })
    })

    onBeforeUnmount(() => {
      unWatchModelValue()
      unWatchLocalValue()
    })

    const clickHandler = () => {
      isEdit.value = true
      nextTick(() => {
        inputRef.value?.focus?.()
      })
    }

    const errorText: ComputedRef<NullString> = computed<NullString>(() => {
      return isRef(props.error) ? (props.error as Ref)?.value : props.error
    })

    const blurAndEmitData = () => {
      emitEventWithData('blur')
    }

    const enterAndEmitData = () => {
      emitEventWithData('enter')
    }

    const emitEventWithData = (eventType: 'enter' | 'blur' | 'update:modelValue') => {
      if (props.numeric) {
        context.emit(eventType, !isEmpty(localValue.value) ? Number(localValue.value) / props.multiplier : undefined)
      }
      else {
        context.emit(eventType, localValue.value?.toString())
      }

      debouncedFieldChange()
    }

    const debouncedFieldChange = debounce(() => {
      if (props.id) {
        trackEvent(props.id, { type: 'table-input', action: 'change', valueLength: localValue.value?.length || 0 })
      }
    }, REQUEST_DEBOUNCE_DELAY)

    const inputBlurCapturedHandler = () => {
      blurAndEmitData()
    }
    const enterKeyDownCaptureHandler = () => {
      enterAndEmitData()
    }

    const blurInput = () => {
      if (!errorText.value) {
        isEdit.value = false
      }
    }

    const inputBlurHandler = () => {
      blurInput()
    }
    const enterKeyDownHandler = () => {
      blurInput()
    }

    return {
      isEdit,
      inputRef,
      clickHandler,
      ChangeStatus,
      localValue,
      valueTitle,
      errorText,
      isEmpty,
      inputBlurHandler,
      enterKeyDownHandler,
      inputBlurCapturedHandler,
      enterKeyDownCaptureHandler,
      globalReadOnlyMode: computed(() => store.getters.isReadonlyMode),
    }
  },
})
</script>

<template>
  <div
    :class="[!($attrs.class as string)?.includes('w-') ? 'w-full' : '']"
    class="relative"
    @keydown.enter="enterKeyDownHandler"
    @keydown.enter.capture="enterKeyDownCaptureHandler"
  >
    <div
      v-if="!(isEdit || showInput) || globalReadOnlyMode"
      :title="valueTitle"
      style="padding-top: 3px; padding-left: 5px; padding-right: 3px"
      class="h-full w-full !inline-block"
      :class="{
        'cursor-pointer hover:bg-purple-100 rounded-md border-dashed border border-gray-300': !globalReadOnlyMode,
        'overflow-hidden': overflowHidden,
        'whitespace-nowrap text-ellipsis max-w-full': ellipsis,
        'underline': underlined,
        'text-error': !!errorText && (localValue || isNaN(localValue)),
        'text-gray-400': localValue === '' && !errorText,
        'text-red-200': localValue === '' && !!errorText,
        'bg-yellow-100 hover:bg-yellow-100': changedStatus === ChangeStatus.CHANGED,
        'bg-red-100 hover:bg-red-100': changedStatus === ChangeStatus.DELETED,
        'bg-green-100 hover:bg-green-100': changedStatus === ChangeStatus.ADDED,
      }"
      @click="clickHandler"
    >
      {{ !isEmpty(localValue) ? localValue : emptyPlaceholder }}{{ !isEmpty(localValue) && suffix ? suffix : '' }}
    </div>
    <BasicInput
      v-else
      :id="id"
      ref="inputRef"
      v-model="localValue"
      class="simple-input"
      type="text"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :error="errorText"
      :suffix="suffix"
      :onlyNumber="numeric"
      autoselect
      :size="size"
      errorIsAbsolutPositioned
      :standardized="standardized"
      :smallText="smallText"
      @blur="inputBlurHandler"
      @blur.capture="inputBlurCapturedHandler"
    />
  </div>
</template>

<style scoped>
:deep(.simple-input) input {
  @apply py-1;
}
</style>
