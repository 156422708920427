import AppSettings from '@app/AppSettings'
import UISettingsService from '@app/services/UISettingsService'
import { store } from '@app/store'
import router from '@router/index'
import {
  MAINTENANCE_ROUTE,
} from '@shared/data/constants'
import { usePermissions } from '@/auth/composables/usePermissions'

export async function appBeforeMountHook(): Promise<void> {
  // eslint-disable-next-line no-console
  console.info(`%cPortal v${PORTAL_VERSION}`, 'color: #F76532; font-size: 14px; font-weight: bold;')
  // eslint-disable-next-line no-console
  console.info(`%cEnvironment: ${import.meta.env.VITE_APP_ENVIRONMENT}`, 'color: #F76532; font-size: 14px; font-weight: bold;')
  // eslint-disable-next-line no-console
  console.info('%c-------------------------------------', 'color: #F76532')

  const { redirectRouteAfterRecover } = AppSettings

  const location = useBrowserLocation()
  const locationURL: string = (location.value?.pathname || '') + (location.value?.search || '') + location.value.hash

  try {
    const appSettings = await UISettingsService.getUISettings()
    if (appSettings?.maintenance_mode) {
      redirectRouteAfterRecover.value = locationURL
      await router.push({ name: MAINTENANCE_ROUTE })
      return
    }
  }
  catch (e: any) {
    console.warn('Bootstrap -> appSettings loading warning', e.message)
  }

  // set the host
  store.commit('host', location.value.host)

  // we should respect this flag for all create/change operations. It allows us to show the Portal during infrastructural issues/degradation.
  const { isReader } = usePermissions()

  store.commit(
    'isReadonlyMode',
    !!AppSettings?.READONLY_MODE || store.getters['user/is_read_only'] || store.getters['user/role'].type === isReader.value || store.getters.isReadonlyMode,
  )
}
