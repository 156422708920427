import { DataType, RUN_DATETIME, RUN_DATETIME_NAME } from '@shared/data/constants'
import { CUSTOMER_GROUPS_FULL_COMPARISON_OPERATORS_LIST } from '@shared/data/mapAndLists'
import { useUseCaseConfig } from '@/usecases/composables/useUseCaseConfig'
import { useColumnDataTypeMap } from '@/data-assets/composables/useColumnDataTypeMap'
import {
  CommonFilter,
  ComparatorName,
  CompareOperator,
  FilterCondition,
  FilterConfigMatchingExpression,
  FilterInputType,
  MatchingOperator,
  RootFilterExpression
} from '@/filtering/models/FilterModel'
import { ActionFieldModel } from '@/usecases/models/server/ActionModel'
import { AssetColumn, NullString, SelectItem } from '@shared/utils/Types'
import { getNewCommonFilter } from '@shared/utils/helpers'
import { slugString } from '@shared/utils/transformHelpers'

export const nodeIsOperator: (node: any) => boolean = (node: any) => {
  // eslint-disable-next-line no-prototype-builtins
  return node.hasOwnProperty('and') || node.hasOwnProperty('or')
}

export const getNodeOperator: (node: any) => MatchingOperator = (node: any): MatchingOperator => {
  return node.and ? ('and' as MatchingOperator) : ('or' as MatchingOperator)
}

export const getNodeExpression: (node: any) => any[] = (node: any) => {
  return node[getNodeOperator(node)]
}

export const getExpressionModelsFromArray = <ConfigMatchingExpression = FilterConfigMatchingExpression>(
  configExpressionArray: (ComparatorName | ConfigMatchingExpression)[] = [],
  comparators?: CommonFilter[]
): (CommonFilter | FilterCondition)[] => {
  const result: (CommonFilter | FilterCondition)[] = []
  for (let i = 0; i < configExpressionArray.length; i++) {
    const configExpressionElement = configExpressionArray[i] as ComparatorName | ConfigMatchingExpression
    if (nodeIsOperator(configExpressionArray[i])) {
      const configExpression: ConfigMatchingExpression | any = configExpressionArray[i] as ConfigMatchingExpression

      const key: MatchingOperator = getNodeOperator(configExpression)
      if (configExpression[key] && configExpression[key]?.length) {
        const newOperatorNode: FilterCondition = {
          [key]: getExpressionModelsFromArray<ConfigMatchingExpression>(configExpression[key] || [], comparators)
        } as FilterCondition

        result.push(newOperatorNode)
      }
    } else {
      const comparatorFilter: CommonFilter | undefined = comparators?.find(
        (filter: CommonFilter) => filter.id === configExpressionElement
      )
      if (comparatorFilter) {
        result.push(comparatorFilter)
      }
    }
  }

  return result.length ? result : [getNewCommonFilter()]
}

export const getComparatorsConfigModelsFromExpression = (
  levelArray: (CommonFilter | FilterCondition)[] | RootFilterExpression
): CommonFilter[] => {
  let result: CommonFilter[] = []

  for (let i = 0; i < levelArray.length; i++) {
    if (nodeIsOperator(levelArray[i])) {
      const logicalNodeElement = levelArray[i] as FilterCondition
      const key: MatchingOperator = getNodeOperator(logicalNodeElement)
      result = result.concat(
        getComparatorsConfigModelsFromExpression(logicalNodeElement[key] as (CommonFilter | FilterCondition)[])
      )
    } else {
      const commonFilter: CommonFilter = levelArray[i] as CommonFilter
      result.push({
        id: commonFilter.id,
        colname: commonFilter.colname,
        compare_op: commonFilter.compare_op,
        compare_val: commonFilter.compare_val
      } as CommonFilter)
    }
  }

  return result
}

export const convertModelExpressionToConfigExpression = <ConfigMatchingExpression, RootConfigExpression>(
  levelArray: (CommonFilter | FilterCondition)[] | RootFilterExpression
): (ComparatorName | ConfigMatchingExpression)[] | RootConfigExpression => {
  const result: (ComparatorName | ConfigMatchingExpression)[] | RootConfigExpression = []

  for (let i = 0; i < levelArray.length; i++) {
    const levelArrayElement = levelArray[i]

    if (nodeIsOperator(levelArrayElement)) {
      const key: MatchingOperator = getNodeOperator(levelArrayElement as FilterCondition)
      result.push({
        [key]: convertModelExpressionToConfigExpression<ConfigMatchingExpression, RootConfigExpression>(
          (levelArrayElement as FilterCondition)[key] as (CommonFilter | FilterCondition)[]
        )
      } as any)
    } else {
      result.push((levelArrayElement as CommonFilter).id as ComparatorName)
    }
  }

  return result
}

export const valueListGetter = (item: AssetColumn | ActionFieldModel): SelectItem[] => {
  const { getActionParameterValues } = useUseCaseConfig()
  const { getColumnDataType } = useColumnDataTypeMap()

  if (item instanceof ActionFieldModel) {
    return getActionParameterValues(item.data.column).map((value: NullString) => {
      return { value: value, label: value } as SelectItem
    })
  }

  if (
    item instanceof AssetColumn &&
    (getColumnDataType(item) === DataType.DATETIME || getColumnDataType(item) === DataType.DATE)
  ) {
    return [{ value: RUN_DATETIME, label: RUN_DATETIME_NAME } as SelectItem]
  }

  return []
}

export const getDescriptiveVersionOfConstantValue = (value: any) => {
  if (value === RUN_DATETIME) return RUN_DATETIME_NAME

  return value
}

export const columnNameGetter = (item: AssetColumn | ActionFieldModel) => {
  if (item instanceof AssetColumn) return item.data.column

  if (item instanceof ActionFieldModel)
    return `${item.parentDimension}__action__${slugString((item as ActionFieldModel).data.column)}`

  return '-'
}

export const comparisonValueInputTypeGetter = (operator?: CompareOperator, dataType?: DataType): FilterInputType => {
  if (dataType === DataType.DATETIME) return FilterInputType.DATETIME
  if (dataType === DataType.DATE) return FilterInputType.DATE

  if (operator && (['notin', 'isin', 'endswith', 'startswith'] as CompareOperator[]).includes(operator)) {
    return FilterInputType.VALUE_LIST
  }

  return FilterInputType.VALUE
}

export const customerGroupsOperatorListGetter: () => SelectItem<CompareOperator>[] = () => {
  return CUSTOMER_GROUPS_FULL_COMPARISON_OPERATORS_LIST
}

export const customerGroupsIsInListGetter: () => SelectItem<CompareOperator>[] = () => {
  return CUSTOMER_GROUPS_FULL_COMPARISON_OPERATORS_LIST.filter((item: SelectItem<CompareOperator>) => item.value === 'endswith')
}

export const getFilterUniqueId: (filter: CommonFilter) => string = (filter: CommonFilter) => {
  if (filter.colname === undefined && filter.compare_op === undefined) {
    return 'new'
  }
  const noCompareVal = filter.compare_op === ('isnull' as CompareOperator) || filter.compare_op === ('notnull' as CompareOperator)

  if (!noCompareVal && filter.compare_val !== undefined && filter.compare_val) {
    let compareVal = filter.compare_val?.toString()

    if (filter.compare_val === RUN_DATETIME) compareVal = 'rundatetime' // to prevent a huge "id" length

    return slugString(`${filter.colname}_${filter.compare_op}_${compareVal.toString()}`)
  }

  return slugString(`${filter.colname}_${filter.compare_op}`)
}
