import type { ROLE_TYPE } from '@shared/data/constants'
import type { ClientModel } from '@/clients/models/server/ClientModel'

export interface UserModel {
  id: number
  username: string
  email: string
  provider?: string
  picture?: string
  confirmed: boolean
  blocked: boolean
  tos_accepted: boolean
  is_read_only: boolean
  is_offerfit_email_enabled: boolean
  role: { type?: ROLE_TYPE, name?: string }
  allClients: ClientModel[] | null
  clients: ClientModel[] | number[] | null
}

export interface UserModelPartial extends Partial<UserModel> {}

export function getEmptyUser(): UserModel {
  return {
    id: 0,
    username: '',
    email: '',
    confirmed: false,
    blocked: true,
    tos_accepted: false,
    is_read_only: false,
    role: { type: undefined },
    allClients: null,
    is_offerfit_email_enabled: false,
    clients: null,
  }
}
