import { PipelineModel } from '@/pipelines/models/server/PipelineModel'

export type PipelineStateType = {
  pipeline: PipelineModel | null
  pipelines: PipelineModel[] | null
}

const state: PipelineStateType = {
  pipeline: null,
  pipelines: null
}

export default state
