// ./plugins/posthog.js
import posthog from 'posthog-js'
import type { App } from 'vue'

export default {
  install(app: App) {
    app.config.globalProperties.$posthog = posthog.init(
      import.meta.env.VITE_POSTHOG_API_KEY,
      {
        api_host: import.meta.env.VITE_POSTHOG_API_HOST,
        session_recording: {
          maskAllInputs: false,
          maskInputOptions: {
            password: true,
          },
        },
      },
    )
  },
}
