import { getQueryString, isEmpty } from '@shared/utils/helpers'
import http from '@app/utils/http-client'
import { PYTHON_MS_API_PREFIX } from '@shared/data/constants'
import type {
  ConvertedCustomers,
  EmailChannelPerformanceMetricsModel,
  PerformanceEmailDailyDataModel,
  PerformanceMetricsNonChannelModel,
  PerformanceOverallDailyDataModel,
  PerformanceReportForDownload,
  UpliftEmailDailyDataModel,
  UpliftOverallDailyDataModel,
  UseCaseStartDatesModel,
} from '@/reporting/models/server/ReportingModel'
import type { AudienceBreakdownDataModel } from '@/usecases/models/server/InboundDataValModel'

export class PerformanceReportService {
  static async getAudienceBreakdown(client: string, usecase: string): Promise<AudienceBreakdownDataModel[]> {
    if (isEmpty(client)) { throw new Error('Client is required!') }
    if (isEmpty(usecase)) { throw new Error('Usecase is required!') }

    const queryParams = {
      client,
      usecase,
    }

    const response = await http.get(`${PYTHON_MS_API_PREFIX}/reporting/get_audience_breakdown`, {
      params: queryParams,
    })
    return response.data as AudienceBreakdownDataModel[]
  }

  static async getReportingStartDate(client: string, usecase: string) {
    if (isEmpty(client)) { throw new Error('Client is required!') }
    if (isEmpty(usecase)) { throw new Error('Use case is required!') }

    const queryParams = {
      client,
      usecase,
    }

    const response = await http.get<UseCaseStartDatesModel>(`${PYTHON_MS_API_PREFIX}/reporting/get_reporting_start_date`, {
      params: queryParams,
      cache: {
        ttl: 1000,
      },
    })

    return response.data
  }

  static async getPerformanceOverallDaily(
    client: string,
    usecase: string,
    datesToExclude: string[],
    dateRange?: Array<string>,
  ): Promise<PerformanceOverallDailyDataModel[]> {
    if (isEmpty(client)) { throw new Error('Client is required!') }
    if (isEmpty(usecase)) { throw new Error('Use case is required!') }

    const queryParams = {
      client,
      usecase,
      start_date: dateRange?.[0],
      end_date: dateRange?.[1],
      dates_to_exclude: datesToExclude,
    }

    const response = await http.get(`${PYTHON_MS_API_PREFIX}/reporting/get_performance_overall_daily`, {
      params: queryParams,
      paramsSerializer: {
        indexes: null,
      },
    })

    return response.data as PerformanceOverallDailyDataModel[]
  }

  static async getPerformanceEmailDaily(
    client: string,
    usecase: string,
    datesToExclude: string[],
    dateRange?: Array<string>,
  ): Promise<PerformanceEmailDailyDataModel[]> {
    if (isEmpty(client)) { throw new Error('Client is required!') }
    if (isEmpty(usecase)) { throw new Error('Usecase is required!') }

    const queryParams = {
      client,
      usecase,
      start_date: dateRange?.[0],
      end_date: dateRange?.[1],
      dates_to_exclude: datesToExclude,
    }

    const response = await http.get(`${PYTHON_MS_API_PREFIX}/reporting/get_performance_email_daily`, {
      params: queryParams,
      paramsSerializer: {
        indexes: null,
      },
    })

    return response.data as PerformanceEmailDailyDataModel[]
  }

  static async getPerformanceOverall(
    client: string,
    usecase: string,
    datesToExclude: string[],
    dateRange?: Array<string>,
  ): Promise<PerformanceMetricsNonChannelModel[]> {
    if (isEmpty(client)) { throw new Error('Client is required!') }
    if (isEmpty(usecase)) { throw new Error('Use case is required!') }

    const queryParams = {
      client,
      usecase,
      start_date: dateRange?.[0],
      end_date: dateRange?.[1],
      dates_to_exclude: datesToExclude,
    }

    const response = await http.get(`${PYTHON_MS_API_PREFIX}/reporting/get_performance_overall`, {
      params: queryParams,
      paramsSerializer: {
        indexes: null,
      },
    })

    return response.data as PerformanceMetricsNonChannelModel[]
  }

  static async getPerformanceEmail(
    client: string,
    usecase: string,
    datesToExclude: string[],
    dateRange?: Array<string>,
  ): Promise<EmailChannelPerformanceMetricsModel[]> {
    if (isEmpty(client)) { throw new Error('Client is required!') }
    if (isEmpty(usecase)) { throw new Error('Use case is required!') }

    const queryParams = {
      client,
      usecase,
      dates_to_exclude: datesToExclude,
      start_date: dateRange?.[0],
      end_date: dateRange?.[1],
    }

    const response = await http.get(`${PYTHON_MS_API_PREFIX}/reporting/get_performance_email`, {
      params: queryParams,
      paramsSerializer: {
        indexes: null,
      },
    })

    return response.data as EmailChannelPerformanceMetricsModel[]
  }

  static async getReportForUserDownload(
    client: string,
    usecase: string,
    datesToExclude: string[],
    includeEmailMetrics: boolean,
    dateRange?: Array<string>,
  ): Promise<PerformanceReportForDownload[]> {
    if (isEmpty(client)) { throw new Error('Client is required!') }
    if (isEmpty(usecase)) { throw new Error('Use case is required!') }
    if (isEmpty(includeEmailMetrics)) { throw new Error('"includeEmailMetrics" is a required parameter!') }

    const queryParams = {
      client,
      usecase,
      datesToExclude,
      includeEmailMetrics,
      startDate: dateRange?.[0],
      endDate: dateRange?.[1],
    }

    const response = await http.get(`/get_report_for_user_download?${getQueryString(queryParams)}`)

    return response.data?.data?.map((item: any) => {
      return {
        ...item,
        rep_dt: item.rep_dt.value,
      }
    }) as PerformanceReportForDownload[]
  }

  static async getUpliftOverallDaily(
    client: string,
    usecase: string,
    regimes: Array<string>,
    comparisonGroup: string,
    datesToExclude: string[],
    dateRange?: Array<string>,
  ): Promise<UpliftOverallDailyDataModel[]> {
    if (isEmpty(client)) { throw new Error('Client is required!') }
    if (isEmpty(usecase)) { throw new Error('Usecase is required!') }

    const queryParams = {
      client,
      usecase,
      regimes,
      comparison_group: comparisonGroup,
      dates_to_exclude: datesToExclude,
      start_date: dateRange?.[0],
      end_date: dateRange?.[1],
    }

    const response = await http.get(`${PYTHON_MS_API_PREFIX}/reporting/get_uplift_overall_daily`, {
      params: queryParams,
      paramsSerializer: {
        indexes: null,
      },
    })

    return response.data as UpliftOverallDailyDataModel[]
  }

  static async getUpliftEmailDaily(
    client: string,
    usecase: string,
    regimes: Array<string>,
    comparisonGroup: string,
    datesToExclude: string[],
    dateRange?: Array<string>,
  ): Promise<UpliftEmailDailyDataModel[]> {
    if (isEmpty(client)) { throw new Error('Client is required!') }
    if (isEmpty(usecase)) { throw new Error('Usecase is required!') }

    const queryParams = {
      client,
      usecase,
      regimes,
      comparison_group: comparisonGroup,
      dates_to_exclude: datesToExclude,
      start_date: dateRange?.[0],
      end_date: dateRange?.[1],
    }

    const response = await http.get(`${PYTHON_MS_API_PREFIX}/reporting/get_uplift_email_daily`, {
      params: queryParams,
      paramsSerializer: {
        indexes: null,
      },
    })

    return response.data as UpliftEmailDailyDataModel[]
  }

  static async getConvertedCustomerNumbers(
    client: string,
    usecase: string,
    dateRange?: Array<string>,
  ): Promise<ConvertedCustomers[]> {
    if (isEmpty(client)) { throw new Error('Client is required!') }
    if (isEmpty(usecase)) { throw new Error('Usecase is required!') }

    const queryParams = {
      client,
      usecase,
      startDate: dateRange?.[0],
      endDate: dateRange?.[1],
    }

    const response = await http.get(`/get_converted_users?${getQueryString(queryParams)}`)

    return (response.data?.data as ConvertedCustomers[]) || []
  }
}
