import {computed} from 'vue'
import {useRoute} from 'vue-router'

export const usePath = () => {
  const route = useRoute()

  const clientSlug = computed(() =>
    Array.isArray(route.params.clientSlug)
      ? route.params.clientSlug[0]
      : route.params.clientSlug,
  )

  const pipelineSlug = computed(() =>
    Array.isArray(route.params.pipelineSlug)
      ? route.params.pipelineSlug[0]
      : route.params.pipelineSlug,
  )

  const usecaseSlug = computed(() =>
    Array.isArray(route.params.usecaseSlug)
      ? route.params.usecaseSlug[0]
      : route.params.usecaseSlug,
  )

  return {
    clientSlug,
    pipelineSlug,
    usecaseSlug,
  }
}
