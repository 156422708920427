import { redirectRouteAfterRecover } from '@app/AppSettings'
import { TERMS_OF_SERVICE_ROUTE } from '@shared/data/constants'
import { debug } from '@shared/utils/logger'
import type { Router } from 'vue-router'
import { usePermissions } from '@/auth/composables/usePermissions'

export function defineMiddleware(router: Router) {
  router.beforeEach((to) => {
    debug('Middleware: TOSAccepted')
    const { userHasAcceptedTos } = usePermissions()
    if (
      !to.meta.public // if route is not public
      && to.meta.bypassTos !== true // and route does not bypass TOS
      && to.name !== TERMS_OF_SERVICE_ROUTE // and route is not Terms of Service
      && !userHasAcceptedTos.value // and user has not accepted TOS
    ) {
      redirectRouteAfterRecover.value = to.fullPath
      debug('Middleware: TOSAccepted -> TERMS_OF_SERVICE_ROUTE')
      return { name: TERMS_OF_SERVICE_ROUTE }
    }
  })
}
