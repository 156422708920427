<template>
  <div class="mt-1">
    <div v-if="featureType === Features.COUNT">
      <span class="no-break">
        Count the instances for each customer<span v-if="daysBack?.length"> over the past {{ daysBack.join(', ') }} days</span>.
      </span>
    </div>

    <div v-else-if="featureType === Features.AGGREGATION && typeSpecific?.operation">
      <span class="no-break">
        Calculate the
        <span class="font-semibold" v-for="(op, idx) in typeSpecific?.operation" :key="idx">
          {{ returnFullAggName(op) }}<span v-if="idx !== typeSpecific?.operation.length - 1">, </span></span
        >
        of the <b>{{ typeSpecific?.agg_col }}</b> field for each customer<span v-if="daysBack?.length">
          over the past {{ daysBack.join(', ') }} days</span
        >.
      </span>
    </div>

    <div v-else-if="featureType === Features.BOOLEAN">
      <span class="no-break">
        Return <b>True</b> if the customer meets filter conditions<span v-if="daysBack?.length">
          over the past {{ daysBack.join(', ') }} days</span
        >.
      </span>
    </div>

    <div v-else-if="featureType === Features.TIME_SINCE">
      <span class="no-break">
        Calculate the number of <b>{{ typeSpecific?.granularity }}s</b> since the <b>{{ typeSpecific?.order }}</b>
        occurrence of where the filter conditions are met, for each customer.
      </span>
    </div>

    <div v-else-if="featureType === Features.BUCKET_TIME">
      <span class="no-break">
        Count the number of occurrences between the
        <b>{{ typeSpecific?.granularity ? `${typeSpecific?.granularity}s` : '[granularity]' }}</b> of

        <template v-if="bucketTimeBuckets?.length">
          <span v-for="(item, idx) in bucketTimeBuckets" :key="idx">
            <span v-if="idx === bucketTimeBuckets.length - 1 && bucketTimeBuckets.length !== 1"> and </span>
            <span class="font-bold">{{ item }}</span>
            <span
              v-if="![bucketTimeBuckets.length - 1, bucketTimeBuckets.length - 2].includes(idx) && bucketTimeBuckets.length !== 1"
              >,
            </span>
          </span>
        </template>
        <span class="font-bold" v-else>[bucket values]</span>
        for each customer<template v-if="daysBack?.length">
          over the past <span class="font-bold">{{ daysBack.join(', ') }} days</span></template
        >.
      </span>
    </div>
    <div v-else-if="featureType === Features.RATIO" class="no-break">
      Divide <b>{{ typeSpecific?.numerator }}</b> by <b>{{ typeSpecific?.denominator }}</b
      >. If the <b>{{ typeSpecific?.denominator }}</b> is zero, then return <b>{{ typeSpecific?.fill }}</b
      >.
    </div>
    <div v-else-if="featureType === Features.IDENTITY" class="no-break">
      Return the value as is from data asset.
      <span v-if="typeSpecific?.fill">If no value is present, fill with {{ typeSpecific?.fill }}</span>
    </div>
    <div v-else-if="featureType === Features.ORDINAL" class="no-break">
      Returns mapped values in column
      <span v-if="typeSpecific?.target_col"
        ><i>{{ typeSpecific?.target_col }}</i></span
      >
      to ranked integers. Note that the latest encoding in the days back window is returned, if encodings changed.
      <div v-if="typeSpecific?.encoding_order">
        Where the mapping is as follows:<br />
        <ul class="list-none ml-2">
          <li v-for="(item, idx) in typeSpecific?.encoding_order" :key="idx">{{ idx + 1 }}: {{ item }}</li>
        </ul>
        <div class="mt-0.5 text-sm">*values not in list are mapped to 0</div>
      </div>
    </div>
    <div v-else-if="featureType === Features.ONE_HOT" class="no-break">
      Returns {{ typeSpecific?.encoding_order?.length * (daysBack?.length || 1) }} features, for which the value can be either 1
      or 0, depending on whether the value for the column <i>{{ typeSpecific?.target_col }}</i> is equal to the encoding value.
    </div>
    <div v-else-if="featureDescription" class="no-break">
      {{ featureDescription }}
    </div>
    <div v-else>No description found.</div>
  </div>
</template>

<script lang="ts">
import { Features } from '@shared/data/constants'
import { FeatureType } from '@/data-assets/models/FeaturesModel'

export default defineComponent({
  name: 'DescriptionRender',
  props: {
    typeSpecific: {
      type: Object as PropType<any>,
      default: null
    },
    featureType: {
      type: String as PropType<FeatureType | undefined>
    },
    featureDescription: {
      type: String as PropType<string | undefined>
    },
    daysBack: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    inModal: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    allSome: {
      type: String as PropType<string>,
      default: 'and'
    }
  },
  setup(props) {
    const renderBucketTimeBuckets = (array: number[] = []) => {
      const result: any = []
      for (let i = 0; i < array.length - 1; i++) {
        result.push(`${array[i]}am - ${array[i + 1]}am`)
      }
      return result
    }

    const bucketTimeBuckets = computed(() => {
      return renderBucketTimeBuckets(props.typeSpecific?.buckets || [])
    })

    const returnFullAggName = (func: string) => {
      const dict: any = {
        sum: 'sum',
        average: 'average',
        sdev: 'standard deviation',
        max: 'maximum',
        min: 'minimum'
      }
      return dict[func] || func
    }
    return {
      renderBucketTimeBuckets,
      returnFullAggName,
      Features,
      bucketTimeBuckets
    }
  }
})
</script>

<style scoped>
.no-break {
  word-break: initial;
}
</style>
