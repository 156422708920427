import type { PipelineConfigModel } from '@/pipelines/models/PipelineConfigModel'
import type { MutationTree } from 'vuex'

import { getDataAssetTemplate } from '@/data-assets/utils/config'
import { useTransform } from '@/data-assets/composables/useTransform'
import type { PipelineStateType } from '@/pipelines/store/state'
import type { CustomerPopulationConfigModel } from '@/pipelines/models/CustomerPopulationModel'
import type { JoinAssetConfigModel, JoinAssetPayload } from '@/data-assets/models/DataJoiningModel'
import type { DeleteFeaturePayload, NewFeaturePayload, defaultCreateFeatureWrapper } from '@/data-assets/models/FeaturesModel'
import type { TransformUpdatePayload, TransformationConfigModel } from '@/data-assets/models/TransformationConfigModel'
import type { MetadataModel, PipelineModel } from '@/pipelines/models/server/PipelineModel'
import { store } from '@app/store'

import type {
  ColumnMappingPayload,
  DataAssetWrapper,
  EmailRecipientsType,
  IDataAsset,
  StoreDataChangePayload,
} from '@shared/utils/Types'
import { isEmpty, isEqual, mergeDeep } from '@shared/utils/helpers'
import { dateTimeFormat } from '@shared/utils/transformHelpers'
import { INTEGRATION_PLATFORM } from '@/level4/data/l4_constants'

const { getTransformationUniqueId } = useTransform()

const mutations: MutationTree<PipelineStateType> = {
  pipeline(state: PipelineStateType, value: PipelineModel) {
    state.pipeline = value
    makeSureAllGeneralFieldsAreCreated(state)
  },
  addPipeline(state: PipelineStateType, value: PipelineModel) {
    if (!state.pipelines) {
      state.pipelines = []
    }
    state.pipelines.push(value)
  },
  pipelines(state: PipelineStateType, value: PipelineModel[]) {
    state.pipelines = value
  },
  assetsMetadata(state: PipelineStateType, value: any) {
    if (!state.pipeline) { throw new Error('Mutation "assetsMetadata" cannot be executed') }
    makeSureAllGeneralFieldsAreCreated(state)
    state.pipeline.metadata.assets = value.assets
  },
  removePreprocessAsset(state: PipelineStateType, key: string) {
    delete state.pipeline?.config.preprocess.assets[key]
  },
  removeAssetInFeatureConfig(state: PipelineStateType, key: string) {
    delete state.pipeline?.config.engineer_features.assets[key]
  },
  removeAssetFromMetadata(state: PipelineStateType, key: string) {
    delete state.pipeline?.metadata.assets[key]
  },
  prepareAssetFields(state: PipelineStateType, payload: DataAssetWrapper) {
    if (!state.pipeline) { return }
    makeSureAllGeneralFieldsAreCreated(state)
    const mergedConfig = mergeDeep(getDataAssetTemplate(), payload.asset || {}) as IDataAsset
    if (!isEqual(state.pipeline.config.preprocess.assets[payload.key], mergedConfig)) {
      state.pipeline.config.preprocess.assets[payload.key] = mergedConfig
    }
  },
  addOrUpdatePreprocessAsset(state: PipelineStateType, payload: DataAssetWrapper) {
    if (!state.pipeline) { return }
    makeSureAllGeneralFieldsAreCreated(state)
    state.pipeline.config.preprocess.assets[payload.key] = mergeDeep(getDataAssetTemplate(), payload.asset) as IDataAsset
  },
  addOrUpdateTransform(state: PipelineStateType, payload: TransformUpdatePayload) {
    if (isEmpty(state.pipeline) && isEmpty(payload.assetKey)) {
      console.error('addOrUpdateTransform first check failed')
      return
    }
    makeSureAllGeneralFieldsAreCreated(state)

    let asset: IDataAsset | undefined = state.pipeline?.config.preprocess.assets[payload.assetKey]

    if (isEmpty(asset)) {
      asset = (state.pipeline as PipelineModel).config.preprocess.assets[payload.assetKey] = getDataAssetTemplate()
    }

    if (asset && isEmpty(asset.transform)) {
      asset.transform = {
        transforms: [],
        drop_fields: [],
      }
    }

    if (asset && asset.transform && isEmpty(asset.transform.transforms)) {
      asset.transform.transforms = []
    }

    if (asset && asset.transform && isEmpty(asset.transform.drop_fields)) {
      asset.transform.drop_fields = []
    }

    if (asset && asset.transform) {
      if (!isEmpty(payload.oldId)) {
        const existingTransformationIndex: number = asset.transform.transforms.findIndex(
          (item: TransformationConfigModel) => getTransformationUniqueId(item) === payload.oldId,
        )

        if (existingTransformationIndex !== -1) {
          asset.transform?.transforms.splice(existingTransformationIndex, 1, payload.transformConfig)
        }
        else {
          asset.transform.transforms.push(payload.transformConfig)
        }
      }
      else {
        asset.transform.transforms.push(payload.transformConfig)
      }
    }
  },
  updateDataJoining(state: PipelineStateType, payload: JoinAssetPayload) {
    if (!state.pipeline) { return }
    makeSureAllGeneralFieldsAreCreated(state)
    let assetJoining = state.pipeline.config.preprocess.assets[payload.parentAsset]?.asset_joining

    if (!state.pipeline.config?.preprocess?.assets[payload.parentAsset]) {
      state.pipeline.config.preprocess.assets[payload.parentAsset] = getDataAssetTemplate()
    }

    if (!assetJoining) {
      state.pipeline.config.preprocess.assets[payload.parentAsset].asset_joining = assetJoining = []
    }

    assetJoining.splice(
      assetJoining.findIndex((item: JoinAssetConfigModel) => item.asset === payload.joinAsset.asset),
      1,
      payload.joinAsset,
    )
  },
  addSegmentToReportingSegments(state: PipelineStateType, segment: { name: string, type: string }) {
    if (!state.pipeline) { return }
    if (Object.keys(state.pipeline.config.reporting_segments).includes(segment.type)) {
      if (!state.pipeline.config.reporting_segments[segment.type].includes(segment.name)) {
        state.pipeline.config.reporting_segments[segment.type].push(segment.name)
      }
    }
    else {
      state.pipeline.config.reporting_segments[segment.type] = [segment.name]
    }
  },
  removeSegmentFromReportingSegments(state: PipelineStateType, segment: { name: string, type: string }) {
    if (!state.pipeline) { return }
    state.pipeline.config.reporting_segments[segment.type] = state.pipeline.config.reporting_segments?.[segment.type]?.filter(
      (feature: string) => feature !== segment.name,
    )
  },
  updatePipelineConfig(state: PipelineStateType, pipelineConfig: PipelineConfigModel) {
    if (!state.pipeline) { return }
    state.pipeline.config = pipelineConfig
    makeSureAllGeneralFieldsAreCreated(state)
  },
  updatePipelineMetadata(state: PipelineStateType, metadata: MetadataModel) {
    if (!state.pipeline) { return }
    state.pipeline.metadata = metadata
    makeSureAllGeneralFieldsAreCreated(state)
  },
  updateCustomerPopulationConfig(state: PipelineStateType, customerPopulationConfig: CustomerPopulationConfigModel) {
    if (!state.pipeline) { return }
    makeSureAllGeneralFieldsAreCreated(state)
    state.pipeline.config.engineer_features.customer_population = customerPopulationConfig
  },
  updatePreprocessConfig(state: PipelineStateType, preprocessConfig: any) {
    if (!state.pipeline) { return }
    makeSureAllGeneralFieldsAreCreated(state)
    state.pipeline.config.preprocess = preprocessConfig
  },
  updateFeaturesConfig(state: PipelineStateType, featureConfig: any) {
    if (!state.pipeline) { return }
    makeSureAllGeneralFieldsAreCreated(state)
    state.pipeline.config.engineer_features = featureConfig
  },
  updateUsecasesArray(state: PipelineStateType, usecases: string[]) {
    if (!state.pipeline) { return }
    makeSureAllGeneralFieldsAreCreated(state)
    state.pipeline.config.usecases = usecases
  },
  updateValidationEmails(state: PipelineStateType, { value }: StoreDataChangePayload) {
    if (!state.pipeline) { return }
    makeSureAllGeneralFieldsAreCreated(state)
    const list: EmailRecipientsType = import.meta.env.VITE_APP_ENVIRONMENT === 'staging' ? 'staging_recipients' : 'recipients'
    state.pipeline.config.validation.email[list] = value
    store.dispatch('pipeline/updatePipeline')
  },
  updateFeatureAssetsConfig(state: PipelineStateType, newAssets: defaultCreateFeatureWrapper) {
    if (!state.pipeline) { return }
    makeSureAllGeneralFieldsAreCreated(state)
    const featuresAssets = state.pipeline.config.engineer_features.assets

    for (const assetName in newAssets) {
      featuresAssets[assetName] = newAssets[assetName]
    }
  },
  addNewFeatureWrapper(state: PipelineStateType, feature: NewFeaturePayload) {
    if (!state.pipeline) { return }
    makeSureAllGeneralFieldsAreCreated(state)
    const featuresAssetsMap = state.pipeline.config.engineer_features.assets
    if (!featuresAssetsMap[feature.assetKey]) {
      featuresAssetsMap[feature.assetKey] = {} as any
    }
    featuresAssetsMap[feature.assetKey][feature.type] = feature.wrapper
  },
  addAssetFeatureConfig(state: PipelineStateType, newFeature: NewFeaturePayload) {
    if (!state.pipeline) { return }
    makeSureAllGeneralFieldsAreCreated(state)
    const featuresAssetsMap = state.pipeline.config.engineer_features.assets

    if (!featuresAssetsMap[newFeature.assetKey]) {
      featuresAssetsMap[newFeature.assetKey] = {} as any
    }

    if (!featuresAssetsMap[newFeature.assetKey][newFeature.type]) {
      featuresAssetsMap[newFeature.assetKey][newFeature.type] = {
        features: {},
      } as any
    }

    let assetFeatures = featuresAssetsMap?.[newFeature.assetKey]?.[newFeature.type]?.features
    if (!assetFeatures) {
      ;(featuresAssetsMap[newFeature.assetKey][newFeature.type] as any).features = assetFeatures = {}
    }
    assetFeatures[newFeature.name] = newFeature.payload
  },
  updateColumnMapping(state: PipelineStateType, columnMap: ColumnMappingPayload) {
    if (!state.pipeline) { return }
    makeSureAllGeneralFieldsAreCreated(state)

    if (!state.pipeline.config.engineer_features.assets[columnMap.asset]) {
      state.pipeline.config.engineer_features.assets[columnMap.asset] = {
        compute_layer: '',
        days_back: [],
        mapping_type: '',
        mapping: {
          event_time_col: null,
          user_identifier_col: undefined,
        },
      }
    }

    if (!state.pipeline.config.engineer_features.assets[columnMap.asset].mapping) {
      state.pipeline.config.engineer_features.assets[columnMap.asset].mapping = {
        user_identifier_col: undefined,
        event_time_col: null,
      }
    }

    state.pipeline.config.engineer_features.assets[columnMap.asset].mapping[columnMap.col_value] = columnMap.map_value
  },
  addTaskRunMetaData(state: PipelineStateType, payload: { asset: string, payload: StoreDataChangePayload }) {
    if (!state.pipeline) { return }
    makeSureAllGeneralFieldsAreCreated(state)

    if (payload.asset && !state.pipeline?.metadata?.assets[payload.asset]) {
      state.pipeline.metadata.assets[payload.asset] = {
        columns: [],
        updates: {},
        table: {},
        updatedAt: dateTimeFormat(new Date(), 'UTC'),
      }
    }

    if (payload.asset && !state.pipeline?.metadata?.assets[payload.asset].updates) {
      state.pipeline.metadata.assets[payload.asset].updates = {}
    }

    store.commit('updateObjectInStore', {
      ...payload.payload,
      fieldObject: state.pipeline.metadata.assets[payload.asset].updates,
    } as StoreDataChangePayload)
  },
  deleteFeatureFromConfig(state: PipelineStateType, feature: DeleteFeaturePayload) {
    if (!state.pipeline) { return }
    makeSureAllGeneralFieldsAreCreated(state)
    const featuresAssetsMap = state.pipeline.config.engineer_features.assets
    if (featuresAssetsMap[feature.key]?.[feature.type]?.features[feature.name]) {
      delete featuresAssetsMap[feature.key]?.[feature.type]?.features[feature.name]
    }
  },
  deleteAllFeaturesForAsset(state: PipelineStateType, assetName: string) {
    if (!state.pipeline) { return }
    makeSureAllGeneralFieldsAreCreated(state)
    const featuresAssetsMap = state.pipeline.config.engineer_features.assets
    delete featuresAssetsMap[assetName]
  },
}

function makeSureAllGeneralFieldsAreCreated(state: PipelineStateType) {
  if (!state.pipeline) { return }

  if (!state.pipeline.config) {
    state.pipeline.config = {} as any
  }

  if (!state.pipeline.config.preprocess) {
    state.pipeline.config.preprocess = {
      assets: {},
    }
  }

  if (!state.pipeline.config.preprocess?.assets) {
    state.pipeline.config.preprocess.assets = {}
  }

  if (!state.pipeline.config.usecases) {
    state.pipeline.config.usecases = []
  }

  if (!state.pipeline.config.validation) {
    state.pipeline.config.validation = {} as any
  }

  if (!state.pipeline.config.validation.email) {
    state.pipeline.config.validation.email = {
      recipients: [],
      staging_recipients: [],
    }
  }

  if (!state.pipeline.config.engineer_features) {
    state.pipeline.config.engineer_features = {
      assets: {},
      customer_population: {} as any,
    }
  }

  if (!state.pipeline.config.engineer_features.assets) {
    state.pipeline.config.engineer_features.assets = {}
  }

  if (!state.pipeline.config.engineer_features.customer_population) {
    state.pipeline.config.engineer_features.customer_population = {} as any
  }

  if (!state.pipeline?.metadata) {
    state.pipeline.metadata = {
      assets: {},
    }
  }

  if (!state.pipeline?.metadata?.assets) {
    state.pipeline.metadata.assets = {}
  }

  if (!state.pipeline.config.reporting_segments) {
    state.pipeline.config.reporting_segments = {}
  }
}

export default mutations
