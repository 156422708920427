<template>
  <div class="flex">
    <NTag
      round
      class="text-offerfit-bright-purple"
      :theme-overrides="themeTagOverrides"
      :type="DeploymentStateDisplayMap.get(status || deployment.state)?.type || 'primary'"
      ><span class="p-2">{{ DeploymentStateDisplayMap.get(status || deployment.state).displayName }}</span></NTag
    >
    <div v-if="isAIEorAdmin && statusIsAwaitingReview">
      <PencilSquareIcon @click="addReviewClickHandler" class="w-5 h-5 ml-1 mt-1 cursor-pointer" />
    </div>
  </div>
</template>

<script lang="ts">
import { PencilSquareIcon } from '@heroicons/vue/24/outline'
import { NTag, TagProps } from 'naive-ui'
import { DeploymentState } from '@shared/data/constants'
import { DeploymentStateDisplayMap } from '@shared/data/mapAndLists'
import { useDeploymentPolling } from '@/deployments/composables/useDeployments'
import { usePermissions } from '@/auth/composables/usePermissions'
import { DeploymentsTableModel } from '@/deployments/models/server/DeploymentModel'

type BasicTagThemeOverrides = NonNullable<TagProps['themeOverrides']>

const themeTagOverrides: BasicTagThemeOverrides = {
  borderPrimary: 'transparent',
  borderWarning: 'transparent',
  borderError: 'transparent',
  borderSuccess: 'transparent',
  colorBorderedWarning: '#F7C032',
  colorBorderedPrimary: '#450E72',
  colorBorderedError: '#F76532',
  colorBorderedSuccess: '#58C897',
  textColorWarning: '#FFFFFF',
  textColorPrimary: '#FFFFFF',
  textColorError: '#FFFFFF',
  textColorSuccess: '#FFFFFF'
}

export default defineComponent({
  name: 'DeploymentStatusRender',
  components: { NTag, PencilSquareIcon },
  props: {
    deployment: {
      type: Object as PropType<DeploymentsTableModel>
    }
  },
  emits: ['open-review-modal'],
  setup(props, { emit }) {
    const { getPoll, status } = useDeploymentPolling(props.deployment as DeploymentsTableModel)
    const { isAIEorAdmin } = usePermissions()

    const statusIsAwaitingReview = computed<boolean>(() => {
      return status.value
        ? status.value === DeploymentState.AWAITING_REVIEW
        : props.deployment?.state === DeploymentState.AWAITING_REVIEW
    })

    onMounted(() => {
      getPoll()
    })

    const addReviewClickHandler = () => {
      emit('open-review-modal', props.deployment)
    }

    return {
      status,
      statusIsAwaitingReview,
      isAIEorAdmin,
      themeTagOverrides,
      DeploymentStateDisplayMap,
      addReviewClickHandler
    }
  }
})
</script>


