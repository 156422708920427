import AppSettings from '@app/AppSettings'
import debounce from 'lodash/debounce'
import uniq from 'lodash/uniq'
import omit from 'lodash/omit'
import { NButton } from 'naive-ui'
import {
  CUSTOMER_ID_COLUMN,
  ConversionEvent,
  DIMENSION_TYPE,
  DimensionKey,
  OFFERFIT_CUSTOMER_GROUP_DISPLAY_NAME,
  PredictionMethodConfigValue,
  PredictionMethodLabel,
  REGIME_OFFERFIT,
  REQUEST_DEBOUNCE_DELAY,
  USECASE_ACTION_BANKS_FIELD,
  UseCaseStatus,
} from '@shared/data/constants'
import type { ChannelEvent, EngagementEvent, EventChannel, EventTypeKey } from '@shared/data/constants'
import {
  ChannelActivationEventMap,
  ChannelEngagementEventMap,
  ChannelPenaltyEventMap,
  DIMENSION_TEMPLATES,
  EVENT_DEFAULTS,
  channelListOptions,
} from '@shared/data/mapAndLists'
import { store } from '@app/store'
import type {
  ActionBankContext,
  CustomerGroupType,
  DimensionName,
  ItemWithDescription,
  NullString,
  SelectItem,
  StoreDataChangePayload,
  StoreDataRewritePayload,
} from '@shared/utils/Types'
import Global from '@shared/utils/global'
import {
  filterOnlyUniqueCallback,
  getNewExpressionWrapper,
  isEmpty,
  isEqual,
  renameKeys,
} from '@shared/utils/helpers'
import type { NaiveSelectItem } from '@shared/utils/naiveProxy'
import { slugString } from '@shared/utils/transformHelpers'
import { OutPutItemType } from '@shared/utils/Types'
import {
  convertDimensionsConfigModelToPartial,
  convertPartialDimensionsConfigModelToRegularConfigModel,
  getDimensionActionMappingByGroupConfig,
  getDimensionType,
  getFilterExpressionForGuardrail,
  getUseCaseConfigWithoutActionBanks,
  isActionFieldFromTemplate,
  markChangedActions,
  markDuplicatedActions,
  prepareUseCaseConfig,
  returnExperienceWithDefaults,
  sortDimensionsOrderInConfigObject,
} from '@/usecases/composables/usecaseUtils'
import { useUseCaseMutations } from '@/usecases/composables/mutations'
import type { UseCaseState } from '@/usecases/composables/state'
import { createUseCaseStateObject } from '@/usecases/composables/state'
import { useEngineeringFeatures } from '@/usecases/composables/useEngineeringFeatures'
import {
  ExposedChannelEvents,
  getSequencePerChannel,
  returnChannelListFromEvents,
} from '@/usecases/composables/useEvents'

import type {
  AudienceConfigMatchingExpression,
  AudienceLogicConfigModel,
  AudienceModel,
  RootAudienceConfigExpression,
} from '@/usecases/models/AudienceModel'
import type {
  CustomerGroupConfigMatchingExpression,
  CustomerGroupConfigModel,
  CustomerGroupLogicConfigModel,
  CustomerGroupLogicModel,
  CustomerGroupModel,
  DimensionActionMappingModel,
  PredictionMethodBodyConfig,
  PredictionMethodConfig,
  PredictionMethodModel,
  RootCustomerGroupConfigExpression,
} from '@/usecases/models/CustomerGroupModel'

import type { DimensionConfigModel, DimensionConfigModelPartial } from '@/usecases/models/DimensionConfigModel'
import type {
  CommonEventType,
  ConversionEventType,
  EventItemModel,
  PerChannelTableDataModel,
  TypeEventMap,
  UsecaseEventPlaceholderType,
} from '@/usecases/models/EventsModel'
import type {
  ExperiencesModel,
  ExperiencesModelPartial,
} from '@/usecases/models/ExperiencesModel'
import type { CommonFilter, RootFilterExpression } from '@/filtering/models/FilterModel'
import type {
  DimensionGuardrailsMap,
  GuardrailConfigMatchingExpression,
  GuardrailConfigModel,
  GuardrailModel,
  GuardrailTableModel,
  RootGuardrailConfigExpression,
} from '@/usecases/models/GuardrailModel'
import type {
  ActionBanksForOutputPartial,
  ActionBanksForTree,
  ActionConfigModel,
  ActionFieldModel,
  ActionModel,
  ActionValueModel,
  ConfigDimensionActionBank,
  ConfigDimensionOnlyActionsAndName,
  DimensionActionBank,
  DimensionActionBankPartial,
} from '@/usecases/models/server/ActionModel'
import { ActionColumn } from '@/usecases/models/server/ActionModel'
import type { UseCaseMetadataModel, UseCaseModel, UseCaseModelWithoutConfig } from '@/usecases/models/server/UseCaseModel.ts'
import type {
  ActionAugmentations,
  DimensionConfig,
  EventSequenceConfigModel,
  FilterAugmentRecsWrapper,
  GuardrailConfig,
  TouchType,
  UseCaseConfigModel,
  UseCaseConfigModelWithoutActionBank,
} from '@/usecases/models/UseCaseConfigModel'
import { PerformanceReportService } from '@/reporting/performance/services/PerformanceReportService'
import UseCaseService from '@/usecases/services/UseCaseService'
import {
  convertModelExpressionToConfigExpression,
  getComparatorsConfigModelsFromExpression,
  getExpressionModelsFromArray,
  getFilterUniqueId,
  getNodeExpression,
  getNodeOperator,
  nodeIsOperator,
} from '@/filtering/utils/filters'
import {
  ActionBankLiveActionIsModifiedError,
  ActionBankRequiredActionIsEmptyError,
  getActionId,
  getDefaultValueForNewActionItem,
  getTemplateParametersByDimensionName,
  getWrappedActionBank,
  isActionBankPromotable,
  isCurrentActionsAreEqualToLiveActions,
} from '@/usecases/utils/actionBank'
import type { AudienceBreakdownDataModel } from '@/usecases/models/server/InboundDataValModel'

const ACTION_BANK_SECTION_PATH = `$.${USECASE_ACTION_BANKS_FIELD}`

const globalState = createUseCaseStateObject()

let globalLiveState: UseCaseState | undefined

let initialized = false
const useCaseEffectScope = effectScope(true)

export const useCaseHasLocalRemoteConflict = ref<boolean>(false)
export const hasAcknowledgedLocalRemoteConflictUseCase = ref<boolean>(false)
export const useCaseLocallyUpdatedAt = ref<Date | null>(null)

function _useUseCaseConfig(stateObject?: UseCaseState) {
  const _currentState: UseCaseState = stateObject || globalState
  const isGlobal = !stateObject
  const { clientName, usecaseName, experimenterName, pipelineName } = AppSettings

  const useCaseMutations = useUseCaseMutations(_currentState)
  const {
    setActionBank,
    addActionBank,
    addFeatureAugmentation,
    addActionAugmentation,
    updateConversionAttributionType,
    editTrainingExperiences,
    addTrainingExperience,
    rewriteObjectInStore,
    updateObjectInConfig,
    removeObjectInStore,
    updateActionBank,
    syncUseCaseConfigHashes,
    syncActionBankConfigHashes,
    initUseCaseConfig,
    initActionBank,
    setLiveUsecase,
    setCurrentUsecase,
  } = useCaseMutations

  const currentUseCase = computed(() => {
    return _currentState.localUsecase
  })

  const usecase = computed(() => {
    return _currentState.localUsecase?.name || currentUseCase.value?.name || usecaseName.value || experimenterName.value
  })

  const loadLiveUseCase = async ({ useCaseName, pipeline }: {
    useCaseName: string
    pipeline: string
  }) => {
    if (isEmpty(useCaseName)) {
      throw new Error('getUseCasesByClient: "useCaseName" is required to make a request')
    }
    if (isEmpty(pipeline)) {
      throw new Error('getUseCasesByClient: "pipeline" is required to make a request')
    }

    try {
      const useCasesData: UseCaseModel = await UseCaseService.getUseCaseConfigFromEnvironment(
        useCaseName,
        pipeline,
        'live',
        store.getters['client/client'].name,
      )
      setLiveUsecase(useCasesData)
    }
    catch (e: any) {
      console.warn(e.message)
    }
  }

  const loadUseCaseConfigFromServer = async () => {
    const useCaseConfigWithoutActionBankArray = await UseCaseService.v2getUseCasesSection<UseCaseConfigModelWithoutActionBank>(
      store.getters['client/client']?.name || clientName.value,
      store.getters['pipeline/pipeline']?.name || pipelineName.value,
      usecase.value,
      undefined,
      [USECASE_ACTION_BANKS_FIELD],
    )

    if (_currentState.localUsecase) {
      _currentState.localUsecase.controlledDimensions = useCaseConfigWithoutActionBankArray?.length
        ? useCaseConfigWithoutActionBankArray[0]?.usecaseControlledDimensions || []
        : []
    }

    let tempUseCaseConfig = useCaseConfigWithoutActionBankArray?.length
      ? useCaseConfigWithoutActionBankArray[0].sectionValue
      : undefined

    if (tempUseCaseConfig) {
      tempUseCaseConfig = prepareUseCaseConfig(tempUseCaseConfig, store.getters['pipeline/pipeline'])

      if (tempUseCaseConfig?.prediction?.dimension) {
        tempUseCaseConfig.prediction.dimension = sortDimensionsOrderInConfigObject(tempUseCaseConfig.prediction.dimension)
      }

      initUseCaseConfig(tempUseCaseConfig)
    }
  }

  const loadActionBanksFromServer = async () => {
    const actionBanksResponseArray = await UseCaseService.v2getUseCasesSection<ConfigDimensionActionBank[]>(
      store.getters['client/client']?.name || clientName.value,
      store.getters['pipeline/pipeline']?.name || pipelineName.value,
      usecase.value,
      ACTION_BANK_SECTION_PATH,
    )

    const tempActionsBanks = actionBanksResponseArray?.length ? actionBanksResponseArray[0].sectionValue : undefined

    if (tempActionsBanks) {
      initActionBank(tempActionsBanks)
    }
  }

  const saveUseCaseModel = async () => {
    if (store.getters.isReadonlyMode) {
      return
    }

    if (!currentUseCase.value?.id) {
      throw new Error('UseCase id does not exist. Cannot save the use case')
    }

    try {
      useCaseLocallyUpdatedAt.value = new Date()
      const updatedModel = await UseCaseService.updateUseCase(currentUseCase.value.id, currentUseCase.value)

      if (updatedModel?.updatedAtDate) {
        useCaseLocallyUpdatedAt.value = updatedModel.updatedAtDate
      }

      setCurrentUsecase(updatedModel)
    }
    catch (e: any) {
      Global.error(e.message)
    }
  }
  const saveUseCaseConfig = async () => {
    if (store.getters.isReadonlyMode) {
      return
    }

    if (!(usecase.value)) {
      throw new Error('UseCase name does not exist. Cannot save the configuration')
    }

    if (!_currentState.localUseCaseConfig) {
      throw new Error('UseCase config does not exist. Cannot save the configuration')
    }

    try {
      useCaseLocallyUpdatedAt.value = new Date()
      const updatedDate = await UseCaseService.updateUseCaseConfig(
        usecase.value,
        store.getters['client/client']?.name || clientName.value,
        _currentState.localUseCaseConfig,
      )

      if (updatedDate) {
        useCaseLocallyUpdatedAt.value = updatedDate
      }
    }
    catch (e: any) {
      Global.error(e.message)
    }

    syncUseCaseConfigHashes()
  }

  const saveActionBankConfig = async () => {
    if (store.getters.isReadonlyMode) {
      return
    }

    if (!currentUseCase.value?.name) {
      throw new Error('UseCase name does not exist. Cannot save action banks')
    }

    // eslint-disable-next-line no-alert
    if (!_currentState.localActionBank?.length && !window.confirm('Are you sure you want to save an empty action bank?')) {
      return
    }

    try {
      useCaseLocallyUpdatedAt.value = new Date()
      const updatedDate = await UseCaseService.updateUseCaseActionBank(
        usecase.value,
        store.getters['client/client'].name || clientName.value,
        _currentState.localActionBank || [],
      )

      if (updatedDate) {
        useCaseLocallyUpdatedAt.value = updatedDate
      }

      void _currentState.usecaseActionBankUpdatedCallbackOnce?.()
      _currentState.usecaseActionBankUpdatedCallbackOnce = undefined
    }
    catch (e: any) {
      Global.error(e.message)
    }

    syncActionBankConfigHashes()
  }

  const { allFeatureNameList } = useEngineeringFeatures()

  const getUseCaseConfig = computed(() => _currentState.localUseCaseConfig)
  const getUseCaseConfigWithActionBank = computed(() => ({ actionBanks: _currentState.localActionBank, ..._currentState.localUseCaseConfig }))

  const useCaseMetadata = computed<UseCaseMetadataModel | undefined>(() => currentUseCase.value?.metadata)

  const channelsList = computed<EventChannel[]>(() => {
    return getUseCaseConfig.value?.channels || []
  })

  const dimensions = computed<DimensionConfigModelPartial[] | undefined>((): DimensionConfigModelPartial[] | undefined => {
    const dimensionConfigObject: DimensionConfig | undefined = getUseCaseConfig.value?.prediction.dimension

    if (!dimensionConfigObject) {
      return undefined
    }

    const entries = Object.entries(dimensionConfigObject)

    entries.sort((itemA: [string, DimensionConfigModel], itemB: [string, DimensionConfigModel]): number => {
      if (!itemA[1] || !itemB[1]) {
        return 0
      }

      if (itemA[0] === DimensionKey.CADENCE || itemA[0] === DimensionKey.FREQUENCY) {
        return -1
      }
      if (itemB[0] === DimensionKey.CADENCE || itemB[0] === DimensionKey.FREQUENCY) {
        return 1
      }

      // "Day" comes first, unless "Frequency" exists, in which case "Day" comes second
      if (itemA[0] === DimensionKey.DAYSOFWEEK) {
        if (itemB[0] === DimensionKey.FREQUENCY) {
          return 1
        }

        return -1
      }

      if (itemB[0] === DimensionKey.DAYSOFWEEK) {
        if (itemA[0] === DimensionKey.FREQUENCY) {
          return -1
        }

        return 1
      }

      return (itemB[1].send_decision_to?.length || 0) - (itemA[1].send_decision_to?.length || 0)
    })

    return entries.map(
      ([key, value]: [string, DimensionConfigModel]): DimensionConfigModelPartial =>
        convertDimensionsConfigModelToPartial(value, key),
    )
  })

  const updateDimensionConfigInStoreModelByPartial = (dimension: DimensionConfigModelPartial): void => {
    const domensionObject = convertPartialDimensionsConfigModelToRegularConfigModel(dimension)
    rewriteObjectInStore({
      targetObject: dimension.original,
      byObject: domensionObject,
    } as StoreDataRewritePayload)
  }

  const dimensionOptions = computed<NaiveSelectItem[] | undefined>(() => {
    return (
      dimensions.value?.map((item: DimensionConfigModelPartial) => {
        return {
          label: item.display_name,
          value: item.name,
        } as NaiveSelectItem
      }) || undefined
    )
  })

  const recalculateSendDecisionFields = (
    reorderedDimensions?: DimensionConfigModelPartial[],
    updateOriginals: boolean = false,
  ) => {
    const dimensionsToField: string[] = <string[]>[]
    const dimensionArray = reorderedDimensions || dimensions.value || []
    dimensionArray
      .concat()
      .reverse()
      .forEach((dimension: DimensionConfigModelPartial) => {
        dimension.send_decision_to = dimensionsToField.concat().reverse()

        if (updateOriginals) {
          updateObjectInConfig({
            fieldObject: dimension.original,
            key: 'send_decision_to',
            value: dimension.send_decision_to,
          } as StoreDataChangePayload)
        }

        dimensionsToField.push(dimension.name || '')
      })
  }

  const getDecidingSimpleAgent = (dimensionName: string) => `${dimensionName || ''}_simple_catboost_oracle`
  const getDecidingComplexAgent = (dimensionName: string) => `${dimensionName || ''}_complex_catboost_oracle`

  const doesDimensionWithNameExist: (dimensionName: string) => boolean = (dimensionName: string) => {
    const dimensionsConfig: any = getUseCaseConfig.value?.prediction.dimension || {}
    return Object.keys(dimensionsConfig).includes(dimensionName)
  }

  // event lists
  const activationEvents = computed(() => {
    return getUseCaseConfig.value?.events?.activation?.map((item: CommonEventType | ConversionEventType) => item.event_type) ?? []
  })

  const engagementEvents = computed(() => {
    return getUseCaseConfig.value?.events?.engagement?.map((item: CommonEventType | ConversionEventType) => item.event_type) ?? []
  })

  const penaltyEvents = computed(() => {
    return getUseCaseConfig.value?.events?.penalty?.map((item: CommonEventType | ConversionEventType) => item.event_type) ?? []
  })

  const allTrainingEvents = computed(() => {
    return activationEvents.value?.concat(engagementEvents.value || []).concat(penaltyEvents.value || []) || []
  })

  const getExperiencesFromConfig = computed<Array<ExperiencesModel>>(() => {
    return getUseCaseConfig.value?.training?.experiences || []
  })

  const addCadenceRecommendedToExperiences = () => {
    const updatedExperiences: ExperiencesModel[] = []
    updatedExperiences.push(returnExperienceWithDefaults(allTrainingEvents.value, undefined, DimensionKey.CADENCE))
    editTrainingExperiences(updatedExperiences)
  }

  const deleteCadenceRecommendedExperience = () => {
    let currentExperiences = toRaw(getExperiencesFromConfig.value)
    currentExperiences = currentExperiences.filter((item: ExperiencesModel) => item.dimension === DimensionKey.CADENCE)
    editTrainingExperiences(currentExperiences)
  }

  const getActionBank = (dimension: string): DimensionActionBank | undefined => {
    if (dimension) {
      const actionBanksArray: ConfigDimensionActionBank[] = _currentState.localActionBank || []
      const configActionBank = actionBanksArray.find((bank: ConfigDimensionActionBank) => bank.name === dimension)
      if (configActionBank) {
        return getWrappedActionBank(configActionBank)
      }
    }

    return undefined
  }

  const createNewActionBank = (dimension: string) => {
    if (!getUseCaseConfig.value) {
      return
    }
    if (!dimension) {
      return
    }

    const newActionBank = {
      name: dimension,
      displayName: dimension,
      actions: [],
      fields: getTemplateParametersByDimensionName(dimension),
    } as DimensionActionBank

    addActionBank(newActionBank)
  }

  const configureDimension = (dimension: DimensionConfigModelPartial, oldName?: string) => {
    const dimensionKey = dimension.name || ''
    const simpleAgent = getDecidingSimpleAgent(dimensionKey)
    dimension.agents = isEmpty(dimension.agents) ? 'default' : dimension.agents
    if (DIMENSION_TEMPLATES.map(item => item.value).includes(dimensionKey)) {
      dimension.dimension_type = dimension.dimension_type || getDimensionType(dimensionKey)
    }
    else {
      dimension.dimension_type = DIMENSION_TYPE.WHAT
    }

    if (dimension.dimension_type === DIMENSION_TYPE.WHAT || dimension.dimension_type === DIMENSION_TYPE.CADENCE) {
      const complexAgent = getDecidingComplexAgent(dimension.name || '')
      dimension.record_ranks_for_agents = isEmpty(dimension.record_ranks_for_agents)
        ? [simpleAgent, complexAgent]
        : dimension.record_ranks_for_agents
      dimension.record_shaps_for_agents = isEmpty(dimension.record_shaps_for_agents)
        ? {
            [simpleAgent]: {
              shap_sample_num: 5000,
            },
            [complexAgent]: {
              shap_sample_num: 5000,
            },
          }
        : dimension.record_shaps_for_agents
    }
    else {
      dimension.record_ranks_for_agents = isEmpty(dimension.record_ranks_for_agents)
        ? [simpleAgent]
        : dimension.record_ranks_for_agents
    }

    if (dimension.name
      && [DimensionKey.CADENCE, DimensionKey.FREQUENCY, DimensionKey.DAYSOFWEEK].includes(dimension.name as DimensionKey)) {
      dimension.action_expiration = isEmpty(dimension.action_expiration) ? true : dimension.action_expiration
      dimension.action_valid_period = isEmpty(dimension.action_valid_period) ? 7 : dimension.action_valid_period
      if (dimension.name as DimensionKey === DimensionKey.CADENCE) {
        addCadenceRecommendedToExperiences()
      }
    }

    if (oldName && oldName === DimensionKey.CADENCE) {
      deleteCadenceRecommendedExperience()
    }
  }

  const createDimension = (
    dimension: DimensionConfigModelPartial,
  ) => {
    // remove name field as they don't exist in config
    const { name, ...dimensionData } = dimension

    if (!name) {
      return
    } // should be validated on execution level

    const dimensionsConfig: DimensionConfig = getUseCaseConfig.value?.prediction.dimension || {}
    const oldDimensionConfig: DimensionConfigModel = dimensionsConfig[name] || {}
    let decisions: (string | undefined)[] = []
    if (name.toString() === DimensionKey.CADENCE || name.toString() === DimensionKey.FREQUENCY) {
      decisions = (dimensions.value || []).map((item: DimensionConfigModelPartial) => item.name)
    }
    else if (name.toString() === DimensionKey.DAYSOFWEEK) {
      decisions = (dimensions.value || [])
        .filter(item => item.name?.toString() !== DimensionKey.FREQUENCY)
        .map((item: DimensionConfigModelPartial) => item.name)
    }

    const newDimensionObject: DimensionConfigModel = {
      ...oldDimensionConfig,
      ...(dimensionData as DimensionConfigModel),
      send_decision_to: decisions,
    } as DimensionConfigModel

    const newDimensionsConfig: DimensionConfig = {
      ...dimensionsConfig,
      [name.toString()]: newDimensionObject,
    }

    updateObjectInConfig({
      fieldObject: getUseCaseConfig.value?.prediction,
      key: 'dimension',
      value: newDimensionsConfig,
    } as StoreDataChangePayload)

    const partialDimension: DimensionConfigModelPartial | undefined = dimensions.value?.find(
      (item: DimensionConfigModelPartial) => item.name === name,
    )

    if (partialDimension) {
      recalculateSendDecisionFields(undefined, true)
      configureDimension(partialDimension)
      updateDimensionConfigInStoreModelByPartial(partialDimension)
    }

    if (name && !getActionBank(name)) {
      createNewActionBank(name)
    }
  }

  // When you rename a dimension, field name should remain as before. Only function below will change it
  const renameDimension = async (dimensionName: string, newDisplayName: string) => {
    // remove name and isNew fields as they don't exist in config
    const dimensionsConfig: DimensionConfig = getUseCaseConfig.value?.prediction.dimension || ({} as DimensionConfig)
    const oldDimension = dimensionsConfig[dimensionName]
    if (!oldDimension) {
      return
    }

    const oldName = oldDimension.name || dimensionName
    const newName = slugString(newDisplayName)
    if (newName && oldName && newName.trim() === oldName.trim()) {
      return
    }

    if (oldDimension && _currentState.timerMap[oldDimension.name]) {
      clearTimeout(_currentState.timerMap[oldDimension.name])
    }

    return new Promise((resolve) => {
      const timeoutValue = window.setTimeout(() => {
        const newDimensionsConfig: DimensionConfig = renameKeys<DimensionConfig>(dimensionsConfig, { [oldName]: newName })
        newDimensionsConfig[newName].display_name = newDisplayName

        if (getUseCaseConfig.value?.prediction) {
          updateObjectInConfig({
            fieldObject: getUseCaseConfig.value?.prediction,
            key: 'dimension',
            value: newDimensionsConfig,
          } as StoreDataChangePayload<typeof getUseCaseConfig.value.prediction>)
        }

        const partialDimension: DimensionConfigModelPartial = convertDimensionsConfigModelToPartial(
          newDimensionsConfig[newName],
          newName,
        )

        configureDimension(newDimensionsConfig[newName], oldName)
        updateDimensionConfigInStoreModelByPartial(partialDimension)
        recalculateSendDecisionFields(undefined, true)

        // update guardrail mappings
        if (getUseCaseConfig.value) {
          const predictionOfferfitGroupConfigObject = getUseCaseConfig.value?.prediction.regime.offerfit
          const newDimensionGuardrailMapping = renameKeys<typeof predictionOfferfitGroupConfigObject.guardrails>(
            predictionOfferfitGroupConfigObject.guardrails,
            {
              [oldName]: newName,
            },
          )
          if (getUseCaseConfig.value?.prediction.regime.offerfit) {
            updateObjectInConfig({
              fieldObject: getUseCaseConfig.value.prediction.regime.offerfit,
              key: 'guardrails',
              value: newDimensionGuardrailMapping,
            } as StoreDataChangePayload<typeof getUseCaseConfig.value.prediction.regime.offerfit>)
          }
        }

        resolve(true)
      }, REQUEST_DEBOUNCE_DELAY)
      if (oldName) {
        _currentState.timerMap[oldName] = timeoutValue
      }
    })
  }

  const changeDimensionDescription = (dimensionName: string, descriptionValue: string) => {
    if (getUseCaseConfig.value?.prediction.dimension) {
      const dimensionsConfig: DimensionConfig = getUseCaseConfig.value.prediction.dimension
      if (getUseCaseConfig.value && descriptionValue) {
        updateObjectInConfig({
          fieldObject: dimensionsConfig[dimensionName],
          key: 'description',
          value: descriptionValue,
        } as StoreDataChangePayload<DimensionConfigModel>)
      }
    }
  }

  const synchronizeLocalDimensions: (changedDimensions: DimensionConfigModelPartial[]) => void = (
    changedDimensions: DimensionConfigModelPartial[],
  ) => {
    if (!changedDimensions.length) {
      return
    }
    recalculateSendDecisionFields(changedDimensions, true)
  }

  const removeDimension = (
    dimension: DimensionConfigModelPartial,
  ) => {
    const dimensionConfigObject: DimensionConfig | undefined = getUseCaseConfig.value?.prediction.dimension
    if (dimension && dimension.name) {
      if (dimensionConfigObject) {
        removeObjectInStore({ fieldObject: dimensionConfigObject, key: dimension.name } as StoreDataChangePayload)

        Object.keys(getUseCaseConfig.value?.prediction.regime || {}).forEach((group: string) => {
          const predictionDimensionGuardrailMap = getUseCaseConfig.value?.prediction.regime[group].guardrails
          if (predictionDimensionGuardrailMap && predictionDimensionGuardrailMap[dimension.name]) {
            removeObjectInStore({
              fieldObject: predictionDimensionGuardrailMap,
              key: dimension.name,
            } as StoreDataChangePayload)
          }
        })

        recalculateSendDecisionFields(undefined, true)
      }

      // Find and remove the action bank config
      const actionBanksArray: ConfigDimensionActionBank[] = _currentState.localActionBank || []
      const newActionBank = actionBanksArray.filter(bank => bank !== null && bank.name !== dimension.name)
      setActionBank(newActionBank)
    }

    if (dimension.name === DimensionKey.CADENCE) {
      deleteCadenceRecommendedExperience()
    }
  }

  const audienceComparators: ComputedRef<CommonFilter[] | undefined> = computed<CommonFilter[] | undefined>(() => {
    const audienceConfigObject = getUseCaseConfig.value?.prediction.audience
    if (!audienceConfigObject?.filter?.comparators) {
      return undefined
    }

    const commonFilters: CommonFilter[] = audienceConfigObject.filter?.comparators?.map((comparator: CommonFilter) => {
      return {
        id: comparator.id || getFilterUniqueId(comparator),
        colname: comparator.colname,
        compare_op: comparator.compare_op,
        compare_val: comparator.compare_val,
      } as CommonFilter
    })

    return commonFilters
  })

  const audience: ComputedRef<AudienceModel | undefined> = computed<AudienceModel | undefined>(() => {
    const audienceConfigObject = getUseCaseConfig.value?.prediction.audience
    const audienceConfigModel: AudienceLogicConfigModel | undefined = audienceConfigObject?.filter?.logic?.[0]

    if (!audienceConfigModel) {
      return undefined
    }

    const expression: RootFilterExpression = getExpressionModelsFromArray<AudienceConfigMatchingExpression>(
      audienceConfigModel.expression,
      audienceComparators.value,
    ) as RootFilterExpression

    return {
      id: audienceConfigModel.id,
      negate_expression: audienceConfigModel.negate_expression,
      expression: expression.length ? expression : ([getNewExpressionWrapper()] as RootFilterExpression),
    } as AudienceModel
  })

  const removeAudience: () => void = () => {
    if (getUseCaseConfig.value && getUseCaseConfig.value.prediction.audience !== undefined) {
      removeObjectInStore({
        fieldObject: getUseCaseConfig.value.prediction,
        key: 'audience',
      } as StoreDataChangePayload<typeof getUseCaseConfig.value.prediction>)
    }
  }

  const updateAudience: (value: AudienceModel) => void = (value: AudienceModel) => {
    const comparatorsConfig: CommonFilter[] = getComparatorsConfigModelsFromExpression(
      value.expression,
    )

    if (getUseCaseConfig.value && getUseCaseConfig.value.prediction.audience === undefined) {
      updateObjectInConfig({
        fieldObject: getUseCaseConfig.value?.prediction,
        key: 'audience',
        value: {
          filter: {
            comparators: [],
            logic: [],
          },
        },
      } as StoreDataChangePayload<typeof getUseCaseConfig.value.prediction>)
    }

    if (getUseCaseConfig.value && getUseCaseConfig.value.prediction.audience?.filter === undefined) {
      updateObjectInConfig({
        fieldObject: getUseCaseConfig.value?.prediction.audience,
        key: 'filter',
        value: {
          comparators: [],
          logic: [],
        },
      } as StoreDataChangePayload<typeof getUseCaseConfig.value.prediction.audience>)
    }

    let existingComparatorList: CommonFilter[] | undefined
      = getUseCaseConfig.value?.prediction.audience?.filter?.comparators.concat()

    if (!existingComparatorList) {
      existingComparatorList = []
      updateObjectInConfig({
        fieldObject: getUseCaseConfig.value?.prediction.audience?.filter,
        key: 'comparators',
        value: [],
      } as StoreDataChangePayload)
    }

    for (let i = 0; i < comparatorsConfig.length; i++) {
      const comparatorsConfigElement: CommonFilter = comparatorsConfig[i]
      const existingConfigElement: CommonFilter | undefined = existingComparatorList.find(
        (item: CommonFilter) => item.id === comparatorsConfigElement.id,
      )

      if (!existingConfigElement && getUseCaseConfig.value?.prediction.audience?.filter) {
        existingComparatorList.push(comparatorsConfigElement)
        updateObjectInConfig({
          fieldObject: getUseCaseConfig.value.prediction.audience.filter,
          key: 'comparators',
          value: existingComparatorList.concat(),
        } as StoreDataChangePayload)
      }
    }

    const audienceObject: AudienceLogicConfigModel = {
      id: value.id,
      negate_expression: false,
      expression: convertModelExpressionToConfigExpression<AudienceConfigMatchingExpression, RootAudienceConfigExpression>(
        value.expression,
      ) as RootAudienceConfigExpression,
    }

    updateObjectInConfig({
      fieldObject: getUseCaseConfig.value?.prediction.audience?.filter,
      key: 'logic',
      value: [audienceObject],
    } as StoreDataChangePayload)
  }

  const removeControlGroup: (controlGroup: CustomerGroupModel) => void = (controlGroup: CustomerGroupModel) => {
    const controlGroupsConfigObject = getUseCaseConfig.value?.prediction.regime
    removeObjectInStore({
      fieldObject: controlGroupsConfigObject,
      key: controlGroup.name,
    } as StoreDataChangePayload)

    // remove maximum recommendations per day for the control group
    const filterAugmentRecs = getUseCaseConfig.value?.filter_and_augment_recs

    if (filterAugmentRecs?.recs_filter?.customer_limit_map?.[controlGroup.name]) {
      removeObjectInStore({
        fieldObject: filterAugmentRecs.recs_filter.customer_limit_map,
        key: controlGroup.name,
      } as StoreDataChangePayload<typeof filterAugmentRecs.recs_filter.customer_limit_map>)
    }
  }

  const getConfigPredictionMethod: (predictionMethodObjectMapping: DimensionActionMappingModel) => PredictionMethodConfig = (
    predictionMethodObjectMapping: DimensionActionMappingModel,
  ) => {
    const resultConfigObject: PredictionMethodConfig = {}

    for (let i = 0; i < (dimensions.value?.length || 0); i++) {
      const dimension = dimensions.value?.[i]
      const dimensionName = dimension?.name || i.toString()

      const predictionMethodModelObject: PredictionMethodModel | undefined = predictionMethodObjectMapping.get(dimensionName)

      if (predictionMethodModelObject) {
        const target: PredictionMethodBodyConfig = {
          method: predictionMethodModelObject.method,
        }

        if (predictionMethodModelObject.actionId !== undefined) {
          target.kwargs = {
            assigned_action_id: predictionMethodModelObject.actionId,
          }
        }

        resultConfigObject[dimensionName] = target
      }
    }

    return resultConfigObject
  }

  const updateControlGroup: (controlGroup: CustomerGroupModel) => void = (controlGroup: CustomerGroupModel) => {
    // do the change for any customer group (control or offerfit)
    const filterAugmentRecs = getUseCaseConfig.value?.filter_and_augment_recs

    if (filterAugmentRecs) {
      if (filterAugmentRecs?.recs_filter === undefined) {
        updateObjectInConfig({
          fieldObject: filterAugmentRecs,
          key: 'recs_filter',
          value: {
            customer_limit_map: {},
          },
        } as StoreDataChangePayload<FilterAugmentRecsWrapper>)
      }

      if (filterAugmentRecs.recs_filter?.customer_limit_map === undefined) {
        updateObjectInConfig({
          fieldObject: filterAugmentRecs?.recs_filter,
          key: 'customer_limit_map',
          value: {},
        } as StoreDataChangePayload<typeof filterAugmentRecs.recs_filter>)
      }

      // This functionality has been removed -- saving code here for potential relocation
      //   store.commit('updateObjectInStore', {
      //     fieldObject: filterAugmentRecs?.recs_filter?.customer_limit_map,
      //     key: controlGroup.name,
      //     value: controlGroup.maxRecommendationPerDay
      //   } as StoreDataChangePayload<typeof filterAugmentRecs.recs_filter>)
      // } else if (controlGroup.maxRecommendationPerDay === undefined) {
      //   if (filterAugmentRecs?.recs_filter?.customer_limit_map?.[controlGroup.name]) {
      //     store.commit('removeObjectInStore', {
      //       fieldObject: filterAugmentRecs.recs_filter.customer_limit_map,
      //       key: controlGroup.name
      //     } as StoreDataChangePayload<typeof filterAugmentRecs.recs_filter.customer_limit_map>)
      //   }
    }

    if (controlGroup.name !== REGIME_OFFERFIT) {
      const comparatorsConfig: CommonFilter[] = getComparatorsConfigModelsFromExpression(
        (controlGroup.assignmentCondition as CustomerGroupLogicModel).expression,
      )

      const controlGroupsConfigObject = getUseCaseConfig.value?.prediction.regime
      let controlGroupConfigObject: CustomerGroupConfigModel | undefined = controlGroupsConfigObject?.[
        controlGroup.name
      ] as CustomerGroupConfigModel

      if (!controlGroupConfigObject) {
        updateObjectInConfig({
          fieldObject: controlGroupsConfigObject,
          key: controlGroup.name,
          value: {
            display_name: controlGroup.displayName,
            description: controlGroup.description,
            prediction_method: getConfigPredictionMethod(controlGroup.predictionMethod),
          } as CustomerGroupConfigModel,
        } as StoreDataChangePayload)

        controlGroupConfigObject = controlGroupsConfigObject?.[controlGroup.name] as CustomerGroupConfigModel
      }
      else {
        updateObjectInConfig({
          fieldObject: controlGroupConfigObject,
          key: 'display_name',
          value: controlGroup.displayName,
        } as StoreDataChangePayload<CustomerGroupConfigModel>)
        updateObjectInConfig({
          fieldObject: controlGroupConfigObject,
          key: 'description',
          value: controlGroup.description,
        } as StoreDataChangePayload<CustomerGroupConfigModel>)
      }

      if (comparatorsConfig.length) {
        if (controlGroupConfigObject.assignment_condition === undefined) {
          updateObjectInConfig({
            fieldObject: controlGroupConfigObject,
            key: 'assignment_condition',
            value: {
              filter: {
                comparators: [],
                logic: [{ id: controlGroup.name, expression: [{}], negate_expression: false }],
              },
            },
          } as StoreDataChangePayload)
        }
        if (controlGroupConfigObject.assignment_condition?.filter === undefined) {
          updateObjectInConfig({
            fieldObject: controlGroupConfigObject.assignment_condition,
            key: 'filter',
            value: {
              comparators: [],
              logic: [{ id: controlGroup.name, expression: [{}], negate_expression: false }],
            },
          } as StoreDataChangePayload)
        }
        if (controlGroupConfigObject.assignment_condition?.filter?.comparators === undefined) {
          updateObjectInConfig({
            fieldObject: controlGroupConfigObject.assignment_condition.filter,
            key: 'comparators',
            value: [],
          } as StoreDataChangePayload)
        }
        if (controlGroupConfigObject.assignment_condition?.filter?.logic === undefined) {
          updateObjectInConfig({
            fieldObject: controlGroupConfigObject.assignment_condition.filter,
            key: 'logic',
            value: [{ id: controlGroup.name, expression: [{}], negate_expression: false }],
          } as StoreDataChangePayload)
        }
      }

      const existingComparatorList: CommonFilter[]
        = (controlGroupConfigObject?.assignment_condition as any).filter?.comparators.concat() || []

      for (let i = 0; i < comparatorsConfig.length; i++) {
        const comparatorsConfigElement: CommonFilter = comparatorsConfig[i]
        const existingConfigElement: CommonFilter | undefined = existingComparatorList.find(
          (item: CommonFilter) => item.id === comparatorsConfigElement.id,
        )

        if (!existingConfigElement) {
          existingComparatorList.push(comparatorsConfigElement)
          updateObjectInConfig({
            fieldObject: (controlGroupConfigObject?.assignment_condition as any).filter,
            key: 'comparators',
            value: existingComparatorList.concat(),
          } as StoreDataChangePayload)
        }
      }

      const controlGroupObject: CustomerGroupLogicConfigModel = {
        id: controlGroup.name,
        negate_expression: false,
        expression: convertModelExpressionToConfigExpression<
          CustomerGroupConfigMatchingExpression,
          RootCustomerGroupConfigExpression
        >((controlGroup.assignmentCondition as CustomerGroupLogicModel).expression) as RootAudienceConfigExpression,
      }

      if (controlGroup.predictionMethod.size) {
        updateObjectInConfig({
          fieldObject: controlGroupConfigObject,
          key: 'prediction_method',
          value: getConfigPredictionMethod(controlGroup.predictionMethod),
        } as StoreDataChangePayload<CustomerGroupConfigModel>)
      }
      else {
        removeObjectInStore({
          fieldObject: controlGroupConfigObject,
          key: 'prediction_method',
        } as StoreDataChangePayload<CustomerGroupConfigModel>)
      }

      updateObjectInConfig({
        fieldObject: controlGroupConfigObject.assignment_condition.filter,
        key: 'logic',
        value: [controlGroupObject],
      } as StoreDataChangePayload<typeof controlGroupConfigObject.assignment_condition.filter>)
    }
  }

  const customerGroups: ComputedRef<CustomerGroupModel[] | undefined> = computed<CustomerGroupModel[] | undefined>(() => {
    const controlGroupsConfigObject = getUseCaseConfig.value?.prediction.regime
    if (!controlGroupsConfigObject) {
      return undefined
    }

    const groupNames: string[] = Object.keys(controlGroupsConfigObject || {}).filter((name: string) => name !== REGIME_OFFERFIT)
    if (!groupNames.length) {
      return undefined
    }

    const { guardrails } = controlGroupsConfigObject[REGIME_OFFERFIT] || {}

    return [
      {
        name: REGIME_OFFERFIT,
        displayName: OFFERFIT_CUSTOMER_GROUP_DISPLAY_NAME,
        guardrails,
      },
    ].concat(
      groupNames.map((name: string) => {
        const configObject: CustomerGroupConfigModel = controlGroupsConfigObject[name] as CustomerGroupConfigModel

        const controlGroupFilter = configObject.assignment_condition?.filter

        const commonFilters: CommonFilter[] | undefined = controlGroupFilter?.comparators?.map((comparator: CommonFilter) => {
          return {
            id: comparator.id || getFilterUniqueId(comparator),
            colname: comparator.colname,
            compare_op: comparator.compare_op,
            compare_val: comparator.compare_val,
          }
        })

        return {
          name,
          displayName: configObject.display_name,
          description: configObject.description,
          guardrails: configObject.guardrails,
          assignmentCondition: {
            id: name,
            negate_expression: controlGroupFilter?.logic?.[0]?.negate_expression || false,
            expression: getExpressionModelsFromArray(controlGroupFilter?.logic?.[0]?.expression, commonFilters),
          },
          predictionMethod: getDimensionActionMappingByGroupConfig(configObject),
        }
      }),
    )
  })

  const getCustomerGroupDisplayNameByName = (groupName: string): string => {
    if (groupName === REGIME_OFFERFIT) {
      return OFFERFIT_CUSTOMER_GROUP_DISPLAY_NAME
    }
    return customerGroups.value?.find((item: CustomerGroupModel) => item.name === groupName)?.displayName || groupName
  }

  const guardrailComparators: ComputedRef<CommonFilter[] | undefined> = computed<CommonFilter[] | undefined>(() => {
    const guardrailsConfigObject: GuardrailConfig | undefined = getUseCaseConfig.value?.prediction.guardrails
    if (!guardrailsConfigObject?.filter_based.comparators) {
      return undefined
    }

    const commonFilters: CommonFilter[] = guardrailsConfigObject.filter_based.comparators.map((comparator: CommonFilter) => {
      return {
        id: comparator.id || getFilterUniqueId(comparator),
        colname: comparator.colname,
        compare_op: comparator.compare_op,
        compare_val: comparator.compare_val,
      }
    })

    return commonFilters
  })

  const removeUnusedGuardrailComparators: () => void = () => {
    const guardrailsConfigObject: GuardrailConfig | undefined = getUseCaseConfig.value?.prediction.guardrails
    if (!guardrailsConfigObject?.filter_based.comparators) {
      return
    }

    function getComparatorsId(expression: (GuardrailConfigMatchingExpression | CommonFilter)[]): string[] {
      const result: string[] = []
      for (let i = 0; i < expression.length; i++) {
        const expressionElement = expression[i]
        if (nodeIsOperator(expressionElement)) {
          result.push(...getComparatorsId(getNodeExpression(expressionElement)))
        }
        else {
          result.push(expressionElement as string)
        }
      }

      return result
    }

    const allComparatorsInUse: string[] = guardrailsConfigObject.filter_based.logic.reduce(
      (result: string[], item: GuardrailConfigModel) => {
        result.push(...getComparatorsId(item.expression))
        return result
      },
      [],
    )

    const comparatorsToRemains: CommonFilter[] = guardrailsConfigObject.filter_based.comparators.filter((item: CommonFilter) => {
      return allComparatorsInUse.includes(item.id || getFilterUniqueId(item))
    })

    updateObjectInConfig({
      fieldObject: getUseCaseConfig.value?.prediction.guardrails.filter_based,
      key: 'comparators',
      value: comparatorsToRemains,
    })
  }

  const guardrails: ComputedRef<GuardrailModel[] | undefined> = computed<GuardrailModel[] | undefined>(() => {
    const guardrailsConfigObject: GuardrailConfig | undefined = getUseCaseConfig.value?.prediction.guardrails
    if (!guardrailsConfigObject?.filter_based?.logic) {
      return undefined
    }

    const guardrailModelArray: GuardrailModel[] = guardrailsConfigObject.filter_based.logic.map(
      (guardrailConfigElement: GuardrailConfigModel) => {
        return {
          name: guardrailConfigElement.id,
          negateExpression: guardrailConfigElement.negate_expression ? 'Match' : 'Not matched',
          description: guardrailConfigElement.description,
          displayName: guardrailConfigElement.display_name,
          expression: getFilterExpressionForGuardrail(guardrailsConfigObject.filter_based.logic, guardrailComparators.value || [], guardrailConfigElement.id),
        }
      },
    )

    return guardrailModelArray
  })

  const dimensionGuardrailMap = computed(() => {
    const regimeConfig = getUseCaseConfig.value?.prediction.regime

    if (!regimeConfig) {
      return
    }

    const guardrailsMap: DimensionGuardrailsMap = {}

    Object.keys(regimeConfig).forEach((group) => {
      const guardrailsData = regimeConfig[group as CustomerGroupType]?.guardrails // workaround
      if (!guardrailsData) {
        return
      }

      const dimensions = Object.keys(guardrailsData)

      dimensions.forEach((dimension) => {
        const groupDimensionGuardrails = guardrailsData[dimension]

        if (!guardrailsMap[dimension]) {
          guardrailsMap[dimension] = []
        }

        // Map each guardrail to an object including the group
        const array: GuardrailModel[] = groupDimensionGuardrails?.map((name) => {
          const guardrailItem = guardrails.value?.find(g => g.name === name)
          if (guardrailItem) {
            return { ...guardrailItem, group: group as CustomerGroupType } satisfies GuardrailModel // Include the group in the object
          }
          return null
        }).filter(Boolean) || []

        guardrailsMap[dimension].push(...array)
      })
    })

    return guardrailsMap
  })

  const currentDimension = computed<DimensionConfigModelPartial | undefined>(() => {
    return dimensions.value?.find((item: DimensionConfigModelPartial) => item.name === _currentState.currentDimensionName)
  })

  const guardrailsTableData = computed(() => {
    if (!dimensionGuardrailMap.value) { return }

    const mergedMap = Object.entries(dimensionGuardrailMap.value).reduce((acc, [dimension, guardrails]) => {
      guardrails.forEach((item: GuardrailModel) => {
        const key = item.name

        if (!acc[key]) {
          // Create a new entry if it doesn't exist
          acc[key] = { ...item, dimension, groups: [item.group] }
          delete acc[key].group // Remove the 'group' field
        }
        else {
          // Update the existing entry
          acc[key].groups.push(item.group)
          // Optional: To avoid duplicate dimensions, use a Set or check before adding
        }
      })

      return acc
    }, {})

    return Object.values(mergedMap)
  })

  const removeGuardrailFromGroup = (guardrail: GuardrailTableModel, group: CustomerGroupType) => {
    if (!getUseCaseConfig.value?.prediction.regime?.[group]) {
      return
    }

    const dimensionPredictionGuardrailList: string[]
      = getUseCaseConfig.value?.prediction.regime?.[group]?.guardrails?.[guardrail.dimension]?.concat() || []

    if (!dimensionPredictionGuardrailList.length) {
      return
    }

    const guardrailInDimensionIndex: number = dimensionPredictionGuardrailList.findIndex(
      (item: string) => item === guardrail.name,
    )

    dimensionPredictionGuardrailList.splice(guardrailInDimensionIndex, 1)

    updateObjectInConfig({
      fieldObject: getUseCaseConfig.value?.prediction.regime[group]?.guardrails,
      key: guardrail.dimension,
      value: dimensionPredictionGuardrailList.concat(),
    } as StoreDataChangePayload)
  }

  const addGuardrailToGroup = (guardrail: GuardrailTableModel, group: CustomerGroupType) => {
    if (!getUseCaseConfig.value?.prediction.regime[group]) {
      throw new Error(`Error while trying to add Guardrail to ${group}. Group does not exist.`)
    }

    if (!getUseCaseConfig.value?.prediction.regime[group]?.guardrails) {
      updateObjectInConfig({
        fieldObject: getUseCaseConfig.value?.prediction.regime[group],
        key: 'guardrails',
        value: {},
      } as StoreDataChangePayload)
    }

    const predictionGuardrailList
      = getUseCaseConfig.value?.prediction.regime[group]?.guardrails?.[`${guardrail.dimension}`]

    if (!predictionGuardrailList) {
      updateObjectInConfig({
        fieldObject: getUseCaseConfig.value?.prediction.regime[group]!.guardrails,
        key: guardrail.dimension,
        value: [],
      } as StoreDataChangePayload)
    }

    const dimensionPredictionGuardrailList
      = getUseCaseConfig.value?.prediction.regime[group]?.guardrails?.[`${guardrail.dimension}`]

    if (!dimensionPredictionGuardrailList) {
      updateObjectInConfig({
        fieldObject: getUseCaseConfig.value?.prediction.regime[group]?.guardrails,
        key: guardrail.dimension,
        value: [guardrail.name],
      } as StoreDataChangePayload)
    }
    else if (!dimensionPredictionGuardrailList.find((element: string) => element === guardrail.name)) {
      updateObjectInConfig({
        fieldObject: getUseCaseConfig.value?.prediction.regime[group]?.guardrails,
        key: guardrail.dimension,
        value: dimensionPredictionGuardrailList.concat([guardrail.name]),
      } as StoreDataChangePayload)
    }
    else {
      const newGuardrailList = dimensionPredictionGuardrailList.filter((element: string) => element !== guardrail.name)
      newGuardrailList.push(guardrail.name)

      store.commit('updateObjectInStore', {
        fieldObject: getUseCaseConfig.value?.prediction.regime[group]?.guardrails,
        key: guardrail.dimension,
        value: newGuardrailList,
      } as StoreDataChangePayload)
    }
  }

  const deleteGuardrail: (guardrail: GuardrailTableModel) => void = (guardrail: GuardrailTableModel) => {
    const existingGuardrailConfigList: GuardrailConfigModel[]
      = getUseCaseConfig.value?.prediction.guardrails.filter_based.logic.concat() || []

    const guardrailIndex: number = existingGuardrailConfigList.findIndex(
      (item: GuardrailConfigModel) => item.id === guardrail.name,
    )

    existingGuardrailConfigList.splice(guardrailIndex, 1)

    updateObjectInConfig({
      fieldObject: getUseCaseConfig.value?.prediction.guardrails.filter_based,
      key: 'logic',
      value: existingGuardrailConfigList,
    } as StoreDataChangePayload)

    guardrail.groups.forEach((group) => {
      removeGuardrailFromGroup(guardrail, group)
    })

    removeUnusedGuardrailComparators()
  }

  const updateGuardrail = (guardrail: GuardrailTableModel) => {
    if (!guardrail.dimension) {
      throw new Error('Guardrail has no dimension!')
    }

    const comparatorsConfig: CommonFilter[] = getComparatorsConfigModelsFromExpression(
      guardrail.expression,
    )

    let existingComparatorList: CommonFilter[] | undefined
      = getUseCaseConfig.value?.prediction.guardrails.filter_based.comparators.concat() ?? []

    if (!existingComparatorList) {
      existingComparatorList = []
      updateObjectInConfig({
        fieldObject: getUseCaseConfig.value?.prediction.guardrails.filter_based,
        key: 'comparators',
        value: [],
      } as StoreDataChangePayload)
    }

    for (let i = 0; i < comparatorsConfig.length; i++) {
      const comparatorsConfigElement: CommonFilter = comparatorsConfig[i]
      const existingConfigElement: CommonFilter | undefined = existingComparatorList.find(
        (item: CommonFilter) => {
          return (
            Object.values(item) === Object.values(comparatorsConfigElement)
          )
        },
      )

      if (!existingConfigElement) {
        existingComparatorList = existingComparatorList.filter(item => item.id !== comparatorsConfigElement.id)
        existingComparatorList.push(comparatorsConfigElement)
        updateObjectInConfig({
          fieldObject: getUseCaseConfig.value?.prediction.guardrails.filter_based,
          key: 'comparators',
          value: existingComparatorList.concat(),
        } as StoreDataChangePayload)
      }
    }

    const configNegateExpression = guardrail.negateExpression === 'Match'

    const guardrailConfig: GuardrailConfigModel = {
      id: guardrail.name,
      negate_expression: configNegateExpression,
      merge_joined_asset: false,
      display_name: guardrail.displayName || '',
      description: guardrail.description,
      expression: convertModelExpressionToConfigExpression<GuardrailConfigMatchingExpression, RootGuardrailConfigExpression>(
        guardrail.expression,
      ) as RootGuardrailConfigExpression,
    }

    let existingGuardrailConfigList: GuardrailConfigModel[] | undefined
      = getUseCaseConfig.value?.prediction.guardrails.filter_based.logic.concat()

    if (!existingGuardrailConfigList) {
      existingGuardrailConfigList = []
      updateObjectInConfig({
        fieldObject: getUseCaseConfig.value?.prediction.guardrails.filter_based,
        key: 'logic',
        value: [],
      } as StoreDataChangePayload)
    }

    const existingGuardrailConfig: GuardrailConfigModel | undefined = existingGuardrailConfigList?.find(
      (item: GuardrailConfigModel) => item.id === guardrail.name,
    )

    if (!existingGuardrailConfig) {
      updateObjectInConfig({
        fieldObject: getUseCaseConfig.value?.prediction.guardrails.filter_based,
        key: 'logic',
        value: existingGuardrailConfigList.concat([guardrailConfig]),
      } as StoreDataChangePayload)
    }
    else {
      const resultGuardrailObject: GuardrailConfigModel = {
        merge_joined_asset: false,
        ...existingGuardrailConfig,
        id: guardrailConfig.id,
        negate_expression: guardrailConfig.negate_expression,
        display_name: guardrailConfig.display_name,
        description: guardrailConfig.description,
        expression: guardrailConfig.expression,
      }

      const guardrailIndex: number = existingGuardrailConfigList.findIndex(
        (item: GuardrailConfigModel) => item.id === guardrail.name,
      )

      existingGuardrailConfigList.splice(guardrailIndex, 1, resultGuardrailObject)

      updateObjectInConfig({
        fieldObject: getUseCaseConfig.value?.prediction.guardrails.filter_based,
        key: 'logic',
        value: existingGuardrailConfigList,
      } as StoreDataChangePayload)
    }

    const existingGroups = Object.keys(getUseCaseConfig.value?.prediction.regime || {}) as CustomerGroupType[]

    existingGroups.forEach((group) => {
      if (guardrail.groups.includes(group)) {
        addGuardrailToGroup(guardrail, group)
      }
      else {
        removeGuardrailFromGroup(guardrail, group)
      }
    })

    removeUnusedGuardrailComparators()
  }

  const prepareUseCaseMetadata = (
    usecaseConfig: UseCaseConfigModel,
    metadataData?: UseCaseMetadataModel,
  ): UseCaseMetadataModel => {
    return metadataData || { version: 1 }
  }

  const getContextObject: () => ActionBankContext = () => {
    return {
      pipelineId: store.getters['pipeline/pipeline']?.id,
      clientId: store.getters['client/client']?.id,
      usecaseId: currentUseCase.value?.id,
    }
  }

  const getActionBankLength = (dimension: string) => {
    if (dimension) {
      const actionBanksArray: ConfigDimensionActionBank[] = _currentState.localActionBank || []
      const configActionBank = actionBanksArray.find((bank: ConfigDimensionActionBank) => bank.name === dimension)
      if (configActionBank) {
        return configActionBank.actions.length
      }
    }

    return 0
  }

  const getActionBankParametersLength = (dimension: string) => {
    if (dimension) {
      const actionBanksArray: ConfigDimensionActionBank[] = _currentState.localActionBank || []
      const configActionBank = actionBanksArray.find((bank: ConfigDimensionActionBank) => bank.name === dimension)
      if (configActionBank) {
        return configActionBank.fields.length
      }
    }

    return 0
  }

  const actions = computed(() => {
    const tempArray = getActionBank(_currentState.currentDimensionName || '')?.actions || []

    tempArray.forEach((item: ActionModel, index: number) => {
      if (!item.key) {
        item.key = `action_${index}`
      }
    })

    return tempArray
  })

  const actionFields = computed<ActionFieldModel[]>(() => {
    return getActionBank(_currentState.currentDimensionName || '')?.fields || []
  })

  const actionBankColumns = computed(() => {
    if (!actionFields.value?.length) {
      return []
    }

    if (!currentDimension.value?.name) {
      return []
    }

    interface SortColumn {
      field: ActionFieldModel
      sort: number
    }

    actionFields.value.forEach((item: ActionFieldModel) => {
      item.fromTemplate = isActionFieldFromTemplate(currentDimension.value!.name!, item)
    })

    return actionFields.value
      .map((actionField: ActionFieldModel): SortColumn => {
        return {
          field: actionField,
          sort: actionField.data.sort,
        } as SortColumn
      })
      .sort((a: SortColumn, b: SortColumn) => {
        if (b.field.data.column === ActionColumn.ACTION_ID) {
          return 1
        }
        if (a.field.data.column === ActionColumn.ACTION_STATUS) {
          return -1
        }
        if (a.field.data.column === ActionColumn.CREATED_DATE && b.field.data.column === ActionColumn.ACTION_STATUS) {
          return 1
        }
        if (a.field.data.column === ActionColumn.ACTION_STATUS && b.field.data.column === ActionColumn.CREATED_DATE) {
          return -1
        }
        if (a.field.data.column === ActionColumn.ACTION_ID
          && (b.field.data.column === ActionColumn.ACTION_STATUS || b.field.data.column === ActionColumn.CREATED_DATE)) {
          return 0
        }

        return a.sort - b.sort
      })
      .map((item: SortColumn): ActionFieldModel => item.field)
  })

  const fullActionBank = computed<DimensionActionBank[]>(() => {
    const dimensionKeys = dimensions.value?.map((dimension: DimensionConfigModelPartial) => dimension.name) || []
    return (
      _currentState.localActionBank
        ?.filter((dimensionActionBank: ConfigDimensionActionBank) => dimensionKeys.includes(dimensionActionBank.name))
        ?.map(getWrappedActionBank) || []
    )
  })

  const currentActionBank = computed(() => {
    return fullActionBank.value.find((item: DimensionActionBank) => item.name === _currentState.currentDimensionName)
  })

  const actionAugmentations = computed<ActionAugmentations | undefined>(() => {
    return getUseCaseConfig.value?.filter_and_augment_recs?.augmentation?.actions
  })

  const fieldsForOutputFlat = computed<Array<string> | undefined>(() => {
    const actionsFields: string[] = []
    if (actionAugmentations.value) {
      Object.keys(actionAugmentations.value).forEach((d: string) => {
        for (const i of actionAugmentations.value?.[d as any] as any) {
          actionsFields.push(i)
        }
      })
    }
    return (getUseCaseConfig.value?.filter_and_augment_recs?.augmentation?.features || []).concat(actionsFields || [])
  })

  const actionFieldsForOutputFlat = computed<Array<{
    value: string
    dimension: string
  }> | undefined>(() => {
    const actionsFields: {
      value: string
      dimension: string
    }[] = []
    if (actionAugmentations.value) {
      Object.keys(actionAugmentations.value).forEach((d: string) => {
        for (const i of actionAugmentations.value?.[d as any] as any) {
          actionsFields.push({ value: i, dimension: d })
        }
      })
    }
    return actionsFields || []
  })

  const actionBanksForTree = computed<ActionBanksForTree | undefined>(() => {
    const fullActionBankTree: ActionBanksForTree = {}
    if (!dimensions.value) {
      return
    }

    fullActionBank.value?.forEach((action: DimensionActionBank) => {
      fullActionBankTree[action.name] = action.fields?.map((fields: ActionFieldModel) => {
        return {
          label: fields.data.column,
          dimension: action.name,
          description: fields.data.description,
          value: fields.data.column,
          type: OutPutItemType.ACTION_FEATURES_ITEM_TYPE,
          isLeaf: true,
          suffix: () =>
            h(
              NButton,
              {
                text: true,
                type: 'primary',
              },
              {
                default: () => {
                  if (
                    actionFieldsForOutputFlat.value?.find((selected: any) => selected.value === fields.data.column)?.dimension
                    === action.name
                    || store.getters.isReadonlyMode
                  ) {
                    return ''
                  }
                  else {
                    return 'add'
                  }
                },
              },
            ),
        }
      })
    })

    return fullActionBankTree
  })

  const variantActionMap = computed(() => {
    if (!_currentState.localActionBank) { return }
    const map = new Map<string, number>()

    _currentState.localActionBank.filter((item: ConfigDimensionActionBank) => item.name === 'email')[0].actions.forEach(
      (action) => {
        map.set(
          action.fields.find(
            field => field.name === 'variant_id',
          )?.value as string,
          action.fields.find(
            field => field.name === 'action_id',
          )?.value as number,
        )
      },
    )
    return map
  })

  const updateAugmentationConfig = (selected: ActionBanksForOutputPartial) => {
    if (selected.type === OutPutItemType.FEATURES_ITEM_TYPE && selected.value) {
      addFeatureAugmentation(selected.value)
    }

    if (selected.type === OutPutItemType.ACTION_FEATURES_ITEM_TYPE) {
      addActionAugmentation(selected)
    }
  }

  const removeAction = (action: ActionModel) => {
    if (!_currentState.currentDimensionName) {
      return
    }

    updateActionBank({
      name: _currentState.currentDimensionName,
      actions:
        actions.value?.filter((item: ActionConfigModel) => {
          if (item.fields === action.fields) {
            return false
          }
          if (getActionId(item) && getActionId(action)) {
            return getActionId(item) !== getActionId(action)
          }

          return true
        }) || [],
    })
  }

  const allAudienceAvailableFeaturesList: ComputedRef<SelectItem[]> = computed<SelectItem[]>(() => {
    if (!allFeatureNameList.value) {
      return []
    }

    return allFeatureNameList.value?.map((item: string) => ({ label: item, value: item } as SelectItem))
  })

  const allControlGroupsAvailableFeaturesList: ComputedRef<SelectItem[]> = computed<SelectItem[]>(() => {
    if (!allFeatureNameList.value) {
      return [{ label: CUSTOMER_ID_COLUMN, value: CUSTOMER_ID_COLUMN } as SelectItem]
    }

    return [{ label: CUSTOMER_ID_COLUMN, value: CUSTOMER_ID_COLUMN } as SelectItem].concat(
      allFeatureNameList.value?.map((item: string) => ({ label: item, value: item } as SelectItem)),
    )
  })

  const getActionParameterValues = (parameterName: string) => {
    if (currentActionBank.value && currentActionBank.value.actions) {
      const values = currentActionBank.value.actions.map((item: ActionModel) => {
        if (item.fields && item.fields.length) {
          const filteredField = item.fields.find((item: ActionValueModel) => item.name === parameterName)
          return filteredField ? filteredField.value : undefined
        }

        return undefined
      })

      return uniq(values)
    }

    return []
  }

  const getInitialUseCaseConfig: () => UseCaseConfigModel = () => ({
    actionBanks: [],
    ...getUseCaseConfigWithoutActionBanks(),
  })

  const usecaseEvents = computed<TypeEventMap>(() => {
    return getUseCaseConfig.value?.events as TypeEventMap
  })

  const usecaseEventItemList = computed<EventItemModel[]>(() => {
    if (!getUseCaseConfig.value?.events) {
      return []
    }

    const events = getUseCaseConfig.value?.events
    const keys = Object.keys(events)

    const array: EventItemModel[] = []

    // tree structured events from config
    keys.forEach((type: string) => {
      events[type]?.forEach((item: CommonEventType | ConversionEventType) => {
        array.push({
          ...item,
          key: type as EventTypeKey,
          storeItem: item,
        } as EventItemModel)
      })
    })

    return array
  })

  function mapEventItemModelToCommonEventType(event: EventItemModel) {
    return omit<CommonEventType>(event, ['key', 'storeItem'])
  }

  // not full check, doesn't include error checking that happens in channels table
  function isEventReady(event: EventItemModel) {
    return !!event.asset
      && !!event.event_ts_col
      && !!event.match_by
  }

  const whatDimensions = computed(() => {
    if (!dimensions.value) {
      return []
    }

    return dimensions.value
      .filter((dimension: DimensionConfigModelPartial) => {
        const dimensionKey = dimension.name
        return dimensionKey && getDimensionType(dimensionKey) === DIMENSION_TYPE.WHAT
      })
      .map((dimension: DimensionConfigModelPartial) => dimension.name)
      .filter(Boolean)
  })

  const conversionEventObject = computed(() => {
    if (!usecaseEventItemList.value) {
      return
    }

    return usecaseEventItemList.value.find((event: EventItemModel) => isEventReady(event)
      && event.event_type === ConversionEvent.CONVERSION)
  })

  function getChannelActivationEventObject(channel: EventChannel) {
    if (!channel) { return }
    if (!usecaseEventItemList.value) {
      return
    }

    return usecaseEventItemList.value.find((event: EventItemModel) => isEventReady(event)
      && event.event_type === ChannelActivationEventMap.get(channel))
  }

  function getChannelEngagementEventObjects(channel: EventChannel) {
    if (!channel) { return }
    if (!usecaseEventItemList.value) {
      return
    }

    return usecaseEventItemList.value.filter((event: EventItemModel) => isEventReady(event)
      && ChannelEngagementEventMap.has(channel)
      && ChannelEngagementEventMap.get(channel)?.includes(event.event_type))
  }

  function getChannelEngagementEvents(channel: EventChannel) {
    if (!getChannelEngagementEventObjects(channel)?.length) { return }

    const eventsInConfig = getChannelEngagementEventObjects(channel)?.map(eventObject => eventObject.event_type)

    return ChannelEngagementEventMap.get(channel)?.filter((event: EngagementEvent) => eventsInConfig && eventsInConfig.includes(event))
  }

  function getChannelPenaltyEventObject(channel: EventChannel) {
    if (!channel) { return }
    if (!usecaseEventItemList.value) {
      return
    }

    return usecaseEventItemList.value.find((event: EventItemModel) => isEventReady(event)
      && ChannelPenaltyEventMap.get(channel) === event.event_type)
  }

  function getChannelPenaltyEvent(channel: EventChannel) {
    if (!getChannelPenaltyEventObject(channel)) { return }

    return ChannelPenaltyEventMap.get(channel) === getChannelPenaltyEventObject(channel)?.event_type ? ChannelPenaltyEventMap.get(channel) : undefined
  }

  function getChannelActivationEvent(channel: EventChannel) {
    if (!getChannelActivationEventObject(channel)) { return }

    return ChannelActivationEventMap.get(channel) === getChannelActivationEventObject(channel)?.event_type ? ChannelActivationEventMap.get(channel) : undefined
  }

  const conversionTypeIsNumber = computed(() => {
    return conversionEventObject.value?.conversion_by_row
  })

  const generatedChannelEventItemList = computed<UsecaseEventPlaceholderType[] | undefined>(() => {
    const channels = getUseCaseConfig.value?.channels || []

    return (
      channels.flatMap(
        (channel: EventChannel) =>
          ExposedChannelEvents.get(channel)?.map((item: ChannelEvent) => {
            return {
              key: EVENT_DEFAULTS.get(item),
              event_type: item,
            } as UsecaseEventPlaceholderType
          }) || [],
      ) || []
    )
  })

  const availableChannelsForAdding = computed<ItemWithDescription<EventChannel>[]>(() => {
    const channels = getUseCaseConfig.value?.channels || []
    return channelListOptions.filter((channelOption: ItemWithDescription<EventChannel>) => !channels.includes(channelOption.value))
  })

  const targetMetric = computed<NullString>(() => {
    return (getUseCaseConfig.value?.events?.conversion?.[0] as ConversionEventType)?.target_metric_col
  })

  const targetMetricDisplayName = computed<NullString | undefined>(() => {
    // Keeping legacy target metric name so reporting doesn't break for usecases using old training config
    return (
      (getUseCaseConfig.value?.events?.conversion?.[0] as ConversionEventType)?.target_metric_display_name
      || getUseCaseConfig.value?.training.target_metric.metric_name
    )
  })

  const loadAudienceData = async () => {
    const client = store.getters['client/client'].name
    if (currentUseCase.value) {
      try {
        _currentState.targetedAudienceData = await PerformanceReportService.getAudienceBreakdown(
          client,
          currentUseCase.value?.name,
        )
      }
      catch (e) {
        console.error(e)
        _currentState.targetedAudienceData = []
      }

      _currentState.targetedAudienceData.sort(
        (a: AudienceBreakdownDataModel, b: AudienceBreakdownDataModel) => {
          if (a.regime === REGIME_OFFERFIT) {
            return -1
          }
          if (a.regime === b.regime) {
            return 0
          }
          return 1
        },
      )
    }
  }

  const getNoInterventionGroup: ComputedRef<NullString> = computed<NullString>(() => {
    return getUseCaseConfig.value?.prediction.no_intervention_group
  })

  const getNoInterventionGroupDisplayName: ComputedRef<NullString> = computed<NullString>(() => {
    if (!getNoInterventionGroup.value) {
      return
    }
    return customerGroups.value?.find((regime: CustomerGroupModel) => regime.name === getNoInterventionGroup.value)?.displayName
  })

  const totalAudienceCount = computed<number | undefined>(() => {
    if (_currentState.targetedAudienceData && _currentState.targetedAudienceData.length) {
      return _currentState.targetedAudienceData.reduce((acc: number, value: AudienceBreakdownDataModel) => {
        return acc + value.customers
      }, 0)
    }

    return undefined
  })

  const getAudienceGroupPercents = (regime: string) => {
    const group = _currentState.targetedAudienceData?.find((item: AudienceBreakdownDataModel) => item.regime === regime)
    if (group && totalAudienceCount.value) {
      return Math.round((group.customers / totalAudienceCount.value) * 10000) / 100
    }

    return 0
  }

  const dimensionActionsConfig = computed<ConfigDimensionOnlyActionsAndName[]>(() => {
    const actionBanksArray: ConfigDimensionActionBank[] = _currentState.localActionBank || []

    return actionBanksArray.map(
      (item: ConfigDimensionActionBank) => ({ name: item.name, actions: item.actions } as ConfigDimensionOnlyActionsAndName),
    )
  })

  const areCurrentActionsChanged: ComputedRef<boolean> = computed<boolean>(() => {
    if (!_currentState.isCurrentUseCaseLive) {
      return false
    }
    if (!_currentState.currentDimensionName) {
      return false
    }

    const actionBank = getActionBank(_currentState.currentDimensionName)
    const liveActionBank = useLiveUseCaseConfig().getActionBank(_currentState.currentDimensionName)

    return !!actionBank && !!liveActionBank && !isCurrentActionsAreEqualToLiveActions(actionBank, liveActionBank)
  })

  const isCurrentActionsPromotable = computed(() => {
    if (!_currentState.isCurrentUseCaseLive) {
      return false
    }
    if (!_currentState.currentDimensionName) {
      return false
    }

    const actionBank = getActionBank(_currentState.currentDimensionName)
    const liveActionBank = useLiveUseCaseConfig().getActionBank(_currentState.currentDimensionName)

    let isPromotable = false
    _currentState.actionBankPromotionError = null

    if (actionBank && liveActionBank) {
      try {
        isPromotable = isActionBankPromotable(actionBank, liveActionBank)
      }
      catch (e: any) {
        _currentState.actionBankPromotionError = e.message
        if (e instanceof ActionBankLiveActionIsModifiedError) {
          console.error(e.message, e.missedAction)
        }
        else if (e instanceof ActionBankRequiredActionIsEmptyError) {
          console.error(e.message, e.emptyActions)
        }
      }
    }

    return isPromotable
  })

  const preprocessActionsForTable = (data: ActionModel[], liveData?: ActionModel[]) => {
    markDuplicatedActions(data)

    if (liveData?.length) {
      markChangedActions(data, liveData)
    }

    return data
  }

  const getCurrentAndParentDimensions = (dimensionName: DimensionName): DimensionConfigModelPartial[] => {
    return (
      dimensions.value?.slice(
        0,
        dimensions.value?.findIndex((item: DimensionConfigModelPartial) => item.name === dimensionName) + 1,
      ) || []
    )
  }

  const dayDimWeekValues = computed(
    () => {
      const daysOfWeekFields = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

      if (_currentState.currentDimensionName !== DimensionKey.DAYSOFWEEK) {
        return []
      }
      const actionBankActions = getActionBank(_currentState.currentDimensionName || '')?.actions || []

      const frequencyValidForCounts = actionBankActions.map((row) => {
        const newFrequency = daysOfWeekFields.reduce((count, day) => {
          const dayField = row.fields.find((item: ActionValueModel) => item.name === day)
          const validTrueValues = new Set(['True', 'true', true])
          if (validTrueValues.has(dayField?.value)) {
            return count + 1
          }
          return count
        }, 0)
        return newFrequency
      })

      return frequencyValidForCounts
    },
  )

  const initUseCasePageHooks = () => {
    watch(
      () => _currentState.localUseCaseConfig,
      () => {
        if (_currentState.localUseCaseConfig) {
          _currentState.currentDimensionName = dimensions.value?.[0]?.name
        }
      },
    )

    watch(currentUseCase, async () => {
      if (currentUseCase.value) {
        await loadAudienceData()

        _currentState.isCurrentUseCaseLive = currentUseCase.value?.status === UseCaseStatus.LIVE
      }
    })

    // this watcher tracks current dimension change
    // and if action fields don't include template parameters
    // it injects them with respect to existing parameters
    watch(actionFields, () => {
      if (actionFields.value) {
        let actionBankShouldBeUpdated: boolean = false

        const fieldsWithTemplate = getTemplateParametersByDimensionName(
          _currentState.currentDimensionName as string,
          actionFields.value,
        )

        const fieldsWithTemplateNames = fieldsWithTemplate.map((fieldModel: ActionFieldModel) => fieldModel.data.column)
        const actualFieldsNames = actionFields.value.map((fieldModel: ActionFieldModel) => fieldModel.data.column)
        const nonReactiveActions: ActionModel[] = [...actions.value]

        for (let i = 0; i < fieldsWithTemplateNames.length; i++) {
          const fieldName = fieldsWithTemplateNames[i]
          if (!actualFieldsNames.includes(fieldName)) {
            const actionField = fieldsWithTemplate.find((fieldModel: ActionFieldModel) => fieldModel.data.column === fieldName)

            // if new action field (parameter) has default value, we need to populate it. It might be 'undefined' as well.
            if (actionField) {
              actionBankShouldBeUpdated = true
              for (let i = 0; i < nonReactiveActions.length; i++) {
                const nonReactiveAction: ActionModel = nonReactiveActions[i]
                nonReactiveAction.fields.push({
                  name: fieldName,
                  value: getDefaultValueForNewActionItem(fieldName, actionField.data.default_value),
                } as ActionValueModel)
              }
            }
          }
        }

        if (actionBankShouldBeUpdated) {
          updateActionBank({
            name: _currentState.currentDimensionName as string,
            actions: nonReactiveActions,
            fields: fieldsWithTemplate,
          } as DimensionActionBankPartial)
        }
      }
    })

    watch(dayDimWeekValues, () => {
      if (_currentState.currentDimensionName === DimensionKey.DAYSOFWEEK) {
        const actionValues = getActionBank(_currentState.currentDimensionName || '')?.actions || []
        actionValues.forEach((actionValue, index) => {
          const newValue = dayDimWeekValues.value[index]
          const frequencyValidFor = actionValue.fields.find(
            (item: ActionValueModel) => item.name === 'frequency_valid_for',
          )
          if (frequencyValidFor) {
            frequencyValidFor.value = newValue.toString()
          }
        })
      }
    })
  }

  const getPredictionMethodNameByMapping = (
    predictionMethodObjectMapping: Map<DimensionName, PredictionMethodModel> | undefined,
  ): PredictionMethodLabel | string => {
    const mappingValues = Array.from(predictionMethodObjectMapping?.values() || [])
    const mappingEntries = Array.from(predictionMethodObjectMapping?.entries() || [])

    if (!mappingValues.length) {
      return 'Please select the prediction method'
    }

    if (mappingValues.every((item: PredictionMethodModel) => item.method === PredictionMethodConfigValue.RANDOM_ACTION)) {
      return 'Random actions'
    } // PredictionMethodLabel.RANDOM_ACTION with an 's' at the end

    if (mappingValues.every((item: PredictionMethodModel) => item.actionId === -1)) {
      return PredictionMethodLabel.NO_SEND_ANY_ACTION
    }

    const nameDisplayNameMap = new Map<DimensionName, string>()

    for (let i = 0; i < (dimensions.value?.length || 0); i++) {
      const dimension = dimensions.value?.[i]
      nameDisplayNameMap.set(dimension?.name || '', dimension?.display_name || '')
    }

    const mapStringArray: string[] = mappingEntries.map((item: [string, PredictionMethodModel]) => {
      const leftPart: string = `${nameDisplayNameMap.get(item[0]) || item[0]}`
      const rightPart: string
        = item[1].method === PredictionMethodConfigValue.RANDOM_ACTION
          ? 'uses random action'
          : `action id: ${item[1].actionId || '-'}`
      return `${leftPart} ${rightPart}`
    })

    return `<span class="font-medium">${PredictionMethodLabel.SPECIFIED_ACTION}</span><br/>
      ${mapStringArray.join('<br/>')}
    `
  }

  const dimensionNameOptions: ComputedRef<SelectItem[] | undefined> = computed<SelectItem[] | undefined>(() => {
    return (
      dimensions.value?.map((item: DimensionConfigModelPartial) => {
        return {
          label: item.display_name,
          value: item.name,
        } as SelectItem
      }) || undefined
    )
  })

  const conversionAttributionMethod = computed(() => {
    return getUseCaseConfig.value?.training.attribution_type
  })

  const updateConversionAttributionTypeInConfig = (payload: TouchType) => {
    updateConversionAttributionType(payload)
  }

  const isOrchestratedBAU = computed<boolean>(() => {
    const bauPredictionMethod = getUseCaseConfig.value?.prediction.regime?.bau?.prediction_method
    if (!bauPredictionMethod) {
      return false
    }

    return Object.keys(bauPredictionMethod).some((key) => {
      const element = bauPredictionMethod[key]
      return element.kwargs && element.kwargs.assigned_action_id !== -1
    })
  })

  const updateExperiencesToIncludeDefaults = (value: ExperiencesModel) => {
    if (!getExperiencesFromConfig.value) {
      return
    }

    // (a) get existing experiences from config & add default to the experience being updated
    const experienceUpdatedWithDefaults
      = getExperiencesFromConfig.value
        .map(
          (experience: ExperiencesModelPartial) =>
            returnExperienceWithDefaults(allTrainingEvents.value, experience),
        )
        .find(
          (item: ExperiencesModel) =>
            item.dimension === value.dimension && (value.trigger ? item.trigger === value.trigger : true),
        ) || returnExperienceWithDefaults(allTrainingEvents.value, value)

    // (b) update experiences array with new default for specific experience
    const updatedExperiences = getExperiencesFromConfig.value.map((item: ExperiencesModel) => {
      if (
        item.dimension === experienceUpdatedWithDefaults?.dimension
        && (item?.trigger ? item.trigger === experienceUpdatedWithDefaults?.trigger : true)
      ) {
        return experienceUpdatedWithDefaults
      }

      return item
    })

    editTrainingExperiences(updatedExperiences)
  }

  const addNewExperience = () => {
    addTrainingExperience(returnExperienceWithDefaults(allTrainingEvents.value, undefined, null))
  }

  const deleteExperience = (experience: ExperiencesModel) => {
    editTrainingExperiences(getExperiencesFromConfig.value?.filter((item: ExperiencesModel) => item !== experience))
  }

  const clearExperienceItem = (experience: StoreDataChangePayload) => {
    const currentExperiences = toRaw(getExperiencesFromConfig.value)
    const upForClearing = currentExperiences?.find(
      (item: ExperiencesModel) => item === (toRaw(experience.fieldObject) as ExperiencesModel),
    )
    const payload: ExperiencesModel = {
      dimension: experience.fieldObject.dimension,
      primary: {
        event: undefined,
        days: undefined,
      },
      trigger: experience.fieldObject.trigger,
    }

    const index: number = currentExperiences?.indexOf(upForClearing as ExperiencesModel)
    currentExperiences?.splice(index, 1, payload)

    editTrainingExperiences(currentExperiences)
  }

  // from useEvents.ts
  const createdEventsList = computed<ChannelEvent[] | undefined>(() => {
    if (!usecaseEventItemList.value) {
      return
    }
    return usecaseEventItemList.value?.map((item: EventItemModel) => item.event_type)
  })

  const eventChannelList = computed<EventChannel[] | undefined>(() => {
    if (!createdEventsList.value) {
      return
    }
    return createdEventsList.value
      .map((item: ChannelEvent) => returnChannelListFromEvents(item))
      .filter((item: EventChannel | undefined) => item !== undefined)
      .filter(filterOnlyUniqueCallback) as EventChannel[]
  })

  const eventsPerChannelTableData = computed<PerChannelTableDataModel[] | undefined>(() => {
    if (!eventChannelList.value) {
      return
    }
    return eventChannelList.value.map((channel: EventChannel) => {
      return {
        channel,
        penalty: ChannelPenaltyEventMap.get(channel),
        sequence: getSequencePerChannel(channel),
        requiredConversionEvent: getUseCaseConfig.value?.training?.event_sequences.find(
          (item: EventSequenceConfigModel) => item.channel === channel,
        )?.required_event_for_conversion,
      } as PerChannelTableDataModel
    })
  })

  const loadUseCaseDataQuery = useQuery({
    queryKey: ['usecase', usecase],
    queryFn: async () => {
      return Promise.allSettled([loadActionBanksFromServer(), loadUseCaseConfigFromServer()])
    },
    enabled: computed(() => !!usecase.value),
    staleTime: 500,
  })

  const loadUseCaseData = async () => {
    await loadUseCaseDataQuery.refetch()
  }

  const hasUseCaseConfigChanged = computed<boolean>(() => {
    if (!_currentState.localUsecaseConfigHash || !_currentState.remoteUsecaseConfigHash) {
      return false
    }
    return _currentState.localUsecaseConfigHash !== _currentState.remoteUsecaseConfigHash
  })

  const hasActionBankChanged = computed<boolean>(() => {
    const { localActionBankConfigHash, remoteActionBankConfigHash } = toRefs(_currentState)
    if (!localActionBankConfigHash.value || !remoteActionBankConfigHash.value) {
      return false
    }
    return localActionBankConfigHash.value !== remoteActionBankConfigHash.value
  })

  const hasUseCaseModelChanged = computed<boolean>(() => {
    return !isEqual(_currentState.localUsecase, _currentState.remoteUseCase)
  })

  const debouncedSaveConfig = debounce(saveUseCaseConfig, REQUEST_DEBOUNCE_DELAY - 200)
  const debouncedSaveActionBank = debounce(saveActionBankConfig, REQUEST_DEBOUNCE_DELAY + 100)
  const debouncedSaveUseCaseModel = debounce(saveUseCaseModel, REQUEST_DEBOUNCE_DELAY + 300)

  function initChangeWatchers() {
    watch(hasUseCaseConfigChanged, () => {
      if (hasUseCaseConfigChanged.value) {
        void debouncedSaveConfig()
      }
    })

    watch(hasActionBankChanged, () => {
      if (hasActionBankChanged.value) {
        void debouncedSaveActionBank()
      }
    })

    watch(hasUseCaseModelChanged, () => {
      if (hasUseCaseModelChanged.value) {
        void debouncedSaveUseCaseModel()
      }
    })
  }

  if (isGlobal && !initialized) {
    initialized = true
    initChangeWatchers()
  }

  return {
    actionAugmentations,
    actionBankColumns,
    actionBanksForTree,
    variantActionMap,
    actionFields,
    actions,
    addCadenceRecommendedToExperiences,
    addNewExperience,
    allAudienceAvailableFeaturesList,
    allControlGroupsAvailableFeaturesList,
    areCurrentActionsChanged,
    audience,
    audienceComparators,
    availableChannelsForAdding,
    changeDimensionDescription,
    clearExperienceItem,
    configureDimension,
    convertModelExpressionToConfigExpression,
    createDimension,
    currentDimension,
    customerGroups,
    channelsList,
    deleteCadenceRecommendedExperience,
    deleteExperience,
    dimensionActionsConfig,
    dimensionGuardrailMap,
    dimensionNameOptions,
    dimensionOptions,
    dimensions,
    doesDimensionWithNameExist,
    fieldsForOutputFlat,
    fullActionBank,
    getActionBank,
    getActionBankLength,
    getActionBankParametersLength,
    loadActionBanksFromServer,
    loadUseCaseConfigFromServer,
    getActionParameterValues,
    getAudienceGroupPercents,
    getComparatorsConfigModelsFromExpression,
    getContextObject,
    conversionAttributionMethod,
    getCurrentAndParentDimensions,
    getCustomerGroupDisplayNameByName,
    getExperiencesFromConfig,
    getFilterUniqueId,
    getInitialUseCaseConfig,
    getNoInterventionGroup,
    getNoInterventionGroupDisplayName,
    getNodeExpression,
    getNodeOperator,
    getPredictionMethodNameByMapping,
    getUseCaseConfig,
    useCaseMetadata,
    guardrailComparators,
    guardrails,
    addGuardrailToGroup,
    hasUseCaseConfigChanged,
    hasActionBankChanged,
    hasUseCaseModelChanged,
    initUseCasePageHooks,
    initChangeWatchers,
    isActionFieldFromTemplate,
    isCurrentActionsPromotable,
    loadAudienceData,
    preprocessActionsForTable,
    nodeIsOperator,
    prepareUseCaseMetadata,
    removeAction,
    removeAudience,
    removeControlGroup,
    removeDimension,
    deleteGuardrail,
    renameDimension,
    sortDimensionsOrderInConfigObject,
    synchronizeLocalDimensions,
    targetMetric,
    targetMetricDisplayName,
    totalAudienceCount,
    updateAudience,
    updateAugmentationConfig,
    updateControlGroup,
    updateConversionAttributionTypeInConfig,
    updateDimensionConfigInStoreModelByPartial,
    updateExperiencesToIncludeDefaults,
    updateGuardrail,
    usecaseEvents,
    usecaseEventItemList,
    generatedChannelEventItemList,

    whatDimensions,

    isEventReady,
    getChannelEngagementEvents,
    getChannelPenaltyEventObject,
    getChannelPenaltyEvent,
    getChannelActivationEvent,
    conversionTypeIsNumber,
    mapEventItemModelToCommonEventType,

    loadUseCaseDataQuery,
    loadUseCaseData,
    loadLiveUseCase,
    saveUseCaseConfig,
    saveActionBankConfig,
    saveUseCaseModel,

    guardrailsTableData,
    isOrchestratedBAU,

    currentUseCase,
    localActionBank: toRef(_currentState, 'localActionBank'),
    localUseCaseConfig: toRef(_currentState, 'localUseCaseConfig'),
    currentDimensionName: toRef(_currentState, 'currentDimensionName'),
    isCurrentUseCaseLive: toRef(_currentState, 'isCurrentUseCaseLive'),
    targetedAudienceData: toRef(_currentState, 'targetedAudienceData'),
    actionBankPromotionErrorMessage: toRef(_currentState, 'actionBankPromotionError'),
    localUseCaseConfigWithActionBanks: getUseCaseConfigWithActionBank,

    ...useCaseMutations,

    state: _currentState,

    createdEventsList,
    eventChannelList,
    eventsPerChannelTableData,
  }
}

export type UseCaseConfigReactive = ReturnType<typeof _useUseCaseConfig>

const useCaseConfigCacheMap = new Map<UseCaseState, UseCaseConfigReactive>()

/***
 *
 * Avoid using this composable as its a global and is not stable.
 * **/
export function useUseCaseConfig(stateObject?: UseCaseState): UseCaseConfigReactive {
  if (!useCaseConfigCacheMap.has(stateObject || globalState)) {
    useCaseConfigCacheMap.set(stateObject || globalState, useCaseEffectScope.run<UseCaseConfigReactive>(() => {
      return _useUseCaseConfig(stateObject)
    }) as UseCaseConfigReactive)
  }

  return useCaseConfigCacheMap.get(stateObject || globalState) as UseCaseConfigReactive
}

export function getIsolatedUseCaseConfig(useCaseModel?: UseCaseModelWithoutConfig): UseCaseConfigReactive {
  return useUseCaseConfig(createUseCaseStateObject(useCaseModel))
}

let globalLiveStateWatcher: WatchStopHandle | undefined
export function useLiveUseCaseConfig() {
  if (!globalLiveState) { globalLiveState = createUseCaseStateObject() }

  if (!globalLiveStateWatcher) {
    globalLiveStateWatcher = watch(
      () => globalState?.liveUsecase,
      () => {
        if (globalLiveState && globalState?.liveUsecase) {
          const { config, ...usecaseModelData } = toRaw(globalState.liveUsecase)
          globalLiveState.localUsecase = usecaseModelData as UseCaseModelWithoutConfig
          const { actionBanks, ...configWithoutActionBank } = config
          globalLiveState.localUseCaseConfig = configWithoutActionBank as UseCaseConfigModelWithoutActionBank
          globalLiveState.localActionBank = actionBanks as ConfigDimensionActionBank[]
        }
      },
    )
  }

  return useUseCaseConfig(globalLiveState)
}

export async function loadIsolatedUseCase(useCase: UseCaseModelWithoutConfig): Promise<UseCaseConfigReactive> {
  const useCaseConfig = getIsolatedUseCaseConfig(useCase)
  await useCaseConfig.loadUseCaseData()
  return useCaseConfig
}
