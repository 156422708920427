<script lang="ts" setup>
import {type Client} from '@level3/types/client'
import {NAvatar, NButton} from 'naive-ui'

type Props = {
  client: Client
  userClients: Client[]
  className?: string
}
withDefaults(defineProps<Props>(), {  })

const emit = defineEmits<{
  addClient: [Client]
  removeClient: [Client]
}>()

const isMyClient = computed(() => {
  return __props.userClients.some(
    (currentClient: Client) => currentClient.name === __props.client.name,
  )
})

const nameInitials = computed(() =>
  __props.client.displayName.slice(0, 2).toUpperCase(),
)

// TODO: Remove this function when level3 v2 is finished, use NLink instead
const handleSelectClient = (clientName: string) => {
  window.location.href = `/use-cases?org=${clientName}&pipeline=default`
}
</script>

<template>
  <div class="flex justify-between" :class="className">
    <div class="flex items-center gap-4">
      <NAvatar
        round
        :style="{
          color: 'black',
          backgroundColor: 'transparent',
          border: '1px solid #d1d5db',
        }"
        >{{ nameInitials }}</NAvatar
      >
      <p class="text-base font-semibold">{{ client.displayName }}</p>
    </div>
    <div class="flex items-center gap-2">
      <NButton
        v-if="isMyClient"
        round
        ghost
        color="#000000"
        size="small"
        @click="emit('removeClient', client)"
      >
        - My clients
      </NButton>
      <NButton
        v-else
        ghost
        round
        color="#000000"
        size="small"
        @click="emit('addClient', client)"
      >
        + My clients
      </NButton>
      <!-- TODO: Introduce NLink once level3v2 is finished -->
      <NButton
        v-if="isMyClient"
        round
        secondary
        color="#000000"
        size="small"
        @click="handleSelectClient(client.name)"
      >
        Select
      </NButton>
    </div>
  </div>
</template>
