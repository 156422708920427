import type { AxiosResponse } from 'axios'
import qs from 'qs'
import { POLLING_DELAY, TASK_STATUS } from '@shared/data/constants'
import { store } from '@app/store'
import type { StoreDataChangePayload, StrapiData, StrapiDataArray, TaskListener } from '@shared/utils/Types'
import Global from '@shared/utils/global'
import { getModelFromStrapiData } from '@shared/utils/helpers'
import http from '@app/utils/http-client'
import type { JobHistoryModel } from '@/dags/models/server/JobHistoryModel'

export default class K8TaskService {
  static pollingTimer: number = Number.NaN

  static KUBERNETES_RUN_TASK: string = '/k8/run_task'
  static KUBERNETES_GET_LOGS: string = '/k8/get_task_logs'
  static TASKS_LIST: string = '/job-histories'

  static listeners: TaskListener[] = []

  static async startTask(
    queryObject: any,
    completedMessageText: string | (() => VNodeChild),
    failedMessageText: string | (() => VNodeChild),
    imitateProgress: boolean = false,
  ): Promise<JobHistoryModel> {
    const query = qs.stringify(queryObject, { encodeValuesOnly: true })
    const response: AxiosResponse = await http.get(`${K8TaskService.KUBERNETES_RUN_TASK}?${query}`)

    const taskJob = response?.data as JobHistoryModel
    taskJob.taskSucceededText = completedMessageText
    taskJob.taskFailedText = failedMessageText
    taskJob.imitateProgress = imitateProgress

    return taskJob
  }

  static async getAllTasks(clientID: number, pipelineTask?: string | string[]): Promise<StrapiDataArray<JobHistoryModel>> {
    const queryData = {
      filters: {
        client: {
          id: clientID,
        },
        job_status: {
          $in: [TASK_STATUS.PENDING, TASK_STATUS.RUNNING],
        },
        pipeline_task: undefined as {
          $in: string[] | undefined
        } | undefined,
      },
      populate: {
        pipeline: {
          fields: ['name'],
        },
        usecase: {
          fields: ['name'],
        },
      },
    }

    if (pipelineTask) {
      queryData.filters.pipeline_task = {
        $in: Array.isArray(pipelineTask) ? pipelineTask : [pipelineTask],
      }
    }

    const query = qs.stringify(queryData)

    const response = await http.get<StrapiDataArray<JobHistoryModel>>(`${K8TaskService.TASKS_LIST}?${query}`, {
      cache: false,
    })

    return response?.data
  }

  static async getTaskLogs(jobName: string): Promise<string[]> {
    const response = await http.get<string[]>(`${K8TaskService.KUBERNETES_GET_LOGS}?jobName=${jobName}`)
    return response?.data?.map((line: object | string) => (typeof line === 'object' ? '' : line))
  }

  static async getJobHistoryItem(taskId: number): Promise<StrapiData<JobHistoryModel>> {
    const response = await http.get<StrapiData<JobHistoryModel>>(`${K8TaskService.TASKS_LIST}/${taskId}`, { cache: false })
    return response.data
  }

  static pullStatuses() {
    if (K8TaskService.pollingTimer) { clearTimeout(K8TaskService.pollingTimer) }

    K8TaskService.pollingTimer = window.setTimeout(async () => {
      const currentTasks = store.getters.jobTasks
      let updatedTasks = currentTasks.concat()
      let somethingChanged = false

      if (currentTasks.length) {
        for (let i = 0; i < currentTasks.length; i++) {
          const currentTask = currentTasks[i] as JobHistoryModel
          let task: JobHistoryModel | undefined

          if (currentTask.job_status === TASK_STATUS.PENDING || currentTask.job_status === TASK_STATUS.RUNNING) {
            if (currentTask.id) {
              try {
                // reload the job history item
                task = getModelFromStrapiData(await K8TaskService.getJobHistoryItem(currentTask.id))
              }
              catch (error: any) {
                if (currentTask.messageRef) {
                  currentTask.messageRef.destroy()
                  store.commit('updateObjectInStore', {
                    fieldObject: currentTask,
                    key: 'message',
                    value: null,
                  } as StoreDataChangePayload)
                }

                const logs = await K8TaskService.getTaskLogs(currentTask.job_id)
                // eslint-disable-next-line no-console
                console.info(`${currentTask.job_id} -> logs:`)
                // eslint-disable-next-line no-console
                console.info(logs)

                updatedTasks = updatedTasks.filter((item: JobHistoryModel) => item.id !== currentTask.id)
                somethingChanged = true
              }
            }

            // only for local debugging
            if (task && import.meta.env.NODE_ENV !== 'production' && currentTask.imitateProgress) {
              if (!currentTask.total_steps) {
                store.commit('updateObjectInStore', {
                  fieldObject: currentTask,
                  key: 'total_steps',
                  value: 100,
                } as StoreDataChangePayload)
              }
              if (currentTask.progress === undefined) {
                store.commit('updateObjectInStore', {
                  fieldObject: currentTask,
                  key: 'progress',
                  value: 0,
                } as StoreDataChangePayload)
              }
              else {
                let progress = currentTask.progress + (Math.random() * 5 + 1)
                progress = Math.min(100, progress)

                store.commit('updateObjectInStore', {
                  fieldObject: currentTask,
                  key: 'progress',
                  value: progress,
                } as StoreDataChangePayload)
              }
            }
            else if (task) {
              store.commit('updateObjectInStore', {
                fieldObject: currentTask,
                key: 'total_steps',
                value: task.total_steps,
              } as StoreDataChangePayload)

              store.commit('updateObjectInStore', {
                fieldObject: currentTask,
                key: 'progress',
                value: task.progress,
              } as StoreDataChangePayload)
            }

            if (task && currentTask.job_status !== task.job_status) {
              store.commit('updateObjectInStore', {
                fieldObject: currentTask,
                key: 'job_status',
                value: task.job_status,
              } as StoreDataChangePayload)

              if (currentTask.job_status === TASK_STATUS.SUCCEEDED || currentTask.job_status === TASK_STATUS.FAILED) {
                if (currentTask.job_status === TASK_STATUS.SUCCEEDED) {
                  Global.success(currentTask.taskSucceededText || 'Task complete')
                  // eslint-disable-next-line no-console
                  console.info(`Task successfully completed: ${currentTask.job_id}`)
                }
                if (currentTask.job_status === TASK_STATUS.FAILED) {
                  Global.warning(currentTask.taskFailedText || 'Task failed')

                  console.warn(`Task failed: ${currentTask.job_id}`)
                }

                K8TaskService.listeners.forEach((element: TaskListener) => {
                  element.listener(currentTask)
                })

                if (currentTask.onComplete && typeof currentTask.onComplete === 'function') {
                  currentTask.onComplete(currentTask)
                }

                if (currentTask.messageRef) {
                  currentTask.messageRef.destroy()
                  store.commit('updateObjectInStore', {
                    fieldObject: currentTask,
                    key: 'message',
                    value: null,
                  } as StoreDataChangePayload)
                }

                const logs = await K8TaskService.getTaskLogs(currentTask.job_id)
                // eslint-disable-next-line no-console
                console.info(`${currentTask.job_id} -> logs:`)
                // eslint-disable-next-line no-console
                console.info(logs)

                updatedTasks = updatedTasks.filter((item: JobHistoryModel) => item.id !== currentTask.id)
                somethingChanged = true
              }
            }

            updatedTasks = updatedTasks.map((item: JobHistoryModel) => {
              if (item.id !== currentTask.id) { return item }
              return {
                ...item,
                job_status: currentTask.job_status,
              } as JobHistoryModel
            })
            somethingChanged = true
          }

          if (somethingChanged) { store.commit('jobTasks', updatedTasks) }
        }
      }

      if (updatedTasks.length > 0) { K8TaskService.pullStatuses() }
    }, POLLING_DELAY)
  }

  static registerListener(listener: (task: JobHistoryModel) => (void | Promise<void>), name: string): void {
    K8TaskService.listeners.push({ name, listener } as TaskListener)
  }

  static unregisterListener(name: string): void {
    K8TaskService.listeners = K8TaskService.listeners.filter((element: TaskListener) => {
      return element.name !== name
    })
  }
}
