import { UserModel } from '@/users/models/server/UserModel'

export type UserStateType = UserModel & {
  userObject: UserModel | null
}

const state: UserStateType = {
  id: 0,
  username: '',
  email: '',
  picture: '',
  provider: '',
  confirmed: false,
  blocked: true,
  tos_accepted: false,
  is_read_only: false,
  role: '',
  allClients: null,
  clients: null,
  userObject: null
}

export default state
