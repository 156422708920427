import type { MutationTree } from 'vuex'

import type { UserNotificationModel } from '@/notifications/models/server/UserNotification'
import type { UserNotificationsStateType } from '@/notifications/store/state'

const mutations: MutationTree<UserNotificationsStateType> = {
  setUserNotifications(state: UserNotificationsStateType, notifications: UserNotificationModel[]) {
    state.notifications = [...notifications]
  },
}

export default mutations
