import { TrashIcon } from '@heroicons/vue/24/outline'
import { LockClosedIcon } from '@heroicons/vue/24/solid'
import type { TreeOption } from 'naive-ui'
import { NEllipsis, NSpin } from 'naive-ui'
import type { Commit } from 'vuex'

import BasicLink from '@shared/components/basic/BasicLink.vue'
import BasicSelect from '@shared/components/basic/BasicSelect.vue'
import ColumnNameAndTypeRender from '@shared/components/basic/ColumnNameAndTypeRender.vue'
import TableEditableDatetime from '@shared/components/basic/TableEditableDatetime.vue'
import TableEditableInput from '@shared/components/basic/TableEditableInput.vue'
import TableEditableRange from '@shared/components/basic/TableEditableRange.vue'
import TableEditableSelect from '@shared/components/basic/TableEditableSelect.vue'
import TableErrorText from '@shared/components/basic/TableErrorText.vue'
import TableMultipleTagsInput from '@shared/components/basic/TableMultipleTagsInput.vue'
import UneditableTags from '@shared/components/basic/UneditableTags.vue'
import TooltipSpan from '@shared/components/basic/TooltipSpan.vue'
import AugmentRecsFailedMessage from '@shared/components/messages/AugmentRecsFailedMessage.vue'
import DeliverRecsFailedMessage from '@shared/components/messages/DeliverRecsFailedMessage.vue'
import JoinAssetsFailedMessage from '@shared/components/messages/JoinAssetsFailedMessage.vue'
import PredictFailedMessage from '@shared/components/messages/PredictFailedMessage.vue'
import PreprocessFailedMessage from '@shared/components/messages/PreprocessFailedMessage.vue'
import TransformAssetsFailedMessage from '@shared/components/messages/TransformAssetsFailedMessage.vue'
import ValidationFailedMessage from '@shared/components/messages/ValidationFailedMessage.vue'
import ValidationSuccessMessage from '@shared/components/messages/ValidationSuccessMessage.vue'
import type { TooltipContent } from '@assets/help/tooltips'
import { REGIME_OFFERFIT } from '@shared/data/constants'
import { store } from '@app/store'
import type {
  DatetimePickerTypes,
  NullString,
  SelectItem,
  StoreDataChangePayload,
  StoreRangeDataChangePayload,
} from '@shared/utils/Types'
import { getExistingValueFromArguments, isEmpty, validateEmailArray } from '@shared/utils/helpers'
import { getStandardizedString } from '@shared/utils/transformHelpers'
import SelectExclusionDateRender from '@/reporting/shared/components/SelectExclusionDateRender.vue'
import InsightsTableChartRender from '@/reporting/insights/components/InsightsTableChartRender.vue'
import ActionDetailsRender from '@/reporting/modelPreferences/components/ActionDetailsRender.vue'
import InsightsTableCustomerRender from '@/reporting/insights/components/InsightsTableCustomerRender.vue'
import SeverityRender from '@/reporting/validio/components/SeverityRender.vue'
import ValidioTableExpandRender from '@/reporting/validio/components/ValidioTableExpandRender.vue'
import AssetValidationTableNameDescriptionRender from '@/data-assets/components/AssetValidationTableNameDescriptionRender.vue'
import DataAssetsTableMetadataRender from '@/data-assets/components/DataAssetsTableMetadataRender.vue'
import DataAssetsTableNameDescriptionRender from '@/data-assets/components/DataAssetsTableNameDescriptionRender.vue'
import TransformFormulaRender from '@/data-assets/components/transform/TransformFormulaRender.vue'
import DeploymentActionsRender from '@/deployments/components/DeploymentActionsRender.vue'
import DeploymentItemRender from '@/deployments/components/DeploymentItemRender.vue'
import DeploymentRender from '@/deployments/components/DeploymentRender.vue'
import DeploymentStatusRender from '@/deployments/components/DeploymentStatusRender.vue'
import DeploymentsExpandedView from '@/deployments/components/DeploymentsExpandedView.vue'
import DescriptionRender from '@/pipelines/components/engineer-features/DescriptionRender.vue'
import FeatureDetailsRender from '@/pipelines/components/engineer-features/FeatureDetailsRender.vue'
import FilterRender from '@/filtering/components/FilterRender.vue'
import ReportingTableLinks from '@/reporting/home-page/components/ReportingTableLinks.vue'
import DeploymentSuccessMessage from '@/deployments/components/DeploymentSuccessMessage.vue'
import FeatureCalculationTaskFailedMessage from '@/dags/components/messages/FeatureCalculationTaskFailedMessage.vue'
import FeatureStatisticsTaskFailedMessage from '@/dags/components/messages/FeatureStatisticsTaskFailedMessage.vue'
import ControlGroupTypeRender from '@/usecases/components/audience/ControlGroupTypeRender.vue'
import EventSequenceRender from '@/usecases/components/events/EventSequenceRender.vue'

import type { CustomerGroupModel } from '@/usecases/models/CustomerGroupModel'
import type { PerChannelTableDataModel } from '@/usecases/models/EventsModel'
import type { MatchingOperator } from '@/filtering/models/FilterModel'
import type { TransformationConfigModel } from '@/data-assets/models/TransformationConfigModel'
import type { DeploymentsTableModel, DiffModalEmitObject } from '@/deployments/models/server/DeploymentModel'
import type { PipelineModel } from '@/pipelines/models/server/PipelineModel'
import type {
  DatesToExcludeSelectionEmit,
  DatesToExcludeTableData,
  PerformanceInsightsModel,
  UseCaseEmailReportModel,
  ValidoAlertsDataModel,
} from '@/reporting/models/server/ReportingModel'
import type { OptionsData } from '@/reporting/modelPreferences/composables/modelPreferencesReport'

/* eslint-disable ts/no-unsafe-return */

export function getLinkButtonRender(dataRow: any, text: string, linkTo: string | null, clickHandler?: (arg: any) => void): VNode {
  let tag: any = BasicLink
  const linkOptions: any = {
    class: 'text-link hover:text-blue-700 cursor-pointer',
  }

  if (linkTo) {
    linkOptions.to = linkTo
  }
  else {
    tag = 'span'
  }

  if (typeof clickHandler === 'function') {
    linkOptions.onClick = () => clickHandler(dataRow)
  }

  return h(tag, linkOptions, {
    default: () => text,
  })
}

export interface EditableSelectRender {
  options: SelectItem[] | ComputedRef<SelectItem[]>
  row: any
  index: number
  fieldName: string
  commitType?: string
  emitChangeEvent?: (payload: StoreDataChangePayload) => void
  error?: string
  storeItemFieldName?: string
  isLoading?: ComputedRef<boolean> | Ref<boolean> | boolean
  heightClass?: string
  placeholder?: string
}

export function getEditableSelectRender(
  { options, row, index, fieldName, commitType, emitChangeEvent, error, storeItemFieldName, isLoading, heightClass, placeholder }: EditableSelectRender,
): VNode {
  if (!row) { return h('span') }

  if ((isRef(isLoading) && isLoading.value) || (!isRef(isLoading) && isLoading)) {
    return h(
      'div',
      {
        class: 'flex items-center justify-center',
      },
      {
        default: () => [
          h(NSpin, {
            size: 16,
          }),
        ],
      },
    )
  }

  return h(TableEditableSelect, {
    'id': `${getStandardizedString(row.name || row[fieldName])}_${String(fieldName)}_${index}`,
    'options': (options as ComputedRef<SelectItem[]>).value || options,
    'class': heightClass || 'h-7',
    'modelValue': row[fieldName],
    'placeholder': placeholder,
    'ellipsis': true,
    'error': error || undefined,
    'onUpdate:modelValue': (value: any) => {
      const payload: StoreDataChangePayload<typeof row> = {
        fieldObject: storeItemFieldName ? row[storeItemFieldName] : row,
        key: fieldName,
        value,
      }

      if (commitType) {
        store.commit(commitType, payload)
      }

      typeof emitChangeEvent === 'function' && emitChangeEvent(payload)
    },
  })
}

export function getEventsSelectRender(
  options: SelectItem[],
  row: any,
  index: number,
  fieldName: string,
  emitChangeEvent: (payload: any) => void,
  multiple: boolean,
  tag?: boolean,
  placeholder?: string,
  error?: string,
): VNode {
  if (!row) { return h('span') }

  return h(BasicSelect, {
    'id': `${getStandardizedString(row.name || row[fieldName])}_${fieldName}_${index}`,
    'options': options,
    'modelValue': row[fieldName],
    'placeholder': placeholder || undefined,
    'multiple': multiple,
    'size': 'small',
    'tag': tag || undefined,
    'error': error,
    'onUpdate:modelValue': (value: string) => {
      const payload: any = {
        fieldObject: row,
        value,
      } as StoreDataChangePayload
      emitChangeEvent(payload)
    },
  })
}

export function getYesNoEditableSelectRender(
  row: any,
  index: number,
  fieldName: string,
  commitType?: string,
  emitChangeEvent?: (payload: StoreDataChangePayload) => void,
  revert: boolean = false,
): VNode {
  if (!row) { return h('span') }

  return h(TableEditableSelect, {
    'id': `${getStandardizedString(row.name || row[fieldName])}_${fieldName}_${index}`,
    'options': [
      { value: 'Yes', label: 'Yes' },
      { value: 'No', label: 'No' },
    ],
    'smallText': true,
    'modelValue': computed<string>(() => {
      const answers = ['Yes', 'No']
      if (revert) { answers.reverse() }
      return row[fieldName] ? answers[0] : answers[1]
    }).value,
    'placeholder': null,
    'onUpdate:modelValue': (value: string) => {
      const answers = ['Yes', 'No']
      if (revert) { answers.reverse() }

      const payload: StoreDataChangePayload = {
        fieldObject: row,
        key: fieldName,
        value: value === answers[0],
      }
      if (commitType) {
        store.commit(commitType, payload)
      }
      if (typeof emitChangeEvent === 'function') {
        emitChangeEvent(payload)
      }
    },
  })
}

export function requiredFieldTableRenderer(
  row: any,
  index: number,
  commit: Commit,
  computedRef: ComputedRef,
  assetStoreObject: any,
  dropColumnsRef: ComputedRef,
  emitChangeEvent?: (payload: StoreDataChangePayload) => void,
): VNode {
  return h(TableEditableSelect, {
    'id': `${row.name}_required_${index}`,
    'options': [
      { value: 'Yes', label: 'Yes' },
      { value: 'No', label: 'No' },
    ],
    'smallText': true,
    'modelValue': computedRef.value ? 'Yes' : 'No',
    'placeholder': null,
    'onUpdate:modelValue': (value: 'Yes' | 'No') => {
      let newDropColumnsValue = []

      if (value === 'No') {
        newDropColumnsValue = dropColumnsRef.value?.filter((item: string) => item !== row.name)
      }
      else if (!dropColumnsRef.value.includes(row.name)) {
        newDropColumnsValue = [...dropColumnsRef.value, row.name]
      }

      const payload: StoreDataChangePayload = {
        fieldObject: assetStoreObject,
        key: 'drop_cols',
        value: newDropColumnsValue,
      }
      commit('updateObjectInStore', payload)
      if (typeof emitChangeEvent === 'function') {
        emitChangeEvent(payload)
      }
    },
  })
}

export function primaryKeyFieldTableRenderer(
  row: any,
  index: number,
  commit: Commit,
  computedRef: ComputedRef,
  assetStoreObject: any,
  pkColumnsRef: ComputedRef,
  emitChangeEvent?: (payload: StoreDataChangePayload) => void,
): VNode {
  return h(TableEditableSelect, {
    'id': `${row.name}_required_${index}`,
    'options': [
      { value: 'Yes', label: 'Yes' },
      { value: 'No', label: 'No' },
    ],
    'smallText': true,
    'modelValue': computedRef.value ? 'Yes' : 'No',
    'placeholder': null,
    'onUpdate:modelValue': (value: 'Yes' | 'No') => {
      let newPrimaryColumnsValue = []
      if (value === 'No') {
        newPrimaryColumnsValue = pkColumnsRef.value?.filter((item: string) => item !== row.name)
      }
      else if (!pkColumnsRef.value?.includes(row.name)) {
        newPrimaryColumnsValue = pkColumnsRef.value ? [...pkColumnsRef.value, row.name] : [row.name]
      }
      const payload: StoreDataChangePayload = {
        fieldObject: assetStoreObject,
        key: 'pk_cols',
        value: newPrimaryColumnsValue,
      }
      commit('updateObjectInStore', payload)
      if (typeof emitChangeEvent === 'function') {
        emitChangeEvent(payload)
      }
    },
  })
}

export function getTextEditableRender(
  row: any,
  index: number,
  fieldName: string,
  commitType?: string,
  emitChangeEvent?: (payload: StoreDataChangePayload) => void,
  showInput: boolean = false,
  numeric: boolean = false,
  float: boolean = false,
): VNode {
  if (!row) { return h('span') }

  return h(TableEditableInput, {
    'id': `${getStandardizedString(row.name || row[fieldName])}_${fieldName}_${index}`,
    'type': 'text',
    'class': 'h-7',
    'modelValue': row[fieldName],
    'ellipsis': true,
    'error': null,
    'showInput': showInput,
    'numeric': numeric,
    'float': float,
    'onUpdate:modelValue': (value: string) => {
      const payload: StoreDataChangePayload = {
        fieldObject: row,
        key: fieldName,
        value: float ? Number.parseFloat(value) : value,
      }
      if (commitType) {
        store.commit(commitType, payload)
      }
      if (typeof emitChangeEvent === 'function') {
        emitChangeEvent(payload)
      }
    },
  })
}

export function getDateTimeEditableRender(
  row: any,
  index: number,
  fieldName: string,
  commitType?: string,
  emitChangeEvent?: (payload: StoreDataChangePayload) => void,
  pickerType: DatetimePickerTypes = 'date',
  format: string = 'yyyy-MM-dd',
  showInput: boolean = false,
  getFormattedValue: boolean = false,
  useDisableDate: boolean = false,
  disabledDates: (ts: number) => boolean = () => false,
): VNode {
  if (!row) { return h('span') }

  return h(TableEditableDatetime, {
    'id': `datetime_${index}`,
    pickerType,
    'modelValue': row[fieldName],
    'emptyPlaceholder': '-',
    'class': 'h-7',
    'format': format,
    'showInput': showInput,
    'size': 'small',
    getFormattedValue,
    useDisableDate,
    disabledDates,
    'onUpdate:modelValue': (value: string) => {
      const payload: StoreDataChangePayload = {
        fieldObject: row,
        key: fieldName,
        value,
      }
      if (commitType) {
        store.commit(commitType, payload)
      }
      if (typeof emitChangeEvent === 'function') {
        emitChangeEvent(payload)
      }
    },
  })
}

export function getTextEditableForStoreFieldRender(
  id: string,
  currentValue: any,
  storeObject: any,
  fieldName: string,
  emitChangeEvent?: (payload: StoreDataChangePayload) => void,
  standardized: boolean = false,
  showInput: boolean = false,
  overflowHidden: boolean = false,
): VNode {
  return h(TableEditableInput, {
    'id': id,
    'type': 'text',
    'class': 'h-7',
    'overflowHidden': overflowHidden,
    'modelValue': currentValue,
    'ellipsis': true,
    'standardized': standardized,
    'error': null,
    'showInput': showInput,
    'onUpdate:modelValue': (value: string) => {
      const payload: StoreDataChangePayload = {
        fieldObject: storeObject,
        key: fieldName,
        value,
      }
      if (value) {
        store.commit('updateObjectInStore', payload)
      }
      else {
        delete payload.value
        store.commit('removeObjectInStore', payload)
      }
      if (typeof emitChangeEvent === 'function') {
        emitChangeEvent(payload)
      }
    },
  })
}

export function detailsRender(row: any) {
  return h(FeatureDetailsRender, {
    typeSpecific: row.typeSpecific,
    featureType: row.type,
    allSome: 'and',
  })
}

export function tableErrorTextRenderer(title: string, tooltipText: string, isError: boolean = true) {
  return h(TableErrorText, {
    title,
    tooltipText,
    isError,
    isWarning: !isError,
  })
}

export function filterRender(filter: any, logicalOperator?: MatchingOperator, descriptiveMode = false) {
  return h(FilterRender, {
    expression: filter,
    descriptiveMode,
    logicalOperator,
  })
}

export function transformFormulaRender(transform: TransformationConfigModel) {
  return h(TransformFormulaRender, {
    transformConfig: transform,
  })
}

export function renderUneditableTags(array: string[]) {
  return h(UneditableTags, {
    tagsArray: array,
  })
}

export function validationSuccessMessageRender(): VNodeChild {
  return h(ValidationSuccessMessage)
}
export function validationFailedMessageRender(): VNodeChild {
  return h(ValidationFailedMessage)
}
export function preprocessFailedMessageRender(): VNodeChild {
  return h(PreprocessFailedMessage)
}
export function predictFailedMessageRender(): VNodeChild {
  return h(PredictFailedMessage)
}
export function deliverRecsFailedMessageRender(): VNodeChild {
  return h(DeliverRecsFailedMessage)
}
export function augmentRecsMessageRender(): VNodeChild {
  return h(AugmentRecsFailedMessage)
}
export function joinAssetsFailedMessageRender(): VNodeChild {
  return h(JoinAssetsFailedMessage)
}
export function transformAssetsFailedMessageRender(): VNodeChild {
  return h(TransformAssetsFailedMessage)
}
export function featureCalculatingFailedMessageRender(): VNodeChild {
  return h(FeatureCalculationTaskFailedMessage)
}
export function featureStatisticsFailedMessageRender(): VNodeChild {
  return h(FeatureStatisticsTaskFailedMessage)
}
export function deploymentSuccessMessageHandler(): VNodeChild {
  return h(DeploymentSuccessMessage)
}

export function descriptionRender(row: any) {
  return h(DescriptionRender, {
    typeSpecific: row.typeSpecific,
    featureDescription: row.description,
    daysBack: row.days_back,
    featureType: row.type,
    inModal: false,
    allSome: 'and',
  })
}

interface MultipleTagRenderOptions {
  maxWidth?: string
  placeholder?: string
  maxTagCount?: number
  validate?: (value: string[]) => boolean
}

export function getMultipleTagRender(
  row: any,
  fieldName: string,
  options: MultipleTagRenderOptions,
  commitType?: string,
  emitChangeEvent?: (payload: StoreDataChangePayload) => void,
): VNode {
  return h(TableMultipleTagsInput, {
    'placeholder': options.placeholder,
    'modelValue': row[fieldName],
    'maxTagCount': options.maxTagCount || 3,
    'widthStyle': options.maxWidth,
    'onUpdate:modelValue': (value: any[]) => {
      if (options.validate && !options.validate(value)) { return }
      const payload: StoreDataChangePayload = {
        fieldObject: row,
        key: fieldName,
        value,
      }
      if (commitType) {
        store.commit(commitType, payload)
      }
      if (typeof emitChangeEvent === 'function') {
        emitChangeEvent(payload)
      }
    },
  })
}

export function getMultipleEmailTagRender(
  row: any,
  fieldName: string,
  maxWidth: string,
  commitType?: string,
  emitChangeEvent?: (passFail: boolean) => void,
): VNode {
  return h(TableMultipleTagsInput, {
    'placeholder': 'Add email',
    'modelValue': row[fieldName],
    'widthStyle': maxWidth,
    'onUpdate:modelValue': (value: string[]) => {
      const result = validateEmailArray(value)
      // TODO: emitChangeEvent should receive a payload instead of validation result
      // if validation is required, it must be done in the parent component

      if (typeof emitChangeEvent === 'function') {
        emitChangeEvent(result)
      }

      if (result) {
        const payload: StoreDataChangePayload = {
          fieldObject: row,
          key: fieldName,
          value,
        }
        if (commitType) {
          store.commit(commitType, payload)
        }
      }
    },
  })
}

export function getPerformanceReportEmailTagRender(
  row: UseCaseEmailReportModel,
  emitChangeEvent: (passFail: boolean) => void,
  emitValidatedChangeEvent: (payload: StoreDataChangePayload<UseCaseEmailReportModel>) => void,
): VNode {
  return h(TableMultipleTagsInput, {
    'placeholder': 'Add email',
    'modelValue': row.recipients,
    'widthStyle': '500px',
    'onUpdate:modelValue': (value: string[]) => {
      emitChangeEvent(validateEmailArray(value))
      if (validateEmailArray(value)) {
        const payload: StoreDataChangePayload<UseCaseEmailReportModel> = {
          fieldObject: row,
          key: 'recipients',
          value,
        }
        emitValidatedChangeEvent(payload)
      }
    },
  })
}

export function getRangeEditableRender(
  row: any,
  index: number,
  leftLabel: string,
  rightLabel: string,
  fieldLeft: string,
  fieldRight: string,
  commitType?: string,
  error?: string,
  emitChangeEvent?: (payload: StoreRangeDataChangePayload) => void,
): VNode {
  return h(TableEditableRange, {
    'id': `${fieldLeft}_${fieldRight}_${index}`,
    'modelValue': row,
    'class': 'h-7',
    'leftValue': row[fieldLeft]?.toString(),
    'rightValue': row[fieldRight]?.toString(),
    'leftLabel': leftLabel,
    'rightLabel': rightLabel,
    'error': error,
    'onUpdate:values': (value: { leftValue: string, rightValue: string }) => {
      const payload: StoreRangeDataChangePayload = {
        fieldObject: row,
        keyLeft: fieldLeft,
        keyRight: fieldRight,
        valueLeft: !isEmpty(value.leftValue) ? Number(value.leftValue) : null,
        valueRight: !isEmpty(value.rightValue) ? Number(value.rightValue) : null,
      }
      if (commitType) {
        store.commit(commitType, payload)
      }
      if (typeof emitChangeEvent === 'function') {
        emitChangeEvent(payload)
      }
    },
  })
}

export function getPercentsEditableRender(
  row: any,
  index: number,
  fieldName: string,
  commitType?: string,
  error?: string,
  emitChangeEvent?: (payload: StoreDataChangePayload) => void,
): VNode {
  if (!row) { return h('span') }

  return h(TableEditableInput, {
    'id': `${getStandardizedString(row.name || row[fieldName])}_${fieldName}_${index}`,
    'type': 'text',
    'class': 'h-7',
    'modelValue': row[fieldName],
    'numeric': true,
    'suffix': '%',
    'maximumValue': 100,
    'multiplier': 100,
    'error': error,
    'onUpdate:modelValue': (value: string | number) => {
      const payload: StoreDataChangePayload = {
        fieldObject: row,
        key: fieldName,
        value,
      }
      if (commitType) {
        store.commit(commitType, payload)
      }
      if (typeof emitChangeEvent === 'function') {
        emitChangeEvent(payload)
      }
    },
  })
}

export function spanWithTooltip(data: any, key?: string | number) {
  const value = getExistingValueFromArguments(!isEmpty(key) ? data[key || 0] : data, '')
  const copyableDirective = resolveDirective('copyable') || {}

  return withDirectives(h('span', { title: value }, { default: () => value }), [[copyableDirective, value]])
}

export function spanWithTooltipForArray(data: any, key: number) {
  const value = data[key] || ''

  if (value === '') { return '' }

  const copyableDirective = resolveDirective('copyable') || {}

  return withDirectives(h('span', { title: value }, { default: () => value }), [[copyableDirective, value]])
}

export function spanWithInfoTooltip(text: NullString, info: string, hasIcon?: boolean, noWrap?: boolean) {
  return h(TooltipSpan, {
    text,
    tooltipContent: { text: info } as TooltipContent,
    hasIcon,
    noWrap,
    widthClass: 'w-60',
  })
}

export function spanWithInfoTooltipContent(rowItem: any, content: TooltipContent) {
  return h(TooltipSpan, {
    text: rowItem,
    tooltipContent: content,
    hasIcon: true,
  })
}

export function deleteIconRender<T = unknown>(deleteClickHandler?: (row: T) => void, row?: T, deleteTitle: string = 'Delete') {
  return h(
    'div',
    {
      class: 'cursor-pointer',
      title: deleteTitle,
      onClick: (e: PointerEvent) => {
        typeof deleteClickHandler === 'function' && deleteClickHandler(row)
        e.stopPropagation()
      },
    },
    [h(TrashIcon, { class: 'w-5 h-5 text-gray-400 hover:text-red-300' })],
  )
}

export function lockIconRender(title?: string) {
  return h(
    'div',
    {
      class: 'cursor-not-allowed flex justify-center items-center',
      title,
    },
    [h(LockClosedIcon, { class: 'w-4 h-4 text-gray-500 bg-gray-50 cursor-not-allowed', title })],
  )
}

export function lockedTableRender(placeholder?: string, heightClass = 'h-4') {
  return h(
    'div',
    {
      class: 'w-full cursor-not-allowed flex justify-start items-center border border-gray-200 rounded-md text-gray-500 bg-gray-50 px-2',
      title: placeholder,
    },
    [h(LockClosedIcon, { class: `w-4 ${heightClass} mr-1` }), h('span', {}, [placeholder])],
  )
}

export function assetHistoryMetadataRender(row: any, lastModified: string, lastRunDate: string, lastRunResult: string, tab: string) {
  return h(DataAssetsTableMetadataRender, {
    rowData: row,
    lastModified,
    lastRunDate,
    lastRunResult,
    tab,
  })
}

export function eventSequenceRender(rowItem: PerChannelTableDataModel) {
  return h(EventSequenceRender, { channel: rowItem.channel, sequence: rowItem.sequence })
}

export function dataAssetsTableNameDescriptionRender(row: any) {
  return h(DataAssetsTableNameDescriptionRender, {
    name: row.display_name || row.key,
    link: `/data-asset/${row.key}`,
    description: row.description,
    isSampleData: !row.file_name_format,
  })
}

export function pipelinesTableNameDescriptionRender(row: PipelineModel) {
  return h(DataAssetsTableNameDescriptionRender, {
    name: row.name,
    link: `/?pipeline=${row.name}`,
  })
}

export function assetValidationTableNameDescriptionRender(row: any, index: number, key: string, storeObject: any, emitChangeEvent?: (payload: StoreDataChangePayload) => void) {
  return h(AssetValidationTableNameDescriptionRender, {
    'name': row.name || key,
    'description': row.description,
    'fieldName': key,
    index,
    'onUpdate:modelValue': (value: string) => {
      const payload: StoreDataChangePayload = {
        fieldObject: storeObject,
        key,
        value,
      }
      if (value) {
        store.commit('updateObjectInStore', payload)
      }
      else {
        delete payload.value
        store.commit('removeObjectInStore', payload)
      }
      if (typeof emitChangeEvent === 'function') {
        emitChangeEvent(payload)
      }
    },
  })
}

export function reportingTableLinksRender(row: any) {
  return h(ReportingTableLinks, {
    pathName: row.pathName,
    download: row.download,
  })
}

export function deploymentItemRender(item: TreeOption) {
  return h(DeploymentItemRender, {
    item,
  })
}

export function controlGroupTypeRender(row: CustomerGroupModel, isNonIntervention: boolean) {
  return h(ControlGroupTypeRender, {
    group: row.name === REGIME_OFFERFIT ? 'Treatment' : 'Control',
    isNonIntervention,
  })
}

export function tableClickableHeader<T>(data: T, key?: keyof T, required: boolean = false, description: NullString = null, onClickHandler?: () => void) {
  const headerValue = key ? data[key] : data

  const renderProps: {
    title: string
    class: string
    onClick: undefined | ((data: any) => void)
  } = {
    title: `${headerValue.toString()}${description ? `\n\n${description}` : ''}`,
    class: 'underline w-full h-full p-1 cursor-pointer text-offerfit-bright-purple',
    onClick: undefined,
  }

  if (onClickHandler !== undefined) {
    renderProps.onClick = (event) => {
      event.preventDefault()
      event.stopPropagation()
      onClickHandler()
    }
  }

  return h('div', [h('span', renderProps, [(headerValue as any).toString()]), h('sup', [required ? '*' : ''])])
}

export function getEllipsisText(maxLines: number, text: string) {
  return h(
    NEllipsis,
    { expandTrigger: 'click', lineClamp: maxLines, tooltip: { width: 600, delay: 1000 } },
    {
      default: () => text,
      tooltip: () => h('div', { class: 'w-120' }, { default: () => text }),
    },
  )
}

export function columnNameAndTypeRender(columnName: string, type?: string) {
  return h(ColumnNameAndTypeRender, {
    columnName,
    type,
  })
}

export function deploymentRender(row: DeploymentsTableModel) {
  return h(DeploymentRender, { promotion: row })
}

export function deploymentStatusRender(row: DeploymentsTableModel, emitModalOpen: (deployment: DeploymentsTableModel) => void) {
  return h(DeploymentStatusRender, {
    deployment: row,
    onOpenReviewModal: (deployment: DeploymentsTableModel) => {
      emitModalOpen(deployment)
    },
  })
}

export function deploymentExpandView(
  row: DeploymentsTableModel,
  emitAddReviewerModalOpen: (deployment: DeploymentsTableModel) => void,
) {
  return h(DeploymentsExpandedView, {
    row,
    onOpenReviewModal: (deployment: DeploymentsTableModel) => {
      emitAddReviewerModalOpen(deployment)
    },
  })
}

export function deploymentsActionRender(row: DeploymentsTableModel, emitChangeEvent: (payload: DiffModalEmitObject) => void) {
  return h(DeploymentActionsRender, {
    row,
    onEmitConfigObjects: (payload: DiffModalEmitObject) => {
      emitChangeEvent(payload)
    },
  })
}

export function selectExclusionDateRender(
  row: DatesToExcludeTableData,
  text: string,
  type: string,
  emitEvent: (payload: DatesToExcludeSelectionEmit) => Promise<void>,
) {
  return h(SelectExclusionDateRender, {
    item: row,
    text,
    type,
    onDateSave: async (payload: DatesToExcludeSelectionEmit) => {
      await emitEvent(payload)
    },
  })
}

export function validioSeverityRender(severity: number) {
  return h(SeverityRender, {
    severity,
  })
}

export function validioExpansionRender(row: ValidoAlertsDataModel) {
  return h(ValidioTableExpandRender, {
    row,
  })
}

export function insightsTableCustomerRender(row: PerformanceInsightsModel) {
  return h(InsightsTableCustomerRender, {
    row,
  })
}

export function insightsTableChartRender(row: PerformanceInsightsModel) {
  return h(InsightsTableChartRender, {
    row,
  })
}

export function modelPreferencesActionsTooltip(row: OptionsData) {
  return h(ActionDetailsRender, {
    row,
  })
}
