import type { Module } from 'vuex'

import { actions } from './actions'
import getters from './getters'
import mutations from './mutations'
import type { PipelineStateType } from './state'
import state from './state'
import type { AppState } from '@app/store/app'

export const pipeline: Module<PipelineStateType, AppState> = {
  namespaced: true,
  getters,
  mutations,
  actions,
  state,
}
