<template>  
  <div class="loading-skeleton" flex="~ col" gap="8" p="8">
    <NSkeleton height="50px" width="100px" :sharp="false"></NSkeleton>
    <NSkeleton height="20px" width="40%" :sharp="false"></NSkeleton>
    <NSkeleton height="50px" width="90%" :sharp="false"></NSkeleton>
    <NSkeleton height="50px" width="50%" :sharp="false"></NSkeleton>
    <div class="loading-skeleton-columns" flex="~" gap="8">
      <NSkeleton height="50px" :sharp="false"></NSkeleton>
      <NSkeleton height="50px" :sharp="false"></NSkeleton>
      <NSkeleton height="50px" :sharp="false"></NSkeleton>
    </div>
  </div>
</template>

<script setup lang="ts">
</script>
