<script lang="ts">
const pageSize = 10
</script>
<script lang="ts" setup>
import {type Client} from '@level3/types/client'



type Props = {
  clients: Client[]
  userClients: Client[]
  isLoading: boolean
}

withDefaults(defineProps<Props>(), {  })

const search = defineModel<string>('search')

const emit = defineEmits<{
  addClient: [Client]
  removeClient: [Client]
}>()

const filteredClients = computed(() =>
  __props.clients.filter((client) =>
    client.displayName.toLowerCase().includes(search.value!.toLowerCase()),
  ),
)

const page = ref(1)

const pageCount = computed(() =>
  Math.ceil(filteredClients.value.length / pageSize),
)

const clientsPage = computed(() =>
  filteredClients.value.slice(
    (page.value - 1) * pageSize,
    page.value * pageSize,
  ),
)
</script>

<template>
  <div class="py-2">
    <NInput v-model:value="search" placeholder="Search" />
    <div class="min-h-[32rem]">
      <ClientOption
        v-for="client in clientsPage"
        :key="client.name"
        :client="client"
        :user-clients="userClients"
        class-name="my-4"
        @add-client="emit('addClient', client)"
        @remove-client="emit('removeClient', client)"
      />
    </div>
    <NPagination v-model:page="page" :page-count="pageCount" />
  </div>
</template>
