import type { IFilterXSSOptions } from 'xss'
import { FilterXSS, whiteList } from 'xss'

function getXssWhiteList(addTagsAllowed: string[] = [], addAllowedAttributes: string[] = [], customWhiteListObject = {}) {
  const whiteListObject = Object.assign({}, whiteList, customWhiteListObject, addTagsAllowed.reduce((acc, tag) => ({ ...acc, [tag]: [] }), {}))
  const tags = Object.keys(whiteListObject)
  tags.forEach((tag: string) => (whiteListObject[tag] = addAllowedAttributes.concat(whiteListObject[tag] || [])))
  return whiteListObject
}

export const xssPlugin = new FilterXSS({
  whiteList: getXssWhiteList([], ['class']),
} as IFilterXSSOptions)

export const emailPreviewXssPlugin = new FilterXSS({
  stripIgnoreTag: true, // filter out all HTML not in the whitelist
  stripIgnoreTagBody: ['script'],
  allowCommentTag: false,
  css: false,
  whiteList: getXssWhiteList(['html', 'head', 'style', 'body'], ['class', 'style', 'yy'], { table: ['align', 'valign', 'border', 'cellpadding', 'cellspacing', 'width', 'role', 'background', 'bgcolor', 'bordercolor'], tr: ['bgcolor', 'background'], td: ['align', 'valign', 'bgcolor', 'colspan', 'background'] }),
} as IFilterXSSOptions)
