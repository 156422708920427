<script lang="ts">
import { ExclamationCircleIcon, MagnifyingGlassIcon } from '@heroicons/vue/24/solid'
import debounce from 'lodash/debounce'
import type { FormValidationStatus } from 'naive-ui/lib/form/src/interface'
import TooltipSpan from '@shared/components/basic/TooltipSpan.vue'

import type { TooltipContent } from '@assets/help/tooltips'
import { REQUEST_DEBOUNCE_DELAY } from '@shared/data/constants'
import { FocusController } from '@shared/utils/FocusController'
import { xssPlugin } from '@shared/utils/xss'
import { useStore } from '@app/store'
import type { NullString } from '@shared/utils/Types'
import { trackEvent } from '@shared/utils/analytics'
import { stringContainsOnlyDigitsOrEmpty } from '@shared/utils/helpers'
import { getStandardizedString } from '@shared/utils/transformHelpers'

export default defineComponent({
  name: 'BasicInput',
  components: {
    TooltipSpan,
    ExclamationCircleIcon,
    MagnifyingGlassIcon,
  },
  props: {
    id: String as PropType<string>,
    type: {
      type: String as PropType<string>,
      default: 'text',
    },
    tooltipContent: [String, Object, undefined] as PropType<TooltipContent | string | undefined>,
    placeholder: {
      type: String as PropType<string>,
      default: '',
    },
    modelValue: [String, Number] as PropType<NullString | number>,
    label: String as PropType<string>,
    suffix: String as PropType<string>,
    error: String as PropType<NullString>,
    required: Boolean as PropType<boolean>,
    autocomplete: Boolean as PropType<boolean>,
    errorIconEnabled: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    isSearchBar: Boolean as PropType<boolean>,
    onlyNumber: Boolean as PropType<boolean>,
    autoselect: Boolean as PropType<boolean>,
    size: {
      type: String as PropType<'tiny' | 'small' | 'medium' | 'large'>,
      default: 'medium',
    },
    errorIsAbsolutPositioned: Boolean as PropType<boolean>,
    disabled: Boolean as PropType<boolean>,
    ignoreGlobalReadOnly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    standardized: Boolean as PropType<boolean>,
    clearable: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  emits: ['update:modelValue', 'blur', 'input'],
  setup(props, context) {
    const store = useStore()
    const inputRef = ref<any>(null)
    const debouncedFieldChange = debounce(() => {
      if (props.id) {
        // eslint-disable-next-line ts/no-use-before-define
        trackEvent(props.id, { type: 'input', action: localValue.value !== '' ? 'change' : 'clear' })
      }
    }, REQUEST_DEBOUNCE_DELAY)

    const localValue = computed({
      get: () => props.modelValue?.toString(),
      set: (value) => {
        let resultValue: string | number | undefined

        if (typeof props.modelValue === 'number' || props.onlyNumber) {
          resultValue = ['', '+', '-'].includes(value ?? '') ? undefined : Number(value)
        }
        else if (props.standardized) {
          resultValue = getStandardizedString(value ?? '')
        }
        else {
          resultValue = value !== '' ? value : undefined
        }

        context.emit('update:modelValue', resultValue)
        debouncedFieldChange()
      },
    })

    const status: ComputedRef<FormValidationStatus> = computed<FormValidationStatus>(() => {
      if (props.error) {
        return 'error'
      }
      return 'success'
    })

    const globalReadOnlyMode = computed(() => store.getters.isReadonlyMode)

    const isDisabled = computed<boolean>(() => {
      return props.disabled || (!props.ignoreGlobalReadOnly && globalReadOnlyMode.value)
    })

    const focusHandler = ($event: Event) => {
      FocusController.focusedElementReference = inputRef
      if (props.autoselect) {
        ;($event.target as HTMLInputElement)?.select()
      }
    }

    const focus = () => {
      inputRef.value?.focus?.()
    }

    return {
      inputRef,
      localValue,
      status,
      isDisabled,
      focusHandler,
      stringContainsOnlyDigitsOrEmpty,
      focus,
      xssPlugin,
    }
  },
})
</script>

<template>
  <div :class="[!($attrs.class as string)?.includes('w-') ? 'w-full' : '']">
    <div class="w-full" :title="localValue">
      <div class="item-center flex">
        <label v-if="label" :for="id" class="mb-1 block font-medium"> {{ label }}<sup v-show="required">*</sup></label>
        <TooltipSpan
          v-if="tooltipContent"
          :tooltipContent="typeof tooltipContent === 'string' ? { text: tooltipContent } as TooltipContent : tooltipContent"
          hasIcon
        />
      </div>

      <NInput
        :id="id"
        ref="inputRef"
        v-model:value="localValue"
        class="w-full rounded-md shadow-sm"
        type="text"
        :placeholder="placeholder"
        :size="size"
        :allowInput="onlyNumber ? stringContainsOnlyDigitsOrEmpty : null"
        :disabled="isDisabled"
        :status="status"
        :autocomplete="autocomplete"
        :onInput="() => $emit('input')"
        :onBlur="() => $emit('blur')"
        :onFocus="focusHandler"
        :clearable="clearable"
      >
        <template v-if="error && errorIconEnabled" #suffix>
          <TooltipSpan :tooltipContent="{ text: error }" isHtmlContent>
            <template #trigger>
              <ExclamationCircleIcon :title="error" class="h-5 w-5 cursor-pointer text-sm text-red-500" aria-hidden="true" />
            </template>
          </TooltipSpan>
        </template>
        <template v-else-if="isSearchBar" #suffix>
          <MagnifyingGlassIcon class="h-4 w-4 text-sm text-gray-400" aria-hidden="true" />
        </template>
        <template v-else-if="suffix" #suffix>
          {{ suffix }}
        </template>
      </NInput>
    </div>
    <p
      v-if="error && !errorIconEnabled"
      :id="`${id}-error`"
      class="mt-1.5 rounded bg-white p-0.5 text-left text-sm text-red-600"
      :class="{ absolute: errorIsAbsolutPositioned }"
      nosem
      v-html="xssPlugin.process(error)"
    />
  </div>
</template>

<style scoped></style>
