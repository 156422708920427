import { ClientModel } from '@/clients/models/server/ClientModel'
import { JobHistoryModel } from '@/dags/models/server/JobHistoryModel'
import JobHistoryService from '@/dags/services/tasks/JobHistoryService'
import { store } from '@app/store'
import Global from '@shared/utils/global'

const isActivityDrawerOpened: Ref<boolean> = ref<boolean>(false)

export const useTaskLogs = () => {
  const isThereNewLogActivity: Ref<boolean> = ref<boolean>(false)
  const client = store.getters['client/client'] as ClientModel
  const jobHistoryItems: Ref<JobHistoryModel[] | undefined> = ref<JobHistoryModel[] | undefined>()

  const loadLastTaskLogs = async (filter?: string) => {
    if (!client || !client.id) return
    try {
      jobHistoryItems.value = await JobHistoryService.getJobHistoryRecords(client.id, filter)
    } catch (error: any) {
      Global.error(error.message)
    }
  }

  return {
    loadLastTaskLogs,
    isActivityDrawerOpened,
    jobHistoryItems,
    isThereNewLogActivity
  }
}
