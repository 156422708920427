<template>
  <div v-if="featureType === Features.AGGREGATION">
    <span class="font-semibold text-offerfit-bright-purple" v-for="(op, idx) in typeSpecific?.operation" :key="idx">
      {{ op.toUpperCase() }}<span v-if="idx !== typeSpecific?.operation.length - 1">, </span>
    </span>
    <span v-if="typeSpecific?.agg_col"> ({{ typeSpecific?.agg_col }})</span>
  </div>

  <div v-if="featureType === Features.IDENTITY">
    <span class="font-semibold text-offerfit-bright-purple">Return </span>
    <span>{{ typeSpecific?.target_col }}&nbsp;</span>
  </div>

  <div v-if="featureType === Features.ONE_HOT || featureType === Features.ORDINAL">
    <span class="font-semibold text-offerfit-bright-purple">Encode </span>
    <span>{{ typeSpecific?.target_col }}&nbsp;</span>
  </div>

  <div v-if="featureType === Features.COUNT">
    <span class="font-semibold text-offerfit-bright-purple">{{ featureType.toUpperCase() }}</span>
    <span> rows</span>
  </div>

  <div v-if="featureType === Features.BOOLEAN">
    <span><span class="font-semibold text-offerfit-bright-purple">TRUE</span> if filter conditions met</span>
  </div>

  <div v-if="featureType === Features.BUCKET_TIME">
    <span class="font-semibold text-offerfit-bright-purple">Get per </span>
    <span>{{ typeSpecific?.granularity }}&nbsp;</span>
    <span>buckets{{ typeSpecific?.buckets }} </span>
  </div>

  <div v-if="featureType === Features.TIME_SINCE">
    <span>Get </span>
    <span v-if="typeSpecific?.granularity" class="font-semibold text-offerfit-bright-purple"
      >{{ typeSpecific?.granularity }}s&nbsp;</span
    >
    <span v-else>...&nbsp;</span>
    <span>since </span>
    <span v-if="typeSpecific?.order" class="font-semibold text-offerfit-bright-purple">{{ typeSpecific?.order }}</span>
    <span v-else>...</span>
    <span> occurrence </span>
  </div>

  <div v-if="featureType === Features.RATIO">
    <span class="mr-1">{{ typeSpecific?.numerator }}</span>
    <span class="font-semibold text-offerfit-bright-purple">/</span>
    <span class="ml-1">{{ typeSpecific?.denominator }}</span>
  </div>
</template>

<script lang="ts">
import { Features } from '@shared/data/constants'
import { FeatureType, FeatureTypePayload } from '@/data-assets/models/FeaturesModel'

export default defineComponent({
  name: 'featureDetailsRender',
  props: {
    typeSpecific: {
      type: Object as PropType<FeatureTypePayload | undefined>
    },
    featureType: {
      type: String as PropType<FeatureType | undefined>
    },
    allSome: {
      type: String as PropType<string>,
      default: 'and'
    }
  },
  setup() {
    return {
      Features
    }
  }
})
</script>
