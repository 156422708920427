import AppSettings from '@app/AppSettings'

import { TASK_NAME } from '@shared/data/constants'
import { TASK_DISPLAY_NAME_MAP } from '@shared/data/mapAndLists'
import { JobHistoryModel } from '@/dags/models/server/JobHistoryModel'
import K8TaskService from '@/dags/services/tasks/K8TaskService'
import { store } from '@app/store'
import { StrapiDataArray } from '@shared/utils/Types'
import Global from '@shared/utils/global'
import { augmentRecsMessageRender } from '@shared/utils/renderHelpers'

export default class FilterAndAugmentRecsTaskService {
  static RUNNING_MESSAGE: string = '"Augment recs" task is running'
  static SUCCEEDED_MESSAGE: string = '"Augment recs" task completed successfully'
  static FAILED_MESSAGE: () => VNodeChild = augmentRecsMessageRender

  static async startTask(usecase: string): Promise<JobHistoryModel | undefined> {
    if (AppSettings.AUGMENT_RECS_TASKS_ENABLED) {
      try {
        const queryObject: any = {
          client: store.getters['client/client'].name,
          pipeline: store.getters['pipeline/pipeline']?.name.toLowerCase(),
          task: TASK_NAME.AUGMENT_RECS,
          product_env: 'draft',
          usecase,
        }
        const task: JobHistoryModel = await K8TaskService.startTask(
          queryObject,
          FilterAndAugmentRecsTaskService.SUCCEEDED_MESSAGE,
          FilterAndAugmentRecsTaskService.FAILED_MESSAGE,
        )
        task.messageRef = Global.message?.loading(FilterAndAugmentRecsTaskService.RUNNING_MESSAGE, {
          duration: 0,
          closable: false,
        })
        // eslint-disable-next-line no-console
        console.info(`${TASK_DISPLAY_NAME_MAP.get(TASK_NAME.AUGMENT_RECS)} task ${task.job_id} started`)
        store.commit('addTask', task)
        K8TaskService.pullStatuses()

        return task
      }
      catch (error: any) {
        Global.error(error.message || 'FilterAndAugmentRecsTaskService -> startTask: Something went wrong.')
      }
    }
    else {
      Global.message.warning(`Feature disabled: ${TASK_DISPLAY_NAME_MAP.get(TASK_NAME.AUGMENT_RECS)} task`)
    }
  }

  static getRunningMessage(task: JobHistoryModel): string {
    return `${FilterAndAugmentRecsTaskService.RUNNING_MESSAGE} for "${task.usecase?.name}"`
  }

  static async getAllTasks(): Promise<StrapiDataArray<JobHistoryModel>> {
    return K8TaskService.getAllTasks(store.getters['client/client'].id, TASK_NAME.AUGMENT_RECS)
  }
}
