import axios from 'axios'
import type { AxiosCacheInstance } from 'axios-cache-interceptor'
import { setupCache } from 'axios-cache-interceptor'
import * as rax from 'retry-axios'
import axiosThrottle from 'axios-request-throttle'
import { httpClientData, initializeMockAPI, requestInterceptor, responseInterceptor, responseInterceptorError } from './common-http-client'

const axiosInstance = axios.create(httpClientData)

const apiClient: AxiosCacheInstance = setupCache(axiosInstance, {
  ttl: 1000 * 60 * 3, // 3 minutes
})

apiClient.defaults.raxConfig = {
  instance: apiClient,
}

rax.attach(apiClient)

axiosThrottle.use(apiClient, { requestsPerSecond: 10 })

apiClient.interceptors.request.use(requestInterceptor)

apiClient.interceptors.response.use(responseInterceptor, responseInterceptorError)

await initializeMockAPI()

export default apiClient
