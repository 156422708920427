import { SentryError } from '@sentry/utils'
import * as Sentry from '@sentry/vue'
import * as rax from 'retry-axios'
import Global from '@shared/utils/global'
import retryHttp from '@app/utils/http-retry-client'
import { PYTHON_MS_API_PREFIX } from '@shared/data/constants'
import { SERVICE_ERROR_GLOBAL_MESSAGE } from '@/level4/data/l4_constants'
import type { INTEGRATION_PLATFORM } from '@/level4/data/l4_constants'
import type { LLMParsedCtaAndHeroCandidates } from '@/level4/stores/useExperimenterVariantsFormsStore'
import type { HTMLStringWithIdentifiers } from '@/level4/utils/LLM/assignIdentifiersToHtmlString'

const LLM_API_PREFIX = 'llm' as const
const LLM_API_URL = `${PYTHON_MS_API_PREFIX}/${LLM_API_PREFIX}` as const

export class LLMService {
  static async findEmailCtasAndHeroImgs({ html }: { html: HTMLStringWithIdentifiers }, signal?: any) {
    if (!html) { throw new Error('html string is required') }
    const res = await retryHttp.post(`${LLM_API_URL}/find_email_cta_and_hero`, {
      html,
    }, {
      timeout: 3 * 60 * 1000, // 3 minutes
      raxConfig: {
        shouldRetry: (err) => {
          return err.response?.status !== 500
        },
        retry: 2,
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err)
          // eslint-disable-next-line no-console
          console.info(`Retry attempt #${cfg?.currentRetryAttempt}`)
          Sentry.captureException(new SentryError(`"find_email_cta_and_hero" request failed. Retrying (${cfg?.currentRetryAttempt})...`))
        },
      },
      signal,
    })
    return res.data as LLMParsedCtaAndHeroCandidates
  }

  static async getCompanyIndustry(companyName: string) {
    if (!companyName) { throw new Error('companyName string is required') }
    const res = await retryHttp.get(`${LLM_API_URL}/company_information/${companyName}`)
    return res.data as { company_info: string }
  }

  static async classifyLineOfTextWithTags({ text }: { text: string }) {
    const res = await retryHttp.post(`${LLM_API_URL}/classify_subject_line`, {
      subject_line: text,
    })
    return res.data as { [category: string]: string[] }
  }

  static async getTagsForLabel(label: string) {
    try {
      const tags = await LLMService.classifyLineOfTextWithTags({
        text: label,
      })
      return Object.values(tags).flat()
    }
    catch (e: any) {
      Global.error(SERVICE_ERROR_GLOBAL_MESSAGE)
      console.error(e)
      Sentry.captureException(e)
    }
  }

  static async generateVariants({
    type_of_variant,
    company_information,
    platform,
    subject_line,
    call_to_action,
    email_body,
    number_of_variants,
    user_feedback,
    previous_variants,
  }: LLMServiceGenerateVariantsOptions) {
    if (!type_of_variant) { throw new Error('type_of_variant is required') }
    if (!company_information) { throw new Error('company_information is required') }
    if (!platform) { throw new Error('platform is required') }
    if (!subject_line) { throw new Error('subject_line is required') }
    if (!email_body) { throw new Error('email_body is required') }
    if (!number_of_variants) { throw new Error('number_of_variants is required') }
    type responseType = Array<{ tags: string[], text: string }>
    const res = await retryHttp.post<responseType>(`${LLM_API_URL}/generate_variants`, {
      type_of_variant,
      company_information,
      marketing_platform: platform,
      subject_line,
      call_to_action,
      email_body,
      number_of_variants,
      user_feedback,
      previous_variants,
    })
    return res.data
  }
}

export interface LLMServiceGenerateVariantsOptions {
  type_of_variant: 'subject_line' | 'cta'
  company_information: string
  platform: INTEGRATION_PLATFORM
  subject_line: string
  call_to_action?: string
  email_body: string
  number_of_variants: number
  user_feedback?: string
  previous_variants?: string
}
