<template>
  <TransitionRoot as="template" :show="isOpened" appear>
    <HDialog as="div" @close="closeHandler">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-300"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          >
            <SimpleTextarea
              :label="title"
              class="w-full"
              placeholder="Type description here"
              tooltipContent="Description will be reflected on the documentation page"
              v-model="localText"
              :disabled="disabled"
              :rows="5"
            />

            <div class="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex justify-end">
              <SimpleButton
                v-show="Boolean(cancelButtonText)"
                class="mr-2"
                @click="cancelClickHandler"
                :text="cancelButtonText"
                ignoreGlobalReadOnly
              />
              <SimpleButton @click="confirmHandler" :text="confirmButtonText" :danger="danger" :primary="!danger" />
            </div>
          </div>
        </TransitionChild>
      </div>
    </HDialog>
  </TransitionRoot>
</template>

<script lang="ts">
import { Dialog as HDialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'
import SimpleButton from '@shared/components/basic/SimpleButton.vue'
import SimpleTextarea from '@shared/components/basic/SimpleTextarea.vue'

export default defineComponent({
  name: 'TextEditModal',
  components: {
    SimpleTextarea,
    SimpleButton,
    HDialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot
  },
  props: {
    confirmButtonText: {
      type: String as PropType<string>,
      default: 'Save'
    },
    cancelButtonText: {
      type: String as PropType<string>,
      default: 'Cancel'
    },
    danger: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    warning: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    isOpened: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    title: {
      type: String as PropType<string>,
      default: ''
    },
    text: {
      type: String as PropType<string>,
      default: ''
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  emits: ['close', 'onUpdate'],
  setup(props, { emit }) {
    const localText = ref<string>(props.text)
    const originalText = ref<string>(props.text)
    const cancelClickHandler = () => {
      localText.value = originalText.value
      emit('close')
    }
    const closeHandler = () => {
      localText.value = props.text
      emit('close')
    }

    const confirmHandler = () => {
      emit('onUpdate', localText.value)
      emit('close')
    }

    return {
      closeHandler,
      cancelClickHandler,
      confirmHandler,
      localText
    }
  }
})
</script>
