<script setup lang="tsx">
import { useWizardNavigation } from '@/level4/composables/useWizardNavigation'
import { TestEmailsSteps } from '@/level4/data/forms'

const { currentStep, goToModule } = useWizardNavigation()

interface TokensMiniMapStep {
  label?: string
  icon: VNode
  completed: boolean
  tooltip?: string
  step: TestEmailsSteps
}

const testEmailsMinimap: TokensMiniMapStep[] = [
    {
      label: 'Select Variants',
      icon: <div class="i-solar-calendar-outline"></div>,
      completed: false,
      tooltip: `Select variants`,
      step: TestEmailsSteps.VARIANTS,
    },{
      label: 'Select Emails',
      icon: <div class="i-solar-letter-outline"></div>,
      completed: false,
      tooltip: `Select emails`,
      step: TestEmailsSteps.EMAILS,
    },{
      label: 'Summary',
      icon: <div class="i-solar-checklist-outline"></div>,
      completed: false,
      tooltip: `Summary`,
      step: TestEmailsSteps.SUMMARY,
    }
  ]

</script>

<template>
    <div v-for="(formStep, formStepIndex) in testEmailsMinimap" class="step-group flex items-center">
        <MinimapChip
          :label="formStep.label"
          :index="formStepIndex"
          :icon="formStep.icon"
          :isCompleted="formStep.completed"
          :isActive="formStep.step === currentStep"
          @click="wizardNavigation.goToModule(formStep.module[0])"
        />
        <div v-if="formStepIndex < testEmailsMinimap.length - 1" class="i-solar-alt-arrow-right-outline text-sm text-gray-400"></div>
    </div>
</template>
